import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import clipCard from "../../asset/images/WFH.png";
import computerCard from "../../asset/images/saveTM.png";
import CustomButton from "../../ui-component/CustomButton";
import globalTalent from "../../asset/images/jobWorld.webp";
import WorkSolutions from "../../asset/images/work-solution.webp";
import HiringCompany from "../../asset/images/hiring-company.webp";
import RemoteJobs from "../../asset/images/remote-jobs.webp";
import { Link } from "react-router-dom";

const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "150%",
}));

const Image = styled("img")({
  maxWidth: "90%",
  maxHeight: "120%",
  objectFit: "cover",
});

const HeadingText = styled(Typography)(({ theme }) => ({
  fontFamily: "Gordita, sans-serif",
  fontSize: "28px",
  color: "rgba(0, 0, 0, 0.5)",
  fontWeight: "500",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: "Gordita, sans-serif",
  fontSize: "18px",
  fontWeight: "400",
  color: "rgba(0, 0, 0, 0.5)",
  lineHeight: "28px",
  padding: "0 2rem 2rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
}));

const ResponsiveGridItem = styled(Grid)(({ theme }) => ({
  // order: ({ order }) => order,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems:'center',
  [theme.breakpoints.down("md")]: {
    order: 0,
  },
}));
// const NavLink = styled(Typography)(({ theme }) => ({
//   fontSize: "14px",
//   color: "#4F5361",
//   fontWeight: "bold",
//   cursor: "pointer",
//   "&:hover": {
//     color: "#4F5361",
//   },
// }));

const About = () => {
  const sections = [
    {
      title: "Save Your Time and Money",
      body: `AMUS HIRING streamlines your hiring process, saving up to 80% of your managerial time. 
      Skip the hassle of job descriptions, postings, profile searches, shortlisting, and interviews. 
      Find qualified employees with just a few clicks. 
      Reduce your employee costs by up to 60% with remote hiring through AMUS HIRING. `,
      image: computerCard,
    },
    {
      title: "Work Remotely From Anywhere",
      body: `Explore immediate opportunities with remote jobs hiring now. 
      AMUS HIRING connects you with top companies in Europe, the US, Australia, and beyond. 
      Enhance your work-life balance by joining the remote workforce from the comfort of your home or any preferred location. 
      Start your journey in the remote working revolution with AMUS HIRING today. `,
      image: clipCard,
    },
    {
      title: "Global Talent Acquisition",
      body: `As a leading global hiring company, we offer access to a diverse pool of skilled candidates, ensuring you find the perfect fit for your corporate needs. 
      Our extensive network spans multiple industries and geographies, allowing us to source top talent no matter where you are located.  `,
      image: globalTalent,
    },
    {
      title: "Remote Work Solutions",
      body: `Our platform simplifies remote company hiring, 
      providing virtual workforce solutions that meet your business demands. Whether you need technical support, 
      administrative assistance, 
      or specialized roles, we have the resources to help you build a robust remote team.`,
      image: WorkSolutions,
    },
    {
      title: "Best Remote Hiring Company",
      body: `Recognized as the top virtual hiring company, we excel in sourcing and hiring remote employees for various sectors. 
      Our reputation is built on delivering exceptional service and successful placements, 
      making us the preferred choice for businesses worldwide.`,
      image: HiringCompany,
    },
  ];

  const points = [
    {
      title: "IT Talent Acquisition",
      description:
        "Find top IT professionals for project management, development, and support roles. Our IT recruitment services cover a wide range of positions, including software developers, IT support specialists, project managers, and system administrators, ensuring you have the technical expertise you need to drive your business forward.",
      image: "/path/to/it_image.jpg",
    },
    {
      title: "Freight Talent Acquisition",
      description:
        "Specializing in logistics and freight forwarding recruitment services, we connect you with experienced candidates in operations, management, and brokerage. Whether you need supply chain managers, freight brokers, or logistics coordinators, our targeted approach ensures you get the right talent for your logistics needs.",
      image: "/path/to/freight_image.jpg",
    },
    {
      title: "Logistics Recruitment",
      description:
        "From supply chain management to logistics consulting, our recruitment services cover all aspects of logistics hiring. We understand the complexities of the logistics industry and provide tailored solutions to meet your staffing requirements, ensuring your supply chain operates smoothly.",
      image: "/path/to/logistics_image.jpg",
    },
    {
      title: "Virtual Assistants",
      description:
        "Hire virtual assistants to streamline your operations and enhance productivity. Our virtual assistants are skilled in a variety of administrative tasks, including scheduling, email management, data entry, and customer service, allowing you to focus on strategic activities.",
      image: "/path/to/virtual_assistants_image.jpg",
    },
    {
      title: "Work from Home Jobs",
      description:
        "Explore a wide range of remote work opportunities tailored to fit your lifestyle and career goals. We offer flexible job options in various fields, helping professionals find rewarding remote positions that suit their skills and preferences.",
      image: "/path/to/work_from_home_image.jpg",
    },
  ];

  function AddedSection() {
    return (
      <Box
        sx={{
          py: 10,
          backgroundColor: "#fff",
          fontFamily: "Gordita, sans-serif",
        }}
      >
        <Container>
          <HeadingText sx={{ mb: 5, textAlign: "center", color: "#03265B" }}>
            Premier Talent Acquisition, Connecting You with the Best
          </HeadingText>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              {points.slice(0, 3).map((point, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      transition: "transform 0.2s",
                      "&:hover": { transform: "scale(1.02)" },
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h2"
                        sx={{ color: "#03265B" }}
                        fontFamily="Gordita, sans-serif"
                        fontWeight={500}
                      >
                        {point.title}
                      </Typography>
                      <Typography
                        sx={{ flexGrow: 1, color: "rgba(0, 0, 0, 0.5)" }}
                      >
                        {point.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              spacing={4}
              direction={{ xs: "column", sm: "row" }}
              sx={{ mt: 1.5 }}
              justifyContent="center"
            >
              {points.slice(3, 5).map((point, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      transition: "transform 0.2s",
                      "&:hover": { transform: "scale(1.02)" },
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h2"
                        sx={{ color: "#03265B" }}
                        fontFamily="Gordita, sans-serif"
                        fontWeight={500}
                      >
                        {point.title}
                      </Typography>
                      <Typography
                        sx={{ flexGrow: 1, color: "rgba(0, 0, 0, 0.5)" }}
                      >
                        {point.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Container>
        <Grid
          sx={{
            "@media(max-width:768px)": {
              width: "60%",
              pt: 6,
              margin: "auto",
            },
          }}
        >
          <Link
            to="/Employersignup"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <CustomButton
              backgroundColor="#0F1B4C"
              color="#fff"
              buttonText="Hire Now"
              heroBtn={true}
              marginApplied={false}
            />
          </Link>
        </Grid>
      </Box>
    );
  }

  function RemoteSection() {
    return (
      <>
        <Box
          sx={{
            py: 10,
            backgroundColor: "#F5F5F5",
            borderRadius: "10px",
            fontFamily: "Gordita, sans-serif",
          }}
        >
          <Container>
            <Grid
              container
              spacing={5}
              direction={{ xs: "column-reverse", sm: "row-reverse" }}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={12} sm={7} md={7}>
                <br />
                <Typography variant="h2" component="h2" color="#03265B" fontFamily= "Gordita, sans-serif" fontWeight= "500">
                  Explore Remote Job Opportunities
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(3, 38, 91, 0.92)",
                    my: 1,
                    fontFamily: "Gordita, sans-serif",
                    "@media(max-width:768px)": {
                      // textAlign:"center",
                      display: "block",
                    },
                  }}
                >
                  <ul style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                    <li>
                      <strong style={{ color: "#03265B", fontWeight: "600" }}>
                        Immediate Remote Jobs:{" "}
                      </strong>
                      Discover opportunities for immediate hiring in remote
                      positions. Our platform ensures you can fill vacancies
                      swiftly with top-tier talent.
                    </li>
                    <li>
                      <strong style={{ color: "#03265B", fontWeight: "600" }}>
                        Remote Working Arrangements:{" "}
                      </strong>
                      Connect with professionals seeking flexible remote work
                      options. Benefit from a global pool of candidates who are
                      eager to work from any location.
                    </li>
                    <li>
                      <strong style={{ color: "#03265B" , fontWeight: "600"}}>
                        Diverse Remote Job Listings:{" "}
                      </strong>
                      Access a wide range of remote job opportunities across
                      various industries. From IT and logistics to
                      administrative support and project management, find the
                      perfect fit for your business needs.
                    </li>
                    <li>
                      <strong style={{ color: "#03265B", fontWeight: "600" }}>
                        Immediate Work-from-Home Jobs:{" "}
                      </strong>
                      Find work-from-home positions available for immediate
                      hiring. Our platform is designed to help you quickly
                      onboard employees who can start contributing right away.
                    </li>
                  </ul>
                </Typography>
                <Grid
                  sx={{
                    "@media(max-width:768px)": {
                      width: "70%",
                      margin: "auto",
                      // border:"1px solid red"
                    },
                  }}
                >
                  <Link
                    to="/Employeesignup"
                    style={{
                      textDecoration: "none",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingLeft: "1em",
                    }}
                  >
                    <CustomButton
                      backgroundColor="#0F1B4C"
                      color="#fff"
                      buttonText="Apply Now"
                      heroBtn={true}
                      marginApplied={true}
                    />
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <ImgContainer>
                  <img
                    src={RemoteJobs}
                    alt="house"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </ImgContainer>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{ py: 10, bgcolor: "#F5F5F5", fontFamily: "Gordita, sans-serif" }}
      >
        <Container>
          <HeadingText sx={{ mb: 5 }}>
            Why <span style={{ color: "#03265B" }}> AMUS HIRING </span> Stands
            Out Among Remote Job Platforms
          </HeadingText>
          <Grid container spacing={4}>
            {sections.map((section, index) => {
              const isImageFirst = index % 2 === 0;
              return (
                <Box
                  key={index}
                  display="flex"
                  sx={{
                    flexDirection: isImageFirst ? "row" : "row-reverse",
                    "@media(max-width:768px)": {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ResponsiveGridItem item xs={12} md={6}>
                    <Box sx={{ display: "block", alignSelf: "center" }}>
                      <Grid>
                        <HeadingText
                          sx={{
                            color: "#03265B",
                            "@media(max-width:768px)": {
                              fontSize: "1rem",
                              width: "110%",
                              marginBottom: "1rem",
                              textAlign: "center",
                            },
                          }}
                        >
                          {section.title}
                        </HeadingText>
                      </Grid>
                      <BodyText
                        sx={{
                          "@media(max-width:768px)": { textAlign: "center" },
                          textAlign: "justify",
                        }}
                      >
                        {section.body}
                      </BodyText>
                    </Box>
                  </ResponsiveGridItem>
                  <ResponsiveGridItem item xs={12} md={6}>
                    <ImgContainer>
                      <Image src={section.image} alt="section illustration" />
                    </ImgContainer>
                  </ResponsiveGridItem>
                </Box>
              );
            })}
          </Grid>
          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: 6,
              "@media(max-width:768px)": {
                flexDirection: "column",
                gap: 3,
              },
            }}
          >
            <Grid
              sx={{
                "@media(max-width:768px)": {
                  width: "70%",
                  margin: "auto",
                  // border:"1px solid red"
                },
              }}
            >
              <Link to="/Employersignup" style={{ textDecoration: "none" }}>
                <CustomButton
                  backgroundColor="#0F1B4C"
                  color="#fff"
                  buttonText="Hire Now"
                  heroBtn={true}
                  marginApplied={false}
                />
              </Link>
            </Grid>
            <Grid
              sx={{
                "@media(max-width:768px)": {
                  width: "70%",
                  margin: "auto",
                  // border:"1px solid red"
                },
              }}
            >
              <Link to="/Employeesignup" style={{ textDecoration: "none" }}>
                <CustomButton
                  backgroundColor="#0F1B4C"
                  color="#fff"
                  buttonText="Apply Now"
                  heroBtn={true}
                  marginApplied={false}
                />
              </Link>
            </Grid>
          </Box>
        </Container>
      </Box>
      <AddedSection />
      <RemoteSection />
    </>
  );
};

export default About;
