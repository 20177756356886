import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';


const Container=styled.div`

h3{
  font-size: 1.5rem;
  @media (max-width: 768px) {

    font-size: 0.8rem;
  

  }
}
p{
  font-size: 1rem;
  @media (max-width: 768px) {

 font-size: 0.8rem;


}
}

`;

const CookieConsentData = () => {
  return (
    <Container><CookieConsent 
    // debug={true}
    buttonText="Accept"
    cookieName="amushiring"
    style={{
      background: "#03265B",
      textShadow: "2px 2px #03265B",
      display:"flex",
     zIndex:"1999",
      justifyContent:"center",
      alignItems:"center",
      
      
   
    }}
    buttonStyle={{
      background: "white",
      color: "#03265B",
      fontWeight: "bolder",
      borderRadius:"1rem",
      fontSize:"1.2rem"
    //   textShadow: "2px 2px black",
     
      

    }}
    >
        <h3>Cookies</h3>
        <p>This website uses its own and third-party cookies for its correct functioning. By continuing to browse you agree to the use of these technologies and the processing of your data for these purposes <NavLink to={"/cookies-policy"} style={{textDecoration:"none",cursor:"pointer",color:"#f5f5f54c"}}>More information</NavLink> </p>
        </CookieConsent></Container>
  )
}

export default CookieConsentData;