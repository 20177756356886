import { useState, useEffect, useContext } from "react";
// import {
//   Button,
//   Chip,
//   Grid,
//   IconButton,
//   InputAdornment,
//   makeStyles,
//   Paper,
//   TextField,
//   Typography,
//   Modal,
//   Slider,
//   FormControlLabel,
//   FormGroup,
//   MenuItem,
//   Checkbox,
//   Avatar,
// } from "@material-ui/core";
//import { useParams } from "react-router-dom";
// import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

//import { SetPopupContext } from "../../App";
import { SetPopupContext } from "../../../routes/index";

//import apiList, { server } from "../../lib/apiList";
import apiList ,{ server } from "../../authentication/lib/apiList";
import {
 // Avatar,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Rating,
  Typography,
  // makeStyles,
} from "@mui/material";
import ReactHelmet from "component/react-helmet/ReactHelmet";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   statusBlock: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textTransform: "uppercase",
//   },
//   jobTileOuter: {
//     padding: "30px",
//     margin: "20px 0",
//     boxSizing: "border-box",
//     width: "100%",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   avatar: {
//     width: theme.spacing(17),
//     height: theme.spacing(17),
//   },
// }));

const FilterPopup = (props) => {
  // const classes = useStyles();
  const { open, handleClose, searchOptions, setSearchOptions, getData } = props;
  return (
    <Modal open={open} onClose={handleClose} sx={{marginTop:"190px"}}>
      <Paper
        style={{
          padding: "50px",
          outline: "none",
          maxWidth: "50%",
          marginLeft:"26%",
          
        }}
      >
        <Grid container direction="column" alignItems="center" spacing={3} >
          {/* <Grid container item alignItems="center">
            <Grid item xs={3}>
              Application Status
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="rejected"
                      checked={searchOptions.status.rejected}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          status: {
                            ...searchOptions.status,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Rejected"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applied"
                      checked={searchOptions.status.applied}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          status: {
                            ...searchOptions.status,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Applied"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="shortlisted"
                      checked={searchOptions.status.shortlisted}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          status: {
                            ...searchOptions.status,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Shortlisted"
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid container item alignItems="center" sx={{flexDirection:"row",'@media(max-width:768px)':{
            flexDirection:"column"
          }}}>
            <Grid item xs={3} sx={{'@media(max-width:768px)':{
              position:"relative",
              right:"1rem"
            }}}>
              Sort
            </Grid>
            <Grid item container direction="row" xs={9} sx={{'@media(max-width:768px)':{
display:"grid",gridTemplateColumns:"1fr"
            }}}>
              <Grid
                item
                container
                xs={6}
                justify="space-around"
                alignItems="center"
                sx={{ border: "1px solid #D1D1D1", borderRadius: "5px",'@media(max-width:768px)':{
                  display:"grid",
                  gridTemplateColumns:"1fr 1fr 1fr",
                  width:"100%",
                  border:"none",
                  gap:"1rem",
                  position:"relative",
                  right:"4rem"
                 
                  
                  
                } }}
              >
                <Grid item >
                  <Checkbox
                    name="name"
                    checked={searchOptions.sort["jobApplicant.name"].status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "jobApplicant.name": {
                            ...searchOptions.sort["jobApplicant.name"],
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="name"
                  />
                </Grid>
                <Grid item sx={{'@media(max-width:768px)':{
                  width:"3rem"
                }}}>
                  <label for="name">
                    <Typography  sx={{'@media(max-width:768px)':{
                  fontSize:"0.8rem"
                }}}>Name</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort["jobApplicant.name"].status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "jobApplicant.name": {
                            ...searchOptions.sort["jobApplicant.name"],
                            desc: !searchOptions.sort["jobApplicant.name"].desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort["jobApplicant.name"].desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="space-around"
                alignItems="center"
                sx={{ border: "1px solid #D1D1D1", borderRadius: "5px",'@media(max-width:768px)':{
                  display:"grid",
                  gridTemplateColumns:"1fr 1fr 1fr",
                  width:"100%",
                  border:"none",
                  gap:"1rem",
                  position:"relative",
                  right:"4rem"
                 
                  
                  
                } }}
              >
                <Grid item>
                  <Checkbox
                    name="jobTitle"
                    checked={searchOptions.sort["job.title"].status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "job.title": {
                            ...searchOptions.sort["job.title"],
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="jobTitle"
                  />
                </Grid>
                <Grid item sx={{'@media(max-width:768px)':{
                  width:"3rem"
                }}}>
                  <label for="jobTitle">
                    <Typography sx={{'@media(max-width:768px)':{
                  fontSize:"0.8rem"
                }}}>Job Title</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort["job.title"].status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "job.title": {
                            ...searchOptions.sort["job.title"],
                            desc: !searchOptions.sort["job.title"].desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort["job.title"].desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="space-around"
                alignItems="center"
                sx={{ border: "1px solid #D1D1D1", borderRadius: "5px",'@media(max-width:768px)':{
                  display:"grid",
                  gridTemplateColumns:"1fr 1fr 1fr",
                  width:"100%",
                  border:"none",
                  gap:"1rem",
                  position:"relative",
                  right:"4rem"
                 
                  
                  
                } }}
              >
                <Grid item>
                  <Checkbox
                    name="dateOfJoining"
                    checked={searchOptions.sort.dateOfJoining.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          dateOfJoining: {
                            ...searchOptions.sort.dateOfJoining,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="dateOfJoining"
                  />
                </Grid>
                <Grid item sx={{'@media(max-width:768px)':{
                  width:"3rem"
                }}}>
                  <label for="dateOfJoining">
                    <Typography sx={{'@media(max-width:768px)':{
                  fontSize:"0.8rem"
                }}}>Date of Joining</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.dateOfJoining.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          dateOfJoining: {
                            ...searchOptions.sort.dateOfJoining,
                            desc: !searchOptions.sort.dateOfJoining.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.dateOfJoining.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={6}
                justify="space-around"
                alignItems="center"
                sx={{ border: "1px solid #D1D1D1", borderRadius: "5px",'@media(max-width:768px)':{
                  display:"grid",
                  gridTemplateColumns:"1fr 1fr 1fr",
                  width:"100%",
                  border:"none",
                  gap:"1rem",
                  position:"relative",
                  right:"4rem"
                 
                  
                  
                } }}
              >
                <Grid item>
                  <Checkbox
                    name="rating"
                    checked={searchOptions.sort["jobApplicant.rating"].status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "jobApplicant.rating": {
                            ...searchOptions.sort[["jobApplicant.rating"]],
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="rating"
                  />
                </Grid>
                <Grid item sx={{'@media(max-width:768px)':{
                  width:"3rem"
                }}}>
                  <label for="rating">
                    <Typography sx={{'@media(max-width:768px)':{
                  fontSize:"0.8rem"
                }}}>Rating</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort["jobApplicant.rating"].status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "jobApplicant.rating": {
                            ...searchOptions.sort["jobApplicant.rating"],
                            desc: !searchOptions.sort["jobApplicant.rating"]
                              .desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort["jobApplicant.rating"].desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={() => getData()}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

const ApplicationTile = (props) => {
  // const classes = useStyles();
  const { application, getData } = props;
  const setPopup = useContext(SetPopupContext);
  const [open, setOpen] = useState(false);
  const [openEndJob, setOpenEndJob] = useState(false);
  const [rating, setRating] = useState(application.jobApplicant.rating);

  const appliedOn = new Date(application.dateOfApplication);

  const changeRating = () => {
    axios
      .put(
        apiList.rating,
        { rating: rating, jobApplicantId: application.jobApplicant.userId, applicantId: application.id, jobId: application.job.id},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setPopup({
          open: true,
          severity: "success",
          message: "Rating updated successfully",
        });
        // fetchRating();
        getData();
        setOpen(false);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        // fetchRating();
        getData();
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseEndJob = () => {
    setOpenEndJob(false);
  };

  // const colorSet = {
  //   applied: "#3454D1",
  //   shortlisted: "#DC851F",
  //   accepted: "#09BC8A",
  //   rejected: "#D1345B",
  //   deleted: "#B49A67",
  //   cancelled: "#FF8484",
  //   finished: "#4EA5D9",
  // };

  const getResume = () => {
    if (
      application.jobApplicant.resume &&
      application.jobApplicant.resume !== ""
    ) {
      const address = `${server}${application.jobApplicant.resume}`;
      console.log(address);
      axios(address, {
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => {
          console.log(error);
          setPopup({
            open: true,
            severity: "error",
            message: "Error",
          });
        });
    } else {
      setPopup({
        open: true,
        severity: "error",
        message: "No resume found",
      });
    }
  };

  const updateStatus = (status) => {
    const address = `${apiList.applications}/${application.id}`;
    const statusData = {
      status: status,
      dateOfJoining: new Date().toISOString(),
    };
    axios
      .put(address, statusData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        handleCloseEndJob();
        getData();
      })
      .catch((err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        console.log(err.response);
        handleCloseEndJob();
      });
  };

  return (
    <Paper elevation={3} sx={{padding:"30px 20px",width:800,margin:"20px auto",marginTop: "20px",
   
    
    '@media(max-width:768px)':{
      width:"100%",
      marginTop:"5rem"
    }}}>
      <Grid container>
        {/* <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src={`${server}${application.jobApplicant.profile}`}
            // className={classes.avatar}
          />
        </Grid> */}
        <Grid container item xs={7} spacing={1} direction="column" sx={{'@media(max-width:768px)':{
          // border:"1px solid red"
          width:"100%"
        }}}>
          <Grid item>
            <Typography variant="h3">
              {application.jobApplicant.name}
            </Typography>
          </Grid>
          <Grid item>
            <Rating
              value={
                application.jobApplicant.rating !== -1
                  ? application.jobApplicant.rating
                  : null
              }
              readOnly
            />
          </Grid>
          <Grid item>Job Title: {application.job.title}</Grid>
          <Grid item >Job Type: {application.job.jobType}</Grid>
          <Grid item>Profile : {application.job.employeeProfile}</Grid>
          <Grid item >Job Role : {application.job.EmployeeJobrole}</Grid>
          {/* <Grid item>Company : {application.job.employeeCompany}</Grid>
          <Grid item>
          Web Address : {application.job.employeeWeb}
          </Grid> */}
          <Grid item >Applied On: {appliedOn.toLocaleDateString()}</Grid>
          <Grid item >
            SOP: {application.sop !== "" ? application.sop : "Not Submitted"}
          </Grid>
          <Grid item>
          Skills : {application.jobApplicant.skills.map((skill) => (
              <Chip label={skill} style={{ marginRight: "2px" }} />
            ))}
          </Grid>
          <Grid item sx={{marginBottom:"8px",marginTop:"8px",display:"flex",columnGap: "30px",'@media(max-width:768px)':{
            flexDirection:"column",
            gap:'1rem'
          }}}>
            {/* <Button
              variant="contained"
              // className={classes.statusBlock}
              color="primary"
              onClick={() => getResume()}
            >
              Download Resume
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              // className={classes.statusBlock}
            
              sx={{ background: "#09BC8A",'@media(max-width:768px)':{
                width:'100%',
                
              }}}
              onClick={() => {
                setOpenEndJob(true);
              }}
            >
              End Job
            </Button>
            <Button
              variant="contained"
              color="primary"
              // className={classes.statusBlock}
              onClick={() => {
                setOpen(true);
              }}
              sx={{  '@media(max-width:768px)':{
                width:'100%',
                
              }}}
            >
              Rate Applicant
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose} sx={{marginTop:'230px'}}>
        <Paper
         sx={{padding: "20px 50px",
         outline: "none",
         display: "flex",
         flexDirection: "column",
         justifyContent: "center",
        // minWidth: "30%",
         alignItems: "center",
         maxWidth: "50%",
         marginLeft:"28%"
       }}
          // sx={{
          //   padding: "20px",
          //   outline: "none",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   minWidth: "30%",
          //   alignItems: "center",
          // }}
        >
          <Rating
            name="simple-controlled"
            style={{ marginBottom: "30px" }}
            value={rating === -1 ? null : rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "10px 50px" }}
            onClick={() => changeRating()}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
      <Modal
        open={openEndJob}
        onClose={handleCloseEndJob}
        sx={{marginTop:'230px'}}
        // className={classes.popupDialog}
      >
        <Paper
        sx={{padding: "20px 50px",
        outline: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
       // minWidth: "30%",
        alignItems: "center",
        maxWidth: "50%",
        marginLeft:"28%"
      }}
          // style={{
          //   padding: "20px",
          //   outline: "none",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   minWidth: "30%",
          //   alignItems: "center",
          // }}
        >
          <Typography variant="h4" style={{ marginBottom: "10px" }}>
            Are you sure?
          </Typography>
          <Grid container justify="center" spacing={5} sx={{justifyContent: "center"}}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 50px" }}
                onClick={() => {
                  updateStatus("finished");
                }}
              >
                Yes
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleCloseEndJob()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </Paper>
  );
};

const AcceptedApplicants = (props) => {
  const setPopup = useContext(SetPopupContext);
  const [applications, setApplications] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    sort: {
      "jobApplicant.name": {
        status: false,
        desc: false,
      },
      "job.title": {
        status: false,
        desc: false,
      },
      dateOfJoining: {
        status: true,
        desc: true,
      },
      "jobApplicant.rating": {
        status: false,
        desc: false,
      },
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let searchParams = [];
    searchParams = [...searchParams, `status=accepted`];

    let asc = [],
      desc = [];

    Object.keys(searchOptions.sort).forEach((obj) => {
      const item = searchOptions.sort[obj];
      if (item.status) {
        if (item.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });

    searchParams = [...searchParams, ...asc, ...desc];
    const queryString = searchParams.join("&");
    console.log(queryString);
    let address = `${apiList.applicants}`;
    if (queryString !== "") {
      address = `${address}?${queryString}`;
    }

    console.log(address);

    axios
      .get(address, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setApplications(response.data);
      })
      .catch((err) => {
        console.log(err.response);
        // console.log(err.response.data);
        setApplications([]);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
      });
  };

  return (
    <>
      <ReactHelmet 
        title={'Employees | AMUS HIRING'}
        description={''}
        route='employees'
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{marginTop: "80px",padding: "30px", minHeight: "93vh"}}
        
      >
        <Grid item>
          <Typography variant="h2">Employees</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setFilterOpen(true)}>
            <FilterListIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          style={{ width: "100%" }}
          alignItems="stretch"
          justify="center"
        >
          {applications.length > 0 ? (
            applications.map((obj) => (
              <Grid item>
                {/* {console.log(obj)} */}
                <ApplicationTile application={obj} getData={getData} />
              </Grid>
            ))
          ) : (
            <Typography variant="h5" style={{ textAlign: "center" }}>
              No Applications Found
            </Typography>
          )}
        </Grid>
      </Grid>
      <FilterPopup
        open={filterOpen}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        handleClose={() => setFilterOpen(false)}
        getData={() => {
          getData();
          setFilterOpen(false);
        }}
      />
    </>
  );
};

export default AcceptedApplicants;
