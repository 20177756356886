import React, { useContext, useMemo, useState } from 'react';
import {
    Box,
    Stack,
    Avatar,
    IconButton,
    Typography,
    Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { display, fontWeight } from '@mui/system';
import { ChatContext } from 'chat/context/ChatContextProvider';

const ChatHeader = ({ name, profile, status, setExpand }) => {
    const is_mobile = window.innerWidth <= 600;
    const { removeChatHandler } = useContext(ChatContext)
    const [isExpand, setIsExpand] = useState(false);

    const flex = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    }

    const typography = {
        fontSize: '12px',
        letterSpacing: '0.1px'
    }

    const btn = {
        p: '6px 10px',
    }
    const expandBtn = {
        "@media (max-width:600px)": {
            display: 'none'
        },
    }

    const isActive = useMemo(() => status === 'online', [status])

    const expandHandle = (e) => {
        e.stopPropagation()
        if (!isExpand) {
            const is_mobile = window.innerWidth <= 600;
            setExpand({
                height: 'calc(100vh - 100px)',
                width: 'fit-content'
            });
            setIsExpand(pre => !pre);
            return;
        }
        setExpand({
            height: '400px',
        });
        setIsExpand(pre => !pre);
    }

    const miniMizeHandler = () => {
        setExpand(pre => {
            const { transform, ...rest } = pre;
            if (!pre.transform) {
                rest.transform = 'translateY(100%) translateY(-48px);'
            }
            return {
                ...rest
            }
        });
    }

    return (
        <Stack
            sx={{
                ...flex,
                minHeight: '48px',
                justifyContent: 'space-between',
                borderBottom: '2px solid #dbdada',
                p: '0px 10px',
                cursor: 'pointer',
                '&:hover': {
                    bgcolor: '#f8fafd'
                }
            }}
            onClick={miniMizeHandler}
        >
            <Stack
                sx={{
                    ...flex,
                    gap: '10px'
                }}
            >
                <Badge
                    color="secondary"
                    variant="dot"
                    invisible={!isActive}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiBadge-badge': {
                            bottom: '6px',
                            right: '3px',
                            bgcolor: isActive ? 'green' : 'inherit'
                        }
                    }}
                >
                    <Avatar
                        src={profile}
                        children={name.slice(0, 1)}
                        sx={{ width: '32px', height: '32px' }}
                    />
                </Badge>

                <Stack>
                    <Typography
                        sx={{ ...typography }}
                        component="h2"
                    >
                        {name}
                    </Typography>
                    <Typography
                        sx={{ ...typography }}
                        component="h2"
                    >
                        {status}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    ...flex,
                }}
            >
                <IconButton
                    onClick={expandHandle}
                    aria-label="delete"
                    sx={{ ...btn, ...expandBtn }}
                >
                    {
                        isExpand ? <ZoomInMapIcon
                            sx={{
                                width: '16px'
                            }}
                        /> : <ZoomOutMapIcon
                            sx={{
                                width: '16px'
                            }}
                        />
                    }
                </IconButton>
                <IconButton
                    aria-label="delete"
                    sx={{ ...btn, p: '6px' }}
                    onClick={removeChatHandler}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default ChatHeader