import Navbars from 'container/layout/MainLayout/NavigationBar/Navbars'
import React from 'react'
import { Box ,Grid , Typography , Stack , TextField , IconButton , InputAdornment  , Button , Modal , Paper , FormControlLabel , Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useQuery } from 'react-query';
import apiList from 'pages/authentication/lib/apiList';
import axios from 'axios';
import { useContext , useState , useMemo } from 'react';
import { SetPopupContext } from 'routes';
import ForwardIcon from '@mui/icons-material/Forward'
import ReactHelmet from 'component/react-helmet/ReactHelmet';

function DataGridDemo(props) {
  const {paginationModel, setPaginationModel , setSelectedRecruiter , setModalOpen} = props;
  return (
    <Box 
    sx={{ height: 400, width: '80%',display:'block', margin:'0 auto' }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        rowCount={props.rowCount}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        pageSizeOptions={[5,10]}
        onRowClick={(params) => {
          setSelectedRecruiter(params.row)
          setModalOpen(true);
        }}
        sx={{
          '.MuiDataGrid-columnHeaderTitle':{
            fontWeight:'bolder'
          }
        }}
      />
    </Box>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  height: '80vh',
  overflowY:'scroll',
  '@media (max-width:750px)':
  {
    width:600
  },
  '@media (max-width:640px)':
  {
    width:550
  },
  '@media (max-width:550px)':
  {
    width:500
  },
  '@media (max-width:500px)':
  {
    width:450
  },
  '@media (max-width:450px)':
  {
    width:400
  },
  '@media (max-width:400px)':
  {
    width:350
  }

    
};

const BasicModal = (props) => {
  const {selectedRecruiter, recruiterData} = props;
  const handleClose = () => props.setModalOpen(false);
  const tableStyle = {
      border:'1px solid black',
      borderCollapse: 'collapse',
      
      
  }
  return (
    <div>
    <Modal
      open={props.modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // sx={{height:'90vh', overflowY:'scroll', marginTop:'10vh'}}
    >
      <Box sx={style}>
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <Typography variant='h2' sx={{fontWeight:'bolder'}}>{selectedRecruiter.name}</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <label htmlFor="" style={{fontWeight:'bold'}}>Email : </label>
                <Typography variant='span'>{selectedRecruiter.user.email}</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <label htmlFor="" style={{fontWeight:'bold'}}>Contact : </label>
                <Typography variant='span'>{selectedRecruiter.recruiterContact}</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <label htmlFor="" style={{fontWeight:'bold'}}>Web Address : </label>
                <Typography variant='span'>{selectedRecruiter.webAddress}</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <label htmlFor="" style={{fontWeight:'bold'}}>Company Type : </label>
                <Typography variant='span'>{selectedRecruiter.companyType}</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <label  htmlFor="" style={{fontWeight:'bold'}}>Bio : </label>
                <Typography variant='span'>{selectedRecruiter.bio}</Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <label htmlFor="" style={{fontWeight:'bold'}}>Job posted : </label>
                <Typography variant='span'>{selectedRecruiter.job.length}</Typography>
                <Typography variant='h4' style={{marginBottom:5}} >Jobs : </Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} style={{overflow:'auto'}}> 
                    {
                      selectedRecruiter.job.length !== 0 && 
                      <table style={{border:'1px solid black',borderCollapse: 'collapse'}} >
                      <thead>
                      <tr >
                        <th style={{border:'1px solid black'}}>Id</th>
                        <th>Job Title</th>
                        <th style={tableStyle}>Max Applicant</th>
                        <th style={tableStyle}>Experience Required</th>
                        <th style={tableStyle}>Deadline</th>
                        <th style={tableStyle}>Job Type</th>
                        <th style={tableStyle}>Positions</th>
                        <th style={tableStyle}>Monthly pay</th>
                      </tr>
                      </thead>
                      <tbody>
                        {
                          selectedRecruiter.job.map((data , i) => {
                            return <tr>
                            <td style={{width:'80px',textAlign:'center',border:'1px solid black'}}>{i+1}</td>
                            <td style={{width:'300px',textAlign:'center',border:'1px solid black'}}>{data.title}</td>
                            <td style={{width:'160px',textAlign:'center',border:'1px solid black'}}>{data.maxApplicants}</td>
                            <td style={{width:'160px',textAlign:'center',border:'1px solid black'}}>{data.experience}</td>
                            <td style={{width:'160px',textAlign:'center',border:'1px solid black'}}>{data.deadline.split("T")[0]}</td>
                            <td style={{width:'160px',textAlign:'center',border:'1px solid black'}}>{data.jobType}</td>
                            <td style={{width:'160px',textAlign:'center',border:'1px solid black'}}>{data.maxPositions}</td>
                            <td style={{width:'160px',textAlign:'center',border:'1px solid black'}}>{data.salary}</td>
                          </tr>
                          })
                        }
                      </tbody>
                    </table>
                    }
                    </Grid>
            </Grid>
       </Box>
    </Modal>
  </div>
  )
}

const FilterPopup = (props) => {
  // const classes = useStyles();
  const {refetch , handleClose , open , searchOptions , setSearchOptions} = props
  
  return (
    <Modal open={open} onClose={handleClose}  sx={{marginTop:'120px'}} >
    <Paper
    sx={{padding: "50px",
    outline: "none",
    maxWidth: "65%",
    marginLeft:"18%",
    // border:'1px solid red',
    '@media(max-width:768px)':{
    width:"110%",
    paddingBottom:"1rem",
    position:'relative',
    bottom:"3rem",
    // paddingRight:"2rem"
    }
  }}
    >
    <Grid container direction="column" alignItems="center" spacing={3} xs={12} sx={{'@media(max-width:768px)':{
    width:"130%",
   
    }}}>
        
        <Grid container item alignItems="center" sx={{'@media(max-width:768px)':{
            justifyContent:'center'
          },
          '@media(max-width:590px)':{
            flexWrap:'wrap',
            justifyContent:'normal'
          }}}>
            <Grid item xs={3} sx={{'@media(max-width:768px)':{
          display:"none",
         
           
            // width:"15rem"
          },fontWeight:'bold'}} >
              Type of Company
            </Grid>
            
            <Grid item >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Startup"
                      checked={searchOptions.companyType["Startup"]}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          companyType:{
                            ...searchOptions.companyType,
                            [event.target.name]:event.target.checked
                          }
                          
                        });
                      }}
                    />
                  }
                  label="Startup"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="SME"
                      checked={searchOptions.companyType["SME"]}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          companyType:{
                            ...searchOptions.companyType,
                            [event.target.name]:event.target.checked
                          }
                          
                        });
                      }}
                    />
                  }
                  label="SME"
                />
              </Grid>
              <Grid item >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Enterprise"
                      checked={searchOptions.companyType["Enterprise"]}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          companyType:{
                            ...searchOptions.companyType,
                            [event.target.name]:event.target.checked
                          }
                          
                        });
                      }}
                    />
                  }
                  label="Enterprise"
                />
              </Grid>
            </Grid>
        
          <Grid container item alignItems="center"    sx={{ 
                '@media(max-width:768px)':{
                  
                   flexWrap:'wrap',
                   justifyContent:'center'
                   
                    // width:"15rem"
                  }
              }}>
            <Grid item xs={3} sx={{'@media(max-width:768px)':{
              display:'none'
            },fontWeight:'bold'}}>
              Sort
            </Grid>
            <Grid item container direction="row" xs={9}  sx={{
               '@media(max-width:768px)':{
                  // border:'1px solid red',
                  position:'relative',
                  right:'0rem',
                  width:'100%'
                  
               }
            }}>
              <Grid
                item
                container
                lg={4}
                md={5}
                sm={9}
                xs={12}
                justify="space-around"
                alignItems="center"
                sx={{ border: "1px solid #D1D1D1", borderRadius: "5px",
                '@media(max-width:768px)':{
                   gap:"0rem",
                   marginLeft:'3rem',
                   alignItems:'center'
                  },
                  '@media(max-width:650px)':{
                    marginLeft:'2rem'
          
                  },
                  '@media(max-width:500px)':{
                    marginLeft:'1.5rem'
          
                  },
                  '@media(max-width:458px)':{
                    marginLeft:'0.5rem',
          
                  },
                  '@media(max-width:320px)':{
                    marginLeft:'0rem',
          
                  }
              }}
              >
                <Grid item >
                  <Checkbox
                    name="name"
                    checked={searchOptions.sort.name.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          name: {
                            ...searchOptions.sort.name,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="name"
                  />
                </Grid>
                <Grid item>
                  <label for="name">
                    <Typography>Name</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.name.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          name: {
                            ...searchOptions.sort.name,
                            desc: !searchOptions.sort.name.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.name.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            

          </Grid>
          <Grid container item alignItems="center"
          sx={{'@media(max-width:768px)':{
            display:"flex",
            justifyContent:"space-around",
            alignItems:'center',
            // border:"1px solid blue",         
          }}}>
              <Grid item xs={3} sx={{'@media(max-width:768px)':{
                display:"none",
              
                
                  // width:"15rem"

                },fontWeight:'bold'}} >
              Search Web Address
            </Grid>
            <Grid item xs sx={{ '@media(max-width:768px)':{
                  width:"100vw",
              }}}
              >
              <TextField
  
              sx={{ '@media(max-width:768px)':{
                
                width:"80%",
                // border:"1px solid red"
                marginLeft:'3rem'
      
              },'@media(max-width:650px)':{
                marginLeft:'2rem'
              },
              '@media(max-width:500px)':{
                marginLeft:'1.5rem'
      
              },
              '@media(max-width:320px)':{
                marginLeft:'0rem'
      
              },
            }}
                label="Search Web Address"
                value={searchOptions.employeeWeb}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    employeeWeb: event.target.value,
                  })
                }
                
                
                // style={{ width: "60vw" }} //already commented
                variant="outlined"
              />
            </Grid>

          </Grid>
          <Grid item sx={{'@media(max-width:768px)':{
            // border:"1px solid red",
            marginLeft:'1rem'
          }}}>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={()=>{
                refetch();
                handleClose();
                

              }}
            >
              Apply
            </Button>
          </Grid>
    </Grid>
    </Paper>
  </Modal>
  );
};

 
export default function RecruiterData() {
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedRecruiter , setSelectedRecruiter] = useState({})
  const [recruiterData, setRecruiterData] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const setPopup = useContext(SetPopupContext);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5
  })

  const [searchOptions, setSearchOptions] = React.useState({
    query: "",
    companyType:{
      ["Startup"]:false,
      ["SME"]:false,
      ["Enterprise"]:false
    },
    sort:{
      name:{
        status:false,
        desc:false
      }
    }
    })

  const getData = () => {
      let searchParams = []
      if(searchOptions.query !== "") searchParams = [...searchParams , `q=${searchOptions.query}`]
      if(searchOptions.companyType["Startup"]) searchParams = [...searchParams , `companyType=Startup`]
      if(searchOptions.companyType["SME"]) searchParams = [...searchParams , `companyType=SME`]
      if(searchOptions.companyType["Enterprise"]) searchParams = [...searchParams , `companyType=Enterprise`]
      if(searchOptions.employeeWeb) searchParams = [...searchParams , `employeeWeb=${searchOptions.employeeWeb}`]
      searchParams = [...searchParams, `page=${paginationModel.page}`, `pageSize=${paginationModel.pageSize}`];
      let desc = [] , asc = []
      Object.keys(searchOptions.sort).forEach(obj=>{
        const elem = searchOptions.sort[obj];
        if(elem.status){
          if(elem.desc){
            desc = [...desc, `desc=${obj}`];
          }else{
            
            asc = [...asc, `asc=${obj}`];
            
          }
        }
      })
      searchParams = [...searchParams , ...asc , ...desc]
      let address = `${apiList.owner}/recruiterData`
      const queryString = searchParams.join("&")
      if(queryString !== "") address = `${address}?${queryString}`
      

      return axios.get(address , {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
    }
  //useQuery
const {isLoading, data, refetch} = useQuery(['owner-recruiter', paginationModel],getData , {
  onError: (error)=>{
    setPopup({
      open: true,
      severity: "error",
      message: "Error",
    });
  }
});
useMemo(()=>{
  if(!data){
    return;
  }
  const rowData = data?.data.recruiter.map((elem, idx)=>{
    return {
      userId:elem.userId,
      id:paginationModel.page*paginationModel.pageSize + idx + 1,
      name: elem.name,
      companyType: elem.employeeCompany,
      recruiterContact: elem.contactNumber,
      webAddress:elem.employeeWeb,
      job:elem.job,
      user:elem.user,
      bio:elem.bio,
      maxPositions:elem.maxPositions,
      salary:elem.salary
    }
  })
  setRowCount(data?.data.count);
  setPaginationModel(data?.data.paginationModel);
  setRecruiterData(data?.data.recruiter);
  setSampleData(rowData);

}, [data , paginationModel])



const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
    editable: false,
    
  },
  {
    field: 'companyType',
    headerName: 'Type of Company',
    width: 200,
    editable: false,
  },
  {
    field: 'recruiterContact',
    headerName: 'Contact',
    width: 150,
    editable: false,
  },
  {
    field:'webAddress',
    headerName:"Web Address",
    width:250,
    editable:false,
    
  },
  {
    field: 'action',
    headerName: 'Details',
    width: 100,
    sortable: false,
    disableClickEventBubbling: true,
    
    renderCell: (params) => {
        const onClick = (e) => {
          const currentRow = params.row;
          setSelectedRecruiter(currentRow)
          setModalOpen(true);
        };
        
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" sx={{color:"#1246A0"}} size="small" onClick={onClick}><ForwardIcon /></Button>
          </Stack>
        );
    },
  }
];
  return (
    <>
      <ReactHelmet 
        title={'Recruiters | AMUS HIRING'}
        description={'Welcome to our recruiter page'}
        route='recruiters'
      />
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          sx={{marginTop:'100px',padding: "30px", minHeight: "10vh", '@media(max-width:768px)':{
            
            // marginTop:"15rem",
            width:"100vw",
            // border:"1px solid green"
          }}}
         
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs sx={{'@media(max-width:768px)':{
              marginRight:"3rem"
            }}}>
              <Typography sx={{marginBottom:1}} variant="h2">Recruiters</Typography>
            </Grid>
            <Grid item xs sx={{ '@media(max-width:768px)':{
                  width:"100vw",
              }}}
              >
              <TextField
  
              sx={{ '@media(max-width:768px)':{
                
                width:"85vw",
                // border:"1px solid red"
      
              }}}
                label="Search Recruiters"
                value={searchOptions.query}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    query: event.target.value,
                  })
                }
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    refetch()
                   
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={() => {
                        refetch()
                      }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                   
                  ),
                }}
                
                // style={{ width: "60vw" }} //already commented
                variant="outlined"
              />
            </Grid>
            <Grid item sx={{'@media(max-width:768px)':{
              marginRight:"3rem"
            }}}>
              <IconButton onClick={() => setFilterOpen(true)}>
                <FilterListIcon />
              </IconButton>
            </Grid>
          </Grid>
      </Grid>
      <FilterPopup 
      searchOptions={searchOptions}
      open = {filterOpen}
      handleClose = {()=>setFilterOpen(false)}
      setSearchOptions = {setSearchOptions}
      refetch={refetch}
      />
      {/* {isLoading ? <CircularIndeterminate /> :  */}
      <DataGridDemo rows={sampleData} 
      columns={columns} 
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={rowCount}
      setSelectedRecruiter={setSelectedRecruiter}
      setModalOpen={setModalOpen}
      />
      {modalOpen && 
      <BasicModal 
      modalOpen={modalOpen} 
      setModalOpen={setModalOpen} 
      recruiterData={recruiterData} 
      selectedRecruiter={selectedRecruiter}/>}
    </>
  )
}

