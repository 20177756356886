import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ReactHelmet from 'component/react-helmet/ReactHelmet';

const Container=styled.div`
padding: 5rem;
@media (max-width: 768px) {
    padding: 5rem 1rem 1rem 1rem;
    width: 100vw;
    font-size: 0.5rem;

  }
h1{
    font-size:3rem ;
    @media (max-width: 768px) {
line-height: 2rem;
font-size: 2.5rem;

}
}
h2{
        font-size: 2rem;
        @media (max-width: 768px) {
line-height: 2rem;
font-size: 1.5rem;

}}
p{
        color: gray;
        font-size: 1.2rem;
        line-height: 1.5rem;
        @media (max-width: 768px) {
  
    font-size: 1rem;

  }
        p{
            color: gray;
        font-size: 1.2rem;
        line-height: 1.6rem;
        @media (max-width: 768px) {
  
  font-size: 1rem;

}
        }
    }
`;

const LegalNotice = () => {
  return (
    <>
    <ReactHelmet 
      title={'Legal Notice | AMUS HIRING'}
      description={''}
      route='legal-notice'
    />
    <Container><h1 >Legal Notice</h1>
    <p>
        <p>
        
        In compliance with Article 10 of Law 34/2002, of 11 July, on Information Society Services and Electronic Commerce (LSSICE), AMUS SOFTWARE SL (hereinafter, “AMUS HIRING”), responsible for this website, makes this information available to Users to define its Conditions of Use.
        </p>
        <p> In addition to the contents herein, the specific aspects related to the protection of personal data and the privacy of the users of this website are developed in the Privacy Policy and Cookies Policy pages.</p>
        <NavLink to={"#"} style={{color:"gray",display:"block"}}>  Identity of the Data Controller:</NavLink>
        <p> Company Name: AMUS SOFTWARE SL</p>
        <p>Commercial Name: AMUS HIRING</p>
        <p>CIF / NIF / NIE: B72561442</p>
        <p>Registered Office: Vilamari, 51, Bajos-1, 08015, Barcelona (Spain)</p>
        <p>Activity: Software Development and Consultancy</p>
        <p>eMail: hola@amussoft.com</p>
        <p> Online contact form: amushiring.com/contact</p>
        <p>Domain Name: amussoft.com</p>
        <p>Registration of Processing by the GDPR: Customers/Suppliers, Mailing Lists, Employees, Recruitment, and Web Users.</p>
        <NavLink to={"#"} style={{color:"gray",display:"block"}}>  Purpose of the Website</NavLink>
        <p>The AMUS HIRING website has the following purposes:</p>
        <p>Making available to the User the products and/or services offered for their acquisition by electronic means, as well as offering information about them and the Responsible.</p>
        <p>Dissemination of information related to the sector of activity of the Responsible.</p>
        <p>Manage the comments and/or evaluations of the Users.</p>
        <p>Manage the list of subscribers and users subscribed to the blog or newsletter of the website.</p>
        <p>Manage communications, associated clients, affiliates, commissions, etc. of collaborators.</p>
        <p>To make documentation available to clients in the private areas of this website.</p>
        </p>
        <h2>Regulatory Framework</h2>
        <p>
           <p> The activity of this website is subject to the Spanish and European legal framework, specifically to the following regulations:</p>
           <p>General Data Protection Regulation (GDPR) (EU) 2016/679, regulates the processing of personal data by Controllers located in European Union countries.</p> 
           <p>Organic Law 3/2018, of 5 December, on data protection and digital rights (LOPD and GDD), regional rules (applicable to Spain), which define and extend many of the concepts and rights present in the GDPR.</p>
           <p>Law 34/2002, of 11 July, on Information Society Services and Electronic Commerce (LSSICE), a regulation that affects those websites that, in some way, carry out economic activities through electronic means, as is the case of this shop.</p>
           <p>General Law 03/2014 for the Defence of Consumers and Users (LGDCU), which regulates the conditions of sale and contract.</p>
           <p>Conditions of Use and Responsibilities</p>
           <p>Any person accessing this website assumes the role of user, committing to the observance and strict compliance with the conditions set forth herein, as well as any other legal provision that may be applicable. “AMUS HIRING” will not be held responsible for any damages, of its own or to third parties, caused by the use of this website by the User.</p>
           <p>“AMUS HIRING” provides access to articles, information, services, and data of its property or third parties, elaborated for merely informative or divulgation purposes, which may not reflect the current state of legislation or jurisprudence, and which refer to general situations, and therefore their content should not necessarily be applied by the User to specific cases. The content of this website, therefore, cannot be considered, under any circumstances, as a substitute for legal advice.</p>
           <p>“AMUS HIRING” reserves the right to modify any type of information that may appear on the website, at any time and without prior notice, without there being any obligation to give prior notice or inform the Users of said obligations, with publication on this website being understood to be sufficient.</p>
           <p>This website has been revised and tested so that it functions correctly without interruption. Nevertheless, “AMUS HIRING” does not rule out the possibility of the existence of certain programming errors, occasional unavailability (for example, server crashes, or server maintenance) or the occurrence of force majeure, natural catastrophes, strikes, or similar circumstances that make temporary access to the website impossible. Likewise, “AMUS HIRING” cannot guarantee the uninterrupted or error-free operation of this website, nor is it responsible for viruses originating in a telematic transmission infiltrated by third parties generated to obtain negative results for a computer system.</p>
           <p> The User undertakes not to use this website or, if applicable, the services or products offered on it, to carry out activities contrary to the law, public order, or these conditions of use. Therefore, “AMUS HIRING” is not responsible for the information and contents stored, including but not limited to, in forums, chats, blog generators, comments, social networks, or any other medium that allows third parties to publish content. Nevertheless, and in compliance with the provisions of art. 11 and 16 of the LSSI-CE, “AMUS HIRING” is at the disposal of all users, authorities, and security forces, and actively collaborates in the removal or blocking of all content that could affect or contravene national or international legislation, the rights of third parties or morality and public order. If a User considers that there is any content on the website that could be susceptible to this classification, please notify our staff immediately.</p>
           <p>“AMUS HIRING” reserves the right to deny or withdraw access to the shop without prior warning, at its request or the request of a third party, to those Users who do not comply with our Conditions of Use.</p>
           </p>
           <h2>Intellectual Property</h2>
           <p>

            <p>The website, including but not limited to its programming, editing, compilation, and other elements necessary for its operation, the designs, logos, text, and/or graphics are the property of “AMUS HIRING” or, where appropriate, it has a license or expresses authorization from the authors.</p>
            <p> All the contents of the website are duly protected by the intellectual and industrial property regulations (articles 8 and 32.1, second paragraph, of the Intellectual Property Law), as well as registered in the corresponding public registers, and the reproduction and/or publication, total or partial, of the website, its computer processing, distribution, diffusion, modification or transformation, is not permitted without the prior written permission of “AMUS HIRING”. “AMUS HIRING” will watch over the fulfillment of the previous conditions, as well as the correct use of the contents presented on its web pages, exercising all the civil and penal actions that correspond to it in the case of infractions or non-fulfillment of these rights by the User.</p>
            <p> The designs, logos, text, and/or graphics not belonging to “AMUS HIRING” and which may appear on the website belong to their respective owners, who are themselves responsible for any possible controversy which may arise concerning the same. In any case, “AMUS HIRING” has the express and previous authorization from them. “AMUS HIRING” recognizes in favor of their owners the corresponding industrial and intellectual property rights, and their mere mention or appearance on the website does not imply the existence of any rights or responsibility whatsoever of the Responsible for the same, nor any endorsement, sponsorship or recommendation by the same.</p>
           </p>
           <h2>Third-Party Links</h2>
           <p>

            <p>“AMUS HIRING” may make available to the User links or other elements that allow access to other websites belonging to third parties. We do not commercialize the products and/or services of these linked pages, nor do we assume any type of responsibility for them, nor for the information contained in them, nor for their veracity or legality, nor for any effects that may derive from them. In any case, “AMUS HIRING” declares that it will proceed to the immediate withdrawal of any content that could contravene national or international legislation, morality or public order, proceeding to the immediate withdrawal of the redirection to said website, informing the competent authorities of the content in question.</p>
           </p>
           <h2>Applicable Law And Jurisdiction</h2>
           <p>The relationship between the Controller and the User is governed in every one of its aspects by Spanish law, to which both parties expressly submit. The language of drafting and interpretation of this legal notice is Spanish. For the resolution of all disputes or questions related to this website or the activities carried out on it, “AMUS HIRING” and the User agree to submit to the Courts and Tribunals of the domicile of “AMUS HIRING”.</p>
    
    </Container>
    </>
  )
}

export default LegalNotice