import { useContext, useEffect, useId, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Paper,
  TextField,
  MenuItem,
  Box,
  Stack,
} from "@mui/material";
import axios from "axios";
//import ChipInput from "material-ui-chip-input";
import { MuiChipsInput } from "mui-chips-input";
//import FileUploadInput from "../../authentication/lib/FileUploadInput";
// import DescriptionIcon from "@material-ui/icons/Description";
// import FaceIcon from "@material-ui/icons/Face";
//import DescriptionIcon from '@mui/icons-material/Description';
//import FaceIcon from '@mui/icons-material/Face';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

//import { SetPopupContext } from "../App";
import { SetPopupContext } from "../../../routes/index";

import apiList from "../../authentication/lib/apiList";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import countries from "../../../asset/countryList.json";
import UploadFile from "component/fileUpload/UploadFile";
import ResumeViewer from "../../../component/resumeViewer/ResumeViewer";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     // padding: "30px",
//   },
// }));

const MultifieldInput = (props) => {
  //  const classes = useStyles();
  const { education, setEducation } = props;
  const newId = useId();
  const removeItem = (id) => {
    const filterFunction = education.filter((obj, key) => {
      return obj.id !== id;
    });
    setEducation(filterFunction);
  };
  return (
    <Grid
      item
      sx={{
        flexDirection: "column",
        display: "flex",
        //alignItems: "center", btton of institute
        minWidth: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: "37px",
        "@media(max-width:768px)": {
          //  border:'1px solid red',
          width: "7rem",
          paddingLeft: "0px",
          gap: "0rem",
          position: "relative",
          right: "3rem",
        },

        // '@media(max-width:600px)':{
        //   position:"relative",
        //   right:"1.5rem"

        // }
        //justifyContent: "center",
      }}
    >
      {/* <Paper  elevation={3} sx={{padding:"30px 20px",width:1000,margin:"20px auto"}} > */}
      {education.map((obj, key) => (
        <Grid
          item
          container
          // className={classes.inputBox}
          key={key}
          sx={{
            flexDirection: "column",
            "@media(max-width:768px)": {
              // border:'1px solid blue',
              width: "100%",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              "@media(max-width:768px)": {
                flexDirection: "column",
                width: "100%",
                // border:"1px solid orange"
              },
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                minWidth: "90%",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: "15px",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"1px solid gray",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "90%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label={`University / College #${key + 1}`}
                value={education[key].institutionName}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].institutionName = event.target.value;
                  setEducation(newEdu);
                }}
                variant="outlined"
              />
            </Grid>
            <Box
              sx={{
                marginBottom: "1rem",
                "@media(max-width:768px)": {
                  position: "relative",
                  left: "10.3rem",
                  bottom: "5.5rem",
                },
              }}
            >
              {key > 0 ? (
                <DeleteIcon
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                  onClick={() => removeItem(obj.id)}
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              "@media(max-width:768px)": {
                gap: "1rem",
                padding: "0rem 1rem",
                //  flexDirection:"column"
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label={`Degree `}
                value={education[key].Degree}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].Degree = event.target.value;
                  setEducation(newEdu);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "43%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label={`Field of Study`}
                value={education[key].Stream}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].Stream = event.target.value;
                  setEducation(newEdu);
                }}
                variant="outlined"
              />
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              my: 2,
              "@media(max-width:768px)": {
                width: "100%",
                // border:"4px solid gray",
                padding: "0rem 1rem",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label="Start Year"
                value={obj.startYear}
                variant="outlined"
                type="date"
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].startYear = event.target.value;
                  setEducation(newEdu);
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "43%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label="End Year"
                value={obj.endYear}
                variant="outlined"
                type="date"
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].endYear = event.target.value;
                  setEducation(newEdu);
                }}
              />
            </Grid>
          </Box>
        </Grid>
      ))}
      <Grid
        item
        sx={{
          alignSelf: "center",
          paddingRight: "4rem",
          "@media(max-width:768px)": {
            marginLeft: "2rem",
          },
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setEducation([
              ...education,
              {
                id: newId + Date.now(),
                institutionName: "",
                Degree: "",
                Stream: "",
                startYear: "",
                endYear: "",
              },
            ]);
            console.log(education);
          }}
          //className={classes.inputBox}
        >
          Add Education Details
        </Button>
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
};

const MultifieldWorkExp = (props) => {
  //  const classes = useStyles();
  const { WorkExperience, setWorkExperience } = props;
  const newId = useId();
  const removeItemAddEducation = (id) => {
    const filterFunction = WorkExperience.filter((obj, key) => {
      return obj.id !== id;
    });
    setWorkExperience(filterFunction);
  };
  return (
    <Grid
      item
      sx={{
        flexDirection: "column",
        display: "flex",
        //alignItems: "center", btton of institute
        minWidth: "100%",
        marginLeft: "37px",
        "@media(max-width:768px)": {
          //  border:'1px solid red',
          width: "7rem",
          paddingLeft: "0px",
          gap: "0rem",
          position: "relative",
          right: "4rem",
        },
        //justifyContent: "center",
      }}
    >
      {/* <Paper  elevation={3} sx={{padding:"30px 20px",width:1000,margin:"20px auto"}} > */}
      {WorkExperience.map((obj, key) => (
        <Grid
          item
          container
          // className={classes.inputBox}
          key={key}
          sx={{
            // minWidth: "100%",
            //justifyContent: "space-evenly"
            flexDirection: "column",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              "@media(max-width:768px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                minWidth: "90%",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: "15px",
                "@media(max-width:768px)": {
                  //  border:'1px solid red',
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    //  border:'1px solid red',
                    width: "90%",
                    marginLeft: "1.5rem",
                  },
                }}
                fullWidth
                label={`Job Title #${key + 1}`}
                value={WorkExperience[key].companyrole}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].companyrole = event.target.value;
                  setWorkExperience(newExp);
                }}
                variant="outlined"
              />
            </Grid>
            <Box
              sx={{
                marginBottom: "1rem",
                "@media(max-width:768px)": {
                  position: "relative",
                  left: "-2rem",
                  bottom: "0.7rem",
                },
              }}
            >
              {key > 0 ? (
                <DeleteIcon
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                  onClick={() => removeItemAddEducation(obj.id)}
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              "@media(max-width:768px)": {
                //  border:'1px solid red',
                width: "100%",
                // border:"1px solid red",
                marginLeft: "1rem",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "43%",
                "@media(max-width:768px)": {
                  //  border:'1px solid red',
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    //  border:'1px solid red',
                    width: "100%",
                  },
                }}
                fullWidth
                label={`Company `}
                value={WorkExperience[key].company}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].company = event.target.value;
                  setWorkExperience(newExp);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  //  border:'1px solid red',
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    //  border:'1px solid red',
                    width: "100%",
                  },
                }}
                fullWidth
                label={`Location `}
                value={WorkExperience[key].location}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].location = event.target.value;
                  setWorkExperience(newExp);
                }}
                variant="outlined"
              />
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              my: 2,
              "@media(max-width:768px)": {
                //  border:'1px solid red',
                width: "100%",
                marginLeft: "1rem",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "43%",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    //  border:'1px solid red',
                    width: "100%",
                  },
                }}
                fullWidth
                label="Start Year"
                value={obj.WorkstartYear}
                variant="outlined"
                // type="number"
                type="date"
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].WorkstartYear = event.target.value;
                  setWorkExperience(newExp);
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  //  border:'1px solid red',
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    //  border:'1px solid red',
                    width: "100%",
                  },
                }}
                fullWidth
                label="End Year"
                value={obj.WorkendYear}
                variant="outlined"
                // type="number"
                type="date"
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].WorkendYear = event.target.value;
                  setWorkExperience(newExp);
                }}
              />
            </Grid>
          </Box>
        </Grid>
      ))}
      <Grid
        item
        sx={{
          alignSelf: "center",
          paddingRight: "4rem",
          "@media(max-width:768px)": {
            marginLeft: "3rem",
          },
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setWorkExperience([
              ...WorkExperience,
              {
                id: newId + Date.now(),
                companyrole: "",
                company: "",
                location: "",
                WorkstartYear: "",
                WorkendYear: "",
              },
            ])
          }
          //className={classes.inputBox}
        >
          Add Work Experience Details
        </Button>
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
};
const MultifieldLanguage = (props) => {
  const { Language, setLanguage } = props;
  const removeItemAddLanguage = (id) => {
    const filterItem = Language.filter((obj) => {
      return obj.id !== id;
    });
    setLanguage(filterItem);
  };
  return (
    <Grid
      item
      sx={{
        flexDirection: "column",
        display: "flex",
        //alignItems: "center", btton of institute
        minWidth: "100%",
        marginLeft: "37px",
        "@media(max-width:768px)": {
          width: "100%",
          //  border:'1px solid red',
          //  width:"7rem",
          paddingLeft: "2.5rem",
          gap: "0rem",
          position: "relative",
          right: "3rem",
        },
        //justifyContent: "center",
      }}
    >
      {/* <Paper  elevation={3} sx={{padding:"30px 20px",width:1000,margin:"20px auto"}} > */}
      {Language.map((obj, key) => (
        <Grid
          item
          container
          // className={classes.inputBox}
          key={key}
          sx={{
            // minWidth: "100%",
            //justifyContent: "space-evenly"
            flexDirection: "column",
            marginBottom: "15px",
          }}
        >
          {/* <Grid item xs={4}  sx={{  minWidth: "90%",paddingLeft: 0, paddingRight: 0,marginBottom:"15px"}}> */}
          {/* <TextField
                          select
                          label="Type of Company"
                          variant="outlined"
                          value={signupDetails.employeeCompany}
                          onChange={(event) => {
                            handleInput("employeeCompany", event.target.value);
                          }}
                          fullWidth
                        >
                          <MenuItem value="Startup">Startup</MenuItem>
                          <MenuItem value="SME">SME</MenuItem>
                          <MenuItem value="Enterprise">Enterprise</MenuItem>
                        </TextField> */}

          {/* </Grid> */}
          <Grid
            sx={{
              display: "flex",
              placeItems: "center",
              gap: "1rem",
              "@media(max-width:768px)": {
                flexDirection: "column",
                width: "100%",
                // border:"1px solid orange"
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "20px",
                minWidth: "100%",
                "@media(max-width:768px)": {
                  flexDirection: "column",
                  width: "100%",
                  // border:"1px solid green",
                  position: "relative",
                  marginRight: "2rem",
                },
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: "43%",
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"1px solid yellow"
                  },
                }}
              >
                <TextField
                  sx={{
                    "@media(max-width:768px)": {
                      width: "150%",
                      marginLeft: "2rem",
                      marginTop: "1rem",
                      //  border:"1px solid pink"
                    },
                  }}
                  fullWidth
                  select
                  label={`Language #${key + 1}`}
                  value={Language[key].Languageknow}
                  onChange={(event) => {
                    const newlan = [...Language];
                    newlan[key].Languageknow = event.target.value;
                    setLanguage(newlan);
                  }}
                  variant="outlined"
                >
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Spanish">Spanish</MenuItem>
                  <MenuItem value="French">French</MenuItem>
                  <MenuItem value="hindi">Hindi</MenuItem>
                  <MenuItem value="German">German</MenuItem>

                  <MenuItem value="Portuguese">Portuguese</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: "43%",
                  "@media(max-width:768px)": {
                    width: "100%",

                    //  border:"1px solid orange"
                  },
                }}
              >
                <TextField
                  sx={{
                    "@media(max-width:768px)": {
                      width: "150%",
                      marginLeft: "2rem",
                      marginTop: "1rem",
                      //  position:"absolute",
                      border: "1px solid aliceblue",
                    },
                  }}
                  fullWidth
                  select
                  label={` language level  `}
                  value={Language[key].ExpLevel}
                  onChange={(event) => {
                    const newlan = [...Language];
                    newlan[key].ExpLevel = event.target.value;
                    setLanguage(newlan);
                  }}
                  variant="outlined"
                >
                  <MenuItem value="Basic">Basic</MenuItem>
                  <MenuItem value="Conversational">Conversational</MenuItem>
                  <MenuItem value="Fluent">Fluent</MenuItem>
                  <MenuItem value="Native">Native</MenuItem>
                </TextField>
              </Grid>
              <Box
                sx={{
                  "@media(max-width:768px)": {
                    width: "2rem",
                    position: "absolute",
                    top: "0.5rem",
                    right: "2rem",
                    // left:"18rem",
                    // bottom:"4rem",
                    // border:"1px solid red",
                    // display:"none",
                  },
                }}
              >
                {key > 0 ? (
                  <DeleteIcon
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontSize: "1.5rem",
                    }}
                    onClick={() => removeItemAddLanguage(obj.id)}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid
        item
        sx={{
          alignSelf: "center",
          paddingRight: "4rem",
          "@media(max-width:768px)": {
            // marginLeft:"1.5rem"
            marginRight: "1rem",
          },
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            setLanguage([
              ...Language,
              { id: Date.now(), Languageknow: "", ExpLevel: "" },
            ])
          }
          //className={classes.inputBox}
        >
          Add Language Details
        </Button>
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
};

const Profile = (props) => {
  // const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  const [resumeUrl, setResumeUrl] = useState(null);
  const [userData, setUserData] = useState();
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState({
    fileName: "",
    fileType: "",
    file: null,
  });

  const [profileDetails, setProfileDetails] = useState({
    name: "",
    jobTitleInput: "",
    Role: "",
    experience: 0,
    noticeperiod: "",
    Setavailability: "",
    Education: [],
    WorkExperience: [],
    Language: [],
    lastCurrencyType: "",
    lastsalary: 0,
    expectedCurrencyType: "",
    expectedsalary: 0,
    residenceCountry: "",
    citizenshipCountry: "",
    employeeContact: "",
    skills: [],
    resume: "",
    profile: "",
    currentCity: "",
  });

  const [education, setEducation] = useState([
    {
      institutionName: "",
      Degree: "",
      Stream: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const [WorkExperience, setWorkExperience] = useState([
    {
      companyrole: "",
      company: "",
      location: "",
      WorkstartYear: "",
      WorkendYear: "",
    },
  ]);

  const [Language, setLanguage] = useState([
    {
      Languageknow: "",
      ExpLevel: "",
    },
  ]);

  const [phoneemployee, setPhoneemployee] = useState("");

  const handleInput = (key, value) => {
    setProfileDetails({
      ...profileDetails,
      [key]: key === "experience" ? parseInt(value) : value,
    });
  };

  useEffect(() => {
    const { ats, selectedApplicant, applicantData } = props;
    if (ats) {
      const applicantDetails = applicantData.find((applicant) => {
        return (
          applicant.name === selectedApplicant.name &&
          applicant.jobTitleInput === selectedApplicant.jobTitle &&
          applicant.experience ===
            parseInt(selectedApplicant.experience.trim().split(" ")[0]) &&
          applicant.noticeperiod === selectedApplicant.noticePeriod &&
          applicant.employeeContact === selectedApplicant.employeeContact
        );
      });
      setProfileDetails(applicantDetails);
      setEducation(applicantDetails.Education);
      setWorkExperience(applicantDetails.WorkExperience);
      setLanguage(applicantDetails.Language);
      setPhoneemployee(applicantDetails.employeeContact);
      return;
    }
    getData();
  }, []);

  const getData = () => {
    axios
      .get(apiList.user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setProfileDetails(response.data);
        setFileData((pre) => ({
          ...pre,
          fileName: response.data?.resume
            ?.split("/")[3]
            ?.split(".")
            ?.slice(0, -1)
            ?.join()
            ?.slice(0, -64),
        }));
        if (response.data.Education.length > 0) {
          setEducation(
            response.data.Education.map((edu) => ({
              id: edu.id ? edu.id : "",
              institutionName: edu.institutionName ? edu.institutionName : "",
              Degree: edu.Degree ? edu.Degree : "",
              Stream: edu.Stream ? edu.Stream : "",
              startYear: edu.startYear ? edu.startYear : "",
              endYear: edu.endYear ? edu.endYear : "",
            }))
          );
        }
        // setProfileDetails(response.data);
        if (response.data.WorkExperience.length > 0) {
          setWorkExperience(
            response.data.WorkExperience.map((exp) => ({
              id: exp.id ? exp.id : "",
              companyrole: exp.companyrole ? exp.companyrole : "",
              company: exp.company ? exp.company : "",
              location: exp.location ? exp.location : "",
              WorkstartYear: exp.WorkstartYear ? exp.WorkstartYear : "",
              WorkendYear: exp.WorkendYear ? exp.WorkendYear : "",
            }))
          );
        }
        // setProfileDetails(response.data);
        if (response.data.Language.length > 0) {
          setLanguage(
            response.data.Language.map((lan) => ({
              id: lan.id ? lan.id : "",
              Languageknow: lan.Languageknow ? lan.Languageknow : "",
              ExpLevel: lan.ExpLevel ? lan.ExpLevel : "",
            }))
          );
        }
        // setProfileDetails(response.data);
        setPhoneemployee(response.data.employeeContact);
        // setProfileDetails(response.data);
        // setPhoneemployee(response.data.Setavailability);
      })
      .catch((err) => {
        // console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editDetails = () => {
    setOpen(true);
  };

  // const handleUpdates = () => {
  //   let updatedDetails = {
  //     ...profileDetails,
  //   };
  //    if (phoneemployee !== "") {
  //       updatedDetails = {
  //         ...profileDetails,
  //         employeeContact: `+${phoneemployee}`,
  //       };
  //     } else {
  //       updatedDetails = {
  //         ...profileDetails,
  //         employeeContact: "",
  //       }

  // };
  // }
  // console.log(education);
  // console.log(profileDetails);
  const handleUpdate = () => {
    // console.log(education);
    // console.log(WorkExperience);
    // console.log(Language);

    // handleUpdates()

    let updatedDetails = {
      ...profileDetails,
      Education: education
        .filter((obj) => obj.institutionName.trim() !== "")
        .map((obj) => {
          if (obj["endYear"] === "") {
            delete obj["endYear"];
          }
          // console.log(obj);
          return obj;
        }),

      WorkExperience: WorkExperience.filter(
        (obj) => obj.companyrole.trim() !== ""
      ).map((obj) => {
        if (obj["WorkendYear"] === "") {
          delete obj["WorkendYear"];
        }
        return obj;
      }),
      Language: Language.filter((obj) => obj.Languageknow.trim() !== "").map(
        (obj) => {
          return obj;
        }
      ),
      employeeContact: `+${phoneemployee?.replace("+", "")}`,
    };
    // console.log(updatedDetails);
    // if (phoneemployee !== "") {
    //   updatedDetails = {
    //     ...profileDetails,
    //     employeeContact: `+${phoneemployee}`,
    //   };
    // } else {
    //   updatedDetails = {
    //     ...profileDetails,
    //     employeeContact: "",
    //   };
    //   // updatedDetails = {
    //   //   ...profileDetails,
    //   //   Setavailability: "",
    //   // }
    // }

    const formData = new FormData();
    Object.entries({ ...updatedDetails }).map(([key, value]) => {
      if (typeof value === "object") {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    console.log(formData);
    if (fileData.file) {
      formData.append("fileCV", fileData.file);
    }
    axios
      .put(apiList.user, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        props.ats ? props.setEditApplicant(false) : getData();
        props.ats && props.refetch();
      })
      .catch((err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err?.response?.data?.message,
        });
        console.log(err);
      });
    setOpen(false);
  };

  return (
    <>
      <ReactHelmet
        title={"Profile | AMUS HIRING"}
        description={""}
        route="profile"
      />
      <Grid
        item
        sx={{
          flexDirection: "column",
          display: "flex",
          //alignItems: "center", btton of institute
          minWidth: "100%",
          paddingLeft: "6px",
          marginTop: "95px",

          "@media(max-width:768px)": {
            width: "100vw",
            gap: "none",
            marginTop: "0rem",
          },

          //justifyContent: "center",
        }}
      >
        <Grid
          container
          item
          sx={{
            // minWidth: "100%",
            // justifyContent: "space-evenly"
            flexDirection: "column",
          }}
          // direction="column"
          // alignItems="center"
          // sx={{ padding: "30px", minHeight: "93vh" }}
        >
          <Grid
            item
            sx={{
              alignSelf: "center",
              "@media(max-width:768px)": {
                marginTop: "10rem",
                //  border:"1px solid blue"
              },
            }}
          >
            <Typography variant="h2">Profile</Typography>
          </Grid>
          <Grid item xs>
            <Paper
              elevation={3}
              sx={{
                padding: "30px 20px",
                width: 800,
                margin: "20px auto",
                marginTop: "85px",
                // border:"1px solid red",
                "@media(max-width:768px)": {
                  width: "100%",

                  // margin:"0px",
                  // marginRight:"0px",
                  // border:"1px solid green",

                  // margin:"auto",
                  // textAlign:"center",
                  placeSelf: "center",
                  placeContent: "center",
                  // gap:"0rem"
                },
              }}
              // sx={{
              //   padding: "20px",
              //   outline: "none",
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
            >
              {/* <Grid sx={{display: "flex",alignItems: "center", width: "100%",
 justifyContent: "space-evenly", }}></Grid> */}
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={3}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  //alignItems: "center", btton of institute
                  minWidth: "100%",
                  //justifyContent: "center",
                  // border:"1px solid red",
                  "@media(max-width:768px)": {
                    flexDirection: "column",
                    width: "100%",
                    //  border:"1px solid red"
                  },
                }}
              >
                <Grid
                  item
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    // marginBottom: "35px",
                    "@media(max-width:768px)": {
                      flexDirection: "column",
                      width: "100%",
                      // border:"1px solid gray"
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    sx={{
                      width: "90%",
                      marginLeft: "1rem",
                      "@media(max-width:768px)": {
                        // border:"1px solid blue",
                        width: "105%",
                      },
                    }}
                    label="Name"
                    value={profileDetails.name}
                    onChange={(event) =>
                      handleInput("name", event.target.value)
                    }
                    // className={classes.inputBox}
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "90%",
                    marginLeft: "50px",
                    // marginBottom:"0.75rem",

                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0px",
                    },
                  }}
                >
                  <PhoneInput
                    inputStyle={{ width: "100%" }}
                    country={"in"}
                    value={phoneemployee}
                    onChange={(phoneemployee) =>
                      setPhoneemployee(phoneemployee)
                    }
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    marginBottom: "1.5rem",
                    // marginBottom: "35px",
                    "@media(max-width:768px)": {
                      flexDirection: "column",
                      width: "100%",
                      // border:"1px solid gray"
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    sx={{
                      width: "90%",
                      marginLeft: "1rem",
                      "@media(max-width:768px)": {
                        // border:"1px solid blue",
                        width: "105%",
                      },
                    }}
                    label="Current City"
                    value={profileDetails.currentCity}
                    onChange={(event) =>
                      handleInput("currentCity", event.target.value)
                    }
                    // className={classes.inputBox}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginBottom: "30px",
                    marginLeft: "24px",

                    "@media(max-width:768px)": {
                      flexDirection: "column",
                      width: "115%",
                      // border:"1px solid red",
                      position: "relative",
                      right: "1.8rem",
                    },
                  }}
                >
                  <Grid item sx={{ width: "87%" }}>
                    <TextField
                      select
                      label="Your Current Status"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.Setavailability}
                      onChange={(event) => {
                        handleInput("Setavailability", event.target.value);
                      }}
                    >
                      <MenuItem value="Working as Employee">
                        Working as Employee
                      </MenuItem>
                      <MenuItem value="Working as Freelancer">
                        Working as Freelancer
                      </MenuItem>
                      <MenuItem value="Not Working">Not Working </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginBottom: "30px",
                    marginLeft: "1rem",
                    // border:"1px solid red",
                    "@media(max-width:768px)": {
                      flexDirection: "column",
                      width: "100%",
                      gap: "2rem",
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: "88%",
                      marginLeft: "1rem",
                      "@media(max-width:768px)": {
                        flexDirection: "column",
                        width: "100%",
                      },
                    }}
                  >
                    <TextField
                      sx={{
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                      label="Job Title"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.jobTitleInput}
                      onChange={(event) => {
                        handleInput("jobTitleInput", event.target.value);
                      }}
                    ></TextField>
                  </Grid>

                  {profileDetails.Profilerole === "Data Entry Support" ? (
                    <Grid
                      item
                      sx={{
                        width: "40%",
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        sx={{
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        select
                        label="Role"
                        variant="outlined"
                        fullWidth
                        value={profileDetails.Role}
                        onChange={(event) => {
                          handleInput("Role", event.target.value);
                        }}
                      >
                        <MenuItem value="Accounting Support">
                          Accounting Support
                        </MenuItem>
                        <MenuItem value="Documentation Support">
                          Documentation Support
                        </MenuItem>
                        <MenuItem value="Custom Process Support">
                          Custom Process Support
                        </MenuItem>
                        <MenuItem value="Air/Sea Process Support">
                          Air/Sea Process Support
                        </MenuItem>
                        <MenuItem value="Import/Export Process Support">
                          Import/Export Process Support
                        </MenuItem>
                        <MenuItem value="Sales SupportAccount Executive">
                          Sales Support
                        </MenuItem>
                        <MenuItem value="Customer Service Support">
                          Customer Service Support
                        </MenuItem>
                      </TextField>
                    </Grid>
                  ) : profileDetails.Profilerole === "CargoWise Consultant" ? (
                    <Grid
                      item
                      sx={{
                        width: "40%",
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        sx={{
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        select
                        label="Role"
                        variant="outlined"
                        fullWidth
                        value={profileDetails.Role}
                        onChange={(event) => {
                          handleInput("Role", event.target.value);
                        }}
                      >
                        <MenuItem value="CargoWise Support">
                          CargoWise Support
                        </MenuItem>
                        <MenuItem value="ERP Consultant">
                          ERP Consultant
                        </MenuItem>
                        <MenuItem value="Power BI Consultant">
                          Power BI Consultant
                        </MenuItem>
                        <MenuItem value="CRM Consultant">
                          CRM Consultant
                        </MenuItem>
                        <MenuItem value="SAP Consultant">
                          SAP Consultant
                        </MenuItem>
                      </TextField>
                    </Grid>
                  ) : profileDetails.Profilerole === "Web Developer" ? (
                    <Grid
                      item
                      sx={{
                        width: "40%",
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        sx={{
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        select
                        label="Role"
                        variant="outlined"
                        fullWidth
                        value={profileDetails.Role}
                        onChange={(event) => {
                          handleInput("Role", event.target.value);
                        }}
                      >
                        <MenuItem value="Front End Developer">
                          Front End Developer
                        </MenuItem>
                        <MenuItem value="Back End Developer">
                          Back End Developer
                        </MenuItem>
                        <MenuItem value="Full Stack Developer">
                          Full Stack Developer
                        </MenuItem>
                        <MenuItem value="Web Designer">Web Designer</MenuItem>
                        <MenuItem value="Mobile App Developer">
                          Mobile App Developer
                        </MenuItem>
                        <MenuItem value="Android Developer">
                          Android Developer
                        </MenuItem>
                        <MenuItem value="IOS Developer">IOS Developer</MenuItem>
                        <MenuItem value="Software Engineer">
                          Software Engineer
                        </MenuItem>
                        <MenuItem value="Integration Developer">
                          Integration Developer
                        </MenuItem>
                      </TextField>
                    </Grid>
                  ) : profileDetails.Profilerole === "IT Manager" ? (
                    <Grid
                      item
                      sx={{
                        width: "40%",
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        sx={{
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        select
                        label="Role"
                        variant="outlined"
                        fullWidth
                        value={profileDetails.Role}
                        onChange={(event) => {
                          handleInput("Role", event.target.value);
                        }}
                      >
                        <MenuItem value="Help Desk Support">
                          Help Desk Support{" "}
                        </MenuItem>
                        <MenuItem value="Database Administrator">
                          Database Administrator
                        </MenuItem>
                        <MenuItem value="System Administrator">
                          System Administrator
                        </MenuItem>
                        <MenuItem value="IT Project Manager">
                          IT Project Manager
                        </MenuItem>
                        <MenuItem value="RPA Developer">RPA Developer</MenuItem>
                        <MenuItem value="AI Developer">AI Developer</MenuItem>
                        <MenuItem value="Cloud Architect">
                          Cloud Architect
                        </MenuItem>
                        <MenuItem value="Data Analyst">Data Analyst</MenuItem>
                        <MenuItem value="Cybersecurity Expert">
                          Cybersecurity Expert
                        </MenuItem>
                        <MenuItem value="IT Support">IT Support</MenuItem>
                      </TextField>
                    </Grid>
                  ) : profileDetails.Profilerole === "Sales Executive" ? (
                    <Grid
                      item
                      sx={{
                        width: "40%",
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        sx={{
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        select
                        label="Role"
                        variant="outlined"
                        fullWidth
                        value={profileDetails.Role}
                        onChange={(event) => {
                          handleInput("Role", event.target.value);
                        }}
                      >
                        <MenuItem value="Lead Generation">
                          Lead Generation{" "}
                        </MenuItem>
                        <MenuItem value="Customer Service">
                          Customer Service
                        </MenuItem>
                        <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                        <MenuItem value="Marketing Specialist">
                          Marketing Specialist
                        </MenuItem>
                        <MenuItem value="SEO Specialist">
                          SEO Specialist
                        </MenuItem>
                        <MenuItem value="Social Media Manager">
                          Social Media Manager
                        </MenuItem>
                        <MenuItem value="Social Media Specialist">
                          Social Media Specialist
                        </MenuItem>
                      </TextField>
                    </Grid>
                  ) : profileDetails.Profilerole === "Others" ? (
                    <Grid
                      item
                      sx={{
                        width: "40%",
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <TextField
                        sx={{
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        label="Role"
                        variant="outlined"
                        fullWidth
                        value={profileDetails.Role}
                        onChange={(event) => {
                          handleInput("Role", event.target.value);
                        }}
                      ></TextField>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {/* <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                      },
                    }}
                  >
                    <TextField
                      sx={{
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                      select
                      label="Role"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.Role}
                      onChange={(event) => {
                        handleInput("Role", event.target.value);
                      }}
                    >
                      <MenuItem value="Web developer">Web developer</MenuItem>
                      <MenuItem value="Front End Developer">
                        Front End Developer
                      </MenuItem>
                      <MenuItem value="Back End Developer">
                        Back End Developer
                      </MenuItem>
                      <MenuItem value="Business Analyst">
                        Business Analyst
                      </MenuItem>
                    </TextField>
                  </Grid> */}
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "3rem",
                    marginBottom: "1rem",
                    "@media(max-width:768px)": {
                      marginLeft: "1.5rem",
                    },
                  }}
                >
                  <MuiChipsInput
                    // className={classes.inputBox}
                    label="Skills"
                    variant="outlined"
                    helperText="Press enter to add skills"
                    value={profileDetails.skills}
                    onAddChip={(chip) =>
                      setProfileDetails({
                        ...profileDetails,
                        skills: [...profileDetails.skills, chip],
                      })
                    }
                    onDeleteChip={(chip, index) => {
                      let skills = profileDetails.skills;
                      skills.splice(index, 1);
                      setProfileDetails({
                        ...profileDetails,
                        skills: skills,
                        chip,
                      });
                    }}
                    fullWidth
                  />
                </Grid>

                {/* profileDetails */}
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginLeft: "24px",
                    marginBottom: "30px",
                    "@media(max-width:768px)": {
                      flexDirection: "column",
                      width: "100%",
                      gap: "2rem",
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        flexDirection: "column",
                        width: "100%",
                      },
                    }}
                  >
                    <TextField
                      sx={{
                        "@media(max-width:768px)": {
                          flexDirection: "column",
                          width: "100%",
                        },
                      }}
                      label="Working Experience in Current Job"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.experience}
                      onChange={(event) => {
                        handleInput("experience", event.target.value);
                      }}
                      type="number"
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        flexDirection: "column",
                        width: "100%",
                      },
                    }}
                  >
                    <TextField
                      sx={{
                        "@media(max-width:768px)": {
                          flexDirection: "column",
                          width: "100%",
                        },
                      }}
                      select
                      label="Notice Period"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.noticeperiod}
                      onChange={(event) => {
                        handleInput("noticeperiod", event.target.value);
                      }}
                    >
                      <MenuItem value="Immediately">Immediately</MenuItem>
                      <MenuItem value="15 days notice">15 days notice</MenuItem>
                      <MenuItem value="1 month notice">1 month notice</MenuItem>
                      <MenuItem value="2 months notice">
                        2 months notice
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginBottom: "30px",
                    marginLeft: "24px",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      display: "grid",
                      gap: "1rem",
                      gridTemplateColumns: "1fr 2fr",
                    }}
                  >
                    <TextField
                      select
                      label=" Currency"
                      variant="outlined"
                      fullWidth
                      // value={signupDetails.noticeperiod}
                      // onChange={(event) => {
                      //   handleInput("noticeperiod", event.target.value);
                      // }}
                      value={profileDetails.lastCurrencyType}
                      onChange={(e) => {
                        handleInput("lastCurrencyType", e.target.value);
                      }}
                    >
                      <MenuItem value="AUD">AUD</MenuItem>
                      <MenuItem value="CNY">CNY</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="INR">INR</MenuItem>
                      <MenuItem value="IDR">IDR</MenuItem>
                      <MenuItem value="PHP">PHP</MenuItem>
                      <MenuItem value="SGD">SGD</MenuItem>
                      <MenuItem value="ZAR">ZAR</MenuItem>

                      <MenuItem value="LKR">LKR</MenuItem>
                      <MenuItem value="TWD">TWD</MenuItem>
                      <MenuItem value="TBH">THB</MenuItem>
                      <MenuItem value="GBP">GBP</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="VND">VND</MenuItem>
                      <MenuItem value="ZWD">ZWD</MenuItem>
                    </TextField>
                    <TextField
                      label="Last Salary "
                      type="number"
                      variant="outlined"
                      value={profileDetails.lastsalary}
                      onChange={(event) => {
                        handleInput("lastsalary", Number(event.target.value));
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      display: "grid",
                      gap: "1rem",
                      gridTemplateColumns: "1fr 2fr",
                    }}
                  >
                    <TextField
                      select
                      label=" Currency"
                      variant="outlined"
                      fullWidth
                      // value={signupDetails.noticeperiod}
                      // onChange={(event) => {
                      //   handleInput("noticeperiod", event.target.value);
                      // }}
                      value={profileDetails.expectedCurrencyType}
                      onChange={(e) => {
                        handleInput("expectedCurrencyType", e.target.value);
                      }}
                    >
                      <MenuItem value="AUD">AUD</MenuItem>
                      <MenuItem value="CNY">CNY</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                      <MenuItem value="INR">INR</MenuItem>
                      <MenuItem value="IDR">IDR</MenuItem>
                      <MenuItem value="PHP">PHP</MenuItem>
                      <MenuItem value="SGD">SGD</MenuItem>
                      <MenuItem value="ZAR">ZAR</MenuItem>

                      <MenuItem value="LKR">LKR</MenuItem>
                      <MenuItem value="TWD">TWD</MenuItem>
                      <MenuItem value="TBH">THB</MenuItem>
                      <MenuItem value="GBP">GBP</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="VND">VND</MenuItem>
                      <MenuItem value="ZWD">ZWD</MenuItem>
                    </TextField>
                    <TextField
                      label="expected Salary "
                      type="number"
                      variant="outlined"
                      value={profileDetails.expectedsalary}
                      onChange={(event) => {
                        handleInput(
                          "expectedsalary",
                          Number(event.target.value)
                        );
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      minWidth: "40%",
                      marginBottom: "40px",
                      marginLeft: "24px",
                    }}
                  >
                    <TextField
                      sx={{ marginLeft: "0.5rem" }}
                      select
                      label="Country of residence"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.residenceCountry}
                      onChange={(event) => {
                        handleInput("residenceCountry", event.target.value);
                      }}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: "200px", // Set the maximum height here
                            },
                          },
                        },
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sx={{ minWidth: "40%", marginBottom: "40px" }}>
                    <TextField
                      sx={{ marginLeft: "0.8rem" }}
                      select
                      label="Country of citizenship"
                      variant="outlined"
                      fullWidth
                      value={profileDetails.citizenshipCountry}
                      onChange={(event) => {
                        handleInput("citizenshipCountry", event.target.value);
                      }}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: "200px", // Set the maximum height here
                            },
                          },
                        },
                      }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.id} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item sx={{ minWidth: "100%", marginBottom: "10px" }}>
                  <MultifieldInput
                    education={education}
                    setEducation={setEducation}
                  />
                </Grid>

                <Grid item sx={{ minWidth: "100%", marginBottom: "30px" }}>
                  <MultifieldWorkExp
                    WorkExperience={WorkExperience}
                    setWorkExperience={setWorkExperience}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    minWidth: "100%",
                    marginBottom: "40px",
                    "@media(max-width:768px)": {
                      // position:"relative",
                      // right:"2rem"
                    },
                  }}
                >
                  <MultifieldLanguage
                    Language={Language}
                    setLanguage={setLanguage}
                  />
                </Grid>

                {/* <Grid item sx={{width: "100%"}}>
                <FileUploadInput
                 // className={classes.inputBox}
                  label="Resume (.pdf)"
                  icon={<DescriptionIcon />}
                  uploadTo={apiList.uploadResume}
                  handleInput={handleInput}
                  identifier={"resume"}
                  fullWidth
                />
              </Grid> */}
                {/* <Grid item  sx={{width: "100%"}} >
                <FileUploadInput
                 // className={classes.inputBox}
                  label="Profile Photo (.jpg/.png)"
                  icon={<FaceIcon />}
                  uploadTo={apiList.uploadProfileImage}
                  handleInput={handleInput}
                  identifier={"profile"}
                />
              </Grid> */}
              </Grid>
              <Stack>
                <UploadFile
                  fileData={fileData}
                  setFileData={setFileData}
                  title="Upload your resume"
                  previewHandler={() => {
                    setResumeUrl(profileDetails.resume);
                  }}
                />
              </Stack>
              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '50px'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ padding: "10px 50px", marginTop: "30px" }}
                  onClick={() => handleUpdate()}
                >
                  Update Details
                </Button>
                {props.ats === true && (
                  <Button
                    variant="outlined"
                    sx={{ padding: "10px 50px", marginTop: "30px", color:'red', border: ' 1px solid red' }}
                    onClick={() => props.setEditApplicant(false)}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {!!resumeUrl && (
          <ResumeViewer
            data={{ url: resumeUrl, applicantName: profileDetails.name }}
            open={resumeUrl}
            handleClose={() => setResumeUrl(undefined)}
          />
        )}
      </Grid>
      {/* <Modal open={open} onClose={handleClose} className={classes.popupDialog}> */}

      {/* </Modal> */}
    </>
  );
};

export default Profile;
