import React, { useState, useEffect } from "react";
import "./styles.css";
import { Box, Container, Typography } from "@mui/material";

const words = [
  "EDISA",
  " ALBA WHEELS UP",
  "CARGOTRANS",
  "MI GESTORIA",
  "LOJISTICO",
  "ETA LOGISTICS",
  "ATLANTIS",
  "FRATOGO",
];
const animationDuration = 2000;

const WordRotator = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, animationDuration);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ py: 3, backgroundColor: "#1B2A59" }}>
      <Typography
        variant="h2"
        component="h2"
        textAlign="center"
        color="#fff"
        sx={{ p: "0px auto" }}
        fontFamily="Gordita, sans-serif"
        fontWeight="500"
      >
        Trusted by startups and enterprises in Forwarding, Logistics, Transport,
        Retail, Banking & Finance, and much more.
      </Typography>
      <div className="word-rotator">
        <h2>{words[index]}</h2>
      </div>
    </Box>
  );
};

export default WordRotator;
