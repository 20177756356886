import {
  Box,
  Button,
  MenuItem,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import apiList from "pages/authentication/lib/apiList";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { SetPopupContext } from "routes";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ApplyModal = ({ open, setOpen, jobDetail }) => {
  console.log("JobDetails:: ", jobDetail);
  const jobId = jobDetail?.id;
  const [sop, setSop] = useState("");
  const setPopup = useContext(SetPopupContext);
  const [jobDetails, setJobDetails] = useState({ ...jobDetail });

  const AddApplication = () => {
    return axios.post(
      `${apiList.jobs}/${jobId}/applications`,
      {
        sop: sop,
        screeningResponses: jobDetails?.screeningQuestions,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    );
  };

  const handleApplication = () => {
    // console.log(jobDetails);
    let temp = false;
    jobDetails?.screeningQuestions?.questions.forEach((q) => {
      if (Object.keys(q).length < 4) {
        temp = true;
      }
    });
    if (temp) {
      setPopup({
        open: true,
        severity: "error",
        message: "All screening questions are mandatory",
      });
      return;
    }
    if (!sop) {
      setPopup({
        open: true,
        severity: "error",
        message: "You have not filled the SOP",
      });
      return;
    }
    mutate();
  };
  const { mutate } = useMutation(AddApplication, {
    onSuccess: (data) => {
      setPopup({
        open: true,
        severity: "success",
        message: data?.data.message,
      });
      setOpen(false);
    },
    onError: (error) => {
      setPopup({
        open: true,
        severity: "error",
        message: error.response.data.message,
      });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose} sx={{ marginTop: "10vh" }}>
      <Paper
        sx={{
          padding: "20px 50px",
          outline: "none",
          display: "flex",
          flexDirection: "column",
          maxWidth: "50%",
          overflow: "auto",
          margin: "30px auto",
          height: jobDetails.screeningQuestions ? "80vh" : "auto",
          scrollbarWidth: "none",
          WebkitScrollbarWidth: "none",
          // justifyContent: "center",
          "@media(max-width:768px)": {
            maxWidth: "90%",
            padding: "10px",
          },
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          sx={{
            "@media(min-width:600px)": {
              display: "none",
            },
          }}
        >
          <CloseRoundedIcon
            sx={{ border: "1px solid blue", borderRadius: "2px" }}
            onClick={handleClose}
          />
        </Stack>
        {jobDetails.screeningQuestions?.questions?.map((ele, idx) => {
          return (
            <Box
              display="flex"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography
                variant="p"
                component="h3"
                width="70%"
                textAlign="left"
              >
                {ele.question}
              </Typography>
              <Typography width="30%" textAlign="right" padding={0.5}>
                {ele.answerType === "boolean" ? (
                  <TextField
                    select
                    value={ele.response ? ele.response : ""}
                    onChange={(e) => {
                      console.log(e.target.name);
                      setJobDetails((prevJobDetails) => ({
                        ...prevJobDetails,
                        screeningQuestions: {
                          questions:
                            prevJobDetails.screeningQuestions.questions?.map(
                              (q) =>
                                q.id == e.target.name
                                  ? { ...q, response: e.target.value }
                                  : q,
                            ),
                        },
                      }));
                      // console.log(jobDetails);
                    }}
                    sx={{ textAlign: "center" }}
                    name={ele.id}
                    fullWidth
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    type="number"
                    name={ele.id}
                    min={0}
                    max={10}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    value={ele.response ? ele.response : 0}
                    onChange={(e) => {
                      setJobDetails((prevJobDetails) => ({
                        ...prevJobDetails,
                        screeningQuestions: {
                          questions:
                            prevJobDetails.screeningQuestions.questions?.map(
                              (q) =>
                                q.id == e.target.name
                                  ? { ...q, response: e.target.value }
                                  : q,
                            ),
                        },
                      }));
                      // console.log(jobDetails);
                    }}
                  />
                )}
              </Typography>
            </Box>
          );
        })}
        <TextField
          label="Say something about yourself, why you are a perfect match for this job."
          multiline
          rows={8}
          sx={{
            width: "100%",
            marginBottom: "30px",
            mt: 2,
            "@media(max-width:768px)": {
              width: "100%",
            },
          }}
          variant="outlined"
          value={sop}
          onChange={(e) => setSop(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            bgcolor: "#284774",
            borderRadius: 4,
            ":hover": { bgcolor: "#284774" },
          }}
          style={{ padding: "10px 50px" }}
          onClick={handleApplication}
        >
          Submit
        </Button>
      </Paper>
    </Modal>
  );
};

export default ApplyModal;
