import { Box, Stack } from "@mui/system";
import axios from "axios";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import apiList from "pages/authentication/lib/apiList";
import React, { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Navbars from "container/layout/MainLayout/NavigationBar/Navbars";
import FeedBackDetails from "./components/FeedBackDetails";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const metaData = {
  title: "FeedBack-List | AMUS HIRING",
  description: "",
  route: "feed-back-list",
};

const FeedBackList = () => {
  const [feedbackData, setFeedbackData] = useState({
    data: [],
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const { pageSize, page } = paginationModel;
  const [selectedRow, setSelectedRow] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("Pending");

  const getFeedBackList = useCallback(async () => {
    setLoading(true);
    axios
      .get(
        `${apiList.feedback}?page=${page}&size=${pageSize}&search=${search}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .then((res) => setFeedbackData(res.data))
      .catch(console.log)
      .finally(() => setLoading((pre) => false));
  }, [pageSize, page, search, status]);

  useEffect(() => {
    getFeedBackList();
  }, [pageSize, page, status]);

  const markAsDoneFeedBack = useCallback((id) => {
    setUpdating(true);
    axios
      .put(
        `${apiList.feedback}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .then(() => {
        getFeedBackList();
        setSelectedRow(null);
      })
      .catch(() => {})
      .finally(() => setUpdating(false));
  });

  const { count, data } = feedbackData;

  const radioSx = {
    userSelect: "none",
    "&.Mui-checked": {
      color: "#083559",
    },
  };

  return (
    <>
      <ReactHelmet
        title={metaData.title}
        description={metaData.description}
        route={metaData.route}
        metaGraph={metaData}
      />
      <Box
        sx={{
          backgroundColor: "#FFF",
          width: "100%",
          maxHeight: "96vh",
          paddingTop: "7rem",
          "@media (max-width:768px)": {
            paddingTop: "6rem",
          },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "85%" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            sx={{
              p: "0 0 25px 0",
              gap: "10px",
              "@media (max-width:768px)": {
                p: "0 0 10px 0",
              },
            }}
          >
            <TextField
              sx={{
                "& label": {
                  fontSize: "18px",
                },
                "& .MuiInputBase-root": {
                  borderRadius: "4px",
                  width: "328px",
                },
                "& input": {
                  borderRadius: "4px",
                  p: "8.5px 14px",
                },
                "& fieldset": {
                  borderRadius: "4px",
                  "& legend": {
                    fontSize: "14px",
                  },
                },
              }}
              helperText=""
              id="demo-helper-text-aligned"
              label="Search"
              type="text"
              value={search}
              InputLabelProps={{ shrink: true }}
              placeholder={"Name, Email, Phone "}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      height: "inherit",
                      m: "0",
                      cursor: "pointer",
                      "&:hover": {
                        bgcolor: "#e6e2e2",
                        borderRadius: "2px",
                      },
                    }}
                    onClick={() => {
                      console.log("search");
                      getFeedBackList();
                    }}
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl>
              <RadioGroup
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "328px",
                  alignItems: "center",
                  "& label": {
                    margin: "0px",
                  },
                }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <FormControlLabel
                  value=""
                  control={<Radio sx={radioSx} />}
                  label="All"
                />
                <FormControlLabel
                  value="Pending"
                  control={<Radio sx={radioSx} />}
                  label="Pending"
                />
                <FormControlLabel
                  value="Resolved"
                  control={<Radio sx={radioSx} />}
                  label="Resolved"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <DataGrid
            sx={{
              height: "70vh",
              maxHeight: "70vh",
              "@media (max-width:768px)": {
                height: "68vh",
              },
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
              "& .MuiDataGrid-row": {
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              },
            }}
            rows={data}
            rowCount={count}
            columns={[
              {
                field: "name",
                headerName: "Name",
                width: 150,
                flex: 1,
              },
              {
                field: "email",
                headerName: "Email",
                width: 150,
                flex: 1,
              },
              {
                field: "phoneNumber",
                headerName: "Phone",
                width: 150,
                flex: 1,
              },
              {
                field: "description",
                headerName: "Description",
                width: 150,
                flex: 1,
              },
              {
                field: "notify",
                headerName: "Notify Me",
                width: 150,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Checkbox
                      checked={params.value}
                      disabled={true}
                      sx={{
                        "&.Mui-checked": {
                          color: "#083559",
                        },
                        p: "0px",
                      }}
                    />
                  );
                },
              },
              {
                field: "status",
                headerName: "Status",
                width: 150,
                flex: 1,
              },
            ]}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            checkboxSelection={false}
            pageSizeOptions={[15, 25, 35, 45]}
            paginationMode="server"
            onRowClick={(param) => {
              const { row } = param;
              setSelectedRow(row);
            }}
            rowSelection={false}
          />
        </Box>
        <Modal
          open={!!selectedRow}
          onClose={() => setSelectedRow(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <FeedBackDetails
            feedback={selectedRow}
            handleOnClose={() => setSelectedRow(null)}
            updating={updating}
            updateFeedBack={markAsDoneFeedBack}
          />
        </Modal>
      </Box>
    </>
  );
};

export default FeedBackList;
