import SpecialtyMaster from "pages/specialityMaster";
import React from "react";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
//routing new
import { createContext, useState } from "react";
//import { Grid } from "@mui/material";
import MessagePopup from "../pages/authentication/lib/MessagePopup";
import Logout from "pages/authentication/auth-forms/Logout";
//import Employee from "pages/hireEmployee/Employee";
import Home from "pages/dashboard";
import Profile from "pages/DashboardAll/EmployeeDash/Profile";
import RecruiterProfile from "pages/DashboardAll/EmployerDashboard/Profile";
import CreateJobs from "pages/DashboardAll/EmployerDashboard/CreateJobs";
import MyJobs from "pages/DashboardAll/EmployerDashboard/MyJobs";
import JobApplications from "pages/DashboardAll/EmployerDashboard/JobApplications";
import EmployeeHome from "pages/DashboardAll/EmployeeDash/EmployeeHome";
import Applications from "pages/DashboardAll/EmployeeDash/Applications";
import AcceptedApplicants from "pages/DashboardAll/EmployerDashboard/AcceptedApplicants";
import isAuth, { userType } from "../pages/authentication/lib/isAuth";
import EmployerSignup from "pages/authentication/auth-forms/EmployerSignup";
import EmployeeSignup from "pages/authentication/auth-forms/EmployeeSignup";
import ForgotPassword from "pages/authentication/auth-forms/ForgotPassword";
import ChangePassword from "pages/authentication/auth-forms/ChangePassword";
import Signup from "pages/authentication/auth-forms/Signup";
import Cookie from "pages/Cookie/Cookie";
import PrivacyPolicy from "pages/Cookie/PrivacyPolicy";
import Error from "pages/Cookie/Error";
import LegalNotice from "pages/Cookie/LegalNotice";
import RecruiterData from "pages/DashboardAll/OwnerDashboard/RecruiterData";
import ApplicantData from "pages/DashboardAll/OwnerDashboard/ApplicantData";
import JobData from "pages/DashboardAll/OwnerDashboard/JobData";
import FeedBack from "pages/feedback/FeedBack";
import FeedBackList from "pages/feedback/FeedBackList";
import NewEmployeeHome from "pages/DashboardAll/EmployeeDash/NewEmployeeHome";
import NewMyJobs from "pages/DashboardAll/EmployerDashboard/NewMyJobs";
import ProtectedRoute from "auth-route/ProtectedRoutes";
import TermsConditions from "pages/terms-and-conditions";
import AboutUs from "pages/about-us";
const Loading = lazy(() => import("../container/loading"));
const Layout = lazy(() => import("../container/layout/MainLayout"));
//const Login = lazy(()=>import("../pages/authentication/authentication3/Login3"));
//const EmployeeRegistation = lazy(()=>import("../pages/authentication/auth-forms/EmployeeRegistation"));
// const Register = lazy(()=>import("../pages/authentication/authentication3/Register3"));
const HomePage = lazy(() => import("../pages/dashboard"));
const HireEmployee = lazy(() => import("../pages/hireEmployee"));
const Login = lazy(() => import("../pages/authentication/auth-forms/Login"));
// const Signup  = lazy(()=>import("../pages/authentication/auth-forms/Signup"));
//const Applications  = lazy(()=>import("../pages/DashboardAll/EmployeeDash/Employee"));
//const  Profile = lazy(()=>import("../pages/DashboardAll/EmployeeDash/Profile"));

//const RegistrationEmployee  = lazy(()=>import("../pages/authentication/auth-forms/RegistrationEmployee"));
const EmployeeAllDetail = lazy(() =>
  import("../pages/authentication/auth-forms/EmployeeAllDetail"),
);
const GetRemote = lazy(() => import("../pages/getRemoteJob"));
const GetStarted = lazy(() => import("../pages/getStarted"));
const PricingPage = lazy(() => import("../pages/pricingPage"));
const BackOffice = lazy(() => import("../pages/backOffice"));
//const Appointments = lazy(()=>import("../pages/appointments"));
const RegisterForm = lazy(() => import("../pages/registerations"));
const ListOfInsurance = lazy(() => import("../pages/listOfInsurance"));
const PageNotFound = lazy(() => import("../pages/pagenotfound"));

//popup for alert and success messsage
export const SetPopupContext = createContext();

const RoutePath = () => {
  const [popup, setPopup] = useState({
    open: false,
    severity: "",
    message: "",
  });
  return (
    <Suspense fallback={<Loading />}>
      {/*set index.js some time*/}
      <SetPopupContext.Provider value={setPopup}>
        <Routes>
          {/* <Route path="/employeeregistation" element={<Applications/>} />  */}
          {/* <Route path="/employeeregistation" element={<Profile/>} /> */}
          {/* ------------------------------------------------------------------------------*/}
          {/* <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} /> */}

          {/* {userType() === "recruiter" ? (
                  <RecruiterProfile />
                ) : (
                  <Profile />
                )}
              </Route> */}

          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              {isAuth() && userType() === "applicant" && (
                <>
                  <Route path="/applications" element={<Applications />} />
                  <Route path="/jobs/*" element={<NewEmployeeHome />} />
                </>
              )}
              <Route path="/logout" element={<Logout />} />

              <Route
                path="/profile"
                element={
                  userType() === "recruiter" ? (
                    <RecruiterProfile />
                  ) : (
                    <Profile />
                  )
                }
              />
              {isAuth() && userType() === "recruiter" && (
                <>
                  <Route path="/addjob" element={<CreateJobs />} />
                  <Route path="/myjobs" element={<NewMyJobs />} />
                  <Route path="/employees" element={<AcceptedApplicants />} />
                </>
              )}
              {isAuth() && userType() === "owner" && (
                <>
                  <Route path="/recruiters" element={<RecruiterData />} />
                  <Route path="/applicants" element={<ApplicantData />} />
                  <Route path="/feed-back-list" element={<FeedBackList />} />
                  <Route path="/allJobs" element={<JobData />} />
                </>
              )}
              {isAuth() && userType() === "admin" && (
                <>
                  <Route path="/applicants" element={<ApplicantData />} />
                </>
              )}

              <Route
                path="/job/applications/:jobId"
                element={<JobApplications />}
              />
            </Route>
          </Route>
          <Route element={<Layout />}>
            <Route path="/HireEmployee" element={<HireEmployee />} />
            <Route path="/GetRemoteJob" element={<GetRemote />} />
            <Route path="/GetStarted" element={<GetStarted />} />
            <Route path="/PricingPage" element={<PricingPage />} />
            <Route path="/cookies-policy" element={<Cookie />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="/talk-to-expert"
              element={
                <FeedBack title={"Talk to an Expert"} display={"none"} />
              }
            />
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route
              path="/admin/frontoffice/registration"
              element={<RegisterForm />}
            />
            <Route path="/admin/backoffice" element={<BackOffice />} />
            {/* <Route
              path="/admin/services/listOfInsurance"
              element={<ListOfInsurance />}
            /> */}
            <Route path="/specialtyMaster" element={<SpecialtyMaster />} />

            <Route path="/" element={<HomePage />} />
            <Route path="/Employeesignup" element={<EmployeeSignup />} />
            <Route path="/Employersignup" element={<EmployerSignup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/employeealldetail" element={<EmployeeAllDetail />} />
            <Route
              path="/forgotpassword/newpassword/:verifyId/:token"
              element={<ChangePassword />}
            />
            <Route path="*" element={<Login />} />
          </Route>
        </Routes>

        <MessagePopup
          open={popup.open}
          setOpen={(status) =>
            setPopup({
              ...popup,
              open: status,
            })
          }
          severity={popup.severity}
          message={popup.message}
        />
      </SetPopupContext.Provider>
    </Suspense>
  );
};

export default RoutePath;
