import React from "react";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomButton from "ui-component/CustomButton";
import About from "../../asset/images/about-us.webp";
import Approach from "../../asset/images/our-approach.webp";

const metaData = {
  title: "About Us - AMUS Hiring | Leading Onsite and Remote Hiring Company",
  description:
    "Learn about AMUS Hiring, a leading onsite as well as global remote hiring company. Discover our mission, vision, and commitment to connecting top talent with employers worldwide.",
  route: "/about-us",
};

const content = [
  {
    heading: "Global Reach",
    point:
      "Access a global pool of talent spanning various industries and skillsets.",
  },
  {
    heading: "Expertise",
    point:
      " Benefit from our years of experience in remote talent acquisition and recruitment.",
  },
  {
    heading: "Efficiency",
    point:
      "Streamline your hiring process and reduce time-to-fill with our intuitive platform.",
  },
  {
    heading: "Dedicated Support",
    point:
      "Our team is committed to providing personalized support at every step of your hiring journey.",
  },
  {
    heading: "Innovation",
    point:
      "Stay ahead with our innovative solutions that adapt to the changing demands of remote work.",
  },
];

function Points({ content }) {
  return (
    <>
      {content.map((elem) => {
        return (
          <Box
            display="flex"
            sx={{
              alignItems: "baseline",
              justifyContent: "baseline",
              width: "18vw",
              height: "150px",
              fontFamily: "Gordita, sans-serif",
              "@media(max-width:768px)": {
                width: "60vw",
                alignItems: "flex-start",
                justifyContent: "baseline",
                mt: 3,
                height: "125px",
              },
            }}
          >
            <Box
              sx={{
                width: "100px",
                background: "#03265B",
                height: "10px",
                transform: "rotate(90deg)",
                "@media(max-width:768px)": {
                  transformOrigin: "top left",
                  marginInlineEnd: "1px",
                },
              }}
            ></Box>
            <Box>
              <Typography
                variant="h3"
                component="h3"
                color="#03265B"
                textAlign="left"
                fontWeight={500}
                fontFamily="Gordita, sans-serif"
              >
                {elem.heading}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="rgba(0, 0, 0, 0.5)"
              >
                {elem.point}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
}

export default function AboutUs() {
  return (
    <>
      <ReactHelmet
        title={metaData.title}
        description={metaData.description}
        route={metaData.route}
        metaGraph={metaData}
      />
      <Box
        display="flex"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 6,
          padding: "10rem",
          flexDirection: "row",
          fontFamily: "Gordita, sans-serif",
          "@media(max-width: 768px)": {
            flexDirection: "column",
            padding: "5rem 2rem",
          },
        }}
      >
        <Box
          sx={{
            flex: "1",
            "@media(max-width: 768px)": {
              width: "100%",
              textAlign: "center",
              marginBottom: "2rem",
            },
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            color="#03265B"
            sx={{ mb: 6 }}
            fontFamily="Gordita, sans-serif"
            fontWeight={500}
          >
            About Us
          </Typography>
          <Typography
            variant="body2"
            component="p"
            fontSize="17px"
            fontFamily="Gordita, sans-serif"
            color="rgba(0, 0, 0, 0.5)"
            textAlign="justify"
            fontWeight={300}
          >
            At AMUS HIRING, we specialize in connecting businesses with top-tier
            talent from around the globe. Whether you're seeking skilled
            professionals for IT projects, logistics operations, or
            administrative roles, our platform simplifies the hiring process
            with efficiency and expertise. We understand the evolving landscape
            of remote work and are dedicated to providing comprehensive
            solutions that meet the diverse needs of modern businesses.
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Link
              to="/talk-to-expert"
              style={{ textDecoration: "none", marginTop: "50px" }}
            >
              <CustomButton
                backgroundColor="#0F1B4C"
                color="#fff"
                buttonText="Get Started"
                heroBtn={true}
                marginApplied={false}
              />
            </Link>
          </Box>
        </Box>
        <Container
          sx={{
            flex: "1",
            "@media(max-width: 768px)": {
              width: "80%",
              margin: "0 auto",
            },
          }}
        >
          <img
            src={About}
            alt="about-us"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "80% 60% 90% 50% / 20% 60% 10% 40%",
              display: "block",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Container>
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          padding: "3rem",
          backgroundColor: "#F5F5F5",
          flexDirection: "row",
          "@media(max-width: 768px)": {
            flexDirection: "column",
            padding: "2rem",
          },
        }}
      >
        <Container
          sx={{
            flex: "1",
            "@media(max-width: 768px)": {
              width: "80%",
              margin: "0 auto",
              marginBottom: "2rem",
            },
          }}
        >
          <img
            src={Approach}
            alt="about-us"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Container>
        <Container
          sx={{
            flex: "1",
            "@media(max-width: 768px)": {
              width: "100%",
              textAlign: "center",
            },
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            color="#03265B"
            sx={{ mb: 6 }}
            fontFamily="Gordita, sans-serif"
            fontWeight={500}
          >
            Our Approach
          </Typography>
          <Typography
            variant="body2"
            component="p"
            fontSize="17px"
            fontFamily="Gordita, sans-serif"
            color="rgba(0, 0, 0, 0.5)"
            textAlign="justify"
            fontWeight={300}
          >
            With a commitment to excellence, AMUS HIRING leverages advanced
            technology and a vast network to deliver outstanding recruitment
            outcomes. We prioritize quality and reliability, ensuring every
            candidate we present is thoroughly vetted through our rigorous
            screening process. By focusing on both technical proficiency and
            cultural fit, we help businesses build resilient and high-performing
            teams that drive success.
          </Typography>
        </Container>
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          padding: "5rem",
          backgroundColor: "#fff",
          flexDirection: "column",
          "@media(max-width: 768px)": {
            flexDirection: "column",
            padding: "2rem",
          },
        }}
      >
        <Container>
          <Typography
            variant="h1"
            component="h2"
            color="#03265B"
            textAlign="center"
            fontFamily="Gordita, sans-serif"
            fontWeight={500}
          >
            Why Choose Us?
          </Typography>
        </Container>
        <Box
          display="flex"
          sx={{
            mt: 6,
            p: 3,
            flexDirection: "row",
            "@media(max-width: 768px)": {
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "baseline",
              gap: 2,
            },
          }}
        >
          <Points content={content} />
        </Box>
        <Typography
          variant="h3"
          component="h3"
          color="rgba(0, 0, 0, 0.5)"
          fontFamily="Gordita, sans-serif"
          fontWeight={500}
        >
          Join <span style={{ color: "#03265B" }}>AMUS HIRING</span> today and
          discover how we can empower your business with the right talent to
          thrive in a remote-first world.
        </Typography>
        <Typography
          variant="h4"
          component="p"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            fontFamily: "Gordita, sans-serif",
          }}
        >
          <Link
            to="/HireEmployee"
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <CustomButton
              backgroundColor="#0F1B4C"
              color="#fff"
              buttonText="Read More"
              heroBtn={true}
              marginApplied={false}
            />
          </Link>
        </Typography>
      </Box>
    </>
  );
}
