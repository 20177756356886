import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { io } from "socket.io-client";
import useChatHistory from "chat/hooks/useChatHistory";
import useActiveChat from "chat/hooks/useActiveChat";
import ChatBody from "chat/pages/ChatBody";
import { chat_base_url } from "chat/helper";
import ChatHistoryList from "chat/pages/ChatHistoryList";
import { chatBoundryStyle } from "../style/index";
import { isMobile } from "react-device-detect";

export const ChatContext = createContext({
  setUser: () => undefined,
  user: null,
  unReadCount: 0,
  removeChatHandler: () => undefined,
  openChatByUserId: () => undefined,
  closeSocketHandler: () => undefined,
  getUserDetails: () => undefined,
  openChatHistory: () => undefined,
});

const ChatContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const socketRef = useRef(null);
  const [openChat, setOpenChat] = useState(false);
  const [unReadCount, setUnreadcount] = useState(0);

  const {
    activeChat,
    setActiveChat,
    updateMessage,
    messageHandler,
    openChatByUserId,
    removeChatHandler,
  } = useActiveChat();

  const {
    chatHistory,
    setChatHistory,
    getChatHistory,
    updateChatHistory,
    updateSelfChatHistory,
  } = useChatHistory({ user, activeChat });

  const openChatHistory = () => {
    setOpenChat((prev) => !prev);
  };

  const getUserDetails = useCallback(() => {
    axios
      .get(`${chat_base_url}/api/v1/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(({ data = null }) => {
        if (data) {
          setUser(data);
        }
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (user) {
      const socket = io(chat_base_url, {
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      socket.on("connect", () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
        socketRef.current = socket;
      });
      socket.on("ClientMsgEvent", (msg) => {
        messageHandler(msg, (data) => {
          if (data === "Delivered") {
            // alert('New Message')
          }
          socketRef.current.emit("RecivedMsgAckEvent", {
            id: msg.id,
            senderId: msg.senderId,
            status: data,
          });
          updateChatHistory({ ...msg, status: data });
        });
      });

      socket.on("MessageDeliveredEvent", updateMessage);

      socket.on("userActiveAckEvent", (data) => {
        setActiveChat((pre) => {
          if (pre && pre.id === data.userId) {
            return {
              ...pre,
              status: data.status,
            };
          }
          return pre;
        });
        setChatHistory((pre) => {
          if (!pre.length) return pre;
          return pre.map((i) => {
            if (i.id === data.userId) {
              return {
                ...i,
                status: data.status,
              };
            }
            return i;
          });
        });
      });

      socket.on("connect_error", () => {
        console.log("error socket error++==>>");
      });

      socket.on("disconnect", () => {
        console.log("connection close!");
      });
    }
    return () => {
      socketRef.current?.disconnect();
    };
  }, [user]);

  const closeSocketHandler = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      removeChatHandler();
    }
  };

  useEffect(() => {
    setUnreadcount(
      chatHistory.reduce((acc, cur) => {
        if (cur.message.status !== "Seen" && user.id !== cur.message.senderId) {
          return acc + 1;
        }
        return acc;
      }, 0),
    );
  }, [chatHistory]);

  return (
    <ChatContext.Provider
      value={{
        setUser,
        user,
        unReadCount,
        removeChatHandler,
        openChatByUserId,
        closeSocketHandler,
        getUserDetails,
        openChatHistory,
      }}
    >
      {children}
      <Box
        sx={{
          ...chatBoundryStyle,
        }}
      >
        {!!activeChat && !!user && (
          <ChatBody
            socket={socketRef.current}
            user={user}
            setActiveChat={setActiveChat}
            activeChat={activeChat}
            updateSelfChatHistory={updateSelfChatHistory}
          />
        )}
        {!!user && openChat && (
          <ChatHistoryList
            user={user}
            unReadCount={unReadCount}
            chatHistory={chatHistory}
            getChatHistory={getChatHistory}
          />
        )}
      </Box>
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
