import { server } from 'pages/authentication/lib/apiList';
import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Stack,
    Avatar,
    IconButton
} from '@mui/material';
import ChatHeader from 'chat/components/ChatHeader';
import ChatContent from 'chat/components/ChatContent';
import ChatTextInput from 'chat/components/ChatTextInput';
import { toast } from 'react-toastify';
import { chatBodyStyle } from '../style/index'

const ChatBody = ({ socket, user, activeChat, setActiveChat, updateSelfChatHistory }) => {
    const { id, name, status, data, profile } = activeChat;
    const [text, setText] = useState('');
    const [expand, setExpand] = useState({
        height: '400px',
    });
    const msgSeenRef = useRef(new Set());
    const [reply, setReply] = useState(null);


    const sendTextHandler = async (e) => {
        e.preventDefault();
        console.log('server:', socket.connected)
        if (!socket?.connected) {
            toast.warning('Server Issue!', {
                autoClose: 2000
            });
            return;
        }
        const { id: from, } = user;
        let timeStamp = new Date().toISOString();
        const body = {
            senderId: from,
            reciverId: id,
            data: text,
            type: 'Text',
            status: 'Pending',
            replyTo: reply?.id ?? null,
            createAt: timeStamp,
        }
        setText('');
        setReply(null);
        let response = await socket.emitWithAck('ServerMsgEvent', body);
        setActiveChat(pre => ({
            ...pre,
            data: [
                ...pre.data,
                response,
            ]
        }));
        updateSelfChatHistory({ ...response });
    }

    useEffect(() => {
        if (text.length) {
            // Typing
        }
        if (!text.length) {
            // Typing false
        }
    }, [(!!text.length).toString()])

    useEffect(() => {
        if (data?.length) {
            data.forEach(msg => {
                const { id, senderId, reciverId, status } = msg;
                if (reciverId === user.id && status !== 'Seen' && !msgSeenRef.current.has(id)) {
                    socket.emit('MsgStatusChangeEvent', {
                        id,
                        status: 'Seen',
                        senderId
                    });
                    msgSeenRef.current.add(id);
                }
            });
        }
    }, [data]);

    const sendFileHandler = (e) => {

    }

    return (
        <Box
            sx={{
                ...chatBodyStyle,
                ...expand,
            }}
        >
            <Stack
                flexGrow={1}
            >
                <ChatHeader
                    name={name}
                    profile={profile}
                    status={status}
                    setExpand={setExpand}
                />
                <Stack
                    flexGrow={1}
                >
                    <ChatContent
                        chatList={data}
                        senderId={user?.id}
                        profile={activeChat.profile}
                        setReply={setReply}
                    />
                    <ChatTextInput
                        text={text}
                        setText={setText}
                        submitHandler={sendTextHandler}
                        setReply={setReply}
                        reply={reply}
                    />
                </Stack>
            </Stack>
        </Box>
    )
}

export default ChatBody