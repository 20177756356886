import ReactHelmet from 'component/react-helmet/ReactHelmet'
import React from 'react'

const SpecialtyMaster = () => {
  return (
    <>
      <ReactHelmet 
        title={'Specialty Master | AMUS HIRING'}
        description={''}
        route='specialtyMaster'
      />
      <div>Speciality Master</div>
    </>
  )
}

export default SpecialtyMaster