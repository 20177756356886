import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { chat_base_url } from 'chat/helper';


const useChatHistory = ({ user, activeChat }) => {
    const [chatHistory, setChatHistory] = useState([]);

    const getChatHistory = ({ search = '', page = 0, size = 100 }) => {
        axios
            .get(`${chat_base_url}/api/v1/chat-history?search=${search}&page=${page}&size=${size}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then(({ data = null }) => {
                if (data) {
                    if (search || !page) {
                        setChatHistory(data);
                        return;
                    }
                    setChatHistory(pre => [...pre, ...data]);
                }
            }).catch(console.log);

    }
    useEffect(() => {
        if (user) {
            getChatHistory({});
        }
    }, [user])

    const getChatHistoryBySender = useCallback((senderId, reciverId) => {
        axios
            .get(`${chat_base_url}/api/v1/chat-history/${senderId}/${reciverId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then(({ data = null }) => {
                if (data) {
                    setChatHistory(pre => {
                        if (!pre.length) return [data];
                        let set = new Set(pre.map(i => i.id));
                        if (set.has(data.id)) return pre;
                        return [data, ...pre];
                    });
                }
            }).catch(console.log);
    }, []);

    const updateChatHistory = useCallback((_data) => {
        setChatHistory(pre => {
            let flag = true;
            let res = pre.map(i => {
                if (i.id === _data.senderId) {
                    const { status, data, senderId, reciverId } = _data;
                    i.message.status = !!activeChat ? 'Seen' : status;
                    i.message.data = data;
                    i.message.senderId = senderId;
                    i.message.reciverId = reciverId;
                    flag = false;
                }
                return i;
            });
            if (flag) {
                getChatHistoryBySender(_data.senderId, _data.reciverId);
            }
            return flag ? pre : res;
        });
    }, []);

    const updateSelfChatHistory = useCallback((_data) => {
        const { reciverId, senderId, } = _data;
        setChatHistory(pre => {
            let flag = true;
            let res = pre.map(i => {
                if (i?.id === _data?.reciverId) {
                    const { data, senderId, reciverId } = _data;
                    i.message.status = 'Seen';
                    i.message.data = data;
                    i.message.senderId = senderId;
                    i.message.reciverId = reciverId;
                    flag = false;
                }
                return i;
            });
            if (flag) {
                getChatHistory({});
            }
            return flag ? pre : res;
        });
    }, [])

    useEffect(() => {
        if (activeChat) {
            const { id } = activeChat;
            setChatHistory(pre => {
                return pre.map(i => {
                    if (id === i.id) {
                        i.message.status = 'Seen';
                    }
                    return i;
                })
            });
        }
    }, [activeChat?.id]);

    return {
        chatHistory,
        setChatHistory,
        getChatHistory,
        updateChatHistory,
        updateSelfChatHistory,
    };
}

export default useChatHistory