// import { useContext, useState ,useEffect} from "react";
// import SendIcon from "@mui/icons-material/Send";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// // import Button from '@mui/material/Button';
// import {
//   Grid,
//   Button,
//   Typography,
//   Paper,
//   // Box,
//   Link as MuiLink,
// } from "@mui/material";

// import { useNavigate,NavLink } from "react-router-dom";

// import Logo from "../../../ui-component/Logo";
// import { Link } from "react-router-dom";
// import { Label } from "@mui/icons-material";
// import CountDown from "./CountDown.jsx";
// import styled from "styled-components";
// import { AppContext } from "../Components/Context";

// const Container = styled.div``;

// const Main=styled.div`
// .inputs-data{
//   #otp{
//     display: none;
//   }
// }
// `;
// const ForgotPassword = (props) => {
//   const {count,countDispatch} =useContext(AppContext);
//   console.log(count);
//     const navigate=useNavigate();
//   const [input, setInput] = useState({
//     email: "",
//     otp: "",
//   });
//   const handleInput = (e) => {
//     e.preventDefault();
//     const name = e.target.name;
//     const value = e.target.value;
//     setInput({ ...input, [name]: value });
//   };

//   const sentOtp = async () => {
// const {email}=input;
// try{
//   const response=await fetch("http://localhost:4444/auth/pass/forgot",{
//     method:"PUT",
//     headers:{
//       "Content-Type":"application/json"
//     },
//     body:JSON.stringify({
//       email
//     })
//   });
//   const data = await response.json();
//   if (response.status === 404) {
//     toast.warn("your email is wrong", {
//       autoClose: 2000,
//     });
//   }
//   if (response.status === 200) {
//     const otpId = document.getElementById("otp");
//     otpId.style.display = "block";
//     toast.success("otp sent successfully", {
//       autoClose: 2000,
//     });
//     if(otpId.style.display = "block"){
//       countDispatch({type:"CHANGE_COUNT",payload:false});
//       setTimeout(()=>{
//         countDispatch({type:"CHANGE_COUNT",payload:true});
//       },30000)

//     }else{

//       countDispatch({type:"CHANGE_COUNT",payload:true});
//     }

//   }
// } catch (error) {
//   console.log(error);
// }
// };

//   const sendAllData=async(e)=>{
//     e.preventDefault();
//     const {email,otp}=input;
// try{
//   const response=await fetch("http://localhost:4444/auth/pass/forgotPass",{
//     method:"POST",
//     headers:{
//       "Content-Type":"application/json",
//     },
//     body:JSON.stringify({
//       email,otp
//     })

//   });
//   const data = await response.json();
//   if(response.status===400){
//      toast.error("plz sent all the data",{
//          autoClose:2000,
//      })
//   }
//   if(response.status===404){
//      toast.error("your otp is incorrect",{
//          autoClose:2000,
//      })
//   }
//   if(response.status===202){
//      toast.success("your otp is correct",{
//          autoClose:2000,
//      })
//      setInput({
//      email: "",
//      otp: "",
//      })
//      navigate("/newpassword");
//   }
//  } catch (error) {
//    console.log(error);
//  }
// };
//   useEffect(()=>{
//     countDispatch({type:"CHANGE_COUNT",payload:true});
//   },[])
//   return (
//     <Container>
//        <CountDown className="countdown" />
//       <Paper
//         elevation={3}
//         sx={{
//           padding: "30px 20px",
//           width: "400px",
//           margin: "20px auto",
//           marginTop: "85px",
//         }}
//       >
//         <Grid
//           container
//           spacing={4}
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item>
//             <Link to="#">
//               <Logo width="250" />
//             </Link>
//           </Grid>
//           <Grid item>
//             <Typography
//               variant="h3"
//               component="h2"
//               sx={{
//                 fontSize: "25px",
//                 color: "#03265B",
//                 fontWeight: "700",
//                 fontFamily: "Gordita, sans-serif",
//                 marginBottom: "20px",
//               }}
//             >
//               Login
//             </Typography>
//           </Grid>
//           <Grid item sx={{ width: "80%" }}>
//             <Main>
//               <div
//                 className="inputs-data"
//                 style={{ width: "17rem", height: "2rem" }}
//               >
//                 <input
//                   type="email"
//                   name="email"
//                   id=""
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     outline: "none",
//                     cursor: "pointer",
//                     padding: "0.5rem 1rem",
//                   }}
//                   placeholder="Email"
//                   value={input.email}
//                   onChange={handleInput}
//                 />
//               </div>
//               <div
//                 className="inputs-data"
//                 style={{ width: "17rem", height: "2rem" }}
//               >
//                 <input
//                   type="number"
//                   name="otp"
//                   id="otp"
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     outline: "none",
//                     cursor: "pointer",
//                     padding: "0.5rem 1rem",
//                     marginTop: "1rem",
//                   }}
//                   placeholder="Otp"
//                   value={input.otp}
//                   onChange={handleInput}
//                 />
//               </div>
//             </Main>
//             <Button
//               variant="contained"
//               endIcon={<SendIcon />}
//               style={{ marginTop: "2rem" }}
//               onClick={sentOtp}
//             >
//               Sent otp
//             </Button>
//             <Grid>
//             <Button variant="contained" style={{marginTop:"2rem",width:"100%"}} onClick={sendAllData}>Sent Data</Button>
//             </Grid>

//           </Grid>
//           <Grid item sx={{ width: "80%" }}></Grid>
//           {/* <Grid><Link to={"/forgotPassword"} style={{textDecoration:"none"}}>Forgot Password</Link></Grid> */}
//         </Grid>
//       </Paper>
//     </Container>
//   );
// };

// export default ForgotPassword;










import { useContext, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Paper,
  // Box,
  Link as MuiLink,
} from "@mui/material";

import Logo from "../../../ui-component/Logo";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import apiList from "../lib/apiList";
import ReactHelmet from "component/react-helmet/ReactHelmet";

const ForgotPassword = (props) => {
  const [input, setInput] = useState({
    email: "",
  });
  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };

  const sentVerification = async () => {
    const { email } = input;
    try {
      const response = await fetch(
        `${apiList.forgotpassword}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        }
      );
      const data = await response.json();
      if (response.status === 400) {
        toast.error("Invalid email", {
          autoClose: 2000,
        });
      }
      if (response.status === 404) {
        toast.error("Your email does not exist", {
          autoClose: 2000,
        });
      }
      if (response.status === 200) {
        toast.success("Please check your email as a verification");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ReactHelmet 
        title={'Forget Password | AMUS HIRING'}
        description={''}
        route='forgotPassword'
      />
      <Paper
      elevation={3}
      sx={{
        padding: "30px 20px",
        width: "400px",
        margin: "20px auto",
        marginTop: "85px",
        '@media(max-width:768px)':{
          marginTop:"12rem",
          width:"100%"
                }
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Link to="#">
            <Logo width="250" />
          </Link>
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: "25px",
              color: "#03265B",
              fontWeight: "700",
              fontFamily: "Gordita, sans-serif",
              marginBottom: "20px",
            }}
          >
            Forgot Password
          </Typography>
        </Grid>
        <Grid item sx={{ width: "80%" }}>
          <div
            className="inputs-data"
            style={{ width: "17rem", height: "2rem" }}
          >
            <input
              type="email"
              name="email"
              id=""
              style={{
                width: "100%",
                height: "100%",
                outline: "none",
                cursor: "pointer",
                padding: "0.5rem 1rem",
              }}
              placeholder="Email"
              value={input.email}
              onChange={handleInput}
            />
          </div>
        </Grid>

        <Grid
          item
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            // color="primary"

            sx={{ width: "100%", bgcolor: "#03265B" }}
            onClick={sentVerification}
          >
            Sent Verification 
          </Button>
        </Grid>
        <Grid item sx={{ marginTop: "20px" }}>
        <Typography variant="body2">
         
          </Typography>
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};

export default ForgotPassword;
