import Navbars from "container/layout/MainLayout/NavigationBar/Navbars";
import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Rating,
  Slider,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SetPopupContext } from "routes";
import apiList from "pages/authentication/lib/apiList";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ForwardIcon from "@mui/icons-material/Forward";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactHelmet from "component/react-helmet/ReactHelmet";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "orange",
            color: "#fff",
          },
        },
      },
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  height: "90vh",
  overflow: "auto",
  "@media(max-width: 768px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    // alignItems: 'center',
    width: "80vw",
    height: "90%",
    overflowY: "auto",
  },
};
function BasicModal(props) {
  const { jobDetails, refetch } = props;
  const setPopup = useContext(SetPopupContext);
  // console.log(selectedJob);
  const [confirmModal, setConfirmModal] = useState(false);
  const handleClose = () => {
    props.setModalOpen(false);
    setConfirmModal(false);
  };
  // const jobDetails = jobData.filter((job, idx)=>{
  //   return job.title===selectedJob.jobTitle &&
  //   job.recruiter.name === selectedJob.recruiter && job.jobType === selectedJob.jobType &&
  //   new Date(job.deadline).toDateString() === new Date(selectedJob.deadline).toDateString() &&
  //   new Date(job.dateOfPosting).toDateString()===new Date(selectedJob.datePosted).toDateString();
  // })
  const { isLoading: isApproving, mutate: approveMutation } = useMutation(
    async ({ isApproved }) => {
      const resp = await axios.put(
        `${apiList.jobs}/${jobDetails.jobId}`,
        {
          isApproved,
          recruiterId: jobDetails.recruiterId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      return resp;
    },
    {
      onSuccess: (data) => {
        setPopup({
          open: true,
          severity: "success",
          message: data?.data.message,
        });
        refetch();
        handleClose();
      },
      onError: (err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
      },
    },
  );
  const handleApproval = () => {
    approveMutation({ isApproved: true });
  };
  const handleCancel = () => {
    approveMutation({ isApproved: false });
  };
  const { isLoading: isCancelling, mutate: cancelMutation } = useMutation(
    async () => {
      const resp = await axios.delete(
        `${apiList.jobs}/delete/${jobDetails.jobId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      return resp;
    },
    {
      onSuccess: (data) => {
        setPopup({
          open: true,
          severity: "success",
          message: data?.data.message,
        });
        refetch();
        handleClose();
      },
      onError: (err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
      },
    },
  );
  const handleDeletion = () => {
    cancelMutation();
  };

  function ConfirmationModal() {
    const [reason, setReason] = useState("");
    const handleCloseJob = () => {
      axios
        .delete(`${apiList.jobs}/${jobDetails.jobId}`, {
          data: {
            reason,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setPopup({
            open: true,
            severity: "success",
            message: response.data.message,
          });
          props.refetch();
          handleClose();
        })
        .catch((err) => {
          // console.log(err.response);
          setPopup({
            open: true,
            severity: "error",
            message: err.response.data.message,
          });
          handleClose();
        });
    };
    return (
      <Modal
        sx={{ marginTop: "250px" }}
        open={confirmModal}
        onClose={handleClose}
      >
        <Paper
          sx={{
            padding: "20px 50px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // minWidth: "30%",
            alignItems: "center",
            maxWidth: "50%",
            marginLeft: "28%",
          }}
        >
          <Typography variant="h4" style={{ marginBottom: "10px" }}>
            Are you sure?
          </Typography>
          {jobDetails.status === "Open" && (
            <TextField
              variant="outlined"
              label="Reason"
              minRows={5}
              placeholder="Reason for closing job"
              sx={{ margin: "20px auto 30px auto", width: "80%" }}
              required
              onChange={(e) => setReason(e.target.value)}
            />
          )}
          <Grid
            container
            justify="center"
            spacing={5}
            sx={{ justifyContent: "center" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleCloseJob()}
              >
                {jobDetails.status === "Close" ? "Open" : "Close"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    );
  }

  return (
    <div>
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h2"
            component="h2"
            textAlign="right"
            sx={{
              "@media(max-width:768px)": {
                position: "fixed",
                top: "20px",
                right: "20px",
              },
            }}
            onClick={handleClose}
          >
            <CancelPresentationIcon />
          </Typography>
          <Typography variant="h2" component="h2">
            {jobDetails.jobTitle}
          </Typography>
          <Typography>
            <Rating name="read-only" value={jobDetails.rating} readOnly />
          </Typography>
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              "@media(max-width: 768px)": {
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              },
            }}
          >
            <Typography variant="body2" component="p">
              Status: &nbsp;
              {!jobDetails.isApproved && !jobDetails.isReviewed && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    fontSize: "10px",
                    padding: "2px 0",
                    width: "fit-content",
                    height: "fit-content",
                    borderRadius: "10px",
                    backgroundColor: "#ffa500",
                  }}
                >
                  Pending
                </Button>
              )}
              {jobDetails.isApproved && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    fontSize: "10px",
                    padding: "2px 0",
                    width: "fit-content",
                    height: "fit-content",
                    borderRadius: "10px",
                    bgcolor: "green",
                  }}
                >
                  Approved
                </Button>
              )}
              {!jobDetails.isApproved && jobDetails.isReviewed && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    fontSize: "10px",
                    padding: "2px 0",
                    width: "fit-content",
                    height: "fit-content",
                    borderRadius: "10px",
                    bgcolor: "red",
                  }}
                >
                  Rejected
                </Button>
              )}
            </Typography>
            <Typography>
              <Stack flexDirection="row" gap="0 10px" padding={0}>
                {!jobDetails.isApproved && !jobDetails.isReviewed && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "green",
                      fontSize: "10px",
                      "&:hover": { bgcolor: "purple" },
                    }}
                    onClick={handleApproval}
                    endIcon={
                      <DoneIcon style={{ fontSize: "16px", color: "#fff" }} />
                    }
                  >
                    Approve
                  </Button>
                )}
                {!jobDetails.isApproved && !jobDetails.isReviewed && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#960018",
                      fontSize: "10px",
                      "&:hover": { bgcolor: "purple" },
                    }}
                    onClick={handleCancel}
                    endIcon={
                      <CloseIcon style={{ fontSize: "16px", color: "#fff" }} />
                    }
                  >
                    Reject
                  </Button>
                )}
                {jobDetails.isApproved && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#960018",
                      fontSize: "10px",
                      "&:hover": { bgcolor: "purple" },
                    }}
                    onClick={handleCancel}
                    endIcon={
                      <CloseIcon style={{ fontSize: "16px", color: "#fff" }} />
                    }
                  >
                    Reject
                  </Button>
                )}
                {!jobDetails.isApproved && jobDetails.isReviewed && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "green",
                      fontSize: "10px",
                      "&:hover": { bgcolor: "purple" },
                    }}
                    onClick={handleApproval}
                    endIcon={
                      <DoneIcon style={{ fontSize: "16px", color: "#fff" }} />
                    }
                  >
                    Approve
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "red",
                    fontSize: "10px",
                    "&:hover": { bgcolor: "purple" },
                  }}
                  onClick={handleDeletion}
                  endIcon={
                    <DeleteIcon style={{ fontSize: "16px", color: "#fff" }} />
                  }
                >
                  Delete
                </Button>
              </Stack>
            </Typography>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Job Posted by: {jobDetails.recruiter}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Recruiter Email : {jobDetails.recruiterEmail}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Recruiter Contact : {jobDetails.recruiterContact}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Job Type: {jobDetails.jobType}
          </Typography>
                
          {
            jobDetails?.workplaceType && 
            <Typography sx={{ mt: 2 }} variant="body2" component='p' textAlign='left'>Workplace Type : {jobDetails?.workplaceType}</Typography>
          }
          {
            ((jobDetails?.workplaceType==="Remote") && jobDetails?.preferredCountry) && 
            <Typography sx={{ mt: 2 }} variant="body2" component='p' textAlign='left'>Preferred Employee Location Country: {jobDetails?.preferredCountry}</Typography>
          }
          {
            ((jobDetails?.workplaceType==="On-Site" || jobDetails?.workplaceType==="Hybrid") && jobDetails?.jobLocationCity) && 
            <Typography sx={{ mt: 2 }} variant="body2" component='p' textAlign='left'>Job Location City : {jobDetails?.jobLocationCity}</Typography>
          }
          {
            ((jobDetails?.workplaceType==="On-Site" || jobDetails?.workplaceType==="Hybrid") && jobDetails?.jobLocationCountry) && 
            <Typography sx={{ mt: 2 }} variant="body2" component='p' textAlign='left'>Job Location Country : {jobDetails?.jobLocationCountry}</Typography>
          }

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Maximum Positions: {jobDetails.maxPositions}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Accepted Candidates: {jobDetails.acceptedCandidates || 0}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Date of Posting: {new Date(jobDetails.dateOfPosting).toDateString()}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Deadline: {new Date(jobDetails.deadline).toDateString()}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Required Experience: {jobDetails.experience}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Required Skills: {jobDetails.skillsets}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duration: {jobDetails.duration}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Job Description : {jobDetails.description}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h2"
            component="h2"
            textAlign="right"
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#03265B",
                "&:hover": { bgcolor: "purple" },
                textTransform: "none",
              }}
              onClick={() => setConfirmModal(true)}
            >
              {jobDetails.status === "Close" ? "Open Job" : "Close Job"}
            </Button>
          </Typography>
          {confirmModal && <ConfirmationModal />}
        </Box>
      </Modal>
    </div>
  );
}

function DataGridDemo(props) {
  const { paginationModel, setPaginationModel, setSelectedJob, setModalOpen } =
    props;
  return (
    <Box sx={{ height: 400, width: "80%", display: "block", margin: "0 auto" }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        rowCount={props.rowCount}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10]}
        onRowClick={(params) => {
          setSelectedJob(params.row);
          setModalOpen(true);
        }}
      />
    </Box>
  );
}

const FilterPopup = (props) => {
  // const classes = useStyles();
  const { open, handleClose, searchOptions, setSearchOptions, refetch } = props;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Paper
        sx={{
          padding: "50px",
          outline: "none",
          width: "80%",
          maxWidth: "800px",
          maxHeight: "85vh",
          overflow: "auto",
        }}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h5">Job Type</Typography>
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fullTime"
                      checked={searchOptions.jobType.fullTime}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Full Time"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="partTime"
                      checked={searchOptions.jobType.partTime}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Part Time"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="wfh"
                      checked={searchOptions.jobType.wfh}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Work From Home"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5">Job Status</Typography>
            <Grid container spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="pending"
                      checked={searchOptions.jobStatus.pending}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobStatus: {
                            ...searchOptions.jobStatus,
                            [event.target.name]: event.target.checked,
                            approved: false,
                            cancelled: false,
                          },
                        });
                      }}
                    />
                  }
                  label="Pending"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="approved"
                      checked={searchOptions.jobStatus.approved}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobStatus: {
                            ...searchOptions.jobStatus,
                            [event.target.name]: event.target.checked,
                            pending: false,
                            cancelled: false,
                          },
                        });
                      }}
                    />
                  }
                  label="Approved"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="cancelled"
                      checked={searchOptions.jobStatus.cancelled}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobStatus: {
                            ...searchOptions.jobStatus,
                            [event.target.name]: event.target.checked,
                            pending: false,
                            approved: false,
                          },
                        });
                      }}
                    />
                  }
                  label="Rejected"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>

          <Typography textAlign={"start"} variant="h5" component="p" sx={{mb:2}}>
              Workplace Type
            </Typography>
            <TextField
              variant="outlined"
              label="eg. Remote"
              fullWidth
              select
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  workplaceType: e.target.value,
                });
              }}
            >
              <MenuItem value="">Choose workplace</MenuItem>
              <MenuItem value="Remote">Remote</MenuItem>
              <MenuItem value="On-Site">On-Site</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
          </TextField>
          </Grid>

          <Grid item>
            <Typography variant="h5">Salary</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                return value * (100000 / 100);
              }}
              marks={[
                { value: 0, label: "0" },
                { value: 100, label: "100000" },
              ]}
              value={searchOptions.salary}
              onChange={(event, value) =>
                setSearchOptions({
                  ...searchOptions,
                  salary: value,
                })
              }
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">Experience</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                return Math.floor(value * (1 / 10));
              }}
              marks={[
                { value: 0, label: "0" },
                { value: 100, label: "10" },
              ]}
              value={searchOptions.experienceLevel}
              onChange={(event, value) =>
                setSearchOptions({
                  ...searchOptions,
                  experienceLevel: value,
                })
              }
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">Skills</Typography>
            <MuiChipsInput
              label="Enter skills to search"
              variant="outlined"
              helperText="Press enter to add skills"
              value={searchOptions.skills}
              onChange={(chips) => {
                setSearchOptions({
                  ...searchOptions,
                  skills: chips,
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">Duration</Typography>
            <TextField
              select
              label="Duration"
              variant="outlined"
              fullWidth
              value={searchOptions.duration}
              onChange={(event) =>
                setSearchOptions({
                  ...searchOptions,
                  duration: event.target.value,
                })
              }
            >
              <MenuItem value="Flexible">Flexible</MenuItem>
              <MenuItem value="Less than 1 month">Less than 1 month</MenuItem>
              <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
              <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
              <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
              <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
            </TextField>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
                gap: "1rem",

                // width:"15rem"
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              Sort
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={9}
              sx={{
                "@media(max-width:768px)": {
                  // border:'1px solid red',
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                sx={{
                  border: "1px solid #D1D1D1",
                  borderRadius: "5px",
                  "@media(max-width:768px)": {
                    gap: "1rem",
                    // width:"15rem"
                  },
                }}
              >
                <Grid item>
                  <Checkbox
                    name="salary"
                    checked={searchOptions.sort.salary.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          salary: {
                            ...searchOptions.sort.salary,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="salary"
                  />
                </Grid>
                <Grid item>
                  <label for="salary">
                    <Typography>Salary</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.salary.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          salary: {
                            ...searchOptions.sort.salary,
                            desc: !searchOptions.sort.salary.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.salary.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="duration"
                    checked={searchOptions.sort.duration.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          duration: {
                            ...searchOptions.sort.duration,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="duration"
                  />
                </Grid>
                <Grid item>
                  <label for="duration">
                    <Typography>Duration</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.duration.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          duration: {
                            ...searchOptions.sort.duration,
                            desc: !searchOptions.sort.duration.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.duration.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="rating"
                    checked={searchOptions.sort.rating.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="rating"
                  />
                </Grid>
                <Grid item>
                  <label for="rating">
                    <Typography>Rating</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.rating.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            desc: !searchOptions.sort.rating.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.rating.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={() => {
                refetch();
                handleClose();
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

function CircularIndeterminate() {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress />
    </Box>
  );
}
export default function JobData() {
  // function to get allJobs from database.
  const [filterOpen, setFilterOpen] = useState(false);
  const setPopup = useContext(SetPopupContext);
  const [jobData, setJobData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [sampleData, setSampleData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [rowCount, setRowCount] = useState(0);
  const [searchOptions, setSearchOptions] = React.useState({
    query: "",
    jobStatus: {
      pending: false,
      approved: false,
      cancelled: false,
    },
    jobType: {
      fullTime: false,
      partTime: false,
      wfh: false,
    },
    salary: [0, 100],
    experienceLevel: [0, 100],
    duration: "",
    skills: [],
    sort: {
      salary: {
        status: false,
        desc: false,
      },
      duration: {
        status: false,
        desc: false,
      },
      rating: {
        status: false,
        desc: false,
      },
    },
    workplaceType: ""
  });
  const getData = () => {
    let searchParams = [];
    if (searchOptions.query !== "") {
      searchParams = [...searchParams, `q=${searchOptions.query}`];
    }
    if (searchOptions.jobType.fullTime) {
      searchParams = [...searchParams, `jobType=Full%20Time`];
    }
    if (searchOptions.jobType.partTime) {
      searchParams = [...searchParams, `jobType=Part%20Time`];
    }
    if (searchOptions.jobType.wfh) {
      searchParams = [...searchParams, `jobType=Work%20From%20Home`];
    }
    if (searchOptions.salary[0] !== 0) {
      searchParams = [
        ...searchParams,
        `salaryMin=${searchOptions.salary[0] * 1000}`,
      ];
    }
    if (searchOptions.salary[1] !== 100) {
      searchParams = [
        ...searchParams,
        `salaryMax=${searchOptions.salary[1] * 1000}`,
      ];
    }
    if (searchOptions.experienceLevel[0] >= 0) {
      searchParams = [
        ...searchParams,
        `experienceMin=${Math.floor(searchOptions.experienceLevel[0] * 0.1)}`,
      ];
    }
    if (searchOptions.experienceLevel[1] <= 100) {
      searchParams = [
        ...searchParams,
        `experienceMax=${Math.floor(searchOptions.experienceLevel[1] * 0.1)}`,
      ];
    }
    if (searchOptions.duration !== "0") {
      searchParams = [...searchParams, `duration=${searchOptions.duration}`];
    }
    searchParams = [
      ...searchParams,
      `page=${paginationModel.page}`,
      `pageSize=${paginationModel.pageSize}`,
    ];
    if (searchOptions.jobStatus.pending) {
      searchParams = [...searchParams, `pending=${true}`];
    } else if (searchOptions.jobStatus.approved) {
      searchParams = [...searchParams, `approved=${true}`];
    } else if (searchOptions.jobStatus.cancelled) {
      searchParams = [...searchParams, `cancelled=${true}`];
    }
    if(searchOptions.workplaceType){
      searchParams = [
        ...searchParams,
        `workplaceType=${searchOptions.workplaceType}`
      ]
    }
    let asc = [],
      desc = [],
      skillSets = [];

    Object.keys(searchOptions.sort).forEach((obj) => {
      const item = searchOptions.sort[obj];
      if (item.status) {
        if (item.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });
    if (searchOptions.skills.length > 0) {
      searchOptions.skills.forEach((skill) => {
        skillSets = [...skillSets, `skillSets=${skill}`];
      });
    }
    searchParams = [...searchParams, ...asc, ...desc, ...skillSets];
    const queryString = searchParams.join("&");
    let address = `${apiList.owner}/jobData`;
    if (queryString !== "") {
      address = `${address}?${queryString}`;
    }
    return axios.get(address, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };
  //Query
  const { isLoading, data, refetch } = useQuery(
    ["owner-jobs", paginationModel],
    getData,
    {
      onSuccess: (data) => {
        setJobData(data?.data.jobs);
        const rowData = data?.data.jobs.map((job, idx) => {
          return {
            ...job,
            id: paginationModel.page * paginationModel.pageSize + idx + 1,
            jobTitle: job.title,
            recruiter: job.recruiter.name,
            jobType: job.jobType,
            deadline: new Date(job.deadline),
            datePosted: new Date(job.dateOfPosting),
            skillsets: job.skillsets.join(", "),
            jobId: job.id,
            isApproved: job.isApproved,
            isReviewed: job.isReviewed,
            recruiterContact: job.recruiter.contactNumber,
            recruiterEmail: job.recruiter.user.email,
          };
        });
        setPaginationModel(data?.data.paginationModel);
        setRowCount(data?.data.count);
        setSampleData(rowData);
      },
      onError: (error) => {
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      },
    },
  );
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 180,
      editable: false,
    },
    {
      field: "recruiter",
      headerName: "Recruiter Name",
      width: 180,
      editable: false,
    },
    {
      field: "jobType",
      headerName: "Job Type",
      width: 150,
      editable: false,
    },
    {
      field: "deadline",
      headerName: "Deadline",
      type: "date",
      width: 150,
      editable: false,
    },
    {
      field: "datePosted",
      headerName: "Date Posted",
      type: "date",
      width: 150,
      editable: false,
    },
    {
      field: "skillsets",
      headerName: "Skills",
      width: 150,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            {!params.row.isApproved && !params.row.isReviewed && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "10px",
                  padding: "2px 0",
                  width: "fit-content",
                  height: "fit-content",
                  borderRadius: "10px",
                  backgroundColor: "#ffa500",
                }}
              >
                Pending
              </Button>
            )}
            {params.row.isApproved && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "10px",
                  padding: "2px 0",
                  width: "fit-content",
                  height: "fit-content",
                  borderRadius: "10px",
                  bgcolor: "green",
                }}
              >
                Approved
              </Button>
            )}
            {!params.row.isApproved && params.row.isReviewed && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "10px",
                  padding: "2px 0",
                  width: "fit-content",
                  height: "fit-content",
                  borderRadius: "10px",
                  bgcolor: "red",
                }}
              >
                Rejected
              </Button>
            )}
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Details",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const onClick = (e) => {
          const currentRow = params.row;
          setSelectedJob(currentRow);
          setModalOpen(true);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={{ color: "#1246A0" }}
              size="small"
              onClick={onClick}
            >
              <ForwardIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <ReactHelmet
        title={"All Jobs | AMUS HIRING"}
        description={"Descover you desired jobs"}
        route="allJobs"
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{
          marginTop: "100px",
          padding: "30px",
          minHeight: "10vh",
          "@media(max-width:768px)": {
            // marginTop:"15rem",
            width: "100vw",
            // border:"1px solid green"
          },
        }}
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs
            sx={{
              "@media(max-width:768px)": {
                marginRight: "3rem",
              },
            }}
          >
            <Typography variant="h2">Jobs</Typography>
          </Grid>
          <Grid
            item
            xs
            sx={{
              "@media(max-width:768px)": {
                width: "100vw",
              },
            }}
          >
            <TextField
              sx={{
                "@media(max-width:768px)": {
                  width: "85vw",
                  // border:"1px solid red"
                },
              }}
              label="Search Jobs"
              value={searchOptions.query}
              onChange={(event) =>
                setSearchOptions({
                  ...searchOptions,
                  query: event.target.value,
                })
              }
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  refetch();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={refetch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // style={{ width: "60vw" }}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sx={{
              "@media(max-width:768px)": {
                marginRight: "3rem",
              },
            }}
          >
            <IconButton onClick={() => setFilterOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <FilterPopup
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        refetch={refetch}
      />
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <DataGridDemo
          rows={sampleData}
          columns={columns}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          rowCount={rowCount}
          setSelectedJob={setSelectedJob}
          setModalOpen={setModalOpen}
        />
      )}
      {modalOpen && (
        <BasicModal
          refetch={refetch}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          jobDetails={selectedJob}
        />
      )}
    </>
  );
}
