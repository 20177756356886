import React, { memo, useEffect, useRef } from "react";
import { Stack, Avatar, Box } from "@mui/material";
import { display, letterSpacing, maxWidth, positions } from "@mui/system";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import {
  chatContentStyle,
  paragraph,
  toPara,
  toStyle,
  fromPara,
  msgStatusStyle,
} from "../style/index";

const ChatContent = memo(function ({ chatList, senderId, profile, setReply }) {
  const elementRef = useRef(null);
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatList]);

  const messageStatus = (item) => {
    if (item.senderId === senderId) {
      if (item.status === "Pending")
        return <DoneRoundedIcon sx={{ ...msgStatusStyle }} />;
      if (item.status === "Delivered")
        return <DoneAllRoundedIcon sx={{ ...msgStatusStyle }} />;
      if (item.status === "Seen") {
        return (
          <img
            key={item.createAt}
            src={""}
            style={{
              width: "10px",
              height: "10px",
              position: "absolute",
              bottom: 0,
              left: "0px",
              borderRadius: "10px",
              border: "1px solid #042d6f",
            }}
          />
        );
      }
    }
    return false;
  };
  console.log("H908==>>");
  return (
    <Stack
      flexGrow={1}
      sx={{
        overflowY: "auto",
        height: 0,
      }}
    >
      {!!chatList?.length && (
        <ul
          ref={elementRef}
          style={{
            listStyle: "none",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            margin: "0px",
          }}
        >
          {chatList.map((item, index) => (
            <li
              key={item.id}
              style={{
                ...chatContentStyle,
                ...(item.senderId == senderId ? toStyle : {}),
              }}
            >
              {!!item?.reply2?.length && (
                <p
                  style={{
                    margin: "0px",
                    fontSize: "10px",
                  }}
                >
                  Replied
                </p>
              )}
              {!!item.reply2?.length &&
                item.reply2.map((reply, index) => (
                  <Box key={String(reply.id) + index}>
                    <p
                      style={{
                        margin: "0px",
                        ...paragraph,
                        borderRadius: "25px",
                        backgroundColor: "rgb(229 254 255)",
                        marginBottom: "2px",
                      }}
                    >
                      {reply.data}
                    </p>
                  </Box>
                ))}

              <Box
                sx={{
                  position: "relative",
                  "& .reply-icon": {
                    opacity: 0,
                    color: "#848a86",
                  },
                  "&:hover": {
                    "& .reply-icon": {
                      opacity: 1,
                      cursor: "pointer",
                    },
                  },
                }}
              >
                <ReplyRoundedIcon
                  className="reply-icon"
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    right: "2px",
                    fontSize: "15px",
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setReply((pre) => ({ ...item }));
                  }}
                />
                <p
                  style={{
                    margin: "0px",
                    position: "relative",
                    ...paragraph,
                    ...(item.senderId == senderId ? toPara : fromPara),
                    ...(item.senderId == senderId ? toStyle : {}),
                    ...(item.senderId !== senderId
                      ? { background: "rgb(236 236 236)" }
                      : {}),
                  }}
                >
                  {item.data}
                  {messageStatus(item)}
                </p>
                <span
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "2px",
                    fontSize: "10px",
                  }}
                ></span>
              </Box>
            </li>
          ))}
        </ul>
      )}
    </Stack>
  );
});

export default ChatContent;
