import { useState, useEffect, useContext } from "react";
// import {
//   Button,
//   Chip,
//   Grid,
//   IconButton,
//   InputAdornment,
//   makeStyles,
//   Paper,
//   TextField,
//   Typography,
//   Modal,
//   Slider,
//   FormControlLabel,
//   FormGroup,
//   MenuItem,
//   Checkbox,
// } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
// import Rating from "@material-ui/lab/Rating";
// import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// import SearchIcon from "@material-ui/icons/Search";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

//import { SetPopupContext } from "../../App";
import { SetPopupContext } from "../../../routes/index";

import apiList from "../../authentication/lib/apiList";

//import apiList from "../../lib/apiList";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Rating,
  Slider,
  TextField,
  TextareaAutosize,
  Typography,
  // makeStyles,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ReactHelmet from "component/react-helmet/ReactHelmet";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "orange",
            color: "#fff"
          }
        }
      }
    }
  }
});
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   button: {
//     width: "100%",
//     height: "100%",
//   },
//   jobTileOuter: {
//     padding: "30px",
//     margin: "20px 0",
//     boxSizing: "border-box",
//     width: "100%",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   statusBlock: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textTransform: "uppercase",
//   },
// }));

const JobTile = (props) => {
  // const classes = useStyles();
  let navigate = useNavigate();
  const { job, getData } = props;
  const setPopup = useContext(SetPopupContext);

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [jobDetails, setJobDetails] = useState(job);
  const [jobTitle, setJobTitle] = useState("");
  const [reason, setReason] = useState("");
  // console.log(jobDetails);

  const handleInput = (key, value) => {
    setJobDetails({
      ...jobDetails,
      [key]: value,
    });
  };

  const handleClick = (location) => {
    navigate(location);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseJob = () => {
    // console.log(job._id);
    axios
      .delete(`${apiList.jobs}/${job.id}`, {
        data: {
          reason
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        getData();
        handleClose();
      })
      .catch((err) => {
        // console.log(err.response);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        handleClose();
      });
  };

  const handleJobUpdate = () => {
    axios
      .put(`${apiList.jobs}/${job.id}`, jobDetails, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        getData();
        handleCloseUpdate();
      })
      .catch((err) => {
        // console.log(err.response);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        handleCloseUpdate();
      });
  };

  const postedOn = new Date(job.dateOfPosting);

  const JobTitleApi = [
    {
      id: 1,
      input: "Data Entry Support",
    },
    {
      id: 2,
      input: "Accounting Support",
    },
    {
      id: 3,
      input: "Documentation Support",
    },
    {
      id: 4,
      input: "Custom Process Support",
    },
    {
      id: 5,
      input: "Air/Sea Process Support",
    },
    {
      id: 6,
      input: "Import/Export Process Support",
    },
    {
      id: 7,
      input: "Sales Support",
    },
    {
      id: 8,
      input: "Customer Service Support",
    },
    {
      id: 9,
      input: "CargoWise Consultant",
    },
    {
      id: 10,
      input: "CargoWise Support",
    },
    {
      id: 11,
      input: "ERP Consultant",
    },
    {
      id: 12,
      input: "Power BI Consultant",
    },
    {
      id: 13,
      input: "CRM Consultant",
    },
    {
      id: 14,
      input: "SAP Consultant",
    },
    {
      id: 15,
      input: "Web Developer",
    },
    {
      id: 16,
      input: "Front End Developer",
    },
    {
      id: 17,
      input: "Back End Developer",
    },
    {
      id: 18,
      input: "Full Stack Developer",
    },
    {
      id: 19,
      input: "Web Designer",
    },
    {
      id: 20,
      input: "Mobile App Developer",
    },
    {
      id: 21,
      input: "Android Developer",
    },
    {
      id: 22,
      input: "IOS Developer",
    },
    {
      id: 23,
      input: "Software Engineer",
    },
    {
      id: 24,
      input: "Integration Developer",
    },
    {
      id: 25,
      input: "IT Manager",
    },
    {
      id: 26,
      input: "Help Desk Support",
    },
    {
      id: 27,
      input: "Database Administrator",
    },
    {
      id: 28,
      input: "System Administrator",
    },
    {
      id: 29,
      input: "IT Project Manager",
    },
    {
      id: 30,
      input: "RPA Developer",
    },
    {
      id: 31,
      input: "AI Developer",
    },
    {
      id: 32,
      input: "Cloud Architect",
    },
    {
      id: 33,
      input: "Data Analyst",
    },
    {
      id: 34,
      input: "Cybersecurity Expert",
    },
    {
      id: 35,
      input: "IT Support",
    },
    {
      id: 36,
      input: "Sales Executive",
    },
    {
      id: 37,
      input: "Lead Generation",
    },
    {
      id: 38,
      input: "Customer Service",
    },
    {
      id: 39,
      input: "Sales Manager",
    },
    {
      id: 40,
      input: "Marketing Specialist",
    },
    {
      id: 41,
      input: "SEO Specialist",
    },
    {
      id: 42,
      input: "Social Media Manager",
    },
    {
      id: 43,
      input: "Social Media Specialist",
    },
  ];

  const jobHandleFunction = (e) => {
    const jobInputData = e.target.value;
    const newJobTitleInput = e.target.value.toLowerCase();
    setJobDetails({
      ...jobDetails,
      title: jobInputData,
    });

    const filterData = JobTitleApi.filter((element) => {
      if (
        newJobTitleInput.length === null ||
        newJobTitleInput.length === 1 ||
        newJobTitleInput.length === 2 ||
        newJobTitleInput === "" ||
        newJobTitleInput.length === 0
      ) {
        return "";
      } else {
        return element.input.toLocaleLowerCase().includes(newJobTitleInput);
      }
    });
    setJobTitle(filterData);
    console.log(jobDetails.title.length);
    console.log(jobTitle);
    if (
      jobDetails.title.length === null ||
      jobDetails.title.length === 0 ||
      jobDetails.title.length === 1 ||
      jobTitle === ""
    ) {
      const jobid = document.getElementById("jobTitleId3");
      jobid.style.height = "0rem";
    } else {
      const jobid = document.getElementById("jobTitleId3");
      jobid.style.height = "8rem";
      jobid.style.overflowY = "scroll";
      if (filterData.length === 0) {
        const jobid = document.getElementById("jobTitleId3");
        jobid.style.height = "0rem";
      }
    }
  };

  const jobTitleInputFunction = (data) => {
    setJobDetails({
      ...jobDetails,
      title: data,
    });
    setJobTitle("");
    const jobid = document.getElementById("jobTitleId3");
    jobid.style.height = "0rem";
  };


  return (
    <Paper
      elevation={3}
      sx={{
        padding: "30px 20px",
        width: 1100,
        margin: "20px auto",
        marginTop: "20px",

        "@media(max-width:768px)": {
          width: "100%",
        },
      }}
    >
      <Grid
        container
        sx={{
          "@media(max-width:768px)": {
            width: "110%",
          },
        }}
      >
        <Grid
          container
          item
          xs={9}
          spacing={1}
          direction="column"
          sx={{
            "@media(max-width:768px)": {
              width: "100%",
              // border:"1px solid green"
            },
          }}
        >
          <Grid item>
            <Grid item>
              <Typography variant="h3">{job.title}</Typography>
            </Grid>
            <Grid item>
              <Rating value={job.rating !== -1 ? job.rating : null} readOnly />
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 1000,
              marginBottom: "8px",
              marginTop: "8px",
              display: "flex",
              columnGap: "80px",
              "@media(max-width:768px)": {
                flexDirection: "column",
                width: "100%",
                // border:"1px solid blue",
                marginLeft: "1.2rem",
              },
            }}
          >
            <Paper
              elevation={3}
              sx={{
                padding: "30px 20px",
                width: 300,
                margin: "20px auto",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <Grid item>
                <Grid item>Job Type : {job.jobType}</Grid>
                {/* <Grid item>Profile : {job.employeeProfile}</Grid>
                <Grid item>Job role : {job.EmployeeJobrole}</Grid> */}
                <Grid item>Approval: &nbsp;
                 {!job.isApproved && !job.isReviewed && <Button variant='contained' size='small' sx={{fontSize:'10px', padding:'2px 0', width:'fit-content', height:'fit-content', borderRadius:'10px', backgroundColor:'#ffa500'}}>Pending</Button>}
                 {job.isApproved && <Button variant='contained' size='small' sx={{fontSize:'10px', padding:'2px 0', width:'fit-content', height:'fit-content', borderRadius:'10px', bgcolor:'green'}}>Approved</Button>}
                 {!job.isApproved && jobDetails.isReviewed && <Button variant='contained' size='small' sx={{fontSize:'10px', padding:'2px 0', width:'fit-content', height:'fit-content', borderRadius:'10px', bgcolor:'red'}}>Rejected</Button>}
                </Grid>
              </Grid>
            </Paper>
            {/* <Grid item>Company : {job.employeeCompany}</Grid>
          <Grid item>Web Address : {job.employeeWeb}</Grid> */}
            {/* <Grid item>
            <Typography variant="h5">{job.title}</Typography>
          </Grid> */}
            <Paper
              elevation={3}
              sx={{
                padding: "30px 20px",
                width: 300,
                margin: "20px auto",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <Grid item>
                <Grid item> Gross Salary : &#8377; {job.salary} per month</Grid>
                <Grid item>
                  Duration :{" "}
                  {job.duration !== "" ? `${job.duration} ` : `Flexible`}
                </Grid>
                <Grid item>
                  Date Of Posting: {postedOn.toLocaleDateString()}
                </Grid>
              </Grid>
            </Paper>

            <Paper
              elevation={3}
              sx={{
                padding: "30px 20px",
                width: 300,
                margin: "20px auto",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <Grid item>
                {/* <Grid item > Experience: {job. experience} Year</Grid> */}
                <Grid item>
                  Remaining Number of Positions:{" "}
                  {job.maxPositions - job.acceptedCandidates}
                </Grid>
                <Grid item>
                  Number of skill:
                  {job.skillsets.map((skill) => (
                    <Chip label={skill} style={{ marginRight: "2px" }} />
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* <Grid item container direction="column" xs={3}> */}
          <Grid
            item
            xs
            sx={{
              marginBottom: "8px",
              marginTop: "8px",
              display: "flex",
              columnGap: "30px",
              "@media(max-width:768px)": {
                // border:"1px solid red",
                flexDirection: "column",
                gap: "1rem",
                marginLeft: "1rem",
              },
            }}
          >
            <Button
              variant="contained"
              //color="primary"
              sx={{ backgroundColor: "#03265B" }}
              // className={classes.statusBlock}
              onClick={() => handleClick(`/job/applications/${job.id}`)}
            >
              View Applications
            </Button>
            <Button
              variant="contained"
              // className={classes.statusBlock}
              onClick={() => {
                setOpenUpdate(true);
              }}
              sx={{
                background: "#03265B",
              }}
            >
              Update Details
            </Button>
            <Button
                variant="contained"
                sx={{ backgroundColor:"#03265B", textTransform:'none'}}
                color="secondary"
                // disabled = {job.status === "Close" ? true : false}
                onClick={() => {
                  setOpen(true);
                }}
              >
                {job.status==="Close" ? "Open Job" : "Close Job" }
              </Button>
          </Grid>
        </Grid>
      </Grid>
      <Modal sx={{ marginTop: "250px" }} open={open} onClose={handleClose}>
        <Paper
          sx={{
            padding: "20px 50px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // minWidth: "30%",
            alignItems: "center",
            maxWidth: "50%",
            marginLeft: "28%",
          }}
        >
          <Typography variant="h4" style={{ marginBottom: "10px" }}>
            Are you sure?
          </Typography>
          {job.status==="Open" && 
          <TextField 
          variant="outlined" 
          label="Reason" minRows={5} 
          placeholder="Reason for closing job" 
          sx={{margin:'20px auto 30px auto', width:'80%'}} 
          required
          onChange={(e)=>setReason(e.target.value)}
          />
          }
          <Grid
            container
            justify="center"
            spacing={5}
            sx={{ justifyContent: "center" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleCloseJob()}
              >
                {job.status==="Close" ? "Open" : "Close" }
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Modal
        sx={{ marginTop: "30px" }}
        open={openUpdate}
        onClose={handleCloseUpdate}
        // className={classes.popupDialog}
      >
        <Paper
          sx={{
            padding: "20px 50px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            //minWidth: "30%",
            alignItems: "center",
            maxWidth: "80%",
            maxHeight:'90vh',
            overflow:'auto',
            margin:"auto",
            // border:"1px solid red",
            "@media(max-width:768px)": {
              // width: "100%",
              // padding: "0rem 10rem",
              // position: "relative",
              // right: "4.4rem",
              // bottom: "1rem",
            },
          }}
          // bharatsaini
          // style={{

          // }}
        >
          <Typography
            variant="h4"
            sx={{
              marginTop:5,
              marginBottom: "10px",
              paddingTop: "1rem",
              "@media(max-width:768px)": {
                textAlign: "center",
                marginTop:30,
                width: "10rem",
              },
              "@media(max-width:720px)": {
                marginTop:40
              },
              "@media(max-width:580px)": {
                marginTop:55
              },
              "@media(max-width:480px)": {
                marginTop:60
              },
            }}
          >
            Update Details
          </Typography>
          <Grid
                container
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  //  border:"1px solid red",
                  "@media(max-width:768px)": {
                    flexDirection: "column",
                    width: "100%",
                    
                  },
                }}
                alignItems="stretch"
                spacing={3}
              >
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Job Title"
                    helperText="Enter more than two character"
                    value={jobDetails.title}
                    onChange={jobHandleFunction}
                    variant="outlined"
                    fullWidth
                  />

                  <Grid
                    id="jobTitleId3"
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      ":hover": {
                        // backgroundColor:"#ededed"
                      },
                      //  height:"8rem",
                      //  overflowY:"scroll"
                    }}
                  >
                    {jobTitle === ""
                      ? ""
                      : jobTitle.map((element) => {
                          return (
                            <Grid
                              onClick={() =>
                                jobTitleInputFunction(element.input)
                              }
                              sx={{
                                marginTop: "0.5rem",
                                padding: "0.2rem 0.8rem",
                                ":hover": {
                                  backgroundColor: "#ededed",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {element.input}
                            </Grid>
                          );
                        })}
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <MuiChipsInput
                    // className={classes.inputBox}
                    label="Enter Required Skills"
                    variant="outlined"
                    helperText="Press enter to add skills"
                    value={jobDetails.skillsets}
                    onChange={(chip) =>
                      setJobDetails({ ...jobDetails, skillsets: chip })
                    }
                    // onAdd={(chip) =>
                    //   setJobDetails({
                    //     ...jobDetails,
                    //     skillsets: [...jobDetails.skillsets, chip],
                    //   })
                    // }
                    // onDelete={(chip, index) => {
                    //   let skillsets = jobDetails.skillsets;
                    //   skillsets.splice(index, 1);
                    //   setJobDetails({
                    //     ...jobDetails,
                    //     skillsets: skillsets,
                    //   });
                    // }}
                    fullWidth
                  />
                </Grid>
                {/* <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Profile"
                    variant="outlined"
                    value={jobDetails.employeeProfile}
                    onChange={(event) => {
                      handleInput("employeeProfile", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Data Entry Support">
                      Data Entry Support
                    </MenuItem>
                    <MenuItem value="CargoWise Consultant">
                      CargoWise Consultant
                    </MenuItem>
                    <MenuItem value="Web Developer">Web Developer</MenuItem>
                    <MenuItem value="IT Manager">IT Manager</MenuItem>
                    <MenuItem value="Sales Executive">Sales Executive</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </TextField>
                </Grid>

                {jobDetails.employeeProfile === "Data Entry Support" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Accounting Support">
                        Accounting Support
                      </MenuItem>
                      <MenuItem value="Documentation Support">
                        Documentation Support
                      </MenuItem>
                      <MenuItem value="Custom Process Support">
                        Custom Process Support
                      </MenuItem>
                      <MenuItem value="Air/Sea Process Support">
                        Air/Sea Process Support
                      </MenuItem>
                      <MenuItem value="Import/Export Process Support">
                        Import/Export Process Support
                      </MenuItem>
                      <MenuItem value="Sales SupportAccount Executive">
                        Sales Support
                      </MenuItem>
                      <MenuItem value="Customer Service Support">
                        Customer Service Support
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "CargoWise Consultant" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="CargoWise Support">
                        CargoWise Support
                      </MenuItem>
                      <MenuItem value="ERP Consultant">ERP Consultant</MenuItem>
                      <MenuItem value="Power BI Consultant">
                        Power BI Consultant
                      </MenuItem>
                      <MenuItem value="CRM Consultant">CRM Consultant</MenuItem>
                      <MenuItem value="SAP Consultant">SAP Consultant</MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "Web Developer" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Front End Developer">
                        Front End Developer
                      </MenuItem>
                      <MenuItem value="Back End Developer">
                        Back End Developer
                      </MenuItem>
                      <MenuItem value="Full Stack Developer">
                        Full Stack Developer
                      </MenuItem>
                      <MenuItem value="Web Designer">Web Designer</MenuItem>
                      <MenuItem value="Mobile App Developer">
                        Mobile App Developer
                      </MenuItem>
                      <MenuItem value="Android Developer">
                        Android Developer
                      </MenuItem>
                      <MenuItem value="IOS Developer">IOS Developer</MenuItem>
                      <MenuItem value="Software Engineer">
                        Software Engineer
                      </MenuItem>
                      <MenuItem value="Integration Developer">
                        Integration Developer
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "IT Manager" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Help Desk Support">
                        Help Desk Support{" "}
                      </MenuItem>
                      <MenuItem value="Database Administrator">
                        Database Administrator
                      </MenuItem>
                      <MenuItem value="System Administrator">
                        System Administrator
                      </MenuItem>
                      <MenuItem value="IT Project Manager">
                        IT Project Manager
                      </MenuItem>
                      <MenuItem value="RPA Developer">RPA Developer</MenuItem>
                      <MenuItem value="AI Developer">AI Developer</MenuItem>
                      <MenuItem value="Cloud Architect">
                        Cloud Architect
                      </MenuItem>
                      <MenuItem value="Data Analyst">Data Analyst</MenuItem>
                      <MenuItem value="Cybersecurity Expert">
                        Cybersecurity Expert
                      </MenuItem>
                      <MenuItem value="IT Support">IT Support</MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "Sales Executive" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Lead Generation">
                        Lead Generation{" "}
                      </MenuItem>
                      <MenuItem value="Customer Service">
                        Customer Service
                      </MenuItem>
                      <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                      <MenuItem value="Marketing Specialist">
                        Marketing Specialist
                      </MenuItem>
                      <MenuItem value="SEO Specialist">SEO Specialist</MenuItem>
                      <MenuItem value="Social Media Manager">
                        Social Media Manager
                      </MenuItem>
                      <MenuItem value="Social Media Specialist">
                        Social Media Specialist
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "Others" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    ></TextField>
                  </Grid>
                ) : (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Accounting Support">
                        Accounting Support
                      </MenuItem>
                      <MenuItem value="Documentation Support">
                        Documentation Support
                      </MenuItem>
                      <MenuItem value="Custom Process Support">
                        Custom Process Support
                      </MenuItem>
                      <MenuItem value="Air/Sea Process Support">
                        Air/Sea Process Support
                      </MenuItem>
                      <MenuItem value="Import/Export Process Support">
                        Import/Export Process Support
                      </MenuItem>
                      <MenuItem value="Sales SupportAccount Executive">
                        Sales Support
                      </MenuItem>
                      <MenuItem value="Customer Service Support">
                        Customer Service Support
                      </MenuItem>
                      <MenuItem value="CargoWise Support">
                        CargoWise Support
                      </MenuItem>
                      <MenuItem value="ERP Consultant">ERP Consultant</MenuItem>
                      <MenuItem value="Power BI Consultant">
                        Power BI Consultant
                      </MenuItem>
                      <MenuItem value="CRM Consultant">CRM Consultant</MenuItem>
                      <MenuItem value="SAP Consultant">SAP Consultant</MenuItem>
                      <MenuItem value="Front End Developer">
                        Front End Developer
                      </MenuItem>
                      <MenuItem value="Back End Developer">
                        Back End Developer
                      </MenuItem>
                      <MenuItem value="Full Stack Developer">
                        Full Stack Developer
                      </MenuItem>
                      <MenuItem value="Web Designer">Web Designer</MenuItem>
                      <MenuItem value="Mobile App Developer">
                        Mobile App Developer
                      </MenuItem>
                      <MenuItem value="Android Developer">
                        Android Developer
                      </MenuItem>
                      <MenuItem value="IOS Developer">IOS Developer</MenuItem>
                      <MenuItem value="Software Engineer">
                        Software Engineer
                      </MenuItem>
                      <MenuItem value="Integration Developer">
                        Integration Developer
                      </MenuItem>
                      <MenuItem value="Help Desk Support">
                        Help Desk Support{" "}
                      </MenuItem>
                      <MenuItem value="Database Administrator">
                        Database Administrator
                      </MenuItem>
                      <MenuItem value="System Administrator">
                        System Administrator
                      </MenuItem>
                      <MenuItem value="IT Project Manager">
                        IT Project Manager
                      </MenuItem>
                      <MenuItem value="RPA Developer">RPA Developer</MenuItem>
                      <MenuItem value="AI Developer">AI Developer</MenuItem>
                      <MenuItem value="Cloud Architect">
                        Cloud Architect
                      </MenuItem>
                      <MenuItem value="Data Analyst">Data Analyst</MenuItem>
                      <MenuItem value="Cybersecurity Expert">
                        Cybersecurity Expert
                      </MenuItem>
                      <MenuItem value="IT Support">IT Support</MenuItem>
                      <MenuItem value="Lead Generation">
                        Lead Generation{" "}
                      </MenuItem>
                      <MenuItem value="Customer Service">
                        Customer Service
                      </MenuItem>
                      <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                      <MenuItem value="Marketing Specialist">
                        Marketing Specialist
                      </MenuItem>
                      <MenuItem value="SEO Specialist">SEO Specialist</MenuItem>
                      <MenuItem value="Social Media Manager">
                        Social Media Manager
                      </MenuItem>
                      <MenuItem value="Social Media Specialist">
                        Social Media Specialist
                      </MenuItem>
                    </TextField>
                  </Grid>
                )} */}
                {/* <Grid item sx={{width:"40%",
              '@media(max-width:768px)':{
                width:"100%",
               marginLeft:"0.7rem"
                
              }}}>
                <TextField
                    select
                    label="Select Role"
                    variant="outlined"
                    value={jobDetails.EmployeeJobrole}
                    onChange={(event) => {
                      handleInput("EmployeeJobrole", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Web developer">Web developer</MenuItem>
                    <MenuItem value="Front End Developer">Front End Developer</MenuItem>
                    <MenuItem value="Back End Developer">Back End Developer</MenuItem>
                    <MenuItem value="Junior Process Executive">Junior Process Executive</MenuItem>
                    <MenuItem value="Sales Support">Sales Support</MenuItem>
                    <MenuItem value="Account Executive">Account Executive</MenuItem>
                    <MenuItem value="Documentation Executive">Documentation Executive</MenuItem>

                    <MenuItem value="Cargowise Consultant"> Cargowise Consultant</MenuItem>
                    <MenuItem value="Cargowise Support">Cargowise Support</MenuItem>
                    <MenuItem value="ERP Consultant">ERP Consultant</MenuItem>
                    <MenuItem value="BI Consultant">BI Consultant</MenuItem>
                    <MenuItem value="CRM Consultant">CRM Consultant</MenuItem>

                  </TextField>
                </Grid> */}
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Job Type"
                    variant="outlined"
                    value={jobDetails.jobType}
                    onChange={(event) => {
                      handleInput("jobType", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                  </TextField>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Expected Duration"
                    variant="outlined"
                    value={jobDetails.duration}
                    onChange={(event) => {
                      handleInput("duration", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Flexible">Flexible</MenuItem>
                    <MenuItem value="Less than 1 month">
                      Less than 1 month
                    </MenuItem>
                    <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
                    <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
                    <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
                    <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
                    {/* <MenuItem value={6}>6 year</MenuItem> */}
                  </TextField>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Required Experience Level"
                    type="number"
                    variant="outlined"
                    value={jobDetails.experience}
                    onChange={(event) => {
                      handleInput("experience", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter No. of Positions Available"
                    type="number"
                    variant="outlined"
                    value={jobDetails.maxPositions}
                    onChange={(event) => {
                      handleInput("maxPositions", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullWidth
                  />
                </Grid>

                {/* <Grid item sx={{width:"40%"}}> 
                  <TextField
                    select
                    label="Type of Company"
                    variant="outlined"
                    value={jobDetails.employeeCompany}
                    onChange={(event) => {
                      handleInput("employeeCompany", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Startup">Startup</MenuItem>
                    <MenuItem value="SME">SME</MenuItem>
                    <MenuItem value="Enterprise">Enterprise</MenuItem>
                  </TextField>
                </Grid> */}
                {/* <Grid item sx={{width:"40%"}}> 
                <TextField
                    
                    label="Enter web address"
                    value={jobDetails.employeeWeb}
                    onChange={(event) =>
                      handleInput("employeeWeb", event.target.value)
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid> */}

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Expected Deadline For Hiring"
                    // type="datetime-local"
                    type="date"
                    value={jobDetails.deadline.split("T")[0]}
                    onChange={(event) => {
                      handleInput("deadline", event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Monthly Pay Budget (USD)"
                    type="number"
                    variant="outlined"
                    value={jobDetails.salary}
                    onChange={(event) => {
                      handleInput("salary", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                  />
                </Grid>
              </Grid>
          <Grid
            container
            justify="center"
            spacing={5}
            sx={{ justifyContent: "center", gap: "1rem", padding: "1rem" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleJobUpdate()}
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleCloseUpdate()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </Paper>
  );
};

const FilterPopup = (props) => {
  // const classes = useStyles();
  const { open, handleClose, searchOptions, setSearchOptions, getData } = props;
  return (
    <Modal open={open} onClose={handleClose} sx={{ marginTop: "16px" }}>
      <Paper
        style={{
          padding: "50px",
          outline: "none",
          maxWidth: "65%",
          marginLeft: "18%",
          // border:'1px solid red',
          // height:"150vh",
        }}
      >
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              Job Type
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fullTime"
                      checked={searchOptions.jobType.fullTime}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Full Time"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="partTime"
                      checked={searchOptions.jobType.partTime}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Part Time"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="wfh"
                      checked={searchOptions.jobType.wfh}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Work From Home"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              Profile
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ProcessExecutive"
                      checked={searchOptions.employeeProfile.ProcessExecutive}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeProfile: {
                            ...searchOptions.employeeProfile,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Process Executive"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Consultant"
                      checked={searchOptions.employeeProfile.Consultant}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeProfile: {
                            ...searchOptions.employeeProfile,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Consultant"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Developer"
                      checked={searchOptions.employeeProfile.Developer}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeProfile: {
                            ...searchOptions.employeeProfile,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Developer"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ITExpert"
                      checked={searchOptions.employeeProfile.ITExpert}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeProfile: {
                            ...searchOptions.employeeProfile,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="IT Expert"
                />
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              Enter job role
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Webdeveloper"
                      checked={searchOptions.EmployeeJobrole.Webdeveloper}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          EmployeeJobrole: {
                            ...searchOptions.EmployeeJobrole,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Web developer"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="FrontEndDeveloper"
                      checked={searchOptions.EmployeeJobrole.FrontEndDeveloper}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          EmployeeJobrole: {
                            ...searchOptions.EmployeeJobrole,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Front End Developer"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="BackEndDeveloper"
                      checked={searchOptions.EmployeeJobrole.BackEndDeveloper}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          EmployeeJobrole: {
                            ...searchOptions.EmployeeJobrole,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Back End Developer"
                />
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
                display: "none",
              },
            }}
          >
            <Grid item xs={3} sx={{ "@media(max-width:768px)": {} }}>
              Type of Company
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Startup"
                      checked={searchOptions.employeeCompany.Startup}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeCompany: {
                            ...searchOptions.employeeCompany,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Startup"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Sme"
                      checked={searchOptions.employeeCompany.Sme}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeCompany: {
                            ...searchOptions.employeeCompany,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="SME"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Enterprise"
                      checked={searchOptions.employeeCompany.Enterprise}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          employeeCompany: {
                            ...searchOptions.employeeCompany,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Enterprise"
                />
              </Grid>
            </Grid>
          </Grid> */}

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                position: "relative",
                right: "2rem",
              },
            }}
          >
            <Grid item xs={3}>
              Salary
            </Grid>
            <Grid item xs={9}>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  return value * (100000 / 100);
                }}
                marks={[
                  { value: 0, label: "0" },
                  { value: 100, label: "100000" },
                ]}
                value={searchOptions.salary}
                onChange={(event, value) =>
                  setSearchOptions({
                    ...searchOptions,
                    salary: value,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              Duration
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                "@media(max-width:768px)": {
                  position: "relative",
                  left: "1.2rem",
                },
              }}
            >
              <TextField
                select
                label="Duration"
                variant="outlined"
                fullWidth
                value={searchOptions.duration}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    duration: event.target.value,
                  })
                }
              >
                <MenuItem value="Flexible">Flexible</MenuItem>
                <MenuItem value="Less than 1 month">Less than 1 month</MenuItem>
                <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
                <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
                <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
                <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "none",
              },
            }}
          >
            <Grid item xs={3}>
              Sort
            </Grid>
            <Grid item container direction="row" xs={9}>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="salary"
                    checked={searchOptions.sort.salary.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          salary: {
                            ...searchOptions.sort.salary,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="salary"
                  />
                </Grid>
                <Grid item>
                  <label for="salary">
                    <Typography>Salary</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.salary.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          salary: {
                            ...searchOptions.sort.salary,
                            desc: !searchOptions.sort.salary.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.salary.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="duration"
                    checked={searchOptions.sort.duration.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          duration: {
                            ...searchOptions.sort.duration,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="duration"
                  />
                </Grid>
                <Grid item>
                  <label for="duration">
                    <Typography>Duration</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.duration.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          duration: {
                            ...searchOptions.sort.duration,
                            desc: !searchOptions.sort.duration.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.duration.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="rating"
                    checked={searchOptions.sort.rating.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="rating"
                  />
                </Grid>
                <Grid item>
                  <label for="rating">
                    <Typography>Rating</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.rating.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            desc: !searchOptions.sort.rating.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.rating.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={() => getData()}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

const MyJobs = (props) => {
  const setPopup = useContext(SetPopupContext);
  const [jobs, setJobs] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterCursor, setFilterCursor] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    query: "",
    jobType: {
      fullTime: false,
      partTime: false,
      wfh: false,
    },
    employeeProfile: {
      ProcessExecutive: false,
      Consultant: false,
      Developer: false,
      ITExpert: false,
    },
    EmployeeJobrole: {
      Webdeveloper: false,
      FrontEndDeveloper: false,
      BackEndDeveloper: false,
    },
    employeeCompany: {
      Startup: false,
      Sme: false,
      Enterprise: false,
    },
    salary: [0, 100],
    duration: "0",
    sort: {
      salary: {
        status: false,
        desc: false,
      },
      duration: {
        status: false,
        desc: false,
      },
      rating: {
        status: false,
        desc: false,
      },
    },
  });

   // Run once when the component mounts.
  useEffect(() => {
    getData();
  }, []);

  // window.innerHeight = An integer value indicating the window's layout viewport height in pixels.
  // document.documentElement.scrollTop = number of pixels that an element's content is scrolled vertically.
  // document.documentElement.offsetHeight = height of the document, including vertical padding and borders, as an integer.
  const handleScroll = ()=>{
    if(window.screen.width<=768){
      if ((window.innerHeight + document.documentElement.scrollTop) < (document.documentElement.offsetHeight - 800) || isLoading || cursor===0) {
        return;
      }else{
        getData();
        return;
      }
    }
    if ((window.innerHeight + document.documentElement.scrollTop) < (document.documentElement.offsetHeight - 600) || isLoading || cursor===0) {
      return;
    }
    getData();
  }

   // useEffect for handling scroll events.
   useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return ()=>{
      window.removeEventListener('scroll', handleScroll);
    }
  }, [isLoading])

  const getData = () => {
    setIsLoading(true);
    let searchParams = [`myjobs=1`];

    if (searchOptions.query !== "") {
      searchParams = [...searchParams, `q=${searchOptions.query}`];
    }
    if (searchOptions.jobType.fullTime) {
      searchParams = [...searchParams, `jobType=Full%20Time`];
    }
    if (searchOptions.jobType.partTime) {
      searchParams = [...searchParams, `jobType=Part%20Time`];
    }
    if (searchOptions.jobType.wfh) {
      searchParams = [...searchParams, `jobType=Work%20From%20Home`];
    }

    if (searchOptions.employeeProfile.ProcessExecutive) {
      searchParams = [...searchParams, `employeeProfile=Process%20Executive`];
    }
    if (searchOptions.employeeProfile.Consultant) {
      searchParams = [...searchParams, `employeeProfile=Consultant`];
    }
    if (searchOptions.employeeProfile.Developer) {
      searchParams = [...searchParams, `employeeProfile=Developer`];
    }
    if (searchOptions.employeeProfile.ITExpert) {
      searchParams = [...searchParams, `employeeProfile=IT%20Expert`];
    }

    if (searchOptions.EmployeeJobrole.Webdeveloper) {
      searchParams = [...searchParams, `EmployeeJobrole=Web%20developer`];
    }
    if (searchOptions.EmployeeJobrole.FrontEndDeveloper) {
      searchParams = [
        ...searchParams,
        `EmployeeJobrole=Front%20End%20Developer`,
      ];
    }
    if (searchOptions.EmployeeJobrole.BackEndDeveloper) {
      searchParams = [
        ...searchParams,
        `EmployeeJobrole=Back%20End%20Developer`,
      ];
    }

    if (searchOptions.employeeCompany.Startup) {
      searchParams = [...searchParams, `employeeCompany=Startup`];
    }
    if (searchOptions.employeeCompany.Sme) {
      searchParams = [...searchParams, `employeeCompany=SME`];
    }
    if (searchOptions.employeeCompany.Enterprise) {
      searchParams = [...searchParams, `employeeCompany=Enterprise`];
    }

    if (searchOptions.salary[0] !== 0) {
      searchParams = [
        ...searchParams,
        `salaryMin=${searchOptions.salary[0] * 1000}`,
      ];
    }
    if (searchOptions.salary[1] !== 100) {
      searchParams = [
        ...searchParams,
        `salaryMax=${searchOptions.salary[1] * 1000}`,
      ];
    }
    if (searchOptions.duration !== "0") {
      searchParams = [...searchParams, `duration=${searchOptions.duration}`];
    }

    searchParams = [...searchParams, `cursor=${cursor}`]
    console.log(searchParams);
    let asc = [],
      desc = [];

    Object.keys(searchOptions.sort).forEach((obj) => {
      const item = searchOptions.sort[obj];
      if (item.status) {
        if (item.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });
    searchParams = [...searchParams, ...asc, ...desc];
    const queryString = searchParams.join("&");
    // console.log(queryString);
    let address = apiList.jobs;
    if (queryString !== "") {
      address = `${address}?${queryString}`;
    }

    console.log(address);
    axios
      .get(address, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        filterCursor && cursor===0 ? setJobs(response.data.jobs) : setJobs([...jobs, ...response.data.jobs]);
        setCursor(response.data.cursor);
        // console.log(response);
      })
      .catch((err) => {
        console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      }).finally(()=>{
        setFilterCursor(false);
        setIsLoading(false)
      });
  };


  return (
    <>
      <ReactHelmet 
        title={'My Job | AMUS HIRING'}
        description={''}
        route='myjobs'
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{
          marginTop: "60px",
          padding: "30px",
          minHeight: "93vh",
          "@media(max-width:768px)": {
            marginTop: "5rem",
            width: "100vw",
            // border:"1px solid green"
          },
        }}
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs
            sx={{
              "@media(max-width:768px)": {
                marginRight: "3rem",
              },
            }}
          >
            <Typography variant="h2">My Jobs</Typography>
          </Grid>
          <Grid
            item
            xs
            sx={{
              "@media(max-width:768px)": {
                width: "100vw",
              },
            }}
          >
            <TextField
              sx={{
                "@media(max-width:768px)": {
                  width: "85vw",
                  // border:"1px solid red"
                },
              }}
              label="Search Jobs"
              value={searchOptions.query}
              onChange={(event) =>
                setSearchOptions({
                  ...searchOptions,
                  query: event.target.value,
                })
              }
              onKeyDown={(ev) => {
                if (ev.key == "Enter") {
                  setCursor(0);
                  setFilterCursor(true);
                  setFilterOpen(false); 
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={() =>{
                      setCursor(0);
                      setFilterCursor(true);
                      setFilterOpen(false); 
                    }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // style={{ width: "500px" }}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sx={{
              "@media(max-width:768px)": {
                marginRight: "3rem",
              },
            }}
          >
            <IconButton onClick={() => setFilterOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs
          direction="column"
          alignItems="stretch"
          justify="center"
        >
          {jobs.length > 0 ? (
            jobs.map((job) => {
              return <JobTile job={job} getData={getData} />;
            })
          ) : (
            <Typography variant="h5" style={{ textAlign: "center" }}>
              No jobs found
            </Typography>
          )}
        </Grid>
      </Grid>
      <FilterPopup
        open={filterOpen}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        handleClose={() => setFilterOpen(false)}
        getData={() => {
          setCursor(0);
          setFilterCursor(true);
          setFilterOpen(false); 
        }}
      />
    </>
  );
};

export default MyJobs;
