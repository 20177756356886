import zIndex from "@mui/material/styles/zIndex"
import { borderRadius, height, margin, maxWidth } from "@mui/system"

export const paragraph = {
    borderBottomLeftRadius: '10px',
    borderTopRightRadius: '10px',
    backgroundColor: 'rgb(232 255 234)',
    width: 'fit-content',
    padding: '10px',
    fontSize: '0.9rem',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
}

export const chatContentStyle = {
    width: 'fit-content',
    maxWidth: '90%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '18px',
}
export const toStyle = {
    alignSelf: "flex-end"
}
export const fromPara = {
    borderBottomRightRadius: '10px',
}
export const toPara = {
    borderTopLeftRadius: '10px',
}

export const msgStatusStyle = {
    fontSize: '13px',
    position: 'absolute',
    bottom: 0,
    right: '2px'
}

export const replyParaStyle = {
    borderRadius: '8px',
    backgroundColor: 'rgb(232 255 234)',
    width: 'fit-content',
    padding: '10px',
    fontSize: '0.9rem',
    letterSpacing: '0.1px',
    wordBreak: 'break-word',
    margin: '2px 0px'
}

export const chatBoundryStyle = {
    position: 'fixed',
    bottom: '0px',
    padding: '0px 10px',
    right: '0px',
    zIndex: 999,
    display: 'flex',
    gap: '10px',
    "@media (max-width:600px)": {
        width: '100%',
        right: '0px'
    },
    justifyContent: 'space-around'
}

export const chatHistoryBodyStyle = {
    width: '250px',
    bgcolor: '#fff',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    boxShadow: '0px 1px 3px #948f8f',
    transitionDuration: '160ms',
    alignSelf: 'end',
    "@media (max-width:600px)": {
        width: '99%',
        position: 'absolute',
        zIndex: 8,
    },
}

export const chatBodyStyle = {
    maxHeight: 'calc(100vh - 100px)',
    bgcolor: '#fff',
    boxShadow: '0px 1px 3px #948f8f',
    overflow: 'hidden',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    transition: 'width 67ms, height 160ms, transform 160ms',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'end',
    width: '336px',
    maxWidth: '500px',
    "@media (max-width:600px)": {
        width: '99%',
        height: 'calc(100vh - 100px)',
        position: 'absolute',
        zIndex: 10,
        maxWidth: '100%'
    },
}