import React from 'react';

import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import './App.css';
import NavigationScroll from './container/layout/NavigationScroll';
import RoutePath from './routes';
import themes from './asset/themes';
// import {QueryClientProvider, QueryClient} from 'react-query';

function App() {
  const customization = useSelector((state) => state.customization);
  // const queryClient = new QueryClient();

  return (
    // <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <RoutePath />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    // </QueryClientProvider>
  );
}

export default App;
