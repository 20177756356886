import React, { memo, useContext, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Badge,
  Avatar,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ChatContext } from "chat/context/ChatContextProvider";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import InfiniteScroll from "react-infinite-scroll-component";
import useChatHistory from "chat/hooks/useChatHistory";
import { chatHistoryBodyStyle } from "../style/index";

const ChatHistoryList = memo(function ({
  user,
  chatHistory,
  getChatHistory,
  unReadCount,
}) {
  const { id, profile, name } = user;
  const [expand, setExpand] = useState({
    transform: undefined,
  });
  const { openChatByUserId } = useContext(ChatContext);
  const [historyQuery, setHistoryQuery] = useState({
    page: 0,
    size: 100,
    search: "",
  });

  useEffect(() => {
    if (!historyQuery.search) {
      getChatHistory(historyQuery);
    }
  }, [historyQuery.search]);

  const flex = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  };

  const typography = {
    fontSize: "12px",
    letterSpacing: "0.1px",
  };

  const miniMizeHandler = (e) => {
    e.stopPropagation();
    setExpand((pre) => {
      const { transform, ...rest } = pre;
      if (!pre.transform) {
        rest.transform = "translateY(100%) translateY(-48px);";
      }
      return {
        ...rest,
      };
    });
  };

  const fetchMore = () => {
    let { page, size } = historyQuery;
    page = page + 1;
    setHistoryQuery((pre) => ({ ...pre, page, size }));
    getChatHistory({ page, size });
  };

  return (
    <Box
      sx={{
        ...chatHistoryBodyStyle,
        ...expand,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          borderBottom: "2px solid #dbdada",
          minHeight: "48px",
          p: "0px 10px",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "#f8fafd",
          },
        }}
        onClick={miniMizeHandler}
      >
        <Stack
          sx={{
            ...flex,
            gap: "10px",
          }}
        >
          <Badge
            color="secondary"
            variant="dot"
            invisible={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                bottom: "6px",
                right: "3px",
                bgcolor: "green",
              },
            }}
          >
            <Avatar
              src={profile}
              children={name?.slice(0, 1)}
              sx={{ width: "32px", height: "32px" }}
            />
          </Badge>
          <Typography sx={{ ...typography }} component="h2">
            Messaging
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <Badge
            badgeContent={unReadCount}
            color="success"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiBadge-badge": {
                bgcolor: "#00e676",
              },
            }}
          >
            <NotificationsNoneRoundedIcon />
          </Badge>
          <IconButton onClick={miniMizeHandler} aria-label="delete" sx={{}}>
            {!!expand.transform ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </Stack>
      </Stack>
      <Stack p={"0 10px"} direction={"row"} alignItems={"center"}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          sx={{
            "& .MuiInputBase-root": {
              background: "none",
            },
            flexGrow: 1,
            "& fieldset": {
              border: "none",
            },
            "& input": {
              p: "6px 5px",
              borderRadius: "3px",
              m: "5px 0px",
              background: "#e8f2fc",
            },
          }}
          placeholder="Name"
          onChange={(e) => {
            setHistoryQuery((pre) => ({
              ...pre,
              search: e.target.value,
            }));
          }}
        />
        <IconButton
          onClick={() => {
            getChatHistory(historyQuery);
          }}
          aria-label="delete"
          sx={{}}
        >
          <SearchOutlinedIcon />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 190px)",
        }}
      >
        <InfiniteScroll
          dataLength={chatHistory.length}
          next={fetchMore}
          height={"calc(100vh - 190px)"}
          hasMore={!!chatHistory.length}
          loader={<p style={{ textAlign: "center" }}>Fetching...</p>}
        >
          {chatHistory.map((item, index) => (
            <Stack
              key={item.id}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "stretch",
                p: "5px 10px",
                gap: "10px",
                fontSize: "13px",
                transition: "background-color 200ms linear",
                "&: hover": {
                  bgcolor: "#eaeff0",
                  cursor: "pointer",
                },
                borderTop: "1px solid #e2e2e2",
                borderBottom:
                  chatHistory.length - 1 === index
                    ? "1px solid #e2e2e2"
                    : "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
                openChatByUserId(item.id);
              }}
            >
              <Box>
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={item.status !== "online"}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      bottom: "6px",
                      right: "6px",
                      bgcolor: "green",
                    },
                  }}
                >
                  <Avatar
                    src={item.profile}
                    children={item.name?.slice(0, 1)}
                  />
                </Badge>
              </Box>
              <Stack flexGrow={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Typography sx={{ fontSize: "inherit" }}>
                      {item.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "inherit" }}>
                      {item.lastSeen}
                    </Typography>
                  </Box>
                </Stack>
                <Stack>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "inherit",
                        height: "33px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "172px",
                        height: "17px",
                        fontWeight:
                          ["Pending", "Delivered"].includes(
                            item.message.status,
                          ) && id !== item.message.senderId
                            ? 600
                            : 500,
                      }}
                    >
                      {item.message.data}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </InfiniteScroll>
      </Stack>
    </Box>
  );
});

export default ChatHistoryList;
