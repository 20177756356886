import React from "react";
import { Grid, Box, Container, Typography, Card } from "@mui/material";
import { styled } from "@mui/system";
import Remote from "../../asset/images/remote-employee.webp";
import QuickHire from "../../asset/images/hire-quick.webp";
import CustomButton from "../../ui-component/CustomButton";
import { Link } from "react-router-dom";
import "./styles.css";
import WordRotator from "./SiderComponent";

const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  padding: theme.spacing(2),
}));

// const HeadingText = styled(Typography)(({ theme }) => ({
//   fontSize: "40px",
//   color: "#03265B",
//   fontWeight: "700",
//   my: 3,
//   [theme.breakpoints.down("md")]: {
//     fontSize: "35px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "28px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     fontSize: "22px",
//   },
// }));

// const BodyText = styled(Typography)(({ theme }) => ({
//   fontSize: "24px",
//   // fontFamily:"Popine",
//   fontWeight: "300",
//   color: "rgba(0, 0, 0, 0.7)",
//   lineHeight: "30px",
//   marginBottom: "2.5rem",
//   [theme.breakpoints.down("md")]: {
//     fontSize: "22px",
//   },
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "20px",
//   },
//   [theme.breakpoints.down("xs")]: {
//     fontSize: "18px",
//   },
// }));

// const Divider = styled("div")(({ theme }) => ({
//   width: "25%",
//   height: "5px",
//   backgroundColor: "#000339",
//   marginLeft: theme.spacing(2),
//   [theme.breakpoints.down("md")]: {
//     width: "35%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "50%",
//   },
// }));

// const DividerText = styled(Typography)(({ theme }) => ({
//   fontSize: "28px",
//   color: "#03265B",
//   fontWeight: "700",
//   my: 3,
//   [theme.breakpoints.down("md")]: {
//     fontSize: "22px",
//   },
// }));

const Details = () => {
  return (
    <>
      <Box
        sx={{
          py: 10,
          backgroundColor: "#F5F5F5",
          borderRadius: "10px",
          fontFamily: "Gordita, sans-serif",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          textAlign="center"
          color="#03265B"
          fontFamily="Gordita, sans-serif"
          fontWeight= "500"
        >
          Unlock Excellence with AMUS HIRING Solutions - Connecting Talent,
          Driving Success
        </Typography>
        <br />
        <br />
        <Container>
          <Grid container direction={{ xs: "column", sm: "row" }} spacing={5}>
            <Grid item xs={12} sm={4} md={4}>
              <Card
                sx={{
                  p: 3,
                  height: "400px",
                  position: "relative",
                  "@media(max-width:'768px')": { height: "auto" },
                }}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  color="#03265B"
                  textAlign="center"
                  fontFamily="Gordita, sans-serif"
                  fontWeight= "500"
                >
                  Staff Outsourcing
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(0, 0, 0, 0.5)",
                    fontWeight: "500",
                    fontFamily: "Gordita, sans-serif",
                    mt: 3,
                    mb: 1,
                    "@media(max-width:768px)": {
                      textAlign: "center",
                    },
                  }}
                >
                  Optimize your operations by outsourcing Back-office staff and
                  Technical Staff. Top Talent ready to join your team at
                  affordable pricing starting from 650€/month only.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(3, 38, 91, 0.92)",
                    my: 1,
                    fontFamily: "Gordita, sans-serif",
                    "@media(max-width:768px)": {
                      // textAlign:"center",
                      display: "block",
                      position: "static",
                      top: 0,
                    },
                    position: "absolute",
                    left: "1em",
                    top: "50%",
                  }}
                >
                  <ul>
                    <li>Submit your request. </li>
                    <li>We will assign the dedicated staff to you.</li>
                    <li>Start working. </li>
                  </ul>
                </Typography>
                <Link to="/talk-to-expert" className="link-class">
                  <CustomButton
                    backgroundColor="#0F1B4C"
                    color="#fff"
                    buttonText="Book Now"
                    heroBtn={true}
                    marginApplied={true}
                  />
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Card
                sx={{
                  p: 3,
                  height: "400px",
                  position: "relative",
                  "@media(max-width:'768px')": { height: "auto" },
                }}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  color="#03265B"
                  textAlign="center"
                  fontFamily="Gordita, sans-serif"
                  fontWeight= "500"
                >
                  Recruitment Partner
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(0, 0, 0, 0.5)",
                    fontWeight: "500",
                    fontFamily: "Gordita, sans-serif",
                    mt: 3,
                    mb: 1,
                    "@media(max-width:768px)": {
                      textAlign: "center",
                    },
                  }}
                >
                  Looking to hire remote or onsite employees on your payroll? We
                  can help you hire the top talent at affordable pricing.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(3, 38, 91, 0.92)",
                    my: 1,
                    fontFamily: "Gordita, sans-serif",
                    "@media(max-width:768px)": {
                      // textAlign:"center",
                      display: "block",
                      position: "static",
                      top: 0,
                    },
                    position: "absolute",
                    left: "1em",
                    top: "50%",
                  }}
                >
                  <ul>
                    <li>Submit your request online. </li>
                    <li>We find the top talent for you.</li>
                    <li>Start working </li>
                  </ul>
                </Typography>
                <Link to="/talk-to-expert" className="link-class">
                  <CustomButton
                    backgroundColor="#0F1B4C"
                    color="#fff"
                    buttonText="Book Now"
                    heroBtn={true}
                    marginApplied={true}
                  />
                </Link>
              </Card>
            </Grid>
            <br />
            <Grid item xs={12} sm={4} md={4}>
              <Card
                sx={{
                  p: 3,
                  height: "400px",
                  position: "relative",
                  "@media(max-width:'768px')": { height: "auto" },
                }}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  color="#03265B"
                  textAlign="center"
                  fontFamily="Gordita, sans-serif"
                  fontWeight= "500"
                >
                  Self-Hiring
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(0, 0, 0, 0.5)",
                    fontWeight: "500",
                    fontFamily: "Gordita, sans-serif",
                    mt: 3,
                    mb: 1,
                    "@media(max-width:768px)": {
                      textAlign: "center",
                    },
                  }}
                >
                  Hire remote or onsite staff globally from our pool of talent.
                  Post a job and hire employees in any country.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    color: "rgba(3, 38, 91, 0.92)",
                    my: 1,
                    fontFamily: "Gordita, sans-serif",
                    "@media(max-width:768px)": {
                      // textAlign:"center",
                      display: "block",
                      position: "static",
                      top: 0,
                    },
                    position: "absolute",
                    left: "1em",
                    top: "50%",
                  }}
                >
                  <ul>
                    <li>Sign up as employer. </li>
                    <li>Post a job.</li>
                    <li>Hire staff globally.</li>
                  </ul>
                </Typography>
                <Link to="/Employersignup" className="link-class">
                  <CustomButton
                    backgroundColor="#0F1B4C"
                    color="#fff"
                    buttonText="Hire Now"
                    heroBtn={true}
                    marginApplied={true}
                  />
                </Link>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <WordRotator />
      <Box sx={{ py: 5, backgroundColor: "#fff", borderRadius: "10px" }}>
        <Container>
          <Grid
            container
            spacing={3}
            direction={{ xs: "column-reverse", sm: "row" }}
            sx={{ placeItems: "center" }}
          >
            <Grid item xs={12} sm={7} md={7}>
              <Typography
                variant="h2"
                component="h2"
                color="#03265B"
                fontWeight= "500"
                fontFamily= "Gordita, sans-serif"
                sx={{
                  "@media(max-width:768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Full Time Remote Employees
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "18px",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "500",
                  fontFamily: "Gordita, sans-serif",
                  mt: 2,
                  mb: 1,
                  "@media(max-width:768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Are you looking for remote employees or full-time dedicated
                employees to work from anywhere? AMUS HIRING offers a
                comprehensive platform where you can quickly hire for remote
                working positions and work-from-home employees.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <ImgContainer>
                <img
                  src={Remote}
                  alt="house"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </ImgContainer>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction={{ xs: "column-reverse", sm: "row" }}
            sx={{ placeItems: "center", marginTop: -6 }}
          >
            <Grid item xs={12} sm={7} md={7}>
              <Typography
                variant="h2"
                component="h2"
                color="#03265B"
                fontFamily= "Gordita, sans-serif"
                fontWeight= "500"
                sx={{
                  "@media(max-width:768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Find Remote Workers Quickly
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "18px",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontWeight: "500",
                  fontFamily: "Gordita, sans-serif",
                  mt: 2,
                  mb: 1,
                  "@media(max-width:768px)": {
                    textAlign: "center",
                  },
                }}
              >
                Discover talented professionals proficient in English, Spanish,
                French, and more languages. Our extensive database features
                skilled individuals ready to join your team remotely. Whether
                you need technical expertise or non-technical support, we have
                the right candidates for you.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <ImgContainer>
                <img
                  src={QuickHire}
                  alt="house"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </ImgContainer>
            </Grid>
          </Grid>
          <Grid
            sx={{
              "@media(max-width:768px)": {
                width: "70%",
                margin: "auto",
                // border:"1px solid red"
              },
            }}
          >
            <Link to="/Employersignup" style={{ textDecoration: "none" }}>
              <CustomButton
                backgroundColor="#0F1B4C"
                color="#fff"
                buttonText="Hire Now"
                heroBtn={true}
                marginApplied={false}
              />
            </Link>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Details;
