import Navbars from "container/layout/MainLayout/NavigationBar/Navbars";
import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Rating,
  Slider,
  TextField,
  Typography,
  Stack,
  Input,
} from "@mui/material";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SetPopupContext } from "routes";
import apiList from "pages/authentication/lib/apiList";
import { useQuery } from "react-query";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ForwardIcon from "@mui/icons-material/Forward";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import { userType } from "pages/authentication/lib/isAuth";
import ResumeViewer from "../../../component/resumeViewer/ResumeViewer";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ApplicantVerificationModal from "./ApplicantVerifyModal";
import CheckCircle from "@mui/icons-material/CheckCircle";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import Profile from "../EmployeeDash/Profile";
import EditIcon from "@mui/icons-material/Edit";

function DataGridDemo(props) {
  const {
    paginationModel,
    setPaginationModel,
    setSelectedApplicant,
    setModalOpen,
  } = props;
  return (
    <Box sx={{ height: 400, width: "80%", display: "block", margin: "0 auto" }}>
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        rowCount={props.rowCount}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[20, 50]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowClick={(params) => {
          setSelectedApplicant(params.row);
          setModalOpen(true);
        }}
      />
    </Box>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  overflowY: "scroll",
  "@media(max-width:768px)": {
    width: "90%",
  },
};

function BasicModal(props) {
  const { selectedApplicant, applicantData } = props;
  const handleClose = () => props.setModalOpen(false);
  const applicantDetails = applicantData.filter((applicant) => {
    return (
      applicant.name === selectedApplicant.name &&
      applicant.jobTitleInput === selectedApplicant.jobTitle &&
      applicant.experience ===
        parseInt(selectedApplicant.experience.trim().split(" ")[0]) &&
      applicant.noticeperiod === selectedApplicant.noticePeriod &&
      applicant.employeeContact === selectedApplicant.employeeContact
    );
  });
  return (
    <div>
      <Modal
        open={props.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{height:'90vh', overflowY:'scroll', marginTop:'10vh'}}
      >
        <Box sx={style}>
          <Typography
            variant="h2"
            component="h2"
            textAlign="right"
            onClick={handleClose}
          >
            <CancelPresentationIcon />
          </Typography>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            {applicantDetails[0].name}
          </Typography>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            <Rating
              name="read-only"
              value={applicantDetails[0].rating}
              readOnly
            ></Rating>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Skills:
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{ flexWrap: "wrap" }}
            >
              {applicantDetails[0].skills.map((skill) => {
                return <Chip label={skill} />;
              })}
            </Stack>
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="body2"
            component="p"
            sx={{ mt: 2 }}
          >
            Current City:{" "}
            {applicantDetails[0].currentCity
              ? applicantDetails[0].currentCity
              : "N/A"}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="body2">
            Country: {applicantDetails[0].residenceCountry}, Citizenship:{" "}
            {applicantDetails[0].citizenshipCountry}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Current Status: {applicantDetails[0].Setavailability}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Notice Period: {applicantDetails[0].noticeperiod}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Experience: {applicantDetails[0].experience}{" "}
            {applicantDetails[0].experience > 1 ? "years" : "year"}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Email: {applicantDetails[0].user.email}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Contact: {applicantDetails[0].employeeContact}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p" sx={{display:'flex', alignItems:'center'}}>
          <strong>Linkedin:&nbsp;</strong> <a href={applicantDetails[0].profile.includes("https://") ? applicantDetails[0].profile : `https://${applicantDetails[0].profile}`} target='_blank'>
          <LinkedInIcon style={{color:'#0077B5'}}/></a>
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Last Salary: {selectedApplicant.lastCurrencyType}.{" "}
            {applicantDetails[0].lastsalary}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            Expected Salary: {selectedApplicant.expectedCurrencyType}.{" "}
            {applicantDetails[0].expectedsalary}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            <Typography id="modal-modal-title" variant="h4" component="p">
              Education:
            </Typography>
            {
              <table style={{ borderCollapse: "collapse" }}>
                <th style={{ width: "160px" }}>Degree</th>
                <th style={{ width: "160px" }}>University/Institute</th>
                <th style={{ width: "160px" }}>Field of Study</th>
                <th style={{ width: "160px" }}>Start Year</th>
                <th style={{ width: "160px" }}>End Year</th>
                <tbody>
                  {applicantDetails[0].Education.map((edu) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{edu.Degree}</td>
                        <td style={{ textAlign: "center" }}>
                          {edu.institutionName}
                        </td>
                        <td style={{ textAlign: "center" }}>{edu.Stream}</td>
                        <td style={{ textAlign: "center" }}>{edu.startYear}</td>
                        <td style={{ textAlign: "center" }}>{edu.endYear}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            <Typography id="modal-modal-title" variant="h4" component="p">
              Experience:
            </Typography>
            {
              <table style={{ borderCollapse: "collapse" }}>
                <th style={{ width: "160px" }}>Job Title</th>
                <th style={{ width: "160px" }}>Company</th>
                <th style={{ width: "160px" }}>Location</th>
                <th style={{ width: "160px" }}>Start Year</th>
                <th style={{ width: "160px" }}>End Year</th>
                <tbody>
                  {applicantDetails[0].WorkExperience.map((exp) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          {exp.companyrole}
                        </td>
                        <td style={{ textAlign: "center" }}>{exp.company}</td>
                        <td style={{ textAlign: "center" }}>{exp.location}</td>
                        <td style={{ textAlign: "center" }}>
                          {exp.WorkstartYear}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {exp.WorkendYear}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="p">
            <Typography id="modal-modal-title" variant="h4" component="p">
              Languages:
            </Typography>
            {
              <table style={{ borderCollapse: "collapse" }}>
                <th style={{ width: "160px" }}>Language</th>
                <th style={{ width: "160px" }}>Fluency</th>
                <tbody>
                  {applicantDetails[0].Language.map((lang) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          {lang.Languageknow}
                        </td>
                        <td style={{ textAlign: "center" }}>{lang.ExpLevel}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

const FilterPopup = (props) => {
  // const classes = useStyles();
  const { open, handleClose, searchOptions, setSearchOptions, refetch } = props;
  return (
    <Modal open={open} onClose={handleClose} sx={{ marginTop: "80px" }}>
      <Paper
        sx={{
          padding: "50px",
          outline: "none",
          maxWidth: "65%",
          marginLeft: "18%",
          maxHeight: "75vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            height: "0px",
          },
          "@media(max-width:768px)": {
            width: "110%",
            paddingBottom: "1rem",
            position: "relative",
            bottom: "3rem",
            maxHeight: "80vh",
            // paddingRight:"2rem"
          },
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={3}
          sx={{
            "@media(max-width:768px)": {
              width: "130%",
            },
          }}
        >
          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1rem",
                  display: "none",
                  // border:"1px solid red",
                },
              }}
            >
              Current Status
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Working as Employee"
                      checked={
                        searchOptions.Setavailability["Working as Employee"]
                      }
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          Setavailability: {
                            ...searchOptions.Setavailability,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Working as Employee"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Working as Freelancer"
                      checked={
                        searchOptions.Setavailability["Working as Freelancer"]
                      }
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          Setavailability: {
                            ...searchOptions.Setavailability,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Working As Freelancer"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Not Working"
                      checked={searchOptions.Setavailability["Not Working"]}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          Setavailability: {
                            ...searchOptions.Setavailability,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Not Working"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                right: "2rem",
                position: "relative",
                // border:"1px solid green",
              },
            }}
          >
            <Grid item xs={3}>
              Last Salary
            </Grid>
            <Grid item xs={9}>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  return value * (100000 / 100);
                }}
                marks={[
                  { value: 0, label: "0" },
                  { value: 100, label: "100000" },
                ]}
                value={searchOptions.salary}
                onChange={(event, value) =>
                  setSearchOptions({
                    ...searchOptions,
                    salary: value,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // border:"1px solid blue",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",

                  // width:"15rem"
                },
              }}
            >
              Notice Period
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                // border:"1px solid red",
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <TextField
                select
                label="Notice Period"
                variant="outlined"
                fullWidth
                value={searchOptions.noticePeriod}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    noticePeriod: event.target.value,
                  })
                }
              >
                <MenuItem value="Immediately">Immediately</MenuItem>
                <MenuItem value="15 days notice">15 days notice</MenuItem>
                <MenuItem value="1 month notice">1 month notice</MenuItem>
                <MenuItem value="2 months notice">2 months notice</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // border:"1px solid blue",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",

                  // width:"15rem"
                },
              }}
            >
              Verify Date
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                // border:"1px solid red",
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <TextField
                type="date"
                fullWidth
                value={searchOptions.verifyDate}
                onChange={(e) =>
                  setSearchOptions((prev) => ({
                    ...prev,
                    verifyDate: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // border:"1px solid blue",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",

                  // width:"15rem"
                },
              }}
            >
              Good Fit for Job Profile
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                // border:"1px solid red",
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <TextField
                select
                label="Good Fit for Job Profile"
                value={searchOptions.fitForProfile}
                onChange={(e) =>
                  setSearchOptions((prev) => ({
                    ...prev,
                    fitForProfile: e.target.value,
                  }))
                }
                fullWidth
                sx={{
                  "& .MuiInputBase-root": { borderColor: "#1246A0" },
                  "& .MuiSelect-select": { color: "#1246A0" },
                }}
              >
                <MenuItem value="Developer">Developer</MenuItem>
                <MenuItem value="IT Consultant">IT Consultant</MenuItem>
                <MenuItem value="IT Support">IT Support</MenuItem>
                <MenuItem value="Sales & Marketing">Sales & Marketing</MenuItem>
                <MenuItem value="Back-Office Staff">Back-Office Staff</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // border:"1px solid blue",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",

                  // width:"15rem"
                },
              }}
            >
              Good Fit for Job Role
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                // border:"1px solid red",
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <TextField
                type="text"
                fullWidth
                value={searchOptions.fitForRole}
                onChange={(e) =>
                  setSearchOptions((prev) => ({
                    ...prev,
                    fitForRole: e.target.value,
                  }))
                }
                placeholder="Enter Job Role"
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // border:"1px solid blue",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",

                  // width:"15rem"
                },
              }}
            >
              Registration Date
            </Grid>
            <Grid
              item
              xs={9}
              sx={{
                // border:"1px solid red",
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <TextField
                type="date"
                fullWidth
                value={searchOptions.registerDate}
                onChange={(e) =>
                  setSearchOptions((prev) => ({
                    ...prev,
                    registerDate: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                // border:"1px solid blue",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",

                  // width:"15rem"
                },
              }}
            >
              Verification Status
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="All"
                    checked={searchOptions.status === ""}
                    onChange={(event) => {
                      setSearchOptions({
                        ...searchOptions,
                        status: "",
                      });
                    }}
                  />
                }
                label="All"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Verified"
                    checked={searchOptions.status === "verified"}
                    onChange={(event) => {
                      setSearchOptions({
                        ...searchOptions,
                        status: "verified",
                      });
                    }}
                  />
                }
                label="Verified"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Unverified"
                    checked={searchOptions.status === "unverified"}
                    onChange={(event) => {
                      setSearchOptions({
                        ...searchOptions,
                        status: "unverified",
                      });
                    }}
                  />
                }
                label="Unverified"
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            alignItems="center"
            sx={{
              "@media(max-width:768px)": {
                flexDirection: "column",
                gap: "1rem",

                // width:"15rem"
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                "@media(max-width:768px)": {
                  display: "none",
                },
              }}
            >
              Sort
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={9}
              sx={{
                "@media(max-width:768px)": {
                  // border:'1px solid red',
                  position: "relative",
                  right: "1rem",
                },
              }}
            >
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                sx={{
                  border: "1px solid #D1D1D1",
                  borderRadius: "5px",
                  "@media(max-width:768px)": {
                    gap: "1rem",
                    // width:"15rem"
                  },
                }}
              >
                <Grid item>
                  <Checkbox
                    name="name"
                    checked={searchOptions.sort.name.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          name: {
                            ...searchOptions.sort.name,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="name"
                  />
                </Grid>
                <Grid item>
                  <label for="name">
                    <Typography>Name</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.name.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          name: {
                            ...searchOptions.sort.name,
                            desc: !searchOptions.sort.name.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.name.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="experience"
                    checked={searchOptions.sort.experience.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          experience: {
                            ...searchOptions.sort.experience,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="experience"
                  />
                </Grid>
                <Grid item>
                  <label for="experience">
                    <Typography>Experience</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.experience.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          experience: {
                            ...searchOptions.sort.experience,
                            desc: !searchOptions.sort.experience.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.experience.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="rating"
                    checked={searchOptions.sort.rating.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="rating"
                  />
                </Grid>
                <Grid item>
                  <label for="rating">
                    <Typography>Rating</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.rating.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            desc: !searchOptions.sort.rating.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.rating.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              "@media(max-width:768px)": {
                // border:"1px solid red",
                marginRight: "2rem",
              },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={() => {
                refetch();
                handleClose();
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

function CircularIndeterminate() {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress />
    </Box>
  );
}

export default function ApplicantData() {
  const [pdfView, setPdfView] = useState(undefined);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState({});
  const [openVerify, setOpenVerify] = useState(false);
  const [applicantData, setApplicantData] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const setPopup = useContext(SetPopupContext);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [editApplicant, setEditApplicant] = useState(false);
  const [searchOptions, setSearchOptions] = React.useState({
    query: "",
    Setavailability: {
      ["Working as Employee"]: false,
      ["Working as Freelancer"]: false,
      ["Not Working"]: false,
    },
    salary: [0, 100],
    noticePeriod: "",
    verifyDate: "",
    fitForProfile: "",
    fitForRole: "",
    registerDate: "",
    status: "",
    sort: {
      name: {
        status: false,
        desc: false,
      },
      experience: {
        status: false,
        desc: false,
      },
      rating: {
        status: false,
        desc: false,
      },
    },
  });
  const getData = () => {
    let searchParams = [];
    if (searchOptions.query !== "") {
      searchParams = [...searchParams, `q=${searchOptions.query}`];
    }
    if (searchOptions.Setavailability["Working as Employee"]) {
      searchParams = [
        ...searchParams,
        `Setavailability=Working%20as%20Employee`,
      ];
    }
    if (searchOptions.Setavailability["Working as Freelancer"]) {
      searchParams = [
        ...searchParams,
        `Setavailability=Working%20as%20Freelancer`,
      ];
    }
    if (searchOptions.Setavailability["Not Working"]) {
      searchParams = [...searchParams, `Setavailability=Not%20Working`];
    }
    if (searchOptions.salary[0] !== 0) {
      searchParams = [
        ...searchParams,
        `salaryMin=${searchOptions.salary[0] * 1000}`,
      ];
    }
    if (searchOptions.salary[1] !== 100) {
      searchParams = [
        ...searchParams,
        `salaryMax=${searchOptions.salary[1] * 1000}`,
      ];
    }
    if (searchOptions.noticePeriod !== "") {
      searchParams = [
        ...searchParams,
        `noticePeriod=${searchOptions.noticePeriod}`,
      ];
    }
    if (searchOptions.verifyDate) {
      searchParams = [
        ...searchParams,
        `verifyDate=${searchOptions.verifyDate}`,
      ];
    }
    if (searchOptions.fitForProfile) {
      searchParams = [
        ...searchParams,
        `fitForProfile=${searchOptions.fitForProfile}`,
      ];
    }
    if (searchOptions.fitForRole) {
      searchParams = [
        ...searchParams,
        `fitForRole=${searchOptions.fitForRole}`,
      ];
    }
    if (searchOptions.registerDate) {
      searchParams = [
        ...searchParams,
        `registerDate=${searchOptions.registerDate}`,
      ];
    }
    if (searchOptions.status) {
      searchParams = [...searchParams, `status=${searchOptions.status}`];
    }
    searchParams = [
      ...searchParams,
      `page=${paginationModel.page}`,
      `pageSize=${paginationModel.pageSize}`,
    ];
    let asc = [],
      desc = [];
    Object.keys(searchOptions.sort).forEach((obj) => {
      const elem = searchOptions.sort[obj];
      if (elem.status) {
        if (elem.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });
    searchParams = [...searchParams, ...asc, ...desc];
    const queryString = searchParams.join("&");
    let address = `${
      userType() === "owner" ? apiList.owner : apiList.admin
    }/applicantData`;
    if (queryString !== "") {
      address = `${address}?${queryString}`;
    }
    return axios.get(address, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };

  // Query
  const { isLoading, data, refetch } = useQuery(
    ["owner-applicant", paginationModel],
    getData,
    {
      onError: (error) => {
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      },
    }
  );

  useMemo(() => {
    if (!data) {
      return;
    }
    // console.log(data);
    const rowData = data?.data.applicant.map((elem, idx) => {
      return {
        id: paginationModel.page * paginationModel.pageSize + idx + 1,
        name: elem.name,
        jobTitle: elem.jobTitleInput,
        experience:
          elem.experience > 1
            ? elem.experience + " years"
            : elem.experience + " year",
        noticePeriod: elem.noticeperiod,
        employeeContact: elem.employeeContact,
        skills: elem.skills.join(","),
        resume: elem?.resume,
        lastCurrencyType: elem.lastCurrencyType,
        expectedCurrencyType: elem.expectedCurrencyType,
        applicantId: elem.id,
        isVerified: elem.isVerified,
        verifiedCandidates: elem.verifiedCandidates,
        registerDate: elem.createdAt,
      };
    });
    setRowCount(data?.data.count);
    setPaginationModel(data?.data.paginationModel);
    setApplicantData(data?.data.applicant);
    setSampleData(rowData);
  }, [data]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      editable: false,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 180,
      editable: false,
    },
    {
      field: "experience",
      headerName: "Experience",
      width: 100,
      editable: false,
    },
    {
      field: "noticePeriod",
      headerName: "Notice Period",
      width: 150,
      editable: false,
    },
    {
      field: "employeeContact",
      headerName: "Contact",
      width: 150,
      editable: false,
    },
    {
      field: "skills",
      headerName: "Skills",
      width: 150,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params) => {
        const currentRow = params.row;
        return (
          <>
            {currentRow.isVerified ? (
              <Stack>
                <Button
                  size="small"
                  sx={{
                    bgcolor: "green",
                    height: "fit-content",
                    color: "#fff",
                    "&:hover": { bgcolor: "green" },
                    p: "2px 15px",
                    borderRadius: 2,
                  }}
                >
                  Verified {<CheckCircle sx={{ ml: 1.5 }} />}
                </Button>
              </Stack>
            ) : (
              <Stack>
                <Button
                  size="small"
                  sx={{
                    bgcolor: "#B22222",
                    height: "fit-content",
                    color: "#fff",
                    "&:hover": { bgcolor: "#B22222" },
                    p: "2px 10px",
                    borderRadius: 2,
                  }}
                >
                  Unverified {<SyncProblemIcon sx={{ ml: 1 }} />}
                </Button>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      field: "resume",
      headerName: "CV",
      width: 150,
      editable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setPdfView({
            url: params.row.resume,
            applicantName: params.row.name,
          });
        };

        return (
          <Stack direction={"row"} sx={{ width: "100%" }}>
            {!!params.row.resume && (
              <Stack
                sx={{
                  p: "3px 20px",
                  borderRadius: "3px",
                  border: "1px solid rgba(33, 150, 243, 0.5)",
                  cursor: "pointer",
                }}
              >
                <InsertDriveFileOutlinedIcon
                  onClick={onClick}
                  sx={{ color: "#1246A0" }}
                />
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      field: "ATS",
      headerName: "ATS",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          const currentRow = params.row;
          setSelectedApplicant(currentRow);
          setOpenVerify(true);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={{ color: "#1246A0", border: "none" }}
              size="small"
              onClick={onClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                />
              </svg>
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit Details",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          const currentRow = params.row;
          setSelectedApplicant(currentRow);
          setEditApplicant(true);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={{ color: "#1246A0" }}
              size="small"
              onClick={onClick}
            >
              <EditIcon />
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Details",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const onClick = (e) => {
          const currentRow = params.row;
          setSelectedApplicant(currentRow);
          setModalOpen(true);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              sx={{ color: "#1246A0" }}
              size="small"
              onClick={onClick}
            >
              <ForwardIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <ReactHelmet
        title={"Applicants | AMUS HIRING"}
        description={"Welcome to applicant page"}
        route="applicants"
      />
      <div>
        {editApplicant ? (
          <Profile
            ats={true}
            selectedApplicant={selectedApplicant}
            applicantData={applicantData}
            setEditApplicant={setEditApplicant}
            refetch={refetch}
          />
        ) : (
          <>
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              sx={{
                marginTop: "100px",
                padding: "30px",
                minHeight: "10vh",
                "@media(max-width:768px)": {
                  // marginTop:"15rem",
                  width: "100vw",
                  // border:"1px solid green"
                },
              }}
            >
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs
                  sx={{
                    "@media(max-width:768px)": {
                      marginRight: "3rem",
                    },
                  }}
                >
                  <Typography variant="h2">Applicants</Typography>
                </Grid>
                <Grid
                  item
                  xs
                  sx={{
                    "@media(max-width:768px)": {
                      width: "100vw",
                    },
                  }}
                >
                  <TextField
                    sx={{
                      "@media(max-width:768px)": {
                        width: "85vw",
                        // border:"1px solid red"
                      },
                    }}
                    label="Search Applicants"
                    value={searchOptions.query}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        query: event.target.value,
                      })
                    }
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter") {
                        refetch();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton onClick={refetch}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // style={{ width: "60vw" }}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    "@media(max-width:768px)": {
                      marginRight: "3rem",
                    },
                  }}
                >
                  <IconButton onClick={() => setFilterOpen(true)}>
                    <FilterListIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <FilterPopup
              searchOptions={searchOptions}
              open={filterOpen}
              handleClose={() => setFilterOpen(false)}
              setSearchOptions={setSearchOptions}
              refetch={refetch}
            />
            {isLoading ? (
              <CircularIndeterminate />
            ) : (
              <DataGridDemo
                rows={sampleData}
                columns={columns}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowCount={rowCount}
                setModalOpen={setModalOpen}
                setSelectedApplicant={setSelectedApplicant}
              />
            )}
          </>
        )}
      </div>
      {modalOpen && (
        <BasicModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          applicantData={applicantData}
          selectedApplicant={selectedApplicant}
        />
      )}
      {!!pdfView && (
        <ResumeViewer
          open={!!pdfView}
          data={pdfView ?? {}}
          handleClose={() => {
            setPdfView(undefined);
          }}
        />
      )}
      {openVerify && (
        <ApplicantVerificationModal
          open={openVerify}
          setOpen={setOpenVerify}
          selectedApplicant={selectedApplicant}
          refetch={refetch}
        />
      )}
    </>
  );
}
