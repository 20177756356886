import { styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import CountUp from 'react-countup';

const CountComp = () => {

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#fff",
    fontWeight: "700",
    transition: "all 0.3s ease",
    // "&:hover": {
    //   color: "#FFFF00",
    // },
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "25px",
    color: "#F5F5F5",
    fontWeight: "500",
    marginBottom: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(7),
    display: "flex",
    justifyContent: "space-between",
    borderRadius:"10px",
    backgroundColor:"#03265B",
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(5),
    },
  }));

  // const Divider = styled("div")(({ theme }) => ({
  //   width: "13%",
  //   height: "5px",
  //   backgroundColor: "#F5F5F5",
  //   [theme.breakpoints.down("md")]: {
  //     marginLeft: "auto",
  //     marginRight: "auto",
  //   },
  // }));

  const [shouldCount, setShouldCount] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const scrollThreshold = window.innerWidth >= 768 ? 5800 : 9000;

      if (!shouldCount && scrollPosition > scrollThreshold) {
        setShouldCount(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldCount]);

  return (
    <Box sx={{ py: 10, bgcolor:"#fff" }}>
      <Container>
        <TextFlexbox>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {shouldCount && 
            <LargeText>
              <CountUp end={1500} duration={4} decimals={1} // Number of decimal places
                separator=" "
                suffix="k"
                formattingFn={(value) => {
                  if (value >= 1000) {
                    return `${(value / 1000).toFixed(1)}`;
                  }
                  return value;
                }}
                />k+
            </LargeText>}
            {/* <Divider /> */}
            <SmallText>Companies accelerated</SmallText>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {shouldCount && 
            <LargeText><CountUp end={1000} duration={5} 
            separator=" "
            suffix="k"
            formattingFn={(value) => {
              if (value >= 1000) {
                return `${(value / 10)}`;
              }
              return value;
            }}
            />k+</LargeText>}
            {/* <Divider /> */}
            <SmallText>Professionals available</SmallText>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {shouldCount && <LargeText><CountUp end={15} duration={3} />+</LargeText>}
            {/* <Divider /> */}
            <SmallText>Languages Speaking</SmallText>
          </Box>
        </TextFlexbox>
      </Container>
    </Box>
  );
};

export default CountComp;
