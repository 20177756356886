import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { useMutation } from "react-query";
import axios from "axios";
import { userType } from "pages/authentication/lib/isAuth";
import apiList from "pages/authentication/lib/apiList";
import { SetPopupContext } from "routes";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Save } from "@mui/icons-material";

const ApplicantVerificationModal = ({
  open,
  setOpen,
  selectedApplicant,
  refetch,
}) => {
  const {
    applicantId,
    verifiedCandidates: verifiedData,
    isVerified,
    registerDate,
  } = selectedApplicant;
  const [verifyDetails, setVerifyDetails] = useState({
    applicantId: applicantId,
    verifyDate: isVerified
      ? new Date(verifiedData?.updatedAt).toISOString().substring(0, 10)
      : new Date().toISOString().substring(0, 10),
    verifiedBy: isVerified ? verifiedData?.verifiedBy : "",
    fitForProfile: isVerified ? verifiedData?.fitForProfile : "",
    fitForRole: isVerified ? verifiedData?.fitForRole : "",
    feedback: isVerified ? verifiedData?.feedback : "",
    id: isVerified ? verifiedData?.id : null,
  });
  const token = localStorage.getItem("token");
  const setPopup = useContext(SetPopupContext);
  const [error, setError] = useState(false);

  const { isLoading, mutate } = useMutation(
    async ({ verifyDetails }) => {
      let address = `${
        userType() === "owner" ? apiList.owner : apiList.admin
      }/${isVerified ? "updateVerify" : "candidateVerify"}`;
      const resp = await axios.post(
        `${address}`,
        {
          verifyDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resp;
    },
    {
      onSuccess: (data) => {
        setPopup({
          open: true,
          severity: "success",
          message: data?.data.message,
        });
        refetch();
        handleClose();
      },
      onError: (err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err?.response?.data?.message,
        });
      },
    }
  );

  const handleSave = () => {
    mutate({ verifyDetails });
  };
  useEffect(() => {
    setError(false);
    if (
      !verifyDetails.applicantId ||
      !verifyDetails.verifyDate ||
      !verifyDetails.verifiedBy ||
      !verifyDetails.fitForProfile ||
      !verifyDetails.fitForRole ||
      !verifyDetails.feedback
    ) {
      setError(true);
    }
  }, [verifyDetails]);

  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "40vw",
          bgcolor: "#ffffff",
          borderRadius: "10px",
          boxShadow: 24,
          mx: "auto",
          mt: "5.5%",
          overflowY: "scroll",
          height: "80vh",
          "@media(max-width:768px)": {
            width: "80vw",
            mt: "15%",
          },
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            height: "60px",
            bgcolor: "#0F1B4C",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{ mb: 2, color: "#fff", p: 2 }}
          >
            Candidate Verification
          </Typography>
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Registration Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={new Date(registerDate).toISOString().substring(0, 10)}
            fullWidth
            sx={{ "& .MuiInputBase-root": { borderColor: "#1246A0" } }}
            disabled
          />

          <TextField
            label={isVerified ? "Last Verified" : "Verify Date"}
            type="date"
            InputLabelProps={{ shrink: true }}
            value={verifyDetails.verifyDate}
            onChange={(e) =>
              setVerifyDetails((prev) => ({
                ...prev,
                verifyDate: e.target.value,
              }))
            }
            fullWidth
            sx={{ "& .MuiInputBase-root": { borderColor: "#1246A0" } }}
            disabled={isVerified}
          />

          <TextField
            label="Verified by"
            value={verifyDetails.verifiedBy}
            onChange={(e) =>
              setVerifyDetails((prev) => ({
                ...prev,
                verifiedBy: e.target.value,
              }))
            }
            fullWidth
            sx={{ "& .MuiInputBase-root": { borderColor: "#1246A0" } }}
          />

          <TextField
            select
            label="Good Fit for Job Profile"
            value={verifyDetails.fitForProfile}
            onChange={(e) =>
              setVerifyDetails((prev) => ({
                ...prev,
                fitForProfile: e.target.value,
              }))
            }
            fullWidth
            sx={{
              "& .MuiInputBase-root": { borderColor: "#1246A0" },
              "& .MuiSelect-select": { color: "#1246A0" },
            }}
          >
            <MenuItem value="Developer">Developer</MenuItem>
            <MenuItem value="IT Consultant">IT Consultant</MenuItem>
            <MenuItem value="IT Support">IT Support</MenuItem>
            <MenuItem value="Sales & Marketing">Sales & Marketing</MenuItem>
            <MenuItem value="Back-Office Staff">Back-Office Staff</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </TextField>

          <TextField
            label="Good Fit for Job Role"
            value={verifyDetails.fitForRole}
            onChange={(e) =>
              setVerifyDetails((prev) => ({
                ...prev,
                fitForRole: e.target.value,
              }))
            }
            fullWidth
            sx={{ "& .MuiInputBase-root": { borderColor: "#1246A0" } }}
          />

          <TextField
            label="Verification Feedback"
            multiline
            rows={4}
            value={verifyDetails.feedback}
            onChange={(e) =>
              setVerifyDetails((prev) => ({
                ...prev,
                feedback: e.target.value,
              }))
            }
            fullWidth
            sx={{ "& .MuiInputBase-root": { borderColor: "#1246A0" } }}
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{
                backgroundColor: "#0F1B4C",
                "&:hover": { backgroundColor: "#0f3c8d" },
              }}
              disabled={error || isLoading}
            >
              {isVerified ? "Save" : "Verify"}{" "}
              {isVerified ? (
                <Save sx={{ ml: 2 }} />
              ) : (
                <CheckCircle sx={{ ml: 2 }} />
              )}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{
                borderColor: "#1246A0",
                color: "#1246A0",
                "&:hover": { borderColor: "#0f3c8d", color: "#0f3c8d" },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApplicantVerificationModal;
