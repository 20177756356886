import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Slider,
  Checkbox,
  MenuItem,
  Modal,
  CircularProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import apiList from "pages/authentication/lib/apiList";
import axios from "axios";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import NewSingleJobModal from "./NewSingleJobModal";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Job from "./job/Job.jsx";
import ApplyModal from "./job/ApplyModal";
import { useQuery } from "react-query";

const NewEmployeeHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, , id] = location.pathname.split("/");
  const [singleJob, setSingleJob] = useState({});
  const [allJobs, setAllJobs] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [jobId, setJobId] = useState("");
  const [cursor, setCursor] = useState(0);
  const [singleJobStored, setSingleJobStored] = useState(false);
  const [openSingleJobModal, setOpenSingleJobModal] = useState(false);
  // const [currentPosition , setCurrentPosition] = useState(0)
  const [searchOptions, setSearchOptions] = useState({
    jobType: {
      partTime: false,
      wfh: false,
      fullTime: false,
      internship: false,
    },
    duration: "",
    query: "",
    salary: [0, 100],
    sort: {
      salary: {
        status: false,
        desc: false,
      },
      duration: {
        status: false,
        desc: false,
      },
      rating: {
        status: false,
        desc: false,
      },
    },
    workplaceType: "",
  });
  useEffect(() => {
    setCursor(0);
  }, [searchOptions]);
  const AllJobs = async () => {
    let queryParams = [];
    if (searchOptions.query !== "") {
      queryParams = [...queryParams, `q=${searchOptions.query}`];
    }
    if (searchOptions.jobType.fullTime) {
      queryParams = [...queryParams, `jobType=Full%20Time`];
    }
    if (searchOptions.jobType.partTime) {
      queryParams = [...queryParams, `jobType=Part%20Time`];
    }
    if (searchOptions.jobType.wfh) {
      queryParams = [...queryParams, `jobType=Work%20From%20Home`];
    }
    if (searchOptions.jobType.internship) {
      queryParams = [...queryParams, `jobType=Internship`];
    }
    if (searchOptions.duration !== "") {
      queryParams = [...queryParams, `duration=${searchOptions.duration}`];
    }
    if (searchOptions.salary[0] !== 0) {
      queryParams = [
        ...queryParams,
        `salaryMin=${searchOptions.salary[0] * 1000}`,
      ];
    }
    if (searchOptions.salary[1] !== 100) {
      queryParams = [
        ...queryParams,
        `salaryMax=${searchOptions.salary[1] * 1000}`,
      ];
    }
    if (searchOptions.workplaceType) {
      queryParams = [
        ...queryParams,
        `workplaceType=${searchOptions.workplaceType}`,
      ];
    }
    let asc = [],
      desc = [];
    Object.keys(searchOptions.sort).forEach((obj) => {
      const item = searchOptions.sort[obj];
      if (item.status) {
        if (item.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });
    queryParams = [...queryParams, `cursor=${cursor}`];
    queryParams = [...queryParams, ...asc, ...desc];
    const queryString = queryParams.join("&");
    return axios.get(apiList.jobs + "?" + queryString, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };
  const { data, isLoading, refetch } = useQuery(["jobs"], AllJobs, {
    onSuccess: (data) => {
      const fetchedJobs = data?.data.jobs || []; // Ensure data is not null
      const newCursor = data?.data.cursor || 0; // Ensure cursor is not null
      let newJobs = [];

      // If cursor is 0, reset the job list with fetched jobs
      if (cursor === 0) {
        newJobs = fetchedJobs;
        setAllJobs(newJobs);
      } else {
        // If cursor is not 0, append only new jobs to the existing list
        const lastJobId =
          allJobs.length > 0 ? allJobs[allJobs.length - 1].id : null;
        newJobs = fetchedJobs.filter((job) => job.id > lastJobId);
        setAllJobs((prevJobs) => [...prevJobs, ...newJobs]);
      }

      // Update cursor and jobs state
      setCursor(newCursor);

      // Update singleJob if not stored and there are new jobs
      if ((!singleJobStored && newJobs.length > 0) || cursor === 0) {
        setSingleJob(newJobs[0]);
        setSingleJobStored(true);
      }
    },
    enabled: allJobs.length === 0,
  });
  const handleScroll = (e) => {
    if (cursor === 0 || isLoading) {
      return;
    }
    const threshold = window.screen.width <= 768 ? 500 : 1000;

    if (
      e.target.scrollTop + e.target.clientHeight >=
      e.target.scrollHeight - threshold
    ) {
      refetch();
    }
  };

  return (
    <>
      <ReactHelmet
        title={"Employee Home | AMUS HIRING"}
        description={""}
        route="employeeHome"
      />
      <Box sx={{ marginTop: 10, textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={5} xs={12}>
            <Box
              id="jobs-grid-container"
              sx={{
                minHeight: "85vh",
                p: 2,
                borderRadius: 3,
                backgroundColor: "#f9f9f9",
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  minHeight: "80vh",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                    justifyContent: "center",
                    "@media(max-width:900px)": {
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Typography
                    variant="h2"
                    color="primary"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    Jobs Posted <WorkOutlineIcon sx={{ fontSize: "30px" }} />
                  </Typography>
                  <Typography
                    variant="h2"
                    color="primary"
                    sx={{ "@media(min-width:900px)": { display: "none" } }}
                    onClick={() => setOpenFilter(true)}
                  >
                    <FilterListIcon
                      cursor="pointer"
                      sx={{ fontSize: "30px" }}
                    />
                  </Typography>
                </Typography>
                {allJobs.length ? (
                  <Box
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    onScroll={handleScroll}
                  >
                    {allJobs?.map((job, i) => {
                      return (
                        <Box
                          sx={{
                            p: "20px 20px",
                            border: "1px solid lightgray",
                            borderRadius: 8,
                            marginBottom: 2,
                            cursor: "pointer",
                            boxShadow:
                              job.id === Number(id)
                                ? "inset #9c9c9c 0px 0px 19px -12px"
                                : "none",
                          }}
                          key={i}
                          onClick={() => {
                            navigate(`/jobs/${job.id}`);
                            if (window.innerWidth < 600) {
                              setSingleJob(job);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h4" textAlign={"start"}>
                              {job.title}
                            </Typography>
                            <Typography sx={{ color: "gray" }}>
                              {Math.abs(
                                Math.floor(
                                  (new Date(job.dateOfPosting) - new Date()) /
                                    (1000 * 60 * 60 * 24),
                                ),
                              )}{" "}
                              Days ago
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "0.5rem",
                            }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Typography>
                                <WorkOutlineIcon />
                              </Typography>{" "}
                              &nbsp; {job.experience} years
                            </Typography>
                            <Typography
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <LocationOnIcon /> &nbsp; {job.jobType}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: "#284774",
                                marginTop: 1,
                                ":hover": { background: "#284774" },
                                borderRadius: 3,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpen(true);
                                setSingleJob({ ...job });
                                setJobId(job.id);
                              }}
                            >
                              Apply now
                            </Button>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "60vh",
                      fontSize: "20px",
                    }}
                  >
                    {isLoading ? <CircularProgress /> : "No Jobs found"}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <>
            <Routes>
              {window.innerWidth < 600 ? (
                <>
                  <Route path="/:id" element={<NewSingleJobModal />} />
                  <Route path="/" element={<Job />} />
                </>
              ) : (
                <>
                  <Route path="/:id" element={<Job />} />
                  <Route path="/" element={<Job />} />
                </>
              )}
            </Routes>
          </>
          <Grid
            item
            lg={2}
            md={3}
            sx={{ "@media(max-width:900px)": { display: "none" } }}
          >
            <Box
              sx={{
                minHeight: "85vh",
                p: 2,
                borderRadius: 3,
                backgroundColor: "#f9f9f9",
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  minHeight: "80vh",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                    justifyContent: "center",
                  }}
                >
                  Filter <FilterAltOutlinedIcon sx={{ fontSize: "30px" }} />
                </Typography>
                <Box
                  sx={{
                    maxHeight: "66vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    sx={{ textAlign: "left", mb: 1 }}
                  >
                    Job Type
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Checkbox
                        size="small"
                        sx={{ m: 0, p: 0, mr: 0.5 }}
                        onChange={(e) => {
                          setSearchOptions({
                            ...searchOptions,
                            jobType: {
                              ...searchOptions.jobType,
                              fullTime: e.target.checked,
                            },
                          });
                        }}
                      />
                      Full Time
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Checkbox
                        size="small"
                        sx={{ m: 0, p: 0, mr: 0.5 }}
                        onChange={(e) => {
                          setSearchOptions({
                            ...searchOptions,
                            jobType: {
                              ...searchOptions.jobType,
                              partTime: e.target.checked,
                            },
                          });
                        }}
                      />
                      Part Time
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Checkbox
                        size="small"
                        sx={{ m: 0, p: 0, mr: 0.5 }}
                        onChange={(e) => {
                          setSearchOptions({
                            ...searchOptions,
                            jobType: {
                              ...searchOptions.jobType,
                              internship: e.target.checked,
                            },
                          });
                        }}
                      />
                      Internship
                    </Typography>
                  </Box>
                  <Typography textAlign={"start"} fontSize={"15px"}>
                    Profile
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="eg. Front-end Developer"
                    fullWidth
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        query: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        refetch();
                      }
                    }}
                  />
                  <Typography textAlign={"start"} fontSize={"15px"}>
                    Duration
                  </Typography>
                  <TextField
                    variant="outlined"
                    label="eg. Flexible"
                    fullWidth
                    select
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        duration: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">Choose Duration</MenuItem>
                    <MenuItem value="Flexible">Flexible</MenuItem>
                    <MenuItem value="Less than 1 month">
                      Less than 1 month
                    </MenuItem>
                    <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
                    <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
                    <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
                    <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
                  </TextField>
                  {/* <Typography fontSize={"15px"} sx={{ textAlign: "left" }}>
                    Annual Salary
                  </Typography> */}

                  <Typography textAlign={"start"} fontSize={"15px"}>
                    Workplace Type
                  </Typography>
                  <TextField
                    variant="outlined"
                    label="eg. Remote"
                    fullWidth
                    select
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        workplaceType: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">Choose workplace</MenuItem>
                    <MenuItem value="Remote">Remote</MenuItem>
                    <MenuItem value="On-Site">On-Site</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                  </TextField>
                  <Typography fontSize={"15px"} sx={{ textAlign: "left" }}>
                    Annual Salary
                  </Typography>

                  <Slider
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {
                      return value * (100000 / 100);
                    }}
                    marks={[
                      { value: 0, label: "0" },
                      { value: 100, label: "100000" },
                    ]}
                    value={searchOptions.salary}
                    onChange={(event, value) =>
                      setSearchOptions({
                        ...searchOptions,
                        salary: value,
                      })
                    }
                    sx={{ width: "70%", mb: 3, textAlign: "left" }}
                  />
                  <Typography fontSize={"15px"} textAlign={"start"}>
                    Sort
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid lightgray",
                      mb: 0.5,
                      borderRadius: 2,
                    }}
                  >
                    <Checkbox
                      name="salary"
                      checked={searchOptions.sort.salary.status}
                      onChange={(event) =>
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            salary: {
                              ...searchOptions.sort.salary,
                              status: event.target.checked,
                            },
                          },
                        })
                      }
                      id="salary"
                    />
                    <label for="salary">
                      <Typography>Salary</Typography>
                    </label>
                    <IconButton
                      disabled={!searchOptions.sort.salary.status}
                      onClick={() => {
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            salary: {
                              ...searchOptions.sort.salary,
                              desc: !searchOptions.sort.salary.desc,
                            },
                          },
                        });
                      }}
                    >
                      {searchOptions.sort.salary.desc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid lightgray",
                      mb: 0.5,
                      borderRadius: 2,
                    }}
                  >
                    <Checkbox
                      name="duration"
                      checked={searchOptions.sort.duration.status}
                      onChange={(event) =>
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            duration: {
                              ...searchOptions.sort.duration,
                              status: event.target.checked,
                            },
                          },
                        })
                      }
                      id="duration"
                    />
                    <label for="duration">
                      <Typography>Duration</Typography>
                    </label>
                    <IconButton
                      disabled={!searchOptions.sort.duration.status}
                      onClick={() => {
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            duration: {
                              ...searchOptions.sort.duration,
                              desc: !searchOptions.sort.duration.desc,
                            },
                          },
                        });
                      }}
                    >
                      {searchOptions.sort.duration.desc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid lightgray",
                      mb: 1,
                      borderRadius: 2,
                    }}
                  >
                    <Checkbox
                      name="rating"
                      checked={searchOptions.sort.rating.status}
                      onChange={(event) =>
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            rating: {
                              ...searchOptions.sort.rating,
                              status: event.target.checked,
                            },
                          },
                        })
                      }
                      id="rating"
                    />
                    <label for="rating">
                      <Typography>Rating</Typography>
                    </label>
                    <IconButton
                      disabled={!searchOptions.sort.rating.status}
                      onClick={() => {
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            rating: {
                              ...searchOptions.sort.rating,
                              desc: !searchOptions.sort.rating.desc,
                            },
                          },
                        });
                      }}
                    >
                      {searchOptions.sort.rating.desc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#284774",
                      marginTop: 1,
                      ":hover": { background: "#284774" },
                      borderRadius: 3,
                    }}
                    fullWidth
                    onClick={() => {
                      setCursor(0);
                      refetch();
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openFilter && (
        <FilterModal
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          jobId={jobId}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          refetch={refetch}
        />
      )}
      {open && (
        <ApplyModal
          open={open}
          setOpen={(val) => {
            setOpen(val);
            setSingleJob({});
          }}
          jobDetail={singleJob}
        />
      )}
    </>
  );
};

export default NewEmployeeHome;

const FilterModal = ({
  openFilter,
  setOpenFilter,
  setSearchOptions,
  searchOptions,
  refetch,
}) => {
  return (
    <Modal open={openFilter} onClose={() => setOpenFilter(false)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: 17,
          mr: 2,
          border: "none",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f9f9f9",
            borderRadius: 2,
            maxHeight: "60vh",
            overflow: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            width: "50%",
            textAlign: "center",
            p: 2,
            "@media(max-width:415px)": { width: "60%" },
          }}
        >
          <Box
            sx={{
              minHeight: "56vh",
              p: 2,
              borderRadius: 3,
              backgroundColor: "#fff",
              boxShadow: 1,
            }}
          >
            <Typography
              fontSize={"15px"}
              sx={{
                textAlign: "left",
                mb: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Job Type{" "}
              <CloseIcon
                cursor="pointer"
                color="primary"
                onClick={() => setOpenFilter(false)}
              />
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Checkbox
                  size="small"
                  sx={{ m: 0, p: 0, mr: 0.5 }}
                  checked={searchOptions.jobType.fullTime}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      jobType: {
                        ...searchOptions.jobType,
                        fullTime: e.target.checked,
                      },
                    });
                  }}
                />
                Full Time
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Checkbox
                  size="small"
                  sx={{ m: 0, p: 0, mr: 0.5 }}
                  checked={searchOptions.jobType.partTime}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      jobType: {
                        ...searchOptions.jobType,
                        partTime: e.target.checked,
                      },
                    });
                  }}
                />
                Part Time
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Checkbox
                  size="small"
                  sx={{ m: 0, p: 0, mr: 0.5 }}
                  checked={searchOptions.jobType.internship}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      jobType: {
                        ...searchOptions.jobType,
                        internship: e.target.checked,
                      },
                    });
                  }}
                />
                Internship
              </Typography>
            </Box>
            <Typography textAlign={"start"} fontSize={"15px"}>
              Profile
            </Typography>
            <TextField
              variant="outlined"
              placeholder="eg. Front-end Developer"
              value={searchOptions.query}
              fullWidth
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  query: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  refetch();
                }
              }}
            />
            <Typography textAlign={"start"} fontSize={"15px"}>
              Duration
            </Typography>
            <TextField
              variant="outlined"
              label="eg. Flexible"
              fullWidth
              select
              value={searchOptions.duration}
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  duration: e.target.value,
                });
              }}
            >
              <MenuItem value="">Choose Duration</MenuItem>
              <MenuItem value="Flexible">Flexible</MenuItem>
              <MenuItem value="Less than 1 month">Less than 1 month</MenuItem>
              <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
              <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
              <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
              <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
            </TextField>

            <Typography textAlign={"start"} fontSize={"15px"}>
              Workplace Type
            </Typography>
            <TextField
              variant="outlined"
              label="eg. Remote"
              fullWidth
              select
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  workplaceType: e.target.value,
                });
              }}
            >
              <MenuItem value="">Choose workplace</MenuItem>
              <MenuItem value="Remote">Remote</MenuItem>
              <MenuItem value="On-Site">On-Site</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
            </TextField>

            <Typography fontSize={"15px"} sx={{ textAlign: "left" }}>
              Annual Salary
            </Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                return value * (100000 / 100);
              }}
              marks={[
                { value: 0, label: "0" },
                { value: 100, label: "100000" },
              ]}
              value={searchOptions.salary}
              onChange={(event, value) =>
                setSearchOptions({
                  ...searchOptions,
                  salary: value,
                })
              }
              sx={{ width: "90%", mb: 3 }}
            />
            <Typography fontSize={"15px"} textAlign={"start"}>
              Sort
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ border: "1px solid lightgray", mb: 0.5, borderRadius: 2 }}
            >
              <Checkbox
                name="salary"
                checked={searchOptions.sort.salary.status}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      salary: {
                        ...searchOptions.sort.salary,
                        status: event.target.checked,
                      },
                    },
                  })
                }
                id="salary"
              />
              <label for="salary">
                <Typography>Salary</Typography>
              </label>
              <IconButton
                disabled={!searchOptions.sort.salary.status}
                onClick={() => {
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      salary: {
                        ...searchOptions.sort.salary,
                        desc: !searchOptions.sort.salary.desc,
                      },
                    },
                  });
                }}
              >
                {searchOptions.sort.salary.desc ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </IconButton>
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ border: "1px solid lightgray", mb: 0.5, borderRadius: 2 }}
            >
              <Checkbox
                name="duration"
                checked={searchOptions.sort.duration.status}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      duration: {
                        ...searchOptions.sort.duration,
                        status: event.target.checked,
                      },
                    },
                  })
                }
                id="duration"
              />
              <label for="duration">
                <Typography>Duration</Typography>
              </label>
              <IconButton
                disabled={!searchOptions.sort.duration.status}
                onClick={() => {
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      duration: {
                        ...searchOptions.sort.duration,
                        desc: !searchOptions.sort.duration.desc,
                      },
                    },
                  });
                }}
              >
                {searchOptions.sort.duration.desc ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </IconButton>
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ border: "1px solid lightgray", mb: 1, borderRadius: 2 }}
            >
              <Checkbox
                name="rating"
                checked={searchOptions.sort.rating.status}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      rating: {
                        ...searchOptions.sort.rating,
                        status: event.target.checked,
                      },
                    },
                  })
                }
                id="rating"
              />
              <label for="rating">
                <Typography>Rating</Typography>
              </label>
              <IconButton
                disabled={!searchOptions.sort.rating.status}
                onClick={() => {
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      rating: {
                        ...searchOptions.sort.rating,
                        desc: !searchOptions.sort.rating.desc,
                      },
                    },
                  });
                }}
              >
                {searchOptions.sort.rating.desc ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </IconButton>
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#284774",
                marginTop: 1,
                ":hover": { background: "#284774" },
                borderRadius: 3,
              }}
              fullWidth
              onClick={() => {
                refetch();
                setOpenFilter(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
