export const server = "https://api.amushiring.com";

const apiList = {
  login: `${server}/auth/login`,
  signup: `${server}/auth/signup`,
  uploadResume: `${server}/upload/resume`,
  uploadProfileImage: `${server}/upload/profile`,
  jobs: `${server}/api/jobs`,
  applications: `${server}/api/applications`,
  rating: `${server}/api/rating`,
  user: `${server}/api/user`,
  applicants: `${server}/api/applicants`,
  changepassword: `${server}/auth/pass/changePassword`,
  forgotpassword: `${server}/auth/pass/forgotPassword`,
  owner: `${server}/api/owner`,
  feedback: `${server}/api/feed-back`,
  admin: `${server}/api/admin`,
  screening: `${server}/api/screeningQuestions`,
  s3fileUrl: `${server}/api/S3-file`,
};

export default apiList;
