import React from 'react'
import Hero from './Hero'
// import Properties from './Properties'
import Details from './Details'
import About from './About'
// import GridComponent from "./GridComponent";
import CountComp from './CountComp';
import CookieConsentData from './CookieConsent'
import ReactHelmet from 'component/react-helmet/ReactHelmet'
import FAQSection, { GetStartedSection } from './FAQSection'
import TextSlider from './SiderComponent'
const metaData = {
  title: 'Best Talent Acquisition Remote Hiring Company - AMUS Hiring',
  description: "AMUS Hiring is a global hiring company that connects employers with top talent acquisition worldwide. Find the best remote job opportunities and skilled candidates today.",
  route: ''
}

const arrayFAQ = [
  {
    question: "What services does AMUS HIRING provide?",
    answer:
      "We specialize in not only remote talent acquisition but also in onsite talents, offering a platform to hire full-time dedicated technical and non-technical profiles. We connect businesses with skilled candidates from around the world, providing solutions for various industries, including IT, logistics, and more.",
  },
  {
    question: "Do you provide Staff Outsourcing service?",
    answer:
      "Yes, we do offer back-office staff and technical staff outsourcing.",
  },
  {
    question: "Can we hire remote staff on our payroll?",
    answer:
      "Yes, we help hiring remote staff on your payroll. We also offer the option to hire remote staff on our payroll.",
  },
  {
    question: "How does AMUS HIRING streamline the remote hiring process?",
    answer:
      "Our platform simplifies the hiring process by providing tools for posting job listings, reviewing candidate profiles, real-time chat, conducting virtual interviews, and performing thorough screenings. This ensures an efficient and seamless hiring experience.",
  },
  {
    question: "What types of positions can I fill through AMUS HIRING?",
    answer:
      "You can fill a wide range of positions through our platform, including IT talent acquisition, freight talent acquisition, logistics supply chain recruitment, virtual assistants, and more. We cater to both technical and non-technical roles across various industries.",
  },
  {
    question: "What is the advantage of using AMUS HIRING for global hiring?",
    answer:
      "Using our platform gives you access to a global pool of skilled candidates, allowing you to find the perfect fit for your business needs. Our global reach ensures that you can hire the best talent from anywhere in the world, enhancing your workforce's diversity and expertise.",
  },
  {
    question: "How does virtual candidate screening work?",
    answer:
      "Our virtual candidate screening process includes video interviews, skills assessments, and background checks. This rigorous screening ensures that only the most qualified and suitable candidates are presented to you, saving you time and ensuring quality hires.",
  },
  {
    question:
      "Can I hire remote employees for both full-time and part-time positions?",
    answer:
      "Yes, we offer flexible hiring solutions, allowing you to hire remote employees for both full-time and part-time positions based on your business requirements.",
  },
  {
    question:
      "What are the benefits of hiring a virtual assistant through AMUS HIRING?",
    answer:
      "Hiring a virtual assistant through our platform helps streamline your operations by handling administrative tasks such as scheduling, email management, data entry, and customer service. This allows you to focus on strategic activities and improve overall productivity.",
  },
  {
    question:
      "How can AMUS HIRING help with IT project management recruitment?",
    answer:
      "We provide specialized IT talent acquisition services to help you find skilled professionals for project management, development, support roles, and more. Our targeted approach ensures you get the right technical expertise to drive your IT projects forward.",
  },
  {
    question: "What makes AMUS HIRING the best remote hiring company?",
    answer:
      "Our reputation as the best remote hiring company is built on our commitment to providing exceptional service, successful placements, and a seamless hiring process. We leverage advanced technology and a global network to connect you with top talent efficiently.",
  },
  {
    question: "How can I get started with AMUS HIRING?",
    answer:
      "Getting started is easy. Simply create an account on our platform, post your job requirements, and start reviewing candidate profiles. Our support team is available to assist you throughout the process to ensure a smooth and successful hiring experience.",
  },
  {
    question: "How does AMUS HIRING support global recruitment?",
    answer:
      "Our global recruitment services offer access to a diverse and skilled candidate pool, making it easier for you to find the right fit for your corporate needs. We handle all aspects of the hiring process, from sourcing to screening, ensuring compliance with international hiring standards.",
  },
  {
    question: "What industries do you specialize in for talent acquisition?",
    answer:
      "We specialize in talent acquisition for various industries, including IT, logistics, freight forwarding, accounting, and more. Our expertise in these sectors ensures that we can meet your specific hiring needs effectively.",
  },
  {
    question: "How does remote talent sourcing work?",
    answer:
      "Remote talent sourcing involves identifying and attracting skilled candidates from around the world to meet your business needs. Our platform uses advanced algorithms and global networks to source top talent, ensuring you get access to the best candidates.",
  },
  {
    question: "What makes AMUS HIRING a top virtual hiring company?",
    answer:
      "Our dedication to providing a seamless, efficient, and high-quality hiring experience sets us apart as a top virtual hiring company. We leverage cutting-edge technology, a vast network of professionals, and a commitment to customer satisfaction to deliver exceptional results.",
  },
];


const Home = () => {
  return (
    <>
      <ReactHelmet 
        title={metaData.title}
        description={metaData.description}
        route={metaData.route}
        metaGraph={metaData}
      />
      <Hero/>
      {/* <TextSlider /> */}
      <Details />
      <About/>
      <CountComp/>
      <FAQSection content={arrayFAQ}/>
      <GetStartedSection />
      {/* <Properties />
      <GridComponent/> */}
      <CookieConsentData/>
    </>
  )
}

export default Home