import React, { useState } from 'react';
import {
    TextField,
    Stack,
    Button,
    Box
} from '@mui/material';
import { replyParaStyle } from '../style/index';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const ChatTextInput = ({ text, setText, submitHandler, setReply, reply }) => {
    const [focus, setfocus] = useState(false);
    return (
        <form onSubmit={submitHandler}>
            <Stack>
                <Box
                    sx={{
                        height: '1.2px',
                        background: focus ? '#319405' : '#c9c9c9'
                    }}
                ></Box>
                <Stack
                    sx={{
                        p: '12px 10px',
                        borderBottom: '1px solid #d5d5d5'
                    }}
                >
                    {
                        !!reply && <Box
                            sx={{
                                position: 'relative',
                                width: 'fit-content'
                            }}
                        >
                            <HighlightOffRoundedIcon
                                sx={{
                                    position: 'absolute',
                                    top: '-2px',
                                    right: '-2px',
                                    zIndex: 1,
                                    fontSize: '15px',
                                    color: 'gray',
                                    cursor: 'pointer'
                                }}
                                onClick={e => {
                                    e.stopPropagation();
                                    setReply(null);
                                }}
                            />
                            <p style={{ ...replyParaStyle }}>{reply.data}</p>
                        </Box>
                    }
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder='Write a message...'
                        multiline={true}
                        focused={true}
                        minRows={2}
                        maxRows={20}
                        value={text}
                        onChange={e => setText(e.target.value)}
                        onFocus={() => setfocus(true)}
                        onBlur={() => setfocus(false)}
                        sx={{
                            '& textarea': {
                                background: '#eef2f0'
                            },
                            '& fieldset': {
                                border: 'none'
                            }
                        }}
                    />
                </Stack>
                <Box
                    sx={{
                        p: '10px'
                    }}
                >
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!text}
                        sx={{
                            width: '65px',
                            height: '25px',
                            borderRadius: '15px',
                            background: '#0f1b4c',
                            '&:hover': {
                                bgcolor: '#0f1b4c'
                            },
                            float: 'right'
                        }}
                    >
                        Send
                    </Button>
                </Box>
            </Stack>
        </form>
    )
}

export default ChatTextInput;