import { useContext, useEffect, useState } from "react";
// import {
//   Button,
//   Grid,
//   Typography,
//   Modal,
//   Paper,
//   makeStyles,
//   TextField,
// } from "@material-ui/core";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

//import { SetPopupContext } from "../../App";
import { SetPopupContext } from "../../../routes/index";

import apiList from "../../authentication/lib/apiList";
//import apiList from "../../lib/apiList";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  // makeStyles,
} from "@mui/material";
import ReactHelmet from "component/react-helmet/ReactHelmet";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     // padding: "30px",
//   },
// }));

const Profile = (props) => {
  // const classes = useStyles();
  const setPopup = useContext(SetPopupContext);

  const [profileDetails, setProfileDetails] = useState({
    name: "",
    employeeCompany: "",
    employeeWeb: "",
    bio: "",
    contactNumber: "",
  });

  const [phone, setPhone] = useState("");

  const handleInput = (key, value) => {
    setProfileDetails({
      ...profileDetails,
      [key]: value,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(apiList.user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        console.log(response.data);
        setProfileDetails(response.data);
        setPhone(response.data.contactNumber);
      })
      .catch((err) => {
        console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  };

  const handleUpdate = () => {
    let updatedDetails = {
      ...profileDetails,
    };
    if (phone !== "") {
      updatedDetails = {
        ...profileDetails,
        contactNumber: `+${phone}`,
      };
    } else {
      updatedDetails = {
        ...profileDetails,
        contactNumber: "",
      };
    }
    // console.log(updatedDetails)

    axios
      .put(apiList.user, updatedDetails, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        getData();
      })
      .catch((err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        console.log(err.response);
      });
  };

  return (
    <>
      <ReactHelmet 
        title={'Profile | AMUS HIRING'}
        description={''}
        route='profile'
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{ padding: "30px", minHeight: "93vh", marginTop: "80px",
      '@media(max-width:768px)':{
        marginTop:"5rem",
        width:"100%"
      }
      
      }}
      >
        <Grid item>
          <Typography variant="h2">Profile</Typography>
        </Grid>
        <Grid item xs style={{ width: "100%" }}>
          <Paper
            elevation={3}
            sx={{
              padding: "30px 20px",
              width: 500,
              margin: "50px auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              '@media(max-width:768px)':{
                width:"100%",
                padding:"1rem 1rem"
              }
            }}

            // style={{

            //   // outline: "none",

            //   // justifyContent: "center",

            //   //   width: "60%",
            // }}
          >
            <Grid container direction="column" alignItems="stretch" spacing={3}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "70%",
                  '@media(max-width:768px)':{
                    width:"100%",
                    // flexDirection:"column"
                    // paddingRight:"3rem"
                  }
                }}
              >
                <TextField
                  label="Name"
                  value={profileDetails.name}
                  onChange={(event) => handleInput("name", event.target.value)}
                  // className={classes.inputBox}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "70%",
                  '@media(max-width:768px)':{
                    width:"100%",
                    // flexDirection:"column"
                    // paddingRight:"3rem"
                  }
                }}
              >
                <TextField
                  select
                  label="Type of Company"
                  variant="outlined"
                  value={profileDetails.employeeCompany}
                  onChange={(event) => {
                    handleInput("employeeCompany", event.target.value);
                  }}
                  fullWidth
                >
                  <MenuItem value="Startup">Startup</MenuItem>
                  <MenuItem value="SME">SME</MenuItem>
                  <MenuItem value="Enterprise">Enterprise</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "70%",
                  '@media(max-width:768px)':{
                    width:"100%",
                    // flexDirection:"column"
                    // paddingRight:"3rem"
                  }
                }}
              >
                <TextField
                  label="Enter web address"
                  value={profileDetails.employeeWeb}
                  onChange={(event) =>
                    handleInput("employeeWeb", event.target.value)
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "70%",
                  '@media(max-width:768px)':{
                    width:"100%",
                    // flexDirection:"column"
                    // paddingRight:"3rem"
                  }
                }}
              >
                <TextField
                  fullWidth
                  label="Bio (upto 250 words)"
                  multiline
                  rows={8}
                  variant="outlined"
                  value={profileDetails.bio}
                  onChange={(event) => {
                    if (
                      event.target.value.split(" ").filter(function (n) {
                        return n != "";
                      }).length <= 250
                    ) {
                      handleInput("bio", event.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item sx={{ display: "flex", justifyContent: "center",
             '@media(max-width:768px)':{
              width:"100%",
              // flexDirection:"column"
              // paddingLeft:"5rem"
              // margin:"0rem 1rem"
            } }}>
                <PhoneInput
                  country={"in"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  style={{ width: "auto" }}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px", marginTop: "30px" }}
              onClick={() => handleUpdate()}
            >
              Update Details
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
