import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import apiList from 'pages/authentication/lib/apiList';
import { chat_base_url } from 'chat/helper';

const useActiveChat = () => {
    const [activeChat, setActiveChat] = useState(null); // make undifiend 
    const messageHandler = (data, cb) => {
        setActiveChat(pre => {
            const { id } = pre ?? {};
            if (typeof cb === 'function') {
                let st = id === data.senderId ? 'Seen' : 'Delivered'
                setTimeout(cb, 0, st)
            }
            if (!pre) return pre;
            if (pre.id !== data.senderId) return pre;
            return ({
                ...pre,
                data: [
                    ...pre.data,
                    {
                        ...data,
                        status: id === data.senderId ? 'Seen' : 'Delivered'
                    }
                ]
            })
        })
    };

    const selfMessageHandler = useCallback((data) => {

    }, []);

    const openChatByUserId = useCallback((id) => {
        axios
            .get(`${chat_base_url}/api/v1/chat/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then(({ data = null }) => {
                if (data) {
                    setActiveChat(data)
                }
            }).catch(console.log);
    }, [])

    const removeChatHandler = useCallback(() => setActiveChat(null), []);

    const updateMessage = useCallback((msg) => {
        setActiveChat(pre => {
            const { data } = pre ?? {};

            if (!data || !data?.length) {
                return pre;
            }
            return ({
                ...pre,
                data: data.map(i => {
                    if (new Date(i.createAt).getTime() === new Date(msg.createAt).getTime()) {
                        return { ...msg };
                    }
                    return i;
                })
            })
        })
    });

    return {
        activeChat,
        updateMessage,
        setActiveChat,
        messageHandler,
        openChatByUserId,
        removeChatHandler,
        selfMessageHandler,
    }

}

export default useActiveChat