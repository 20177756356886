import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const FeedBackDetails = ({
  feedback,
  handleOnClose,
  updating,
  updateFeedBack,
}) => {
  const {
    id,
    name,
    email,
    phoneNumber,
    description,
    notify,
    policyAgreement,
    status,
  } = feedback;

  const typoSx = {
    width: "140px",
    fontSize: "17px",
    letterSapcing: "0.1px",
  };

  return (
    <Box
      sx={{
        position: "absolute",
        maxHeight: "70vh",
        top: "50%",
        left: "50%",
        maxWidth: "600px",
        width: "90%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Paper>
        <Stack>
          <Typography
            component={"h1"}
            sx={{
              userSelect: "none",
              fontSize: {
                xs: "1.2rem",
                md: "1.5rem",
              },
              letterSpacing: "0.3px",
              fontWeight: 600,
              p: "15px 25px",
            }}
          >
            Details
          </Typography>
          <Divider />
          <Box sx={{ p: "5px 25px 25px 25px" }}>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing={8}
              alignItems={"center"}
              p={"10px 0px"}
            >
              <Typography sx={typoSx}>Name</Typography>
              <Typography>{name}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing={8}
              alignItems={"center"}
              p={"10px 0px"}
            >
              <Typography sx={typoSx}>Email</Typography>
              <Typography>{email}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing={8}
              alignItems={"center"}
              p={"10px 0px"}
            >
              <Typography sx={typoSx}>Phone</Typography>
              <Typography>{phoneNumber}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing={8}
              alignItems={"felx-start"}
              p={"10px 0px"}
            >
              <Typography sx={typoSx}>Description</Typography>
              <Box
                sx={{
                  maxHeight: "250px",
                  overflow: "auto",
                }}
              >
                {description}
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              spacing={8}
              alignItems={"felx-start"}
              p={"10px 0px"}
            >
              <Typography sx={typoSx}>Notify Me</Typography>
              <Checkbox
                checked={notify}
                disabled={true}
                sx={{
                  "&.Mui-checked": {
                    color: "#083559",
                  },
                  p: "0px",
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              spacing={5}
              mt={"10px"}
            >
              <Button
                onClick={handleOnClose}
                variant="contained"
                sx={{
                  bgcolor: "#07367e",
                  "&:hover": {
                    bgcolor: "#03265b",
                  },
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                }}
              >
                Cancle
              </Button>
              {status === "Pending" && (
                <Button
                  onClick={() => updateFeedBack(id)}
                  disabled={updating}
                  variant="contained"
                  sx={{
                    bgcolor: "#07367e",
                    "&:hover": {
                      bgcolor: "#03265b",
                    },
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                  }}
                >
                  Mark As Resolved
                  {updating && (
                    <CircularProgress
                      sx={{ position: "absolute", color: "#02213a" }}
                      size={18}
                    />
                  )}
                </Button>
              )}
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default FeedBackDetails;
