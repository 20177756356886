import { Stack, Typography } from "@mui/material";
import React, { useContext, useId } from "react";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import { SetPopupContext } from "routes";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const UploadFile = ({
  setFileData = () => undefined,
  fileData = {},
  title = "Click Here To Upload Your File",
  previewHandler = undefined,
}) => {
  const id = useId();
  const setPopup = useContext(SetPopupContext);
  const fileUploadHandler = (e) => {
    const { files } = e.target;
    if (!files) {
      return;
    }
    if (files.length > 1) {
      setPopup({
        open: true,
        severity: "error",
        message: "You can upload one file at time",
      });
      return;
    }
    const [file] = files;
    const { name, type, size } = file;
    if (size > 10 * 1024 * 1024) {
      setPopup({
        open: true,
        severity: "error",
        message: "Can't upload more then 10mb file",
      });
      return;
    }
    if (!["pdf", "doc", "docx"].some((i) => type.includes(i))) {
      setPopup({
        open: true,
        severity: "error",
        message: "Files type must be pdf or doc",
      });
      return;
    }
    console.log(file);
    setFileData({
      fileName: name,
      fileType: type,
      file,
    });
  };

  const clearFileHandler = (e) => {
    e.stopPropagation();
    setFileData({
      fileName: "",
      fileType: "",
      file: null,
    });
  };

  const { fileName } = fileData;

  return (
    <Stack
      p={"0px 53px"}
      mb={"20px"}
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={2}
    >
      <form>
        <input
          type="file"
          id={id}
          onChange={(e) => {
            fileUploadHandler(e);
            e.target.value = "";
          }}
          hidden
          multiple
          disabled={false}
        />
        <button
          type="button"
          onClick={(e) => document.getElementById(id).click()}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#03265B",
            border: "none",
            padding: "7px 10px",
            borderRadius: "5px",
            gap: "15px",
          }}
        >
          <UploadFileOutlinedIcon sx={{ color: "white" }} />{" "}
          <Typography sx={{ color: "white" }}>Resume</Typography>
        </button>
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {!fileName && (
          <p
            style={{
              margin: 0,
              fontSize: "15px",
              color: "rgb(33 63 66)",
              letterSpacing: "1px",
              textAlign: "center",
            }}
          >
            {title}
          </p>
        )}
        {!!fileName && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              position: "relative",
              width: "fit-content",
            }}
          >
            <Typography sx={{}}>{fileName}</Typography>
            <CancelPresentationOutlinedIcon
              onClick={clearFileHandler}
              sx={{
                position: "absolute",
                right: "-30px",
                fontSize: "20px",
                color: "red",
                cursor: "pointer",
              }}
            />
            {!!previewHandler && fileName && (
              <RemoveRedEyeOutlinedIcon
                sx={{
                  position: "absolute",
                  right: "-70px",
                  fontSize: "20px",
                  color: "rgb(3, 38, 91)",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  previewHandler();
                }}
              />
            )}
          </Stack>
        )}
      </div>
    </Stack>
  );
};

export default UploadFile;
