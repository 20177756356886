import { Box, Typography } from "@mui/material";
import * as React from "react";

export default function TermsConditions() {
  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#FFF",
          minHeight: "90vh",
          padding: "6rem 2rem 5rem 1rem",
          "@media (max-width:768px)": {
            marginTop: "2rem",
          },
        }}
      >
        <Box>
          <Typography variant="h3" component="h3" mt={2}>
            Terms of Service
          </Typography>
          <Typography variant="p" component="p">
            Last updated: 26 June 2024
          </Typography>
        </Box>
        <Box>
          <Typography variant="h1" component="h1" mt={2}>
            Overview
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            This is an agreement between AMUS SOFTWARE SL{" "}
            <strong>(AMUS HIRING, We, Us, Our)</strong>, and the User{" "}
            <strong>(User, You or Your)</strong>, setting out the general Terms
            of Your use <strong>(Terms)</strong>&nbsp;
            of the amushiring.com website (Platform), and any of Our products or
            services <strong>(Product)</strong>.
          </Typography>
          <Typography variant="p" component="p">
            Where other terms for services have been agreed with us, those terms
            shall take precedence over these Terms, where they conflict.
          </Typography>
          <Typography variant="h3" component="h2" mt={1}>
            AMUS SOFTWARE SL
          </Typography>
          <Typography variant="p" component="p" lineHeight={2}>
            <strong>Address</strong>: Vilamari, 51, Bj.1, Barcelona
          </Typography>
          <Typography variant="p" component="p">
            <strong>VAT Number</strong>: ES B72561442
          </Typography>
          <Typography variant="p" component="p">
            <strong>Contact Information</strong>
            <br />
            email: hola@amussoft.com
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Accounts and membership
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            By using the Platform and Products, You warrant and represent that
            You are at least 18 years of age, that you have full authority to
            enter into these Terms, and perform Your obligations. In turn, We
            warrant and represent that We have full authority to perform the
            services under these Terms. If You create an account on the
            Platform, You are responsible for maintaining the security of Your
            account and You are fully responsible for all activities that occur
            under the account and any other actions taken in connection with it.
            Providing false contact information of any kind may result in the
            termination of Your account. You must immediately notify Us of any
            unauthorized uses of Your account or any other breaches of security.
            We will not be liable for any acts or omissions by You, including
            any damages of any kind incurred as a result of such acts or
            omissions.
            <br />
            Because We value great relationships and transparency, the provision
            of Our Services is subject to a compliance check (KYC), following
            which We may suspend or cancel Your account, should We deem the
            check not satisfactory. You therefore warrant and represent that You
            are in good standing under the laws of the jurisdiction in which You
            are present, and You have not, or will You enter into any agreement
            that would prevent You from complying with or performing Your
            obligations under these Terms. We may suspend, disable, or delete
            Your account (or any part thereof) and discontinue any Services, if
            we determine that You have violated any provision of these Terms,
            any other terms You have agreed to, or that Your conduct or content
            would damage Our reputation and goodwill.
          </Typography>
          <Typography variant="h2" component="h2" mt={1}>
            User content
          </Typography>
          <Typography variant="p" component="p">
            <strong>Your submitted content:</strong>
            <br />
            <ul style={{lineHeight: 1.5}}>
              <li>Isn't owned by Us.</li>
              <li>
                You are responsible for its accuracy, legality, and ownership.
              </li>
              <li>
                If You submit Your personal data or of other individuals, We do
                not own it. We will only process it as described in Our privacy
                policy, and in the privacy and data processing section of these
                Terms.
              </li>
            </ul>
          </Typography>
          <Typography variant="p" component="p">
            <strong>When using the Platform, You agree not to:</strong>
            <br />
            <ul style={{lineHeight: 1.5}}>
              <li>
                Store, distribute or transmit viruses, malicious code, or other
                harmful technologies- that will in any way will affect the
                functionality or operation of the Platform.
              </li>
              <li>
                Do anything that infringes or violate Our intellectual property,
                and intellectual property rights of a third party.
              </li>
              <li>
                Create one or more accounts for other commercial or illegitimate
                purposes.
              </li>
              <li>
                Reverse engineer, copy, modify, data scape, automate, or re-sell
                the Platform in any way.
              </li>
              <li>
                Do anything unlawful or illegal, fraudulent, harmful,
                threatening, defamatory, discriminatory, or offensive and as
                such negatively impact Yours or Our public image.
              </li>
              <li>
                Solicit others to perform or participate in any unlawful acts.
              </li>
              <li>
                Violate any international, federal, provincial or state
                regulations, rules, laws, or local ordinances.
              </li>
              <li>
                Harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability.
              </li>
              <li>Submit false or misleading information.</li>
              <li>Spam, phish, pharm, pretext, spider, crawl, or scrape.</li>
              <li>Use the Platform for any obscene or immoral purpose; or</li>
              <li>
                Interfere with or circumvent the security features of the
                Platform or any related website.
              </li>
            </ul>
          </Typography>
          <Typography variant="h2" component="h2" mt={1}>
            Consequences (of any violation to these Terms)
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            If You violate any of the above restrictions or do anything else We
            reasonably believe to be harmful or illegitimate We may suspend Your
            access to the Platform, and We may subsequently terminate these
            Terms. Such termination shall be without liability to You.
            <br />
            We may suspend, disable, or delete Your account (or any part
            thereof) and discontinue any access to any of Our Products, if We
            determine that You have violated any provision of these Terms, any
            other terms You have agreed to with Us, or that Your conduct or
            content would damage Our reputation and goodwill.
          </Typography>
          <ul>
            <li>
              If We delete Your account for the foregoing reasons, You may not
              re-register for Our Services.
            </li>
            <li>
              We may block Your email address and Internet protocol address to
              prevent further registration.
            </li>
          </ul>
          <Typography variant="h2" component="h2" mt={2}>
            Billing and payments
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            You shall pay all fees or charges to Your account in accordance with
            the fees, charges, and billing terms in effect at the time a fee or
            charge is due and payable. If auto-renewal is enabled for the
            Product You have requested, You will be charged automatically in
            accordance with the terms You agreed to. If, in Our judgment, any of
            Your payments or transactions constitute a high-risk transaction, We
            will require You to provide us with a copy of Your valid
            government-issued photo identification, and any other additional
            documents required by Us, to verify compliance with applicable laws,
            policies and procedures. We reserve the right to change Products and
            Product pricing at any time.
            <br />
            We may, in Our sole discretion, limit or cancel access to Products
            purchased per person or entity. These restrictions may include
            Products requested by or under the same customer account, the same
            credit card, and that uses the same billing and/or shipping address.
            In the event that We make a change to or remove a Product, We will
            notify You by the e-mail and/or billing address/phone number listed
            on the Platform or provided at the time the Product was requested.
            <br />
            If You are a user using Our payment services, and it is required as
            part of the Product You avail from Us, You hereby authorize Us to
            act and hold ourselves out to be Your payment collection agent
            (“Payment Collection Agent”), and to hold and receive funds on Your
            behalf, for the limited purpose of facilitating payments for
            services You may provide to other entities on or off the Platform
            (“Service Recipients”).
            <br />
            In the capacity of Your Payment Collection Agent, in Our contract
            management product, Our role shall be limited to transferring
            payments received from Your Service Recipients to Your designated
            account(s) either directly, or through its payment partners, and its
            obligation to pay You is subject to and conditional upon successful
            receipt of such payments from the Service Recipients. We shall not
            be liable for any acts or omissions by You in the performance of
            Your services to Service Recipient, or any unpaid or disputed fees
            arising out of disputes between You and the Service Recipient.
            <br />
            The receipt of funds by Us from Service Recipients on Your behalf,
            shall be considered and deemed for all purposes to be full and
            complete payment of funds to You, and Service Recipients shall be
            relieved of any and all risk of loss immediately upon payment to Us,
            as if Service Recipients had delivered funds directly to You.
            <br />
            You shall advise Service Recipients that payments to AMUS HIRING
            will be deemed payment directly to You and will discharge Service
            Recipients of all payment obligations to You by the amount of the
            payment received by Us. Upon receipt of funds from Service
            Recipients by Us on Your behalf, We shall contemporaneously issue a
            receipt or confirmation to Service Recipients acknowledging such
            receipt of funds, and confirming that such receipt is deemed payment
            to You, and that the Service Recipient shall be relieved of all risk
            of loss arising from the subsequent transfer of such funds to You.
            Payment services for contractors are powered by Third Party Service
            Providers, whose licenses can be found on their web.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Social Media
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            As part of the Services, you may be able to link your account with
            other accounts you have with third-party service providers (each a
            "Third-Party Provider Account") by: (a) providing your Third-Party
            Provider Account login information through the Services; or (b)
            allowing us to access your Third-Party Provider Account. You
            represent and warrant that you are entitled to disclose your
            Third-Party Provider Account login information to us and/or grant us
            access to your Third-Party Provider Account, without breach by you
            of any of the terms and conditions that govern your use of the
            applicable Third-Party Provider Account, and without obligating us
            to pay any fees or making us subject to any usage limitations
            imposed by the third-party service provider of the Third-Party
            Provider Account. By granting us access to any Third-Party Provider
            Accounts, you acknowledge and agree that (i) we may access, make
            available, and store (if applicable) any content that you have
            provided to and stored in your Third-Party Provider Account (the
            "Social Media Content") so that it is available on and through the
            Services via your account, including without limitation any friend
            or connections lists and (ii) we may submit to and receive from your
            Third-Party Provider Account additional information to the extent
            you are notified when you link your account with the Third-Party
            Provider Account. Depending on the Third-Party Provider Accounts you
            choose and subject to the privacy settings that you have set in such
            Third-Party Provider Accounts, personally identifiable information
            that you post to your Third-Party Provider Accounts may be available
            on and through your account on the Services. Please note that if a
            Third-Party Provider Account or associated service becomes
            unavailable or our access to such Third Party Provider Account is
            terminated by the third-party service provider, then Social Media
            Content may no longer be available on and through the Services. You
            will have the ability to disable the connection between your account
            on the Services and your Third-Party Provider Accounts at any time.
            PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
            PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY PROVIDER ACCOUNTS IS
            GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
            PROVIDERS. We make no effort to review any Social Media Content for
            any purpose, including but not limited to, for accuracy, legality,
            or non-infringement, and we are not responsible for any Social Media
            Content. You acknowledge and agree that we may access your email
            address book associated with a Third-Party Provider Account and your
            contacts list stored on your mobile device or tablet computer solely
            for purposes of identifying and informing you of those contacts who
            have also registered to use the Services. You can deactivate the
            connection between the Services and your Third-Party Provider
            Account by contacting us using the contact information below or
            through your account settings (if applicable).
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Intellectual Property
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            You acknowledge that any and all Intellectual Property Rights
            subsisting in or used in connection with the Services and their
            original content, features and functionality are and will remain the
            exclusive property of AMUS HIRING, and its licensors and you shall
            not at any time (including after termination of the license granted
            herein) in any way dispute, challenge, or undertake any action
            directly or indirectly that would be inconsistent with the ownership
            thereof by Us or Our licensors. The Services are protected by
            copyright, trademark, and other laws of any country. Our trademarks
            and trade dress may not be used in connection with any product or
            service without the prior written consent of AMUS HIRING.
            <br />
            If you provide to us any ideas, proposals, suggestions or other
            materials ("Feedback"), whether related to the Services or
            otherwise, you hereby acknowledge and agree that such Feedback is
            not confidential, and that your provision of such Feedback is
            gratuitous, unsolicited and without restriction, and does not place
            us under any fiduciary or other obligation. We may use such Feedback
            without compensating you and without any restriction or obligation
            to you.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Accuracy of information and backups
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            Occasionally there may be information on the Platform that contains
            typographical errors, inaccuracies or omissions that may relate to
            promotions and offers.{" "}
            <strong>
              We reserve the right to correct any errors, inaccuracies or
              omissions, and to change or update information
            </strong>
            . We undertake no obligation to update, amend or clarify information
            on the Platform including, without limitation, pricing information,
            except as required by law. No specified update or refresh date
            applied on the Platform should be taken to indicate that all
            information on the Website or on any related Product have been
            modified or updated.
            <br />
            We will not be liable for any acts or omissions by You, including
            processing of payments to You on the basis of inaccurate information
            on Your account, or any damages of any kind incurred as a result of
            such acts or omissions. Also, We regularly back up the Platform and
            its Content and will do Our best to ensure completeness and
            accuracy. If there's a hardware issue or data loss, we'll
            automatically restore backups to minimize disruptions.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Links to other websites
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            While this Platform may have links or application programming
            interfaces (APIs) that provide ways to access other websites, We
            don't imply approval or association nor do We warrant association
            unless stated.
            <br />
            We are not responsible for others' offerings, products, services,
            actions, or content.
            <br />
            Your use of external websites is at Your own risk. Review their
            terms and conditions.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Disclaimer
          </Typography>
          <Typography variant="p" component="p" fontSize="18px" lineHeight={1.5}>
            <Typography fontSize="18px">
              YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
              PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE SERVICES ARE
              PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT OR COURSE OF PERFORMANCE.
              <br />
              AMUS HIRING, ITS SUBSIDIARIES, AFFILIATES, AND ITS LICENSORS DO
              NOT WARRANT THAT (A) THE SERVICES WILL FUNCTION UNINTERRUPTED,
              SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY
              ERRORS OR DEFECTS WILL BE CORRECTED; (C) THE SERVICES ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING
              THE SERVICES WILL MEET YOUR REQUIREMENTS.
            </Typography>
            <br />
            Consider consulting professionals and Your attorney before accepting
            these Terms.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Limitation of liability
          </Typography>
          <Typography variant="p" component="p" fontSize="18px" lineHeight={1.5}>
            EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW, IN NO EVENT SHALL
            AMU SHIRING, NOR ITS DIRECTORS, EMPLOYEES, PARTNERS, AGENTS,
            SUPPLIERS, OR AFFILIATES, BE LIABLE FOR ANY (A) INDIRECT,
            INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES,
            INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL,
            OR OTHER INTANGIBLE LOSSES, RESULTING FROM (I) YOUR ACCESS TO OR USE
            OF OR INABILITY TO ACCESS OR USE THE SERVICE; (II) ANY CONDUCT OR
            CONTENT OF ANY OTHER USERS OR THIRD PARTY ON OR THROUGH THE SERVICE;
            (III) ANY CONTENT OBTAINED FROM THE SERVICE; AND (IV) UNAUTHORIZED
            ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER
            BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY
            OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE
            POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS
            FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
            <br />
            TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, OUR MAXIMUM
            LIABILITY TO YOU RELATING TO THE SERVICES OR ARISING OUT OF OR IN
            ANY WAY CONNECTED TO THESE TERMS SHALL NOT EXCEED ONE HUNDRED EUROS.
            THE EXISTENCE OF ONE OR MORE CLAIMS BY YOU WILL NOT INCREASE OUR
            LIABILITY.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Governing law
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            These Terms shall be governed and construed in accordance with the
            laws of Barcelona, Spain, without regard to its conflict of law
            provisions. You hereby irrevocably waive any and all rights to trial
            by jury in any legal proceeding arising out of or relating to these
            Terms.
            <br />
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have had between us regarding the Service.
            <br />
            We reserve the right to modify these Terms and any policies relating
            to the Platform at any time.
            <br />
            Continued use of the Platform after any such changes shall
            constitute Your consent to such changes.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Acceptance of these terms
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            <strong>
              You acknowledge that You have read these Terms and agree to all
              its Terms.
            </strong>
            <br />
            <strong>
              By using the Platform or Product requested on the Platform, You
              agree to be bound by the Terms.
            </strong>
            <br />
            If You do not agree to abide by the Terms, You are not authorized to
            use or access the Platform and any of the Product.
            <br />
            Should You have been invited to the Platform by one of Our clients,
            and wish to terminate your account at any time, including due to a
            disagreement with any updates to these Terms, please reach out to
            the client. These Terms shall otherwise remain in effect until such
            client’s subscription for You terminates, or Your access to the
            Platform has been terminated by the client or Us.
          </Typography>
          <Typography variant="h2" component="h2" mt={2}>
            Special Terms for specific users
          </Typography>
          <Typography variant="h3" component="h3" mt={2}>
            Contractors
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            If You are a contractor of Our Client registered on the Platform,
            You authorize Us to digitally send You any document required to be
            shared with You under local tax regulations and applicable law, at
            any time in the period in which You use Our Products.
          </Typography>
          <Typography variant="h3" component="h3" mt={2}>
            Candidates
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            If You are a candidate using our platform, You acknowledge that:
            <ol style={{lineHeight:1.5}}>
              <li>
                Some job listings are submitted by third-party employers. We do
                not guarantee, endorse, or verify the accuracy, completeness, or
                real-time availability of any role or the reliability of any
                employer,
              </li>
              <li>
                The hiring and application process for any role listed is
                managed directly by the respective employer. We are not
                responsible for, nor do We have any control over, the selection
                process, interviews, or any decisions made by employers.
              </li>
              <li>
                If You choose to apply to a role listed, Your personal data will
                be processed in accordance with the employer’s privacy policy.
              </li>
            </ol>
          </Typography>
          <Typography variant="h3" component="h3" mt={2}>
            Users of the Platform via API integration
          </Typography>
          <Typography variant="p" component="p" lineHeight={1.5}>
            If you are using Our Products via a full or partial integration of
            Our API with another web platform or provider{" "}
            <strong>(External Provider)</strong>, You agree to be bound by these
            Terms, and acknowledge that Your access to Our Products and Our
            Platform may be limited by the functionality and capabilities of the
            API. You acknowledge that We have no control over External Providers
            or the availability of External Providers. We may add or remove an
            External Provider at any time.
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
