import { useState, useEffect, useContext } from "react";
// import {
//   Button,
//   Chip,
//   Grid,
//   IconButton,
//   InputAdornment,
//   makeStyles,
//   Paper,
//   TextField,
//   Typography,
//   Modal,
//   Slider,
//   FormControlLabel,
//   FormGroup,
//   MenuItem,
//   Checkbox,
//   Avatar,
// } from "@material-ui/core";
import { useParams } from "react-router-dom";
// import Rating from "@material-ui/lab/Rating";
import axios from "axios";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import styled from "styled-components";

//import { SetPopupContext } from "../../App";
import { SetPopupContext } from "../../../routes/index";
import styled from "styled-components";

import apiList, { server } from "../../authentication/lib/apiList";
//import apiList, { server } from "../../lib/apiList";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Rating,
  Typography,
  // makeStyles,
} from "@mui/material";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import { ChatContext } from "chat/context/ChatContextProvider";
import SendIcon from '@mui/icons-material/Send';
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   statusBlock: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textTransform: "uppercase",
//   },
//   jobTileOuter: {
//     padding: "30px",
//     margin: "20px 0",
//     boxSizing: "border-box",
//     width: "100%",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   avatar: {
//     width: theme.spacing(17),
//     height: theme.spacing(17),
//   },
// }));

const FilterPopup = (props) => {
  // const classes = useStyles();
  const { open, handleClose, searchOptions, setSearchOptions, getData } = props;
  return (
    <Modal open={open} onClose={handleClose} sx={{ marginTop: '230px' }}>
      <Paper
        sx={{
          padding: "20px 50px",
          //  outline: "none",
          //  display: "flex",
          //  flexDirection: "column",
          //  justifyContent: "center",
          // minWidth: "30%",
          //  alignItems: "center",
          maxWidth: "70%",
          marginLeft: "15%"
        }}
      // style={{
      //   padding: "50px",
      //   outline: "none",
      //   minWidth: "50%",
      // }}
      >
        <Grid container direction="column" alignItems="center" spacing={3} >
          <Grid container item alignItems="center" sx={{
            '@media(max-width:768px)': {
              flexDirection: 'column',



            }
          }}>
            <Grid item xs={3} sx={{
              '@media(max-width:768px)': {
                width: '100%',
                marginRight: "3rem"


              }
            }}>
              Applications
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
              sx={{
                '@media(max-width:768px)': {
                  position: "relative",
                  left: "1rem"
                }
              }}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="rejected"
                      checked={searchOptions.status.rejected}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          status: {
                            ...searchOptions.status,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Rejected"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="applied"
                      checked={searchOptions.status.applied}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          status: {
                            ...searchOptions.status,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Applied"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="shortlisted"
                      checked={searchOptions.status.shortlisted}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          status: {
                            ...searchOptions.status,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Shortlisted"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item alignItems="center" sx={{
            '@media(max-width:768px)': {
              flexDirection: "column"
            }
          }}>
            <Grid item xs={3} sx={{
              '@media(max-width:768px)': {
                position: "relative",
                right: "1rem"
              }
            }}>
              Sort
            </Grid>
            <Grid item container direction="row" xs={9} sx={{
              '@media(max-width:768px)': {
                display: "grid", gridTemplateColumns: "1fr"
              }
            }}>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                sx={{
                  border: "1px solid #D1D1D1", borderRadius: "5px", '@media(max-width:768px)': {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    width: "100%",
                    border: "none",
                    gap: "1rem",
                    position: "relative",
                    right: "1.5rem"



                  }
                }}
              >
                <Grid item>
                  <Checkbox
                    name="name"
                    checked={searchOptions.sort["name"].status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "name": {
                            ...searchOptions.sort["name"],
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="name"
                  />
                </Grid>
                <Grid item sx={{
                  '@media(max-width:768px)': {
                    width: "3rem"
                  }
                }}>
                  <label for="name">
                    <Typography sx={{
                      '@media(max-width:768px)': {
                        fontSize: "0.8rem"
                      }
                    }}>Name</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort["name"].status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "name": {
                            ...searchOptions.sort["name"],
                            desc: !searchOptions.sort["name"].desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort["name"].desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                sx={{
                  border: "1px solid #D1D1D1", borderRadius: "5px", '@media(max-width:768px)': {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    width: "100%",
                    border: "none",
                    gap: "1rem",
                    position: "relative",
                    right: "1.5rem"



                  }
                }}
              >
                <Grid item>
                  <Checkbox
                    name="dateOfApplication"
                    checked={searchOptions.sort.dateOfApplication.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          dateOfApplication: {
                            ...searchOptions.sort.dateOfApplication,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="dateOfApplication"
                  />
                </Grid>
                <Grid item sx={{
                  '@media(max-width:768px)': {
                    width: "3rem"
                  }
                }}>
                  <label for="dateOfApplication">
                    <Typography sx={{
                      '@media(max-width:768px)': {
                        fontSize: "0.8rem"
                      }
                    }}>Date of Application</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.dateOfApplication.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          dateOfApplication: {
                            ...searchOptions.sort.dateOfApplication,
                            desc: !searchOptions.sort.dateOfApplication.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.dateOfApplication.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                sx={{
                  border: "1px solid #D1D1D1", borderRadius: "5px", '@media(max-width:768px)': {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    width: "100%",
                    border: "none",
                    gap: "1rem",
                    position: "relative",
                    right: "1.5rem"



                  }
                }}
              >
                <Grid item>
                  <Checkbox
                    name="rating"
                    checked={searchOptions.sort["rating"].status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "rating": {
                            ...searchOptions.sort[["rating"]],
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="rating"
                  />
                </Grid>
                <Grid item sx={{
                  '@media(max-width:768px)': {
                    width: "3rem"
                  }
                }}>
                  <label for="rating">
                    <Typography sx={{
                      '@media(max-width:768px)': {
                        fontSize: "0.8rem"
                      }
                    }}>Rating</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort["rating"].status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          "rating": {
                            ...searchOptions.sort["rating"],
                            desc: !searchOptions.sort["rating"]
                              .desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort["rating"].desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={() => getData()}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

const ContainerGrid = styled.div`
.btn-short{
 background-color: #DC851F;
 color: #fff;
transition: all 0.5s ease;
}
:hover{
background-color: #fff;
color: #DC851F;
border: 1px solid #DC851F;
  }


 


`;

const ContainerAccept = styled.div`
.btn-accept{
  background-color: #09BC8A;
  color: #fff;
 transition: all 0.5s ease;
 }
 :hover{
 background-color: #fff;
 color: #09BC8A;
 border: 1px solid #09BC8A;
   }
`;
const ContainerReject = styled.div`
  .btn-reject{
 background-color: #D1345B;
 color: #fff;
 transition: all 0.5s ease;

}
:hover{
background-color: #fff;
color: #D1345B;
border: 1px solid #D1345B;
  }
`;






const ApplicationTile = (props) => {
  // const classes = useStyles();
  const { application, getData } = props;
  const setPopup = useContext(SetPopupContext);
  const [open, setOpen] = useState(false);
  const { openChatByUserId } = useContext(ChatContext);

  const appliedOn = new Date(application.dateOfApplication);

  const handleClose = () => {
    setOpen(false);
  };

  const colorSet = {
    applied: "#3454D1",
    shortlisted: "#DC851F",
    accepted: "#09BC8A",
    rejected: "#D1345B",
    deleted: "#B49A67",
    cancelled: "#FF8484",
    finished: "#4EA5D9",
  };

  const getResume = () => {
    if (
      application.jobApplicant.resume &&
      application.jobApplicant.resume !== ""
    ) {
      const address = `${server}${application.jobApplicant.resume}`;
      console.log(address);
      axios(address, {
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => {
          console.log(error);
          setPopup({
            open: true,
            severity: "error",
            message: "Error",
          });
        });
    } else {
      setPopup({
        open: true,
        severity: "error",
        message: "No resume found",
      });
    }
  };

  const updateStatus = (status) => {
    const address = `${apiList.applications}/${application.id}`;
    const statusData = {
      status: status,
      dateOfJoining: new Date().toISOString(),
    };
    axios
      .put(address, statusData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        getData();
      })
      .catch((err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        console.log(err.response);
      });
  };

  const buttonSet = {
    applied: (
      <>
        <Grid item xs>
          <ContainerGrid >
            <Button
              className="btn-short"
              // className={classes.statusBlock}
              sx={{
                textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center",

              }}
              onClick={() => updateStatus("shortlisted")}
            >
              Shortlist
            </Button>
          </ContainerGrid>
        </Grid>
        <Grid item xs>
          <ContainerReject >
            <Button
              className="btn-reject"
              // className={classes.statusBlock}
              sx={{
                textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center",
              }}
              onClick={() => updateStatus("rejected")}
            >
              Reject
            </Button>
          </ContainerReject>
        </Grid>
        <Grid item xs>
          <Button
            onClick={() => {
              openChatByUserId(application.jobApplicant.userId)
            }}
            variant="contained"
            sx={{
              bgcolor: 'rgb(52, 84, 209)',
              borderRadius: '4px',
              '&:hover': {
                bgcolor: 'rgb(52, 84, 209)'
              }
            }}
          >Chat <SendIcon sx={{ ml: '10px' }} /> </Button>
        </Grid>
      </>
    ),
    shortlisted: (
      <>
        <Grid item xs sx={{ display: "grid", minWidth: "121px" }}>
          <ContainerAccept>
            <Button
              className="btn-reject"

              sx={{
                textAlign: "center", display: "grid", minHeight: "42px", alignContent: "center",
                background: colorSet["accepted"],
                color: "#ffffff",
                '@media(max-width:768px)': {
                  minWidth: "100%",
                  padding: "0.1rem ",
                  // border:"1px solid red",

                }
              }}
              onClick={() => updateStatus("accepted")}
            >
              Accept
            </Button>
          </ContainerAccept>
        </Grid>
        <Grid item xs>
          <ContainerReject>
            <Button
              // className={classes.statusBlock}
              sx={{
                textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center",
                background: colorSet["rejected"],
                color: "#ffffff",
              }}
              onClick={() => updateStatus("rejected")}
            >
              Reject
            </Button>
          </ContainerReject>
        </Grid>
      </>
    ),
    rejected: (
      <>
        <Grid item xs>
          <Paper
            sx={{
              textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center", background: colorSet["rejected"],
              color: "#ffffff",
              '@media(max-width:768px)': {
                minWidth: "100%",
                padding: "0.1rem ",
                // border:"1px solid red",

              }
            }}
          // className={classes.statusBlock}

          >
            Rejected
          </Paper>
        </Grid>
      </>
    ),
    accepted: (
      <>
        <Grid item xs   >
          <Paper
            sx={{
              textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center", background: colorSet["accepted"], color: "#ffffff",
              '@media(max-width:768px)': {
                minWidth: "100%",
                padding: "0.1rem ",
                // border:"1px solid red",

              }
            }}
          // className={classes.statusBlock}

          >
            Accepted
          </Paper>
        </Grid>
      </>
    ),
    cancelled: (
      <>
        <Grid item xs>
          <Paper
            // className={classes.statusBlock}
            sx={{
              textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center",
              background: colorSet["cancelled"],
              color: "#ffffff",
              '@media(max-width:768px)': {
                minWidth: "100%",
                padding: "0.1rem ",
                // border:"1px solid red",

              }
            }}
          >
            Cancelled
          </Paper>
        </Grid>
      </>
    ),
    finished: (
      <>
        <Grid item xs>
          <Paper
            // className={classes.statusBlock}
            sx={{
              textAlign: "center", maxWidth: "30%", display: "grid", minHeight: "42px", alignContent: "center",
              background: colorSet["finished"],
              color: "#ffffff",
              '@media(max-width:768px)': {
                minWidth: "100%",
                padding: "0.1rem ",
                // border:"1px solid red",

              }
            }}
          >
            Finished
          </Paper>
        </Grid>
      </>
    ),
  };

  return (
    <Paper elevation={3} sx={{
      padding: "30px 20px", width: 800, margin: "20px auto", marginTop: "20px",
      '@media(max-width:768px)': {
        width: "100%",

      }
    }}>
      <Grid container>
        {/* <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src={`${server}${application.jobApplicant.profile}`}
            // className={classes.avatar}
          />
        </Grid> */}
        <Grid container item xs={7} spacing={1} direction="column" sx={{
          '@media(max-width:768px)': {
            width: "100%",

          }
        }}>
          <Grid item>
            <Typography variant="h3">
              {application.jobApplicant.name}
            </Typography>
          </Grid>
          <Grid item>
            <Rating
              value={
                application.jobApplicant.rating !== 0
                  ? application.jobApplicant.rating
                  : null
              }
              readOnly
            />
          </Grid>
          <Grid item> Profile: {application.jobApplicant.Profilerole} </Grid>
          <Grid item > Job Type: {application.jobApplicant.Role} </Grid>
          <Grid item> Experience: {application.jobApplicant.experience} </Grid>
          <Grid item> Notice Period: {application.jobApplicant.noticeperiod} </Grid>
          {/* <Grid item> Last Salary: {application.jobApplicant.lastsalary} </Grid>
          <Grid item > Expected Salary: {application.jobApplicant.expectedsalary} </Grid> */}
          <Grid item>Applied On: {appliedOn.toLocaleDateString()}</Grid>
          <Grid item>
            Education:{" "}
            {application.jobApplicant.Education
              .map((edu) => {
                return `${edu.institutionName} (start Year:${edu.startYear})(end Year:${edu.endYear ? edu.endYear : "Ongoing"
                  })`;
              })
              .join(", ")}
          </Grid>

          <Grid item>
            Work Experience:{" "}
            {application.jobApplicant.WorkExperience
              .map((exp) => {
                return `${exp.companyrole}   (Work start Year:${exp.WorkstartYear})   (Work end Year: ${exp.WorkendYear ? exp.WorkendYear : "Ongoing"
                  })`;
              })
              .join(", ")}
          </Grid>
          <Grid item>
            Language:{" "}
            {application.jobApplicant.Language
              .map((lan) => {
                return `${lan.Languageknow} 
                  level : ${lan.ExpLevel} `;
              })
              .join(", ")}
          </Grid>
          <Grid item>
            SOP: {application.sop !== "" ? application.sop : "Not Submitted"}
          </Grid>
          <Grid item>
            Skill: {application.jobApplicant.skills.map((skill) => (
              <Chip label={skill} style={{ marginRight: "2px" }} />
            ))}
          </Grid>
          <Grid item>
            Last Salary : {application.jobApplicant.lastCurrencyType}. {application.jobApplicant.lastsalary}
          </Grid>
          <Grid item>
            Expected Salary : {application.jobApplicant.expectedCurrencyType}. {application.jobApplicant.expectedsalary}
          </Grid>
          <Grid item >
            {/* <Button
              variant="contained"
              // className={classes.statusBlock}
              color="primary"
              onClick={() => getResume()}
            >
              Download Resume
            </Button> */}
            <Grid item container xs sx={{
              '@media(max-width:768px)': {
                gap: "1rem",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                width: '100%'

              }
            }}>
              {buttonSet[application.status]}
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose} sx={{ marginTop: '230px' }}>
        <Paper
          style={{
            padding: "20px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "30%",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "10px 50px" }}
          // onClick={() => changeRating()}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </Paper>
  );
};

const JobApplications = (props) => {
  const setPopup = useContext(SetPopupContext);
  const [applications, setApplications] = useState([]);
  const { jobId } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    status: {
      all: false,
      applied: false,
      shortlisted: false,
    },
    sort: {
      "name": {
        status: false,
        desc: false,
      },
      dateOfApplication: {
        status: true,
        desc: true,
      },
      "rating": {
        status: false,
        desc: false,
      },
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let searchParams = [];

    if (searchOptions.status.rejected) {
      searchParams = [...searchParams, `status=rejected`];
    }
    if (searchOptions.status.applied) {
      searchParams = [...searchParams, `status=applied`];
    }
    if (searchOptions.status.shortlisted) {
      searchParams = [...searchParams, `status=shortlisted`];
    }

    let asc = [],
      desc = [];

    Object.keys(searchOptions.sort).forEach((obj) => {
      const item = searchOptions.sort[obj];
      if (item.status) {
        if (item.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });
    searchParams = [...searchParams, ...asc, ...desc];
    const queryString = searchParams.join("&");
    console.log(queryString);
    let address = `${apiList.applicants}?jobId=${jobId}`;
    if (queryString !== "") {
      address = `${address}&${queryString}`;
    }

    console.log(address);

    axios
      .get(address, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setApplications(response.data);
      })
      .catch((err) => {
        console.log(err.response);
        // console.log(err.response.data);
        setApplications([]);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
      });
  };

  return (
    <>
      <ReactHelmet
        title={`${jobId} My Job | AMUS HIRING`}
        description={''}
        route=''
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{ marginTop: "60px" }}
        style={{ padding: "30px", minHeight: "93vh" }}
      >
        <Grid item>
          <Typography variant="h2">Applications</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setFilterOpen(true)}>
            <FilterListIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          style={{ width: "100%" }}
          alignItems="stretch"
          justify="center"
        >
          {applications.length > 0 ? (
            applications.map((obj) => (
              <Grid item>
                {/* {console.log(obj)} */}
                <ApplicationTile application={obj} getData={getData} />
              </Grid>
            ))
          ) : (
            <Typography variant="h5" style={{ textAlign: "center" }}>
              No Applications Found
            </Typography>
          )}
        </Grid>
      </Grid>
      <FilterPopup
        open={filterOpen}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        handleClose={() => setFilterOpen(false)}
        getData={() => {
          getData();
          setFilterOpen(false);
        }}
      />
    </>
  );
};

export default JobApplications;
