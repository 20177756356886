import { useState, useContext } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  MenuItem,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
//import { Redirect } from "react-router-dom";
//import ChipInput from 'material-ui-chip-input'
//import {Chip} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import DescriptionIcon from "@mui/icons-material/Description";
import FaceIcon from "@mui/icons-material/Face";
//import DescriptionIcon from "@material-ui/icons/Description";
//import FaceIcon from "@material-ui/icons/Face";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Link } from "react-router-dom";

import PasswordInput from "../lib/PasswordInput";
import EmailInput from "../lib/EmailInput";
import FileUploadInput from "../lib/FileUploadInput";
//import CloudUploadIcon from "@mui/icons-material/CloudUpload";
//import { SetPopupContext } from "../App";
import { SetPopupContext } from "../../../routes/index";

import apiList from "../lib/apiList";
import isAuth from "../lib/isAuth";
import ReactHelmet from "component/react-helmet/ReactHelmet";

const EmployerSignup = () => {
  const location = useNavigate();
  //const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  const [loading, setLoading] = useState(false);
  const [loggedin, setLoggedin] = useState(isAuth());

  const [signupDetails, setSignupDetails] = useState({
    type: "recruiter",
    email: "",
    password: "",
    name: "",
    education: [],
    WorkExperience: [],
    Language: [],
    Profilerole: "",
    selectedCountry: "",
    Role: "",
    experience: "",
    noticeperiod: "",
    lastsalary: "",
    expectedsalary: "",
    Setavailability: "",
    skills: [],
    resume: "",
    profile: "",
    employeeCompany: "",
    employeeWeb: "",
    bio: "",
    contactNumber: "",
    employeeContact: "",
  });

  const [phone, setPhone] = useState("");

  const [phoneemployee, setPhoneemployee] = useState("");

  // const [selectedCountry, setSelectedCountry] = useState('');

  const [education, setEducation] = useState([
    {
      institutionName: "",
      Degree: "",
      Stream: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const [WorkExperience, setWorkExperience] = useState([
    {
      companyrole: "",
      company: "",
      location: "",
      WorkstartYear: "",
      WorkendYear: "",
    },
  ]);

  const [Language, setLanguage] = useState([
    {
      Languageknow: "",
      ExpLevel: "",
    },
  ]);

  const [inputErrorHandler, setInputErrorHandler] = useState({
    email: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    password: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    name: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    companyType: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    webAddress: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
  });

  const handleInput = (key, value) => {
    setSignupDetails({
      ...signupDetails,
      [key]: value,
    });
  };

  const handleInputError = (key, status, message) => {
    setInputErrorHandler({
      ...inputErrorHandler,
      [key]: {
        required: true,
        untouched: false,
        error: status,
        message: message,
      },
    });
  };

  const handleLogin = () => {
    const tmpErrorHandler = {};
    Object.keys(inputErrorHandler).forEach((obj) => {
      if (inputErrorHandler[obj].required && inputErrorHandler[obj].untouched) {
        tmpErrorHandler[obj] = {
          required: true,
          untouched: false,
          error: true,
          message: inputErrorHandler[obj].message,
        };
      } else {
        tmpErrorHandler[obj] = inputErrorHandler[obj];
      }
    });

    // let updatedDetails = {
    //   ...signupDetails,
    //   education: education
    //     .filter((obj) => obj.institutionName.trim() !== "")
    //     .map((obj) => {
    //       if (obj["endYear"] === "") {
    //         delete obj["endYear"];
    //       }
    //       return obj;
    //     }),
    //      WorkExperience: WorkExperience
    //     .filter((obj) => obj.companyrole.trim() !== "")
    //     .map((obj) => {
    //       if (obj["WorkendYear"] === "") {
    //         delete obj["WorkendYear"];
    //       }
    //       return obj;
    //     }),
    //     Language: Language
    //         .filter((obj) => obj.Languageknow.trim() !== "")
    //         .map((obj) => {

    //           return obj;
    //         }),

    // };

    //   // let updatedDetails = {
    //   //   ...signupDetails,
    //   //   education: education
    //   //     .filter((obj) => obj.institutionName.trim() !== "")
    //   //     .map((obj) => {
    //   //       if (obj["endYear"] === "") {
    //   //         delete obj["endYear"];
    //   //       }
    //   //       return obj;
    //   //     }),
    //   //      WorkExperience: WorkExperience
    //   //     .filter((obj) => obj.companyrole.trim() !== "")
    //   //     .map((obj) => {
    //   //       if (obj["WorkendYear"] === "") {
    //   //         delete obj["WorkendYear"];
    //   //       }
    //   //       return obj;
    //   //     }),
    //   //     Language: Language
    //   //     .filter((obj) => obj.Languageknow.trim() !== "")
    //   //     .map((obj) => {

    //   //       return obj;
    //   //     }),
    //   // };

    //   if (phoneemployee !== " ") {
    //     updatedDetails = {
    //       ...signupDetails,
    //       employeeContact: `+${phoneemployee}`
    //     };
    //   } else {
    //     updatedDetails = {
    //       ...signupDetails,
    //       employeeContact: "",

    //     };
    //   };

    //   setSignupDetails(updatedDetails);

    let updatedDetails = {
      ...signupDetails,
    };

    updatedDetails.education = education
      .filter((obj) => obj.institutionName.trim() !== "")
      .map((obj) => {
        if (obj["endYear"] === "") {
          delete obj["endYear"];
        }
        return obj;
      });

    updatedDetails.WorkExperience = WorkExperience.filter(
      (obj) => obj.companyrole.trim() !== "",
    ).map((obj) => {
      if (obj["WorkendYear"] === "") {
        delete obj["WorkendYear"];
      }
      return obj;
    });

    updatedDetails.Language = Language.filter(
      (obj) => obj.Languageknow.trim() !== "",
    ).map((obj) => {
      return obj;
    });

    if (phoneemployee !== "") {
      updatedDetails.employeeContact = `+${phoneemployee}`;
    } else {
      updatedDetails.employeeContact = "";
    }

    setSignupDetails(updatedDetails);

    const verified = !Object.keys(tmpErrorHandler).some((obj) => {
      return tmpErrorHandler[obj].error;
    });

    // let updatedDetails = {
    //   ...signupDetails,
    // };

    if (verified) {
      setLoading(true);
      axios
        .post(apiList.signup, updatedDetails)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          setLoggedin(isAuth());
          setPopup({
            open: true,
            severity: "success",
            message: "Logged in successfully",
          });
          console.log(response);
        })
        .catch((err) => {
          setPopup({
            open: true,
            severity: "error",
            message: err.response.data.message,
          });
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setInputErrorHandler(tmpErrorHandler);
      setPopup({
        open: true,
        severity: "error",
        message: "Incorrect Input",
      });
    }
  };

  const [recruiterPhone, setRecruiterPhone] = useState(true);
  const handleLoginRecruiter = () => {
    const tmpErrorHandler = {};
    Object.keys(inputErrorHandler).forEach((obj) => {
      if (inputErrorHandler[obj].required && inputErrorHandler[obj].untouched) {
        tmpErrorHandler[obj] = {
          required: true,
          untouched: false,
          error: true,
          message: `${obj[0].toUpperCase() + obj.substr(1)} is required`,
        };
      } else {
        tmpErrorHandler[obj] = inputErrorHandler[obj];
      }
    });

    console.log(signupDetails);

    let updatedDetails = {
      ...signupDetails,
    };
    if (phone !== "") {
      updatedDetails = {
        ...signupDetails,
        contactNumber: `+${phone}`,
      };
    } else {
      updatedDetails = {
        ...signupDetails,
        contactNumber: "",
      };
    }

    // let updatedDetails = {
    //   ...signupDetails,
    // if (phoneemployee !== "") {
    //   updatedDetails = {
    //     ...signupDetails,
    //     employeeContact: `+${phone}`,
    //   };
    // } else {
    //   updatedDetails = {
    //     ...signupDetails,
    //     employeeContact: "",
    //   };
    // };

    // }

    setSignupDetails(updatedDetails);

    let verified = !Object.keys(tmpErrorHandler).some((obj) => {
      return tmpErrorHandler[obj].error;
    });
    if (phone.length < 11 || !phone) {
      verified = false;
      setRecruiterPhone(false);
    } else {
      verified = true;
    }

    console.log(updatedDetails);

    if (verified) {
      setLoading(true);
      axios
        .post(apiList.signup, updatedDetails)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          setLoggedin(isAuth(location("/addjob")));
          setPopup({
            open: true,
            severity: "success",
            message: "Logged in successfully",
          });
          console.log(response);
        })
        .catch((err) => {
          setPopup({
            open: true,
            severity: "error",
            message: err.response.data.message,
          });
          console.log(err.response);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setInputErrorHandler(tmpErrorHandler);
      setPopup({
        open: true,
        severity: "error",
        message: "Incorrect Input",
      });
    }
  };

  return (
    <>
      <ReactHelmet
        title={"Employer Signup | AMUS HIRING"}
        description={"Welcome To Employeer Signup Page."}
        route="Employersignup"
      />
      <Paper
        elevation={3}
        sx={{
          padding: "30px 20px",
          width: 800,
          margin: "20px auto",
          marginTop: "85px",
          "@media(max-width:768px)": {
            marginTop: "12rem",
            width: "100vw",
            // border:"1px solid red"
          },
        }}
      >
        {/* <Grid container  spacing={4}  sx={{display: "flex",//marginLeft:"-16px"
    }} > */}
        <Grid
          item
          sx={{
            flexDirection: "column",
            display: "flex",
            //alignItems: "center", btton of institute
            minWidth: "100%",
            //justifyContent: "center",
          }}
        >
          <Grid
            item
            container
            // className={classes.inputBox}

            sx={{
              // minWidth: "100%",
              // justifyContent: "space-evenly"
              flexDirection: "column",
            }}
          >
            <Grid
              item
              sx={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h3" component="h2">
                Signup as Employer to Hire Remote Employees.
              </Typography>
            </Grid>
            <Grid
              sx={{
                textAlign: "center",
                marginBottom: "1.5rem",
                fontSize: "1rem",
                textTransform: "capitalize",
              }}
            >
              Already have an account? <Link to="/login">Login</Link>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              {/* <Grid item  sx={{width: "40%",marginBottom:"15px"}} > */}
              {/* <TextField
                select
                label="Category"
                variant="outlined"
               fullWidth
                value={signupDetails.type}
                onChange={(event) => {
                  handleInput("type", event.target.value);
                }}
    >
    <MenuItem value="applicant">Employee</MenuItem>
    <MenuItem value="recruiter">Employer</MenuItem>
    </TextField> */}
              {/* </Grid> */}

              <Grid item sx={{ width: "87%", marginBottom: "15px" }}>
                <TextField
                  fullWidth
                  label="Name"
                  value={signupDetails.name}
                  onChange={(event) => handleInput("name", event.target.value)}
                  error={inputErrorHandler.name.error}
                  helperText={inputErrorHandler.name.message}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      handleInputError("name", true, "Name is required");
                    } else {
                      handleInputError("name", false, "");
                    }
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Grid item sx={{ width: "40%", marginBottom: "15px" }}>
                <EmailInput
                  fullWidth
                  label="Email"
                  value={signupDetails.email}
                  onChange={(event) => handleInput("email", event.target.value)}
                  inputErrorHandler={inputErrorHandler}
                  handleInputError={handleInputError}
                  required={true}
                />
              </Grid>

              <Grid
                item
                sx={{ width: "40%", marginBottom: "15px", marginTop: "20px" }}
              >
                <PasswordInput
                  fullWidth
                  label="Password"
                  value={signupDetails.password}
                  onChange={(event) =>
                    handleInput("password", event.target.value)
                  }
                  //className={classes.inputBox}
                  error={inputErrorHandler.password.error}
                  helperText={inputErrorHandler.password.message}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      handleInputError(
                        "password",
                        true,
                        "Password is required",
                      );
                    } else {
                      handleInputError("password", false, "");
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "30px",
              }}
            >
              <Grid item sx={{ width: "40%" }}>
                <TextField
                  select
                  label="Type of Company"
                  variant="outlined"
                  value={signupDetails.employeeCompany}
                  onChange={(event) => {
                    handleInput("employeeCompany", event.target.value);
                  }}
                  error={inputErrorHandler.companyType.error}
                  helperText={inputErrorHandler.companyType.message}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      handleInputError(
                        "companyType",
                        true,
                        "Company Type is required",
                      );
                    } else {
                      handleInputError("companyType", false, "");
                    }
                  }}
                  fullWidth
                >
                  <MenuItem value="Startup">Startup</MenuItem>
                  <MenuItem value="SME">SME</MenuItem>
                  <MenuItem value="Enterprise">Enterprise</MenuItem>
                </TextField>
              </Grid>
              <Grid item sx={{ width: "40%" }}>
                <TextField
                  label="Enter web address"
                  value={signupDetails.employeeWeb}
                  onChange={(event) =>
                    handleInput("employeeWeb", event.target.value)
                  }
                  variant="outlined"
                  fullWidth
                  error={inputErrorHandler.webAddress.error}
                  helperText={inputErrorHandler.webAddress.message}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      handleInputError(
                        "webAddress",
                        true,
                        "Web address is required",
                      );
                    } else {
                      handleInputError("webAddress", false, "");
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                width: "87%",
                marginLeft: "50px",
                marginBottom: "30px",
                "@media(max-width:768px)": {
                  // border:"1px solid red",
                  position: "relative",
                  right: "1.5rem",
                },
              }}
            >
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  borderColor: recruiterPhone ? "inherit" : "red",
                }}
                country={"in"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                onFocus={() => setRecruiterPhone(true)}
                onBlur={() =>
                  phone.length < 11
                    ? setRecruiterPhone(false)
                    : setRecruiterPhone(true)
                }
              />
              {recruiterPhone ? (
                ""
              ) : (
                <Typography fontSize="13px" color="red">
                  Phone is required
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                width: "87%",
                marginLeft: "50px",
                marginBottom: "30px",
                "@media(max-width:768px)": {
                  // border:"1px solid red",
                  position: "relative",
                  right: "1.5rem",
                },
              }}
            >
              <TextField
                label="Bio (upto 250 words)"
                multiline
                rows={5}
                style={{ width: "100%" }}
                variant="outlined"
                value={signupDetails.bio}
                onChange={(event) => {
                  if (
                    event.target.value.split(" ").filter(function (n) {
                      return n != "";
                    }).length <= 250
                  ) {
                    handleInput("bio", event.target.value);
                  }
                }}
              />
            </Grid>

            <Grid
              sx={{
                width: "90%",
                display: "flex",
                marginLeft: "50px",
                alignItems: "center",
                paddingRight: "1rem",
                gap: "1rem",
              }}
            >
              <input type="checkbox" name="" id="" required />

              <p>
                I would like to receive notifications about the company,
                products, services and events.
              </p>
            </Grid>
            <Grid
              sx={{
                width: "90%",
                display: "flex",
                marginLeft: "50px",
                alignItems: "center",
                paddingRight: "1rem",
                gap: "1rem",
              }}
            >
              <input type="checkbox" name="" id="" required />

              <p>
                {" "}
                I have read and accept the
                <Link to={"/legal-notice"} style={{ textDecoration: "none" }}>
                  {" "}
                  Legal Notice
                </Link>{" "}
                and{" "}
                <Link to={"/privacy-policy"} style={{ textDecoration: "none" }}>
                  Privacy Policy
                </Link>
                .
              </p>
            </Grid>

            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                // color="primary"
                disabled={loading}
                sx={{
                  backgroundColor: "#03265B",
                  "&:hover": {
                    backgroundColor: "#03265B",
                    //color:"black"
                  },
                }}
                onClick={() => {
                  signupDetails.type === "applicant"
                    ? handleLogin()
                    : handleLoginRecruiter();
                }}
                // className={classes.submitButton}
              >
                {"Signup"}{" "}
                {loading && (
                  <Stack justifyContent={"center"} sx={{ ml: "10px" }}>
                    <CircularProgress sx={{ color: "#1b2a59" }} size={16} />
                  </Stack>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default EmployerSignup;
