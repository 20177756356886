import React, { useState } from 'react';
import { Button, Modal, Typography, Box, Rating, Chip, Stack, IconButton} from '@mui/material';
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const ApplicantModal = ({open, setOpen, applicantData}) => {

  const handleClose = () => {
    setOpen(false);
  };
  console.log(applicantData);
  const body = (
    <Box
      sx={{
        width: {
          xs: '90%',
          sm: '80%',
          md: '70%',
          lg: '60%',
        },
        height: '80vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p:4,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px',
        overflowY: 'scroll',
        paddingRight: '17px',
        paddingBottom: '17px',
        scrollbarWidth: 'none',
        WebkitScrollbarWidth: 'none',
        position:'relative'
      }}
    >
      <IconButton
      sx={{position:'absolute', top:'5px', left:'5px'}}
      onClick={handleClose}
      ><ArrowBackIcon /></IconButton>
      <Box sx={{position:'relative', mt:3}}>
        <Box>
          <Typography variant="h3" component="h2">
            {applicantData.jobApplicant.name}
          </Typography>
          <Typography variant='body2' component='p' sx={{display:'flex', alignItems:'center', gap:3}}>
            <WorkOutlineIcon />
            {applicantData.jobApplicant.experience > 1 ? applicantData.jobApplicant.experience+" years" : applicantData.jobApplicant.experience+" year"}
          </Typography>
          <Typography variant='p' component='p'>
            Notice Period: {applicantData.jobApplicant.noticeperiod}
          </Typography>
          <Typography variant='p' component='p'>
            Current Status: {applicantData.jobApplicant.Setavailability}
          </Typography>
          <Typography variant='p' component='p' sx={{mt: 3}}>
            <strong>Skills:</strong> 
            <Box spacing={1} display='flex' sx={{flexWrap:'wrap', gap:"5px"}}>
              {
                applicantData.jobApplicant.skills.map(skill=><Chip label={skill}/>)
              }
            </Box>
          </Typography>
          <Typography variant='p' component='p'>
              <strong>Education:</strong>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Degree</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Stream</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Institute</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Start Date</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {applicantData.jobApplicant.Education.map((edu, index) => (
                    <tr key={index}>
                      <td style={{ padding: '8px' }}>{edu.Degree}</td>
                      <td style={{ padding: '8px' }}>{edu.Stream}</td>
                      <td style={{ padding: '8px' }}>{edu.institutionName}</td>
                      <td style={{ padding: '8px' }}>{edu.startYear}</td>
                      <td style={{ padding: '8px' }}>{edu.endYear}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </Typography>
          <Typography variant='p' component='p'>
              <strong>Experience:</strong>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Company</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Designation</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Location</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Start Date</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {applicantData.jobApplicant.WorkExperience.map((exp, index) => (
                    <tr key={index}>
                      <td style={{ padding: '8px' }}>{exp.company}</td>
                      <td style={{ padding: '8px' }}>{exp.companyrole}</td>
                      <td style={{ padding: '8px' }}>{exp.location}</td>
                      <td style={{ padding: '8px' }}>{exp.WorkstartYear}</td>
                      <td style={{ padding: '8px' }}>{exp.WorkendYear}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </Typography>
          <Typography variant='p' component='p' sx={{mt: '5px'}}>
            <strong>Contact Number:</strong> {applicantData.jobApplicant.employeeContact}
          </Typography>
          <Typography variant='p' component='p' sx={{mt: '5px'}}>
            <strong>Current City:</strong> {applicantData.jobApplicant.currentCity? applicantData.jobApplicant.currentCity : "N/A"}
          </Typography>
          <Typography variant='p' component='p' sx={{mt: '5px', display:'flex', alignItems:'center'}}>
            <strong>Linkedin:&nbsp;</strong> <a href={applicantData.jobApplicant.profile.includes("https://") ? applicantData.jobApplicant.profile : `https://${applicantData.jobApplicant.profile}`} target='_blank'>
              <LinkedInIcon style={{color:'#0077B5'}}/></a>
          </Typography>
          <Typography variant='p' component='p' sx={{mt: '5px'}}>
            <strong>Last Salary:</strong> {applicantData.jobApplicant.lastCurrencyType}&nbsp;{applicantData.jobApplicant.lastsalary}
          </Typography>
          <Typography variant='p' component='p' sx={{mt: '5px'}}>
            <strong>Expected Salary:</strong> {applicantData.jobApplicant.expectedCurrencyType}&nbsp;{applicantData.jobApplicant.expectedsalary}
          </Typography>
        </Box>
        <Typography sx={{position:"absolute", top:'10px', right:'5px'}}>
          <Rating value={applicantData.jobApplicant.rating} readOnly/>
        </Typography>
        <Typography variant='p' component='p' sx={{mt: '5px'}}>
          <strong>Current Location:</strong> {applicantData.jobApplicant.residenceCountry}
        </Typography>
        <Typography variant='p' component='p' sx={{mt: '5px'}}>
          <strong>Citizenship:</strong> {applicantData.jobApplicant.citizenshipCountry}
        </Typography>
        <Typography variant='p' component='p' sx={{mt: '5px'}}>
          <strong>Date of Application:</strong> {new Date(applicantData.dateOfApplication).toDateString()}
        </Typography>
        <Typography variant='p' component='p' sx={{mt: '5px'}}>
          <strong>Date of Joining:</strong> {new Date(applicantData.dateOfJoining).toDateString()}
        </Typography>
        <Typography variant='p' component='p' sx={{mt: '5px'}}>
          <strong>Competence:</strong> {applicantData.sop}
        </Typography>
        <Typography variant='p' component='p' sx={{mt: '5px'}}>
          <strong>Screening Questions & Answers:</strong> 
          {
            applicantData.screeningResponses?.questions?.map((q, idx)=>{
              return (
                <>
                <Typography variant='p' component='p' textAlign='left'>Q{idx+1}.&nbsp;{q.question}</Typography>
                <Typography variant='p' component='p' textAlign='left'>Answer:&nbsp;{q.response}</Typography>
                </>
              )
            })
          }
        </Typography>
      </Box>
    </Box>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
  );
}

export default ApplicantModal;
