import { Button } from "@mui/material";
import apiList from "pages/authentication/lib/apiList";
import React from "react";
import { useContext } from "react";
import { SetPopupContext } from "routes";
import axios from "axios";

const buttonStyle = {
    marginTop: 1,
    m: 1,
    borderRadius: 3,
  };
  
  const acceptedButtonStyle = {
    ...buttonStyle,
    background: "green",
    ":hover": { background: "green" }
  };
  
  const rejectedButtonStyle = {
    ...buttonStyle,
    background: "red",
    ":hover": { background: "red" },
  };
const DynamicButtons = ({app , refetch , FetchApplicants , setAllApplicants}) => {
    const setPopup = useContext(SetPopupContext)
    const UpdateApplicantStatus = async (status , id) => {
        try {
          setAllApplicants([])
          const statusData = {
            status: status,
            dateOfJoining: new Date().toISOString(),
          };
          const response = await axios
                .put(`${apiList.applications}/${id}`, statusData, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                })
          setPopup({
            open: true,
            severity: "success",
            message: response.data.message,
          });
          FetchApplicants(app?.jobId)
        } catch (err) {
          setPopup({
            open: true,
            severity: "error",
            message: err.response?.data.message,
          });
          FetchApplicants(app?.jobId)
        }
      }
  return (
    <>
      {app.status === "applied" ? (
        <>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#ffa500",
              marginTop: 1,
              m: 1,
              ":hover": { background: "#284774" },
              borderRadius: 3,
            }}
            onClick={(e) => {
              e.stopPropagation();
              UpdateApplicantStatus("shortlisted", app?.id);
            }}
          >
            Shortlist
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "red",
              marginTop: 1,
              m: 1,
              ":hover": { background: "#284774" },
              borderRadius: 3,
            }}
            onClick={(e) => {
              e.stopPropagation();
              console.log(app?.id);
              UpdateApplicantStatus("rejected", app?.id);
            }}
          >
            Reject
          </Button>
        </>
      ) : app.status === "shortlisted" ? (
        <>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#ffa500",
              marginTop: 1,
              m: 1,
              ":hover": { background: "#284774" },
              borderRadius: 3,
            }}
            onClick={(e) => {
              e.stopPropagation();
              UpdateApplicantStatus("accepted", app?.id);
            }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "red",
              marginTop: 1,
              m: 1,
              ":hover": { background: "#284774" },
              borderRadius: 3,
            }}
            onClick={(e) => {
              e.stopPropagation();
              console.log(app?.id);
              UpdateApplicantStatus("rejected", app?.id);
            }}
          >
            Reject
          </Button>
        </>
      ) : app.status === "rejected" ? (
        <Button
            variant="contained"
            sx={rejectedButtonStyle}
          >
            Rejected
        </Button>)
        : app.status === "cancelled" ?
        <Button
            variant="contained"
            sx={{...buttonStyle , background:'orange' , ':hover':{background:'orange'}}}
          >
            Cancelled
          </Button>
          :
          <Button
            variant="contained"
            sx={acceptedButtonStyle}
          >
            Accepted
          </Button>
      }
      </>
  );
};

export default DynamicButtons;
