import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import "./styles.css";
import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import CustomButton from "../../ui-component/CustomButton";
import GetStarted from "../../asset/images/get-started.webp";

const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div className={`accordion-item ${isOpen ? "open" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <span>{title}</span>
        <span className={`icon ${isOpen ? "open" : ""}`}>+</span>
      </div>
      <div
        className="accordion-content"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <p>{content}</p>
      </div>
    </div>
  );
};

export function GetStartedSection() {
  return (
    <Box sx={{ py: 10, backgroundColor: "#fff", fontFamily: "Gordita, sans-serif", }}>
      <Container>
        <Grid
          container
          spacing={5}
          direction={{ xs: "column-reverse", sm: "row-reverse" }}
          sx={{ alignItems: "center" }}
        >
          <Grid item xs={12} sm={7} md={7}>
            <br />
            <Typography
              variant="h2"
              component="h2"
              color="#03265B"
              fontWeight= "500"
              sx={{
                "@media(max-width:768px)": {
                  textAlign: "center",
                },
              }}
            >
              Get Started Today
            </Typography>
            <Typography
              variant="body2"
              fontWeight= "500"
              sx={{
                fontSize: "18px",
                color: "rgba(0, 0, 0, 0.5)",
                my: 1,
                fontFamily: "Gordita, sans-serif",
                "@media(max-width:768px)": {
                  // textAlign:"center",
                  display: "block",
                },
              }}
            >
              Experience the future of remote working with AMUS HIRING. Hire
              virtual employees with ease and transform your workforce dynamics.
              Join the best remote hiring company and discover top talent for
              your business. Our user-friendly platform and dedicated support
              team make it simple to find and hire the right candidates for your
              needs.
            </Typography>
            <Grid
              container
              spacing={3}
              direction={{ xs: "column", sm: "row" }}
            >
              <Grid
                item
                sx={{
                  "@media(max-width:768px)": {
                    width: "70%",
                    margin: "auto",
                    // border:"1px solid red"
                  },
                }}
              >
                <Link
                  to="/Employersignup"
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    // display: "flex",
                    // justifyContent: "flex-start",
                  }}
                >
                  <CustomButton
                    backgroundColor="#0F1B4C"
                    color="#fff"
                    buttonText="Hire Now"
                    heroBtn={true}
                    marginApplied={false}
                  />
                </Link>
              </Grid>
              <Grid
                item
                sx={{
                  "@media(max-width:768px)": {
                    width: "70%",
                    margin: "auto",
                    // border:"1px solid red"
                  },
                }}
              >
                <Link
                  to="/Employeesignup"
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <CustomButton
                    backgroundColor="#0F1B4C"
                    color="#fff"
                    buttonText="Apply Now"
                    heroBtn={true}
                    marginApplied={false}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <ImgContainer>
              <img
                src={GetStarted}
                alt="house"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </ImgContainer>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const FAQSection = ({ content: arrayFAQ, message }) => {
  return (
    <>
      <Box sx={{ py: 10, backgroundColor: "#F5F5F5" }}>
        <Container>
          <Typography
            variant="h2"
            fontWeight= "500"
            sx={{ mb: 5, textAlign: "center", color: "#03265B", fontFamily: "Gordita, sans-serif", }}
          >
            Frequently Asked Questions (FAQ)
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 5,
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: "1rem",
            }}
          >
            {message}
          </Typography>
          <div className="faq-section">
            {arrayFAQ.map((resp, index) => (
              <AccordionItem
                key={index}
                title={resp.question}
                content={resp.answer}
              />
            ))}
          </div>
        </Container>
      </Box>
    </>
  );
};

export default FAQSection;
