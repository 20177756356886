import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ReactHelmet from 'component/react-helmet/ReactHelmet';

const Container=styled.div`
padding: 5rem;
@media (max-width: 768px) {
    padding: 5rem 1rem 1rem 1rem;
    width: 100vw;
    font-size: 0.5rem;

  }
h1{
font-size: 3rem;
@media (max-width: 768px) {
line-height: 2rem;
font-size: 2.5rem;

}
    } 
    h2{
        font-size: 2rem;
        @media (max-width: 768px) {
line-height: 2rem;
font-size: 1.5rem;

}
    }
p{
        color: gray;
        font-size: 1.2rem;
        line-height: 1.5rem;
        @media (max-width: 768px) {
  
  font-size: 1rem;

}
        p{
            color: gray;
        font-size: 1.2rem;
        line-height: 1.6rem;
        @media (max-width: 768px) {
  
  font-size: 1rem;

}
        }
    }

`;

const PrivacyPolicy = () => {
  return (
    <>
    <ReactHelmet 
        title={'Privacy Policy | AMUS HIRING'}
        description={''}
        route='privacy-policy'
    />
    <Container>
    <h1 >Privacy Policy</h1>
<p>“AMUS HIRING” is deeply committed to compliance with Spanish and European regulations on the protection of personal data, and guarantees full compliance with the obligations set forth, as well as the implementation of the security measures outlined in the General Data Protection Regulation (RGPD) (EU) 2016/679 and in Organic Law 3/2018, of 5 December, on data protection and digital rights (LOPD and GDD, hereinafter LOPD). By these regulations, we inform you that the use of our blog may require you to provide certain personal data through registration or contact forms, or by sending emails, and that these will be processed by “AMUS HIRING”, Data Controller, whose data are:</p>
<p>
    <p>
    Company Name: AMUS SOFTWARE SL
    </p>
    <p>CIF / NIF / NIE: B72561442</p>
    <p>Registered Address: Vilamari, 51, Bajos-1, 08015, Barcelona</p>
    <p>eMail: hola@amussoft.com</p>
    </p>
<h2>Collection And Processing Of Personal Data</h2>
<p>
    <p>Personal data is any information relating to a person: name, email, address, telephone number, NIF/NIE… Additionally, when a User visits our website, certain information is automatically stored for technical reasons, such as the IP address assigned by their Internet access provider.</p>
    <p>“AMUS HIRING”, as Data Controller, must inform the Users of its website about the collection of personal data that may be carried out, either by sending an e-mail or by filling in the forms included on the website.</p>
    <p> Only the data necessary to carry out the service contracted, or to be able to respond appropriately to the request for information made by the User, will be obtained. The data collected are identified and correspond to a reasonable minimum to be able to carry out the activity carried out. In particular, no specially protected data is collected at any time. Under no circumstances will the data be used for any purpose other than that for which it was collected.</p>
    <NavLink to={"#"} style={{color:"gray",display:"block"}}>Contact forms/email</NavLink>
    <p>Purpose: To reply to your request for information made through our contact form/s.</p>
    <p>Legitimation: The legal basis that legitimizes this processing is the consent of the User, which may be revoked at any time.</p>
    <p>Transfer of data: Personal data will be processed through servers managed by Servicebox, which will be considered the Data Processor.</p>
    <NavLink to={"#"} style={{color:"gray",display:"block"}}> Customer registration forms</NavLink>
    <p> Purposes:</p>
    <p>To manage your User registration on our website.</p>
    <p>To manage the contracts made.</p>
    <p>To provide information about the processing and status of contracts.</p>
    <p>To provide access to a private area for document management.</p>
    <p>Management of comments and contents.</p>
    <p>Sending communications via email and/or telephone, to inform the User of possible incidents, errors, problems, and/or status of orders.</p>
    <p>Legitimation: The legal basis that legitimates this treatment is the execution of a contract.</p>
    <p>Transfer of data: “AMUS HIRING” will not transfer or communicate your data to any third party, except in the cases legally foreseen or when the provision of a service implies the need for a contractual relationship with a Data Processor. Thus, the User accepts that some of the personal data collected may be provided to these Data Processors (payment platforms, agencies, intermediaries, etc.) when this is necessary for the effective performance of a contracted service or purchased product. The User also accepts that, in the case of the provision of services, these may be totally or partially subcontracted to other persons or companies, who will be considered as Data Processors, with whom the corresponding confidentiality contract has been agreed, or who have adhered to their privacy policies, established on their respective websites. The User may refuse the transfer of his/her data to the Data Processors, using a written request, by any of the aforementioned means.</p>
    <p> Furthermore, in those cases in which it is necessary, Client data may be transferred to certain bodies, in compliance with a legal obligation: the Spanish Tax Agency, banks, Labour Inspectorate, etc.</p>
    <p> Data Processors in the United States</p>
    <p>“AMUS HIRING” uses the storage services of Dropbox Inc. and Google Drive, a company registered in the Privacy Shield agreement. In addition, “AMUS HIRING” makes use of the American social networks Facebook, Twitter, Linkedin, and Google Plus.</p>
    <p>The user expressly and unequivocally agrees to the processing of his or her data and the international transfer of data to this service provider.</p>
</p>
<h2>Minors</h2>
<p>Only persons over 18 years of age may contract services on this website.</p>
<h2>User Registration</h2>
<p>
    <p>When the User makes a contract, an associated User will be registered. The information we collect includes:</p>
    <p>Name and surname/company name</p>
    <p>NSF/NIE</p>
    <p>Email and/or telephone</p>
    <p>Address</p>
    <p>IP</p>
    <p>Username/password</p>
    <p>In addition, the User must accept the terms and conditions. Passwords are at the User’s discretion. They do not expire. To recover the password the user must go to the specific form and enter his/her email address.</p>
    <p>The User, once registered, has access to a private panel, where he/she can view certain specific contents, register contracts made, etc. They can also manage their account options, such as their password or User data.</p>
    <p> The User may receive the following notifications:</p>
    <p> When registering on the platform (account validation email).</p>
    <p>When making contracts, such as proof of contract, incidents, invoice sending, etc.</p>
    <p>Password recovery (specified in the previous field).</p>
    <p>For the management of the contracted service: new legislation, management of renewals, etc.</p>
    <p>At “AMUS HIRING” we will block a User account if it commits suspicious or fraudulent actions. User accounts are deleted in case of cancellation of the service by the customer (after 30 days). To delete an account, the User must request it through his User panel, or by contacting us.</p>
    
    </p>
    <h2>Security Measures</h2>
    <p>
        <p> Users of this website are informed that “AMUS HIRING” has adopted the technical, organizational, and security measures within our reach to avoid the loss, misuse, alteration, unauthorized access, and theft of the data, which guarantee the confidentiality, integrity, and quality of the information contained in the same, by that established in the current data protection regulations. The personal data collected in the forms are only processed by the staff of “AMUS HIRING” or the designated Data Processors.</p>
        <p>The Website of “AMUS HIRING” also has SSL encryption, which allows the user to securely send their data through the contact or registration forms of the website.</p>
       </p>
<h2>Veracity Of The Data
</h2>
<p>The User declares that all the data provided by him/her are true and correct and undertakes to keep them updated. The User shall be responsible for the veracity of his or her data and shall be solely liable for any disputes or litigation that may arise as a result of the falsity of such data. It is important that, so that we can keep the personal data updated, the User informs “AMUS HIRING” whenever there has been any change in them</p>
<h2>Exercise Of User Rights</h2>
<p>The LOPD and GDD and the RGPD grant the interested parties the possibility of exercising a series of rights related to the processing of their data. To do so, the User must contact us, providing documentation proving their identity (ID card or passport), by e-mail to hola@amussoft.com, or by written communication to the address that appears in our Legal Notice. Said communication must include the following information: name and surname(s) of the User, the request, address, and supporting data.</p>
<p style={{marginTop:"2rem"}}>
    <p> The exercise of rights must be carried out by the User himself/herself. However, they may be exercised by a person authorized as the User’s legal representative, providing the documentation that accredits such representation.</p>
    <p> The User may request the exercise of the following rights:</p>
    <p>Right to request access to personal data, which is the right to obtain information on whether their data are being processed, the purpose of the processing that, where applicable, is being carried out, as well as the information available on the origin of said data and the communications made or planned for said data.</p>
    <p>The right to request rectification, if the personal data is incorrect, or deletion of data that proves to be inadequate or excessive.</p>
    <p>The right to request the limitation of their processing, in which case only the data strictly necessary for the exercise or defense of claims will be kept by “AMUS HIRING”.</p>
    <p>Right to object to the processing: this refers to the data subject’s right not to have their data processed or to cease processing in cases where their consent to the processing is not required, where the data are for commercial prospecting files or to make decisions relating to the data subject and based solely on the automated processing of their data unless, for legitimate reasons or the exercise or defense of possible claims, they must continue to be processed.</p>
    <p>Right to data portability: if you want your data to be processed by another company, “AMUS HIRING” will provide you with the portability of your data in an exportable format.</p>
    <p>If consent has been granted for a specific purpose, the User has the right to withdraw consent at any time, without affecting the lawfulness of the processing based on the consent before its withdrawal.</p>
    <p> We do not undertake to execute all these rights within the maximum legal deadline of 10 working days.</p>
    <p>If a User considers that there is a problem with how “AMUS HIRING” is handling his/her data, he/she can address his/her complaints to the Security Manager or the corresponding data protection authority, being the Spanish Data Protection Agency the one indicated in the case of Spain.</p>
   </p>
<h2>Retention Of Data</h2>
<p>
    <p>The personal data of Users who use the contact form or send us an email requesting information will be processed for the time strictly necessary to respond to the request for information, or until the consent given is revoked.</p>
    <p> The Customer’s data will be processed until the end of the contractual relationship. The period of conservation of the personal data will be the minimum necessary, and may be maintained for up to:</p>
    <p>4 years: Law on Infringements and Penalties in the Social Order (obligations regarding affiliation, registrations, cancellations, contributions, payment of salaries…); Art. 66 et seq. General Tax Law (accounting books…)</p>
    <p>5 years: Art. 1964 Civil Code (personal actions without special time limit).</p>
    <p>6 years: Art. 30 Code of Commerce (accounting books, invoices…)</p>
    <p>10 years: Art. 25 Prevention of Money Laundering and Terrorist Financing Act.</p>
    <p>No time limit: disaggregated and anonymized data.</p>
</p>
<h2>Social Networking
</h2>
<p>
    <p>“AMUS HIRING” has a profile on some of the main social networks on the Internet (Facebook, Twitter, Google+), recognizing itself in all cases responsible for the processing of the data of its followers, fans, subscribers, commentators, and other User profiles (hereinafter, followers) published by “AMUS HIRING”.</p>
    <p> The purpose of the processing of data by “AMUS HIRING”, when the law does not prohibit it, will be to inform its followers about its activities and offers, by any means that the social network allows, as well as to provide personalized service to the User. The legal basis that legitimates this treatment will be the consent of the interested party, which may be revoked at any time.</p>
    <p> Under no circumstances will “AMUS HIRING” extract data from the social networks, unless the consent of the User is specifically and expressly obtained for this purpose (for example, for the holding of a competition).</p>
</p>
<h2>Personnel Recruitment</h2>
<p>The applicant who sends electronic communications to “AMUS HIRING”, to access the personnel selection processes of the person in charge, authorizes us to analyze the documents sent (for example, the C.V. ), all content that is directly accessible through Internet search engines (e.g. Google), the profiles that you maintain on professional social networks (e.g. LinkedIn), the data obtained in the entrance tests and the information that you reveal in the job interview, to assess your candidacy and be able, if necessary, to offer you a job. If the candidate is not selected, “AMUS HIRING” will be able to keep his/her C.V. stored for a maximum of two years, to incorporate it into future calls for applications, unless the candidate states otherwise. The legal basis that legitimates this treatment will be the consent of the interested party, which may be revoked at any time.</p>
<h2>Confidentiality</h2>
<p>
<p>
The lawyers and advisers of “AMUS HIRING” who have any kind of intervention in the services provided to the client are committed to not disclosing or making use of the information to which they have had access because of their profession, as provided for in Article 5 of the Code of Ethics of the Spanish Legal Profession. The information provided by the client will be, in any case, considered confidential, and cannot be used for purposes other than those related to the services contracted to “AMUS HIRING”. “AMUS HIRING” undertakes not to disclose or reveal information about the client’s claims, the reasons for the advice requested, or the duration of its relationship with the client.
</p>
<p> To preserve and guarantee confidentiality, “AMUS HIRING” undertakes to destroy all confidential information to which it has had access due to the provision of services after 1 to 3 months from the end of the service to the Client unless there is a legally justified obligation to keep it. If the Customer wishes to keep the original or a copy of this information, he/she must print it or keep it by his/her means or go to the headquarters of “AMUS HIRING” to collect it before it is destroyed.</p>
</p>
<h2>Validity</h2>
<p>This privacy and data protection policy has been drafted by AMUS HIRING, RGPD company, on May 01, 2022, and may vary depending on changes in regulations and jurisprudence that may occur, being the responsibility of the owner of the data the reading of the updated document, in order to know their rights and obligations in this regard at all times.</p>
    </Container>
    </>
  )
}

export default PrivacyPolicy