import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Slider,
  Checkbox,
  MenuItem,
  Rating,
  Divider,
  Modal,
  Paper,
  CircularProgress,
  Chip,
  Autocomplete,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import { useMutation, useQuery } from "react-query";
import apiList from "pages/authentication/lib/apiList";
import axios from "axios";
import { SetPopupContext } from "routes";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import NewSingleMyJobModal from "./NewSingleMyJobModal";
import SendIcon from '@mui/icons-material/Send';
import ApplicantData from "../OwnerDashboard/ApplicantData";
import JobApplications from "./JobApplications";
import { MuiChipsInput } from "mui-chips-input";
import { ChatContext } from "chat/context/ChatContextProvider";
import DynamicButtons from "./DynamicButtons";
import { Stack } from "@mui/system";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ApplicantModal from "./NewJobApplicantModal";
import countries from "../../../asset/countryList.json";

const NewMyJobs = () => {
  const [singleMyJob, setSingleMyJob] = useState({});
  const [allMyJobs, setAllMyJobs] = useState([]);
  const [open , setOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [myJobId, setMyJobId] = useState("");
  const [cursor, setCursor] = useState(null);
  const [singleMyJobStored, setSingleMyJobStored] = useState(false);
  const [openSingleMyJobModal, setOpenSingleMyJobModal] = useState(false);
  const [jobId , setJobId] = useState()
  const [allApplicants , setAllApplicants] = useState([])
  const [openCloseModal , setOpenCloseModal] = useState(false)
  const { openChatByUserId } = useContext(ChatContext);
  const setPopup = useContext(SetPopupContext)
  const [loading , setLoading] = useState(true)
  const [openApplicant, setOpenApplicant] = useState(false);
  const [applicantData, setApplicantData] = useState({});
  // const [currentPosition , setCurrentPosition] = useState(0)
  const [searchOptions, setSearchOptions] = useState({
    jobType: {
      partTime: false,
      wfh: false,
      fullTime: false,
      internship:false
    },
    duration: "",
    query: "",
    salary: [0, 100],
    sort: {
      salary: {
        status: false,
        desc: false,
      },
      duration: {
        status: false,
        desc: false,
      },
      rating: {
        status: false,
        desc: false,
      },
    },
    workplaceType: ""
  });
  useEffect(() => {
    setCursor(0);
  }, [searchOptions]);
  const AllMyJobs = async () => {
    let queryParams = [`myjobs=1`];
    if (searchOptions.query !== "") {
      queryParams = [...queryParams, `q=${searchOptions.query}`];
    }
    if (searchOptions.jobType.fullTime) {
      queryParams = [...queryParams, `jobType=Full%20Time`];
    }
    if (searchOptions.jobType.partTime) {
      queryParams = [...queryParams, `jobType=Part%20Time`];
    }
    if (searchOptions.jobType.wfh) {
      queryParams = [...queryParams, `jobType=Work%20From%20Home`];
    }
    if (searchOptions.jobType.internship) {
      queryParams = [...queryParams, `jobType=Internship`];
    }
    if (searchOptions.duration !== "") {
      queryParams = [...queryParams, `duration=${searchOptions.duration}`];
    }
    if (searchOptions.salary[0] !== 0) {
      queryParams = [
        ...queryParams,
        `salaryMin=${searchOptions.salary[0] * 1000}`,
      ];
    }
    if (searchOptions.salary[1] !== 100) {
      queryParams = [
        ...queryParams,
        `salaryMax=${searchOptions.salary[1] * 1000}`,
      ];
    }
    if(searchOptions.workplaceType){
      queryParams = [
        ...queryParams,
        `workplaceType=${searchOptions.workplaceType}`
      ]
    }
    let asc = [],
      desc = [];
    Object.keys(searchOptions.sort).forEach((obj) => {
      const item = searchOptions.sort[obj];
      if (item.status) {
        if (item.desc) {
          desc = [...desc, `desc=${obj}`];
        } else {
          asc = [...asc, `asc=${obj}`];
        }
      }
    });
    queryParams = [...queryParams, `cursor=${cursor}`];
    queryParams = [...queryParams, ...asc, ...desc];
    const queryString = queryParams.join("&");
    return axios.get(apiList.jobs + "?" + queryString, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };
  const { data, isLoading, refetch } = useQuery(["jobs"], AllMyJobs, {
    onSuccess: (data) => {
      const fetchedJobs = data?.data.jobs || []; // Ensure data is not null
      const newCursor = data?.data.cursor || 0; // Ensure cursor is not null
      let newJobs = [];
      console.log(data.data.jobs);
      console.log(newCursor);

      // If cursor is 0, reset the job list with fetched jobs
      if (cursor === 0) {
        newJobs = fetchedJobs;
        setAllMyJobs(newJobs);
      } else {
        // If cursor is not 0, append only new jobs to the existing list
        const lastJobId =
          allMyJobs.length > 0 ? allMyJobs[allMyJobs.length - 1].id : null;
        newJobs = fetchedJobs.filter((job) => job.id > lastJobId);
        setAllMyJobs((prevJobs) => [...prevJobs, ...newJobs]);
      }

      // Update cursor and jobs state
      setCursor(newCursor);

      // Update singleJob if not stored and there are new jobs
      if ((!Object.keys(singleMyJob).length && newJobs.length > 0)) {
        setSingleMyJob(newJobs[0]);
        FetchApplicants(newJobs[0]?.id)
        setSingleMyJobStored(true);
      }
    },
    enabled: cursor!==0,
  });
  const FetchApplicants = async (jobId) => {
    setLoading(true)
    try {
      const response = await axios.get(`${apiList.applicants}?jobId=${jobId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setAllApplicants(response.data);
      console.log(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  const handleScroll = (e) => {
    if (cursor === 0 || isLoading) {
      return;
    }
    const threshold = window.screen.width <= 768 ? 500 : 1000;

    if (
      e.target.scrollTop + e.target.clientHeight >=
      e.target.scrollHeight - threshold
    ) {
      refetch();
    }
  };
  
  const UpdateApplicantStatus = async (status , id) => {
    try {
      const statusData = {
        status: status,
        dateOfJoining: new Date().toISOString(),
      };
      console.log(id)
      const response = await axios
            .put(`${apiList.applications}/${id}`, statusData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
      setPopup({
        open: true,
        severity: "success",
        message: response.data.message,
      });
    } catch (err) {
      setPopup({
        open: true,
        severity: "error",
        message: err.response.data.message,
      });
    }
  }

  const {isLoading: isChanging, mutate: mutateSingleJob} = useMutation(async({id})=>{
    const resp = await axios.get(`${apiList.jobs}/${id}`, 
  {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  }
  )
  return resp;
  },
  {
    onSuccess: data=>{
      setSingleMyJob(data?.data);
      FetchApplicants(data?.data.id);
      // refetch();
    },
    onError: err=>{
      setPopup({
        open: true,
        severity: "error",
        message: err.response.data.message,
      });
    }
  }
  )

  // const jobRefetch = async()=>{
  //   return await axios.get(`${apiList.jobs}/?limit=${allMyJobs.length}&myjobs=1&cursor=${cursor}`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`
  //     }
  //   })
  // }
  // const {refetch: updationQuery} = useQuery('updateAllJobs', jobRefetch, {
  //   onSuccess: data=>{
  //     setAllMyJobs(data?.data.jobs);
  //   },
  //   enabled: allMyJobs.length>0
  // })


  return (
    <>
      <ReactHelmet
        title={"My Job | AMUS HIRING"}
        description={""}
        route="myJobs"
      />
      <Box sx={{ marginTop: 10, textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={5} xs={12}>
            <Box
              id="jobs-grid-container"
              sx={{
                minHeight: "85vh",
                p: 2,
                borderRadius: 3,
                backgroundColor: "#f9f9f9",
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  minHeight: "80vh",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "100%",
                    justifyContent: "center",
                    "@media(max-width:900px)": {
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Typography
                    variant="h2"
                    color="primary"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    My Jobs <WorkOutlineIcon sx={{ fontSize: "30px" }} />
                  </Typography>
                  <Typography
                    variant="h2"
                    color="primary"
                    sx={{ "@media(min-width:900px)": { display: "none" } }}
                    onClick={() => setOpenFilter(true)}
                  >
                    <FilterListIcon
                      cursor="pointer"
                      sx={{ fontSize: "30px" }}
                    />
                  </Typography>
                </Typography>
                {allMyJobs.length ? (
                  <Box
                    sx={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    onScroll={handleScroll}
                  >
                    {allMyJobs?.map((job, i) => {
                      return (
                        <Box
                          sx={{
                            p: "20px 20px",
                            border: "1px solid lightgray",
                            borderRadius: 8,
                            marginBottom: 2,
                            cursor: "pointer",
                          }}
                          key={i}
                          onClick={() => {
                            // setSingleMyJob(job);
                            // FetchApplicants(job.id)
                            mutateSingleJob({id: job.id});
                            setAllApplicants([]);
                            if (window.innerWidth < 600) {
                              // setSingleMyJob(job);
                              // FetchApplicants(job.id)
                              mutateSingleJob({id: job.id});
                              setOpenSingleMyJobModal(true);
                              setAllApplicants([])
                            }
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h4" textAlign={"start"}>
                              {job.title}
                            </Typography>
                            <Typography sx={{ color: "gray" }}>
                              {Math.abs(
                                Math.floor(
                                  (new Date(job.dateOfPosting) - new Date()) /
                                    (1000 * 60 * 60 * 24)
                                )
                              )}{" "}
                              Days ago
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "0.5rem",
                            }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <Typography>
                                <WorkOutlineIcon />
                              </Typography>{" "}
                              &nbsp; {job.experience} years
                            </Typography>
                            <Typography
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <LocationOnIcon /> &nbsp; {job.jobType}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "end" }}>
                            <Button
                              variant="contained"
                              sx={{
                                bgcolor: "#284774",
                                marginTop: 1,
                                ":hover": { background: "#284774" },
                                borderRadius: 3,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                // setOpen(true);
                                setMyJobId(job.id);
                                // setSingleMyJob(job)
                                // FetchApplicants(job.id)
                                mutateSingleJob({id: job.id});
                                setAllApplicants([])
                                if (window.innerWidth < 600) {
                                  // setSingleMyJob(job);   
                                  // FetchApplicants(job.id)
                                  mutateSingleJob({id: job.id});
                                  setOpenSingleMyJobModal(true);
                                  setAllApplicants([])
                                }
                              }}
                            >
                              View Job
                            </Button>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "60vh",
                      fontSize: "20px",
                    }}
                  >
                    {isLoading ? <CircularProgress /> : "No Jobs found"}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={7}
            md={5}
            sm={7}
            xs={12}
            sx={{ "@media(max-width:600px)": { display: "none" } }}
          >
            <Box
              sx={{
                minHeight: "85vh",
                p: 2,
                borderRadius: 3,
                backgroundColor: "#f9f9f9",
                boxShadow: 1,
              }}
            >
              {isChanging ? 
              <Stack direction="row" spacing={2} width='inherit' minHeight='85vh' alignItems="center" justifyContent="center">
                <CircularProgress />
              </Stack>
              :
              <Box
                sx={{
                  minHeight: "80vh",
                  p: 2,
                  borderRadius: 5,
                  backgroundColor: "#fff",
                }}
              >
                {allMyJobs.length ? (
                  <Box
                    sx={{
                      maxHeight: "75vh",
                      overflow: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="h3">{singleMyJob?.title}</Typography>
                      <Box>
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "#284774",
                            marginTop: 1,
                            marginRight: 1,
                            ":hover": { background: "#284774" },
                            borderRadius: 3,
                          }}
                          onClick={() => {
                            setMyJobId(singleMyJob?.id);
                            setUpdateModalOpen(true)
                          }}
                        >
                          Update Details
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: "#284774",
                            marginTop: 1,
                            ":hover": { background: "#284774" },
                            borderRadius: 3,
                          }}
                          onClick={() => {
                            setOpenCloseModal(true);
                          }}
                        >
                          {singleMyJob.status === "Close"
                            ? "Open Job"
                            : "Close Job"}
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{ mt: 1 }}
                    >
                      <Typography>Job Type : {singleMyJob?.jobType}</Typography>
                      <Rating value={Number(singleMyJob?.rating)} readOnly />
                    </Box>
                    {
                      singleMyJob?.workplaceType && 
                      <Typography variant="body2" component='p' textAlign='left'>Workplace Type : {singleMyJob?.workplaceType}</Typography>
                    }
                     {
                      ((singleMyJob?.workplaceType==="Remote") && singleMyJob?.preferredCountry) && 
                      <Typography variant="body2" component='p' textAlign='left'>Preferred Employee Location Country: {singleMyJob?.preferredCountry}</Typography>
                    }
                    {
                      ((singleMyJob?.workplaceType==="On-Site" || singleMyJob?.workplaceType==="Hybrid") && singleMyJob?.jobLocationCity) && 
                      <Typography variant="body2" component='p' textAlign='left'>Job Location City : {singleMyJob?.jobLocationCity}</Typography>
                    }
                    {
                     ((singleMyJob?.workplaceType==="On-Site" || singleMyJob?.workplaceType==="Hybrid") && singleMyJob?.jobLocationCountry) && 
                      <Typography variant="body2" component='p' textAlign='left'>Job Location Country : {singleMyJob?.jobLocationCountry}</Typography>
                    }
                    <Typography textAlign={"start"}>
                      Job Required Experience : {singleMyJob?.experience}
                    </Typography>
                    <Typography textAlign={"start"}>
                      Duration :{" "}
                      {singleMyJob?.duration === ""
                        ? "Flexible"
                        : singleMyJob?.duration}
                    </Typography>
                    <Typography textAlign={"start"}>
                      Date of Posting :{" "}
                      {singleMyJob?.dateOfPosting?.split("T")[0]}
                    </Typography>
                    <Typography textAlign={"start"}>
                      Application Deadline :{" "}
                      {singleMyJob?.deadline?.split("T")[0]}
                    </Typography>
                    <Typography textAlign={"start"}>
                      No. of Positions : {singleMyJob?.maxPositions}
                    </Typography>
                    <Typography textAlign={"start"}>
                      Approval Status :{" "}
                      {!singleMyJob.isApproved && !singleMyJob.isReviewed && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "10px",
                            padding: "2px 0",
                            width: "fit-content",
                            height: "fit-content",
                            borderRadius: "10px",
                            backgroundColor: "#ffa500",
                          }}
                        >
                          Pending
                        </Button>
                      )}
                      {singleMyJob.isApproved && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "10px",
                            padding: "2px 0",
                            width: "fit-content",
                            height: "fit-content",
                            borderRadius: "10px",
                            bgcolor: "green",
                          }}
                        >
                          Approved
                        </Button>
                      )}
                      {!singleMyJob.isApproved && singleMyJob.isReviewed && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "10px",
                            padding: "2px 0",
                            width: "fit-content",
                            height: "fit-content",
                            borderRadius: "10px",
                            bgcolor: "red",
                          }}
                        >
                          Rejected
                        </Button>
                      )}
                    </Typography>
                    <Box textAlign={"start"} sx={{ mt: 1 }}>
                      <Typography variant="span">Skills :</Typography>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {singleMyJob?.skillsets?.map((e) => (
                          <Typography
                            variant="span"
                            sx={{
                              background: "#EDEDED",
                              color: "#000",
                              mr: 1,
                              mt: 1,
                              fontSize: "12px",
                              borderRadius: 4,
                              p: 1,
                            }}
                          >
                            {e}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 3 }} />
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        textAlign={"start"}
                        sx={{ fontWeight: "700" }}
                      >
                        Job Description :
                      </Typography>
                      <Typography textAlign={"start"}>
                        {singleMyJob?.description}
                      </Typography>
                    </Box>
                    <Divider sx={{ mt: 3 }} />
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        textAlign={"start"}
                        sx={{ fontWeight: "700" }}
                      >
                        Job Applications :
                      </Typography>
                      {allApplicants.length ? allApplicants?.map((app, i) => {
                        return (
                          <Box
                            sx={{
                              mt: 1,
                              p: "20px 20px",
                              border: "1px solid lightgray",
                              borderRadius: 8,
                              marginBottom: 2,
                              cursor: "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="h4" textAlign={"start"}>
                                {app.jobApplicant.name}
                              </Typography>

                              <Rating
                                value={Number(app.jobApplicant.rating)}
                                readOnly
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                                Profile :{" "}
                                {app.jobApplicant?.Profilerole}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                              Job Type: {app.jobApplicant.Role}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                              Experience: {app.jobApplicant.experience}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                              Notice Period: {app.jobApplicant.noticeperiod}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                                Education:{" "}
                                {app.jobApplicant.Education
                                  .map((edu) => {
                                    return `${edu.institutionName} (start Year:${edu.startYear})(end Year:${edu.endYear ? edu.endYear : "Ongoing"
                                      })`;
                                  })
                                  .join(", ")}
                              </Typography>
                            </Box>
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                             <DynamicButtons app={app} refetch={refetch} FetchApplicants={FetchApplicants} setAllApplicants={setAllApplicants}/>
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: "#284774",
                                  marginTop: 1,
                                  m: 1,
                                  ":hover": { background: "#284774" },
                                  borderRadius: 3,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openChatByUserId(app.jobApplicant.userId)
                                }}
                              >
                                Chat
                                <SendIcon sx={{ ml: '10px' }} />
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: "#CC5500",
                                  marginTop: 1,
                                  m: 1,
                                  ":hover": { background: "#CC5500" },
                                  borderRadius: 3,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenApplicant(true);
                                  setApplicantData(app);
                                }}
                              >
                                View Applicant
                                <ViewModuleIcon sx={{ ml: '10px' }} />
                              </Button>
                            </Box>
                          </Box>
                        );
                      }) :  <Box sx={{height:'100px' , display:'flex' , alignItems:'center' , justifyContent:'center'}}>{loading ? <CircularProgress/> : "No Application Found"}</Box>}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "75vh",
                      fontSize: "20px",
                    }}
                  >
                    {isLoading ? <CircularProgress /> : "No Job Data"}
                  </Box>
                )}
              </Box>
              }
            </Box>
          </Grid>
          <Grid
            item
            lg={2}
            md={3}
            sx={{ "@media(max-width:900px)": { display: "none" } }}
          >
            <Box
              sx={{
                minHeight: "85vh",
                p: 2,
                borderRadius: 3,
                backgroundColor: "#f9f9f9",
                boxShadow: 1,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  minHeight: "80vh",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                    justifyContent: "center",
                  }}
                >
                  Filter <FilterAltOutlinedIcon sx={{ fontSize: "30px" }} />
                </Typography>
                <Box
                  sx={{
                    maxHeight: "66vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    sx={{ textAlign: "left", mb: 1 }}
                  >
                    Job Type
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Checkbox
                        size="small"
                        sx={{ m: 0, p: 0, mr: 0.5 }}
                        onChange={(e) => {
                          setSearchOptions({
                            ...searchOptions,
                            jobType: {
                              ...searchOptions.jobType,
                              fullTime: e.target.checked,
                            },
                          });
                        }}
                      />
                      Full Time
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Checkbox
                        size="small"
                        sx={{ m: 0, p: 0, mr: 0.5 }}
                        onChange={(e) => {
                          setSearchOptions({
                            ...searchOptions,
                            jobType: {
                              ...searchOptions.jobType,
                              partTime: e.target.checked,
                            },
                          });
                        }}
                      />
                      Part Time
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: "flex", alignItems: "center", mb: 1 }}
                    >
                      <Checkbox
                        size="small"
                        sx={{ m: 0, p: 0, mr: 0.5 }}
                        onChange={(e) => {
                          setSearchOptions({
                            ...searchOptions,
                            jobType: {
                              ...searchOptions.jobType,
                              internship: e.target.checked,
                            },
                          });
                        }}
                      />
                      Internship
                    </Typography>
                  </Box>
                  <Typography textAlign={"start"} fontSize={"15px"}>
                    Profile
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder="eg. Front-end Developer"
                    fullWidth
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        query: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        refetch();
                      }
                    }}
                  />
                  <Typography textAlign={"start"} fontSize={"15px"}>
                    Duration
                  </Typography>
                  <TextField
                    variant="outlined"
                    label="eg. Flexible"
                    fullWidth
                    select
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        duration: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">Choose Duration</MenuItem>
                    <MenuItem value="Flexible">Flexible</MenuItem>
                    <MenuItem value="Less than 1 month">
                      Less than 1 month
                    </MenuItem>
                    <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
                    <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
                    <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
                    <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
                  </TextField>
                  <Typography textAlign={"start"} fontSize={"15px"}>
                    Workplace Type
                  </Typography>
                  <TextField
                    variant="outlined"
                    label="eg. Remote"
                    fullWidth
                    select
                    sx={{ mb: 2 }}
                    onChange={(e) => {
                      setSearchOptions({
                        ...searchOptions,
                        workplaceType: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">Choose workplace</MenuItem>
                    <MenuItem value="Remote">Remote</MenuItem>
                    <MenuItem value="On-Site">On-Site</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                  </TextField>
                  <Typography fontSize={"15px"} sx={{ textAlign: "left" }}>
                    Annual Salary
                  </Typography>

                  <Slider
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {
                      return value * (100000 / 100);
                    }}
                    marks={[
                      { value: 0, label: "0" },
                      { value: 100, label: "100000" },
                    ]}
                    value={searchOptions.salary}
                    onChange={(event, value) =>
                      setSearchOptions({
                        ...searchOptions,
                        salary: value,
                      })
                    }
                    sx={{ width: "70%", mb: 3, textAlign: "left" }}
                  />
                  <Typography fontSize={"15px"} textAlign={"start"}>
                    Sort
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid lightgray",
                      mb: 0.5,
                      borderRadius: 2,
                    }}
                  >
                    <Checkbox
                      name="salary"
                      checked={searchOptions.sort.salary.status}
                      onChange={(event) =>
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            salary: {
                              ...searchOptions.sort.salary,
                              status: event.target.checked,
                            },
                          },
                        })
                      }
                      id="salary"
                    />
                    <label for="salary">
                      <Typography>Salary</Typography>
                    </label>
                    <IconButton
                      disabled={!searchOptions.sort.salary.status}
                      onClick={() => {
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            salary: {
                              ...searchOptions.sort.salary,
                              desc: !searchOptions.sort.salary.desc,
                            },
                          },
                        });
                      }}
                    >
                      {searchOptions.sort.salary.desc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid lightgray",
                      mb: 0.5,
                      borderRadius: 2,
                    }}
                  >
                    <Checkbox
                      name="duration"
                      checked={searchOptions.sort.duration.status}
                      onChange={(event) =>
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            duration: {
                              ...searchOptions.sort.duration,
                              status: event.target.checked,
                            },
                          },
                        })
                      }
                      id="duration"
                    />
                    <label for="duration">
                      <Typography>Duration</Typography>
                    </label>
                    <IconButton
                      disabled={!searchOptions.sort.duration.status}
                      onClick={() => {
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            duration: {
                              ...searchOptions.sort.duration,
                              desc: !searchOptions.sort.duration.desc,
                            },
                          },
                        });
                      }}
                    >
                      {searchOptions.sort.duration.desc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Typography
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      border: "1px solid lightgray",
                      mb: 1,
                      borderRadius: 2,
                    }}
                  >
                    <Checkbox
                      name="rating"
                      checked={searchOptions.sort.rating.status}
                      onChange={(event) =>
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            rating: {
                              ...searchOptions.sort.rating,
                              status: event.target.checked,
                            },
                          },
                        })
                      }
                      id="rating"
                    />
                    <label for="rating">
                      <Typography>Rating</Typography>
                    </label>
                    <IconButton
                      disabled={!searchOptions.sort.rating.status}
                      onClick={() => {
                        setSearchOptions({
                          ...searchOptions,
                          sort: {
                            ...searchOptions.sort,
                            rating: {
                              ...searchOptions.sort.rating,
                              desc: !searchOptions.sort.rating.desc,
                            },
                          },
                        });
                      }}
                    >
                      {searchOptions.sort.rating.desc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#284774",
                      marginTop: 1,
                      ":hover": { background: "#284774" },
                      borderRadius: 3,
                    }}
                    fullWidth
                    onClick={() => {
                      setCursor(0);
                      refetch();
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* {open && (
        <ApplyModal
          open={open}
          setOpen={setOpen}
          jobId={myJobId}
          setOpenSingleJobModal={setOpenSingleMyJobModal}
        />
      )} */}
      {openFilter && (
        <FilterModal
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          jobId={myJobId}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          refetch={refetch}
        />
      )}
      {openSingleMyJobModal && (
        <NewSingleMyJobModal
          singleMyJob={singleMyJob}
          openSingleMyJobModal={openSingleMyJobModal}
          setOpenSingleMyJobModal={setOpenSingleMyJobModal}
          setJobId={setMyJobId}
          setOpen={setOpen}
          setUpdateModalOpen={setUpdateModalOpen}
          allApplicants={allApplicants}
          refetch={refetch}
          openChatByUserId={openChatByUserId}
          loading={loading}
          setOpenCloseModal={setOpenCloseModal}
          FetchApplicants={FetchApplicants} 
          setAllApplicants={setAllApplicants}
          setAllMyJobs={setAllMyJobs}
          setOpenApplicant={setOpenApplicant}
          setApplicantData={setApplicantData}
        />
      )}
      {updateModalOpen && (
        <UpdateDetailModal
          singleMyJob={singleMyJob}
          updateModalOpen={updateModalOpen}
          setUpdateModalOpen={setUpdateModalOpen}
          setJobId={setMyJobId}
          refetch={refetch}
          setAllMyJobs={setAllMyJobs}
          setOpenSingleMyJobModal={setOpenSingleMyJobModal}
          setCursor={setCursor}
          mutateSingleJob={mutateSingleJob}
          AllMyJobs={AllMyJobs}
          
        />
      )}
      {openCloseModal && (
        <OpenCloseJobModal
          job={singleMyJob}
          setOpenCloseModal={setOpenCloseModal}
          openCloseModal = {openCloseModal}
          refetch={refetch}
          setAllMyJobs={setAllMyJobs}
          setOpenSingleMyJobModal={setOpenSingleMyJobModal}
          setCursor={setCursor}
          FetchApplicants={FetchApplicants}
          mutateSingleJob={mutateSingleJob}
        />
      )}
      {openApplicant && <ApplicantModal open={openApplicant} setOpen={setOpenApplicant} applicantData={applicantData} />}
    </>
  );
};

export default NewMyJobs;

const FilterModal = ({
  openFilter,
  setOpenFilter,
  setSearchOptions,
  searchOptions,
  refetch,
}) => {
  return (
    <Modal open={openFilter} onClose={() => setOpenFilter(false)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mt: 17,
          mr: 2,
          border: "none",
        }}
      >
        <Box
          sx={{
            bgcolor: "#f9f9f9",
            borderRadius: 2,
            maxHeight: "60vh",
            overflow: "auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            width: "50%",
            textAlign: "center",
            p: 2,
            "@media(max-width:415px)": { width: "60%" },
          }}
        >
          <Box
            sx={{
              minHeight: "56vh",
              p: 2,
              borderRadius: 3,
              backgroundColor: "#fff",
              boxShadow: 1,
            }}
          >
            <Typography
              fontSize={"15px"}
              sx={{
                textAlign: "left",
                mb: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Job Type{" "}
              <CloseIcon
                cursor="pointer"
                color="primary"
                onClick={() => setOpenFilter(false)}
              />
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Checkbox
                  size="small"
                  sx={{ m: 0, p: 0, mr: 0.5 }}
                  checked={searchOptions.jobType.fullTime}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      jobType: {
                        ...searchOptions.jobType,
                        fullTime: e.target.checked,
                      },
                    });
                  }}
                />
                Full Time
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Checkbox
                  size="small"
                  sx={{ m: 0, p: 0, mr: 0.5 }}
                  checked={searchOptions.jobType.partTime}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      jobType: {
                        ...searchOptions.jobType,
                        partTime: e.target.checked,
                      },
                    });
                  }}
                />
                Part Time
              </Typography>
              <Typography
                variant="body2"
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <Checkbox
                  size="small"
                  sx={{ m: 0, p: 0, mr: 0.5 }}
                  checked={searchOptions.jobType.internship}
                  onChange={(e) => {
                    setSearchOptions({
                      ...searchOptions,
                      jobType: {
                        ...searchOptions.jobType,
                        internship: e.target.checked,
                      },
                    });
                  }}
                />
                Internship
              </Typography>
            </Box>
            <Typography textAlign={"start"} fontSize={"15px"}>
              Profile
            </Typography>
            <TextField
              variant="outlined"
              placeholder="eg. Front-end Developer"
              value={searchOptions.query}
              fullWidth
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  query: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  refetch();
                }
              }}
            />
            <Typography textAlign={"start"} fontSize={"15px"}>
              Duration
            </Typography>
            <TextField
              variant="outlined"
              label="eg. Flexible"
              fullWidth
              select
              value={searchOptions.duration}
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  duration: e.target.value,
                });
              }}
            >
              <MenuItem value="">Choose Duration</MenuItem>
              <MenuItem value="Flexible">Flexible</MenuItem>
              <MenuItem value="Less than 1 month">Less than 1 month</MenuItem>
              <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
              <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
              <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
              <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
            </TextField>

            <Typography textAlign={"start"} fontSize={"15px"}>
              Workplace Type
            </Typography>
            <TextField
              variant="outlined"
              label="eg. Remote"
              fullWidth
              select
              sx={{ mb: 2 }}
              onChange={(e) => {
                setSearchOptions({
                  ...searchOptions,
                  workplaceType: e.target.value,
                });
              }}
            >
              <MenuItem value="">Choose workplace</MenuItem>
              <MenuItem value="Remote">Remote</MenuItem>
              <MenuItem value="On-Site">On-Site</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
            </TextField>

            <Typography fontSize={"15px"} sx={{ textAlign: "left" }}>
              Annual Salary
            </Typography>

            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                return value * (100000 / 100);
              }}
              marks={[
                { value: 0, label: "0" },
                { value: 100, label: "100000" },
              ]}
              value={searchOptions.salary}
              onChange={(event, value) =>
                setSearchOptions({
                  ...searchOptions,
                  salary: value,
                })
              }
              sx={{ width: "90%", mb: 3 }}
            />
            <Typography fontSize={"15px"} textAlign={"start"}>
              Sort
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ border: "1px solid lightgray", mb: 0.5, borderRadius: 2 }}
            >
              <Checkbox
                name="salary"
                checked={searchOptions.sort.salary.status}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      salary: {
                        ...searchOptions.sort.salary,
                        status: event.target.checked,
                      },
                    },
                  })
                }
                id="salary"
              />
              <label for="salary">
                <Typography>Salary</Typography>
              </label>
              <IconButton
                disabled={!searchOptions.sort.salary.status}
                onClick={() => {
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      salary: {
                        ...searchOptions.sort.salary,
                        desc: !searchOptions.sort.salary.desc,
                      },
                    },
                  });
                }}
              >
                {searchOptions.sort.salary.desc ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </IconButton>
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ border: "1px solid lightgray", mb: 0.5, borderRadius: 2 }}
            >
              <Checkbox
                name="duration"
                checked={searchOptions.sort.duration.status}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      duration: {
                        ...searchOptions.sort.duration,
                        status: event.target.checked,
                      },
                    },
                  })
                }
                id="duration"
              />
              <label for="duration">
                <Typography>Duration</Typography>
              </label>
              <IconButton
                disabled={!searchOptions.sort.duration.status}
                onClick={() => {
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      duration: {
                        ...searchOptions.sort.duration,
                        desc: !searchOptions.sort.duration.desc,
                      },
                    },
                  });
                }}
              >
                {searchOptions.sort.duration.desc ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </IconButton>
            </Typography>
            <Typography
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ border: "1px solid lightgray", mb: 1, borderRadius: 2 }}
            >
              <Checkbox
                name="rating"
                checked={searchOptions.sort.rating.status}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      rating: {
                        ...searchOptions.sort.rating,
                        status: event.target.checked,
                      },
                    },
                  })
                }
                id="rating"
              />
              <label for="rating">
                <Typography>Rating</Typography>
              </label>
              <IconButton
                disabled={!searchOptions.sort.rating.status}
                onClick={() => {
                  setSearchOptions({
                    ...searchOptions,
                    sort: {
                      ...searchOptions.sort,
                      rating: {
                        ...searchOptions.sort.rating,
                        desc: !searchOptions.sort.rating.desc,
                      },
                    },
                  });
                }}
              >
                {searchOptions.sort.rating.desc ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </IconButton>
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#284774",
                marginTop: 1,
                ":hover": { background: "#284774" },
                borderRadius: 3,
              }}
              fullWidth
              onClick={() => {
                refetch();
                setOpenFilter(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const UpdateDetailModal = ({singleMyJob , 
  updateModalOpen , 
  setUpdateModalOpen , 
  refetch , setAllMyJobs , 
  setCursor , 
  setOpenSingleMyJobModal, 
  mutateSingleJob,
  AllMyJobs
  }) => {
  const [jobDetails , setJobDetails] = useState(singleMyJob)
  const [jobTitle, setJobTitle] = useState("");
  const setPopup = useContext(SetPopupContext);
  const [selectedCounty, setSelectedCountry] = useState(countries.filter(el=>{
    return (jobDetails.jobLocationCountry === el.name || jobDetails.preferredCountry === el.name)
  })[0])
  const {refetch: updateJobs} = useQuery({
    queryKey: ['jobs'],
    queryFn: AllMyJobs
  })
  const JobTitleApi = [
    {
      id: 1,
      input: "Data Entry Support",
    },
    {
      id: 2,
      input: "Accounting Support",
    },
    {
      id: 3,
      input: "Documentation Support",
    },
    {
      id: 4,
      input: "Custom Process Support",
    },
    {
      id: 5,
      input: "Air/Sea Process Support",
    },
    {
      id: 6,
      input: "Import/Export Process Support",
    },
    {
      id: 7,
      input: "Sales Support",
    },
    {
      id: 8,
      input: "Customer Service Support",
    },
    {
      id: 9,
      input: "CargoWise Consultant",
    },
    {
      id: 10,
      input: "CargoWise Support",
    },
    {
      id: 11,
      input: "ERP Consultant",
    },
    {
      id: 12,
      input: "Power BI Consultant",
    },
    {
      id: 13,
      input: "CRM Consultant",
    },
    {
      id: 14,
      input: "SAP Consultant",
    },
    {
      id: 15,
      input: "Web Developer",
    },
    {
      id: 16,
      input: "Front End Developer",
    },
    {
      id: 17,
      input: "Back End Developer",
    },
    {
      id: 18,
      input: "Full Stack Developer",
    },
    {
      id: 19,
      input: "Web Designer",
    },
    {
      id: 20,
      input: "Mobile App Developer",
    },
    {
      id: 21,
      input: "Android Developer",
    },
    {
      id: 22,
      input: "IOS Developer",
    },
    {
      id: 23,
      input: "Software Engineer",
    },
    {
      id: 24,
      input: "Integration Developer",
    },
    {
      id: 25,
      input: "IT Manager",
    },
    {
      id: 26,
      input: "Help Desk Support",
    },
    {
      id: 27,
      input: "Database Administrator",
    },
    {
      id: 28,
      input: "System Administrator",
    },
    {
      id: 29,
      input: "IT Project Manager",
    },
    {
      id: 30,
      input: "RPA Developer",
    },
    {
      id: 31,
      input: "AI Developer",
    },
    {
      id: 32,
      input: "Cloud Architect",
    },
    {
      id: 33,
      input: "Data Analyst",
    },
    {
      id: 34,
      input: "Cybersecurity Expert",
    },
    {
      id: 35,
      input: "IT Support",
    },
    {
      id: 36,
      input: "Sales Executive",
    },
    {
      id: 37,
      input: "Lead Generation",
    },
    {
      id: 38,
      input: "Customer Service",
    },
    {
      id: 39,
      input: "Sales Manager",
    },
    {
      id: 40,
      input: "Marketing Specialist",
    },
    {
      id: 41,
      input: "SEO Specialist",
    },
    {
      id: 42,
      input: "Social Media Manager",
    },
    {
      id: 43,
      input: "Social Media Specialist",
    },
  ];

  const jobHandleFunction = (e) => {
    const jobInputData = e.target.value;
    const newJobTitleInput = e.target.value.toLowerCase();
    setJobDetails({
      ...jobDetails,
      title: jobInputData,
    });

    const filterData = JobTitleApi.filter((element) => {
      if (
        newJobTitleInput.length === null ||
        newJobTitleInput.length === 1 ||
        newJobTitleInput.length === 2 ||
        newJobTitleInput === "" ||
        newJobTitleInput.length === 0
      ) {
        return "";
      } else {
        return element.input.toLocaleLowerCase().includes(newJobTitleInput);
      }
    });
    setJobTitle(filterData);
    console.log(jobDetails.title.length);
    console.log(jobTitle);
    if (
      jobDetails.title.length === null ||
      jobDetails.title.length === 0 ||
      jobDetails.title.length === 1 ||
      jobTitle === ""
    ) {
      const jobid = document.getElementById("jobTitleId3");
      jobid.style.height = "0rem";
    } else {
      const jobid = document.getElementById("jobTitleId3");
      jobid.style.height = "8rem";
      jobid.style.overflowY = "scroll";
      if (filterData.length === 0) {
        const jobid = document.getElementById("jobTitleId3");
        jobid.style.height = "0rem";
      }
    }
  };

  const jobTitleInputFunction = (data) => {
    setJobDetails({
      ...jobDetails,
      title: data,
    });
    setJobTitle("");
    const jobid = document.getElementById("jobTitleId3");
    jobid.style.height = "0rem";
  };
  const handleInput = (key, value) => {
    setJobDetails({
      ...jobDetails,
      [key]: value,
    });
  };
  const UpdateJobDetail = () => {
    setCursor(0)
    return axios.put(`${apiList.jobs}/${singleMyJob.id}` , jobDetails , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
  }
  const {mutate : updateJob} = useMutation(UpdateJobDetail , {
    onSuccess:(data) => {
      setPopup({
        open: true,
        severity: "success",
        message: data?.data.message,
      });
      // setAllMyJobs([])
      // setCursor(0)
      mutateSingleJob({id: singleMyJob.id});
      setUpdateModalOpen(false);
      setOpenSingleMyJobModal(false);
      updateJobs();
    },
    onError:(err) => {
      setPopup({
        open: true,
        severity: "error",
        message: err.response.data.message,
      });
      setUpdateModalOpen(false)
    }
  })
  return (
    <Modal
        sx={{ marginTop: "50px" }}
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        // className={classes.popupDialog}
      >
        <Paper
          sx={{
            padding: "20px 50px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            //minWidth: "30%",
            alignItems: "center",
            maxWidth: "80%",
            height:'90vh',
            overflow:'auto',
            margin:"auto",
            // border:"1px solid red",
            "@media(max-width:768px)": {
              // width: "100%",
              // padding: "0rem 10rem",
              // position: "relative",
              // right: "4.4rem",
              // bottom: "1rem",
            },
          }}
          // bharatsaini
          // style={{

          // }}
        >
          <Typography
            variant="h4"
            sx={{
              marginTop:5,
              marginBottom: "10px",
              paddingTop: "1rem",
              "@media(max-width:768px)": {
                textAlign: "center",
                marginTop:30,
                width: "10rem",
              },
              "@media(max-width:720px)": {
                marginTop:40
              },
              "@media(max-width:580px)": {
                marginTop:55
              },
              "@media(max-width:480px)": {
                marginTop:10
              },
            }}
          >
            Update Details
          </Typography>
          <Grid
                container
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  //  border:"1px solid red",
                  "@media(max-width:768px)": {
                    flexDirection: "column",
                    width: "100%",
                    
                  },
                }}
                alignItems="stretch"
                spacing={3}
              >
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Job Title"
                    helperText="Enter more than two character"
                    value={jobDetails.title}
                    onChange={jobHandleFunction}
                    variant="outlined"
                    fullWidth
                  />

                  <Grid
                    id="jobTitleId3"
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      ":hover": {
                        // backgroundColor:"#ededed"
                      },
                      //  height:"8rem",
                      //  overflowY:"scroll"
                    }}
                  >
                    {jobTitle === ""
                      ? ""
                      : jobTitle.map((element) => {
                          return (
                            <Grid
                              onClick={() =>
                                jobTitleInputFunction(element.input)
                              }
                              sx={{
                                marginTop: "0.5rem",
                                padding: "0.2rem 0.8rem",
                                ":hover": {
                                  backgroundColor: "#ededed",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {element.input}
                            </Grid>
                          );
                        })}
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <MuiChipsInput
                    // className={classes.inputBox}
                    label="Enter Required Skills"
                    variant="outlined"
                    helperText="Press enter to add skills"
                    value={jobDetails.skillsets}
                    onChange={(chip) =>
                      setJobDetails({ ...jobDetails, skillsets: chip })
                    }
                    // onAdd={(chip) =>
                    //   setJobDetails({
                    //     ...jobDetails,
                    //     skillsets: [...jobDetails.skillsets, chip],
                    //   })
                    // }
                    // onDelete={(chip, index) => {
                    //   let skillsets = jobDetails.skillsets;
                    //   skillsets.splice(index, 1);
                    //   setJobDetails({
                    //     ...jobDetails,
                    //     skillsets: skillsets,
                    //   });
                    // }}
                    fullWidth
                  />
                </Grid>
                
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Job Type"
                    variant="outlined"
                    value={jobDetails.jobType}
                    onChange={(event) => {
                      handleInput("jobType", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                  </TextField>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Expected Duration"
                    variant="outlined"
                    value={jobDetails.duration}
                    onChange={(event) => {
                      handleInput("duration", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Flexible">Flexible</MenuItem>
                    <MenuItem value="Less than 1 month">
                      Less than 1 month
                    </MenuItem>
                    <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
                    <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
                    <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
                    <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
                    {/* <MenuItem value={6}>6 year</MenuItem> */}
                  </TextField>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Required Experience Level"
                    type="number"
                    variant="outlined"
                    value={jobDetails.experience}
                    onChange={(event) => {
                      handleInput("experience", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter No. of Positions Available"
                    type="number"
                    variant="outlined"
                    value={jobDetails.maxPositions}
                    onChange={(event) => {
                      handleInput("maxPositions", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Expected Deadline For Hiring"
                    // type="datetime-local"
                    type="date"
                    value={jobDetails.deadline.split("T")[0]}
                    onChange={(event) => {
                      handleInput("deadline", event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Monthly Pay Budget (USD)"
                    type="number"
                    variant="outlined"
                    value={jobDetails.salary}
                    onChange={(event) => {
                      handleInput("salary", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  // item
                  // sx={{
                  //   width: jobDetails.workplaceType==="Remote" ? "40%" : "80%",
                  //   "@media(max-width:768px)": {
                  //     width: "100%",
                  //     marginLeft: "0.7rem",
                  //   },
                  // }}
                  item
                  sx={{
                    width: jobDetails.workplaceType==="Remote" ? "40%" : "80%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Workplace Type"
                    variant="outlined"
                    value={jobDetails.workplaceType}
                    onChange={(event) => {
                      handleInput("workplaceType", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Remote">Remote</MenuItem>
                    <MenuItem value="On-Site">On-Site</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                  </TextField>
                </Grid>
                {jobDetails.workplaceType==="Remote" && 
                <Grid
                item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option ? option.name : ""}
                  value={selectedCounty}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setJobDetails({
                      ...jobDetails,
                      preferredCountry: newValue && newValue.name ? newValue.name : ""
                    })
                    setSelectedCountry(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} label="Preferred Employee Location Country" variant="outlined" />}
                  />
                </Grid>
                }
                {
                  (jobDetails.workplaceType==="On-Site" || jobDetails.workplaceType==="Hybrid") &&
                  <> 
                  <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                  >
                    <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option ? option.name : ""}
                    value={selectedCounty}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setJobDetails({
                        ...jobDetails,
                        jobLocationCountry: newValue && newValue.name ? newValue.name : ""
                      })
                      setSelectedCountry(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label="Search & Select Job Location Country" variant="outlined" />}
                    />
                  </Grid>
                  <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Job Location City"
                    variant="outlined"
                    value={jobDetails.jobLocationCity}
                    onChange={(event) => {
                      handleInput("jobLocationCity", event.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                </>
                }
              </Grid>
          <Grid
            container
            justify="center"
            spacing={5}
            sx={{ justifyContent: "center", gap: "1rem", padding: "1rem" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 50px" }}
                onClick={updateJob}
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px" }}
                onClick={() => setUpdateModalOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
  )
}

const OpenCloseJobModal = ({job , openCloseModal , setOpenCloseModal , refetch , setAllMyJobs , setOpenSingleMyJobModal , setCursor, mutateSingleJob}) => {
  const setPopup = useContext(SetPopupContext);
  const [reason , setReason] = useState("")
  const HandleJobStatus = () => {
    return axios
    .delete(`${apiList.jobs}/${job.id}`, {
      data: {
        reason
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
  }
  const {mutate : handleCloseJob} = useMutation(HandleJobStatus , {
    onSuccess:(data) => {
      setPopup({
        open: true,
        severity: "success",
        message: data?.data.message,
      });
      // refetch()
      // setAllMyJobs([])
      // setCursor(0)
      mutateSingleJob({id: job.id})
      setOpenCloseModal(false)
      setOpenSingleMyJobModal(false)
    },
    onError:(err) => {
      setPopup({
        open: true,
        severity: "error",
        message: err.response.data.message,
      });
      refetch()
      setOpenCloseModal(false)
    }
  })

  return (
    <Modal sx={{ marginTop: "250px" }} open={openCloseModal} onClose={() => setOpenCloseModal(false)}>
        <Paper
          sx={{
            padding: "20px 50px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // minWidth: "30%",
            alignItems: "center",
            maxWidth: "50%",
            marginLeft: "28%",
          }}
        >
          <Typography variant="h4" style={{ marginBottom: "10px" }}>
            Are you sure?
          </Typography>
          {job.status==="Open" && 
          <TextField 
          variant="outlined" 
          label="Reason" minRows={5} 
          placeholder="Reason for closing job" 
          sx={{margin:'20px auto 30px auto', width:'80%'}} 
          required
          onChange={(e)=>setReason(e.target.value)}
          />
          }
          <Grid
            container
            justify="center"
            spacing={5}
            sx={{ justifyContent: "center" }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: "10px 50px" }}
                onClick={() => handleCloseJob()}
              >
                {job.status==="Close" ? "Open" : "Close" }
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "10px 50px" }}
                onClick={() => setOpenCloseModal(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
  )
}