import { useState, useEffect, useContext } from "react";
import {
  Button,
  Chip,
  Grid,
  // IconButton,
  // InputAdornment,
  Paper,
  //TextField,
  Typography,
  Modal,
  Rating,
} from "@mui/material";
//import Rating from "@material-ui/lab/Rating";
import axios from "axios";

//import { SetPopupContext } from "../App";
import { SetPopupContext } from "../../../routes/index";
import apiList from "../../authentication/lib/apiList"
import ReactHelmet from "component/react-helmet/ReactHelmet";
import { ChatContext } from "chat/context/ChatContextProvider";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },//
//   statusBlock: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     textTransform: "uppercase",
//   },//
//   jobTileOuter: {
//     padding: "30px",
//     margin: "20px 0",
//     boxSizing: "border-box",
//     width: "100%",
//   },//
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

const ApplicationTile = (props) => {
  //const classes = useStyles();
  const { openChatByUserId } = useContext(ChatContext);
  const { application } = props;
  const setPopup = useContext(SetPopupContext);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(application.job.rating);

  const appliedOn = new Date(application.dateOfApplication);
  const joinedOn = new Date(application.dateOfJoining);

  const fetchRating = () => {
    axios
      .get(`${apiList.jobs}/${application.job.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setRating(response.data.rating);
        console.log(response.data);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  };

  const changeRating = () => {
    axios
      .put(
        apiList.rating,
        { rating: rating, jobId: application.job.id, applicantId: application.id, recruiterId: application.job.recruiterId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setPopup({
          open: true,
          severity: "success",
          message: "Rating updated successfully",
        });
        fetchRating();
        setOpen(false);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        fetchRating();
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const colorSet = {
    applied: "#3454D1",
    shortlisted: "#DC851F",
    accepted: "#09BC8A",
    rejected: "#D1345B",
    deleted: "#B49A67",
    cancelled: "#FF8484",
    finished: "#4EA5D9",
  };

  return (
    <Paper
      //  sx={{ padding: "30px", margin: "20px 0", boxSizing: "border-box", width: "100%",}}
      elevation={3} sx={{
        padding: "30px 20px", width: 800, margin: "20px auto", marginTop: "40px", '@media(max-width:768px)': {
          width: "80vw",
          marginTop: "8rem"
        }
      }}
    >
      <Grid container>
        <Grid container item xs={9} spacing={1} direction="column">
          <Grid item>
            <Typography variant="h3">{application.job.title}</Typography>
          </Grid>
          <Grid item>
            <Rating name="read-only" value={rating} readOnly />
          </Grid>
          <Grid item>
            skill: {application.job.skillsets.map((skill) => (
              <Chip label={skill} style={{ marginRight: "3px" }} />
            ))}
          </Grid>
          {/* <Grid item>Posted By: {application.recruiter.name}</Grid> */}
          <Grid item >Job Type : {application.job.jobType}</Grid>
          <Grid item>Profile : {application.job.employeeProfile}</Grid>
          <Grid item>Job Role : {application.job.EmployeeJobrole}</Grid>
          {/* <Grid item>Company : {application.job.employeeCompany}</Grid>
          <Grid item>
          Web Address : {application.job.employeeWeb}
          </Grid> */}
          {/* <Grid item>Salary : &#8377; {application.job.salary} per month</Grid> */}
          <Grid item >
            Duration :{" "}
            {application.job.duration !== 0
              ? `${application.job.duration} month`
              : `Flexible`}
          </Grid>

          <Grid item >Applied On: {appliedOn.toLocaleDateString()}</Grid>
          {application.status === "accepted" ||
            application.status === "finished" ? (
            <Grid item>Joined On: {joinedOn.toLocaleDateString()}</Grid>
          ) : null}
          <Grid item xs sx={{ marginBottom: "8px", marginTop: "8px", display: "flex", columnGap: "30px" }}>
            <Paper
              sx={{
                textAlign: "center", minWidth: "26%", display: "grid", minHeight: "42px", alignContent: "center", '@media(max-width:768px)': {
                  padding: "0.3rem 0.9rem"
                }
              }}
              // sx={{width: "100%",height: "100%", display: "flex", alignItems: "center", justifyContent: "center",textTransform: "uppercase",}}
              style={{
                background: colorSet[application.status],
                color: "#ffffff",
                textTransform: "capitalize",

              }}
            >
              {application.status}
            </Paper>
            {/* <Button
              onClick={() => {
                openChatByUserId(application.job.recruiterId)
              }}
              variant="contained"
              sx={{
                bgcolor: 'rgb(52, 84, 209)',
                borderRadius: '12px',
                '&:hover': {
                  bgcolor: 'rgb(52, 84, 209)'
                }
              }}
            >Let's Chat</Button> */}
            {application.status === "accepted" ||
              application.status === "finished" ? (
              <Grid item sx={{ textAlign: "center", minWidth: "26%", display: "grid", minHeight: "42px", alignContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"

                  // sx={{width: "100%",  height: "100%",  display: "flex",  alignItems: "center",  justifyContent: "center",
                  // textTransform: "uppercase",}}
                  // className={classes.statusBlock}
                  onClick={() => {
                    fetchRating();
                    setOpen(true);
                  }}
                >
                  Rate Job
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Modal open={open} onClose={handleClose} sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", }}
      //className={classes.popupDialog}
      >
        <Paper
          style={{
            padding: "20px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "30%",
            alignItems: "center",
          }}
        >
          <Rating
            name="simple-controlled"
            style={{ marginBottom: "30px" }}
            value={rating === null ? null : rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "10px 50px" }}
            onClick={() => changeRating()}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </Paper>
  );
};

const Applications = (props) => {
  const setPopup = useContext(SetPopupContext);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(apiList.applications, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setApplications(response.data);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  };

  return (
    <>
      <ReactHelmet
        title={'Applications | AMUS HIRING'}
        description={''}
        route='applications'
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{ marginTop: "65px", padding: "30px", minHeight: "93vh" }}

      >
        <Grid item>
          <Typography variant="h2">Applications</Typography>
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          style={{ width: "100%" }}
          alignItems="stretch"
          justify="center"
        >
          {applications.length > 0 ? (
            applications.map((obj) => (
              <Grid item>
                <ApplicationTile application={obj} />
              </Grid>
            ))
          ) : (
            <Typography variant="h5" style={{ textAlign: "center" }}>
              No Applications Found
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Applications;