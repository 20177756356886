import { useEffect, useContext } from "react";
//import { Redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { SetPopupContext } from "../../../routes/index";
import { ChatContext } from "chat/context/ChatContextProvider";

const Logout = (props) => {
  const location = useNavigate();
  const setPopup = useContext(SetPopupContext);
  const { closeSocketHandler, setUser } = useContext(ChatContext);
  useEffect(() => {
    closeSocketHandler();
    setUser(null)
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    setPopup({
      open: true,
      severity: "success",
      message: "Logged out successfully",
    });
  }, []);
   
  return location("/login") ;
};

export default Logout;
