import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Box,
  Typography,
  Rating,
  Divider,
  Slide,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import apiList from "pages/authentication/lib/apiList";
import ApplyModal from "./job/ApplyModal";

const NewSingleJobModal = ({}) => {
  const navigate = useNavigate();
  const [singleJob, setSingleJob] = useState({});
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${apiList.jobs}/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(({ data }) => {
          if (data) {
            setSingleJob({ ...data });
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  }, [id]);
  return (
    <Modal
      open={!!id}
      onClose={() => navigate("/jobs")}
      sx={{ "@media(min-width:600px)": { display: "none" }, p: 1 }}
    >
      <Slide
        direction="up"
        in={!!id}
        mountOnEnter
        unmountOnExit
        transitionDuration={500}
      >
        <Box sx={{ display: "flex", justifyContent: "end", mt: 10 }}>
          <Box
            sx={{
              bgcolor: "#f9f9f9",
              borderRadius: 5,
              maxHeight: "90vh",
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              width: "100%",
              textAlign: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                minHeight: "90vh",
                overflow: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                background: "#fff",
                p: 2,
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <KeyboardBackspaceIcon
                    cursor="pointer"
                    onClick={() => navigate("/jobs")}
                  />{" "}
                  {singleJob?.title}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#284774",
                    marginTop: 1,
                    ":hover": { background: "#284774" },
                    borderRadius: 3,
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Apply
                </Button>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ mt: 1 }}
              >
                <Typography>Job Type : {singleJob?.jobType}</Typography>
                <Rating value={Number(singleJob?.rating)} readOnly />
              </Box>
              <Typography textAlign={"start"}>
                Job Required Experience : {singleJob?.experience}
              </Typography>
              {singleJob?.workplaceType && (
                <Typography variant="body2" component="p" textAlign="left">
                  Workplace Type : {singleJob?.workplaceType}
                </Typography>
              )}
              {singleJob?.workplaceType === "Remote" &&
                singleJob?.preferredCountry && (
                  <Typography variant="body2" component="p" textAlign="left">
                    Preferred Employee Location Country:{" "}
                    {singleJob?.preferredCountry}
                  </Typography>
                )}
              {(singleJob?.workplaceType === "On-Site" ||
                singleJob?.workplaceType === "Hybrid") &&
                singleJob?.jobLocationCity && (
                  <Typography variant="body2" component="p" textAlign="left">
                    Job Location City : {singleJob?.jobLocationCity}
                  </Typography>
                )}
              {(singleJob?.workplaceType === "On-Site" ||
                singleJob?.workplaceType === "Hybrid") &&
                singleJob?.jobLocationCountry && (
                  <Typography variant="body2" component="p" textAlign="left">
                    Job Location Country : {singleJob?.jobLocationCountry}
                  </Typography>
                )}
              <Typography textAlign={"start"}>
                Duration :{" "}
                {singleJob?.duration === "" ? "Flexible" : singleJob?.duration}
              </Typography>
              <Typography textAlign={"start"}>
                Application Deadline : {singleJob?.deadline?.split("T")[0]}
              </Typography>
              <Box textAlign={"start"} sx={{ mt: 1 }}>
                <Typography variant="span">Skills :</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {singleJob?.skillsets?.map((e) => (
                    <Typography
                      variant="span"
                      sx={{
                        background: "#EDEDED",
                        color: "#000",
                        mr: 1,
                        mt: 1,
                        fontSize: "12px",
                        borderRadius: 4,
                        p: 1,
                      }}
                    >
                      {e}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 1 }}>
                <Typography variant="h4" textAlign={"start"} sx={{ mb: 1 }}>
                  About the job
                </Typography>
                <Typography textAlign={"start"}>Job Description :</Typography>
                <Typography textAlign={"start"}>
                  {singleJob?.description}
                </Typography>
              </Box>
            </Box>
            {open && (
              <ApplyModal open={open} setOpen={setOpen} jobDetail={singleJob} />
            )}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default NewSingleJobModal;
