import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
//import Navbar from "./Navbar";
import heroImg from "../../asset/images/jobPortal.jpg";
import CustomButton from "../../ui-component/CustomButton";
import { Link } from 'react-router-dom';

const Hero = () => {
  // const CustomBox = styled(Box)(({ theme }) => ({
  //   display: "flex",
  //   justifyContent: "center",
  //   gap: theme.spacing(5),
  //   marginTop: theme.spacing(3),
  //   [theme.breakpoints.down("md")]: {
  //     flexDirection: "column",
  //     alignItems: "center",
  //     textAlign: "center",
  //   },
  // }));
  const NavLink = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#4F5361",
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
     
      color:"#4F5361",
    },
  }));
  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "40px",
    color: "rgba(4, 13, 27, 0.97)",
    fontWeight: "500",
    fontFamily: "Gordita, sans-serif",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  }));
  const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    color: "#000336",
    fontWeight: "400",
    fontFamily: "Gordita, sans-serif",
    lineHeight: "27px",
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  }));

  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        minHeight: "90vh",
        paddingTop: "10rem",
        '@media (max-width:768px)': {
       marginTop:"2rem"
        }
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={7}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              
              <Title variant="h1" sx={{fontWeight:"500",'@media(max-width:768px)':{
                
                fontSize:"1.5rem"
              }}}>
                WELCOME TO THE FUTURE OF REMOTE WORKING
              </Title>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "18px",
                  color: "#03265B",
                  fontWeight: "500",
                  fontFamily: "Gordita, sans-serif",
                  mt: 6,
                  mb: 1,
                  '@media(max-width:768px)':{
                    textAlign:"center",
                  }
                }}
              >
                Hire virtual employees in few clicks 
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "18px", color: "rgba(3, 38, 91, 0.92)", my: 1, fontFamily: "Gordita, sans-serif",
                '@media(max-width:768px)':{
                  // textAlign:"center",
                  display:'block'
                } }}
              >
                <ul>
                  <li>Save time and money by finding employees efficiently. </li>
                  <li>
                    Discover skilled candidates for both technical and non-technical roles at your fingertips. 
                  </li>
                  <li>
                    Build your remote team from our extensive pool of talented professionals.
                  </li>
                </ul>
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" ,width: "160px",marginLeft:"20px",
            '@media(max-width:768px)':{
              width:"70%",
              margin:"auto"
              
              
              
            }}}>
              <Link to="/Employersignup" style={{textDecoration:"none"}}>
              <CustomButton
             
                backgroundColor="#0F1B4C"
                color="#fff"
                buttonText="Hire Now"
                heroBtn={true}
              /></Link>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center",'@media(max-width:768px)':{
                display:"block",
                textAlign:"center",
                marginTop:"1rem"
              } }}>
                <SubTitle variant="p">Looking for remote jobs?</SubTitle>
                <Link to="/Employeesignup">
                <Button>
                  <NavLink variant="body2">Apply now</NavLink>
                </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={heroImg}
                alt="heroImg"
                style={{ width: "95%", height: "auto", marginTop: "4rem" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
