import { useState, useContext, useId } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  MenuItem,
  Box,
  Stack,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//import { Redirect } from "react-router-dom";
//import ChipInput from 'material-ui-chip-input'
//import {Chip} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
//import DescriptionIcon from '@mui/icons-material/Description';
//import FaceIcon from '@mui/icons-material/Face';
//import DescriptionIcon from "@material-ui/icons/Description";
//import FaceIcon from "@material-ui/icons/Face";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import PasswordInput from "../lib/PasswordInput";
import EmailInput from "../lib/EmailInput";
//import FileUploadInput from "../lib/FileUploadInput";
//import CloudUploadIcon from "@mui/icons-material/CloudUpload";
//import { SetPopupContext } from "../App";
import { SetPopupContext } from "../../../routes/index";

import apiList from "../lib/apiList";
import isAuth from "../lib/isAuth";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import countries from "../../../asset/countryList.json";
import UploadFile from "component/fileUpload/UploadFile";

const MultifieldInput = (props) => {
  const { education, setEducation } = props;
  const newId = useId();
  const [educationToggle, setEducationToggle] = useState({
    start: true,
    end: true,
  });
  const removeItem = (id) => {
    const filterFunction = education.filter((obj, key) => {
      return obj.id !== id;
    });
    setEducation(filterFunction);
  };

  return (
    <Grid
      item
      sx={{
        flexDirection: "column",
        display: "flex",
        // border:'1px solid red',
        //alignItems: "center", btton of institute
        minWidth: "100%",
        paddingLeft: "50px",
        "@media(max-width:768px)": {
          //  border:'1px solid red',
          width: "100%",
          paddingLeft: "0px",
        },

        //justifyContent: "center",
      }}
    >
      {/* <Paper  elevation={3} sx={{padding:"30px 20px",width:1000,margin:"20px auto"}} > */}
      {education.map((obj, key) => (
        <Grid
          item
          container
          // className={classes.inputBox}
          key={key}
          sx={{
            width: "100%",
            flexDirection: "column",
            "@media(max-width:768px)": {
              // border:'1px solid blue',
              width: "100%",
              margin: "auto",
              position: "relative",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              placeItems: "center",
              gap: "1rem",
              "@media(max-width:768px)": {
                width: "100%",
                flexDirection: "column",
                //  border:"1px solid orange",
                textAlign: "center",
              },
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                minWidth: "93%",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: "15px",
                "@media(max-width:768px)": {
                  width: "100%",
                },
                // border: "1px solid red",
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "90%",
                    // marginLeft:"2rem",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label={`University / College #${key + 1}`}
                value={education[key].institutionName}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].institutionName = event.target.value;
                  setEducation(newEdu);
                }}
                variant="outlined"
              />
            </Grid>
            <Box
              sx={{
                marginBottom: "1rem",
                "@media(max-width:768px)": {
                  position: "absolute",
                  width: "2rem",

                  right: "-0.5rem",
                },
              }}
            >
              {key > 0 ? (
                <DeleteIcon
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                  onClick={() => removeItem(obj.id)}
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>

          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              "@media(max-width:768px)": {
                gap: "1rem",
                padding: "0rem 1rem",
                //  flexDirection:"column"
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label={`Degree `}
                value={education[key].Degree}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].Degree = event.target.value;
                  setEducation(newEdu);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "46%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label={`Field of Study`}
                value={education[key].Stream}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].Stream = event.target.value;
                  setEducation(newEdu);
                }}
                variant="outlined"
              />
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              my: 2,
              "@media(max-width:768px)": {
                width: "100%",
                // border:"4px solid gray",
                padding: "0rem 1rem",
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label="Start Year"
                value={obj.startYear}
                variant="outlined"
                type={educationToggle.start ? "text" : "date"}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].startYear = event.target.value;
                  setEducation(newEdu);
                }}
                onFocus={() =>
                  setEducationToggle({
                    ...educationToggle,
                    start: false,
                  })
                }
                onBlur={() =>
                  setEducationToggle({
                    ...educationToggle,
                    start: true,
                  })
                }
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "46%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label="End Year"
                value={obj.endYear}
                variant="outlined"
                type={educationToggle.end ? "text" : "date"}
                onChange={(event) => {
                  const newEdu = [...education];
                  newEdu[key].endYear = event.target.value;
                  setEducation(newEdu);
                }}
                onFocus={() =>
                  setEducationToggle({
                    ...educationToggle,
                    end: false,
                  })
                }
                onBlur={() =>
                  setEducationToggle({
                    ...educationToggle,
                    end: true,
                  })
                }
              />
            </Grid>
          </Box>
        </Grid>
      ))}
      <Grid item sx={{ alignSelf: "center", paddingRight: "4rem" }}>
        <Button
          variant="contained"
          //  color="secondary"
          sx={{
            backgroundColor: "#03265B",
            "&:hover": {
              backgroundColor: "#03265B",

              //color:"black"
            },
            "@media(max-width:768px)": {
              marginLeft: "3rem",
            },
          }}
          onClick={() =>
            setEducation([
              ...education,
              {
                id: newId + Date.now(),
                institutionName: "",
                Degree: "",
                Stream: "",
                startYear: "",
                endYear: "",
              },
            ])
          }
          //className={classes.inputBox}
        >
          Add Education Details
        </Button>
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
};

const MultifieldWorkExp = (props) => {
  const { WorkExperience, setWorkExperience } = props;
  const newId = useId();
  const [experienceToggle, setExperienceToggle] = useState({
    start: true,
    end: true,
  });
  const removeItemAddEducation = (id) => {
    const filterFunction = WorkExperience.filter((obj, key) => {
      return obj.id !== id;
    });
    setWorkExperience(filterFunction);
  };
  return (
    <Grid
      item
      sx={{
        flexDirection: "column",
        display: "flex",
        //alignItems: "center", btton of institute
        minWidth: "100%",
        paddingLeft: "50px",
        "@media(max-width:768px)": {
          //  border:'1px solid red',
          //  width:"100%",
          // placeItems:"center",
          paddingLeft: "0px",
        },
        //justifyContent: "center",
      }}
    >
      {/* <Paper  elevation={3} sx={{padding:"30px 20px",width:1000,margin:"20px auto"}} > */}
      {WorkExperience.map((obj, key) => (
        <Grid
          item
          container
          // className={classes.inputBox}
          key={key}
          sx={{
            width: "100%",
            flexDirection: "column",
            "@media(max-width:768px)": {
              // border:'1px solid blue',
              width: "80%",
              // margin:"auto",
              // border:"1px solid green",
              // alignItems:"center",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              placeItems: "center",
              gap: "1rem",
              "@media(max-width:768px)": {
                flexDirection: "column",
                width: "100%",
                //  border:"1px solid orange"
              },
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                minWidth: "92%",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: "15px",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "80%",
                    // border:"1px solid gray",
                    marginLeft: "4rem",
                  },
                }}
                fullWidth
                label={`Job Title #${key + 1}`}
                value={WorkExperience[key].companyrole}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].companyrole = event.target.value;
                  setWorkExperience(newExp);
                }}
                variant="outlined"
              />
            </Grid>
            <Box
              sx={{
                marginBottom: "1rem",
                "@media(max-width:768px)": {
                  position: "relative",
                  left: "10rem",
                  bottom: "5.5rem",
                  marginBottom: "0rem",
                  // display:"none",
                },
              }}
            >
              {key > 0 ? (
                <DeleteIcon
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                  onClick={() => removeItemAddEducation(obj.id)}
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              "@media(max-width:768px)": {
                // border:"1px solid red",
                columnGap: "0px",
                gap: "2.5rem",
                // paddingLeft:"0rem",
                marginRight: "5rem",

                //  border:"1px solid red"
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  width: "100%",

                  //  border:"1px solid orange",
                  //  textAlign:"center",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray",
                    marginLeft: "2rem",
                  },
                }}
                fullWidth
                label={`Company `}
                value={WorkExperience[key].company}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].company = event.target.value;
                  setWorkExperience(newExp);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "45%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray",
                  // textAlign:"center",
                  marginRight: "2rem",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"1px solid red",
                  },
                }}
                fullWidth
                label={`Location `}
                value={WorkExperience[key].location}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].location = event.target.value;
                  setWorkExperience(newExp);
                }}
                variant="outlined"
              />
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              minWidth: "100%",
              my: 2,
              "@media(max-width:768px)": {
                // border:"1px solid red",
                columnGap: "0px",
                //  border:"1px solid red"
              },
            }}
          >
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "44%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "80%",
                    // border:"4px solid gray"
                    marginLeft: "2rem",
                  },
                }}
                fullWidth
                label="Start Year"
                value={obj.WorkstartYear}
                variant="outlined"
                //type="number"
                type={experienceToggle.start ? "text" : "date"}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].WorkstartYear = event.target.value;
                  setWorkExperience(newExp);
                }}
                onFocus={() =>
                  setExperienceToggle({
                    ...experienceToggle,
                    start: false,
                  })
                }
                onBlur={() =>
                  setExperienceToggle({
                    ...experienceToggle,
                    start: true,
                  })
                }
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                minWidth: "45%",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"4px solid gray"
                  marginRight: "2rem",
                },
              }}
            >
              <TextField
                sx={{
                  "@media(max-width:768px)": {
                    width: "80%",
                    // border:"4px solid gray"
                  },
                }}
                fullWidth
                label="End Year"
                value={obj.WorkendYear}
                variant="outlined"
                //  type="number"
                type={experienceToggle.end ? "text" : "date"}
                onChange={(event) => {
                  const newExp = [...WorkExperience];
                  newExp[key].WorkendYear = event.target.value;
                  setWorkExperience(newExp);
                }}
                onFocus={() =>
                  setExperienceToggle({
                    ...experienceToggle,
                    end: false,
                  })
                }
                onBlur={() =>
                  setExperienceToggle({
                    ...experienceToggle,
                    end: true,
                  })
                }
              />
            </Grid>
          </Box>
        </Grid>
      ))}
      <Grid
        item
        sx={{
          alignSelf: "center",
          paddingRight: "4rem",
          "@media(max-width:768px)": {
            // border:"1px solid red",
            paddingLeft: "2rem",
            // marginLeft:"2rem"
          },
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#03265B",
            "&:hover": {
              backgroundColor: "#03265B",
              //color:"black"
            },
            "@media(max-width:768px)": {
              // border:"1px solid red",
              // marginLeft:"2rem",
              // paddingLeft:"2rem"
            },
          }}
          // color="secondary"
          onClick={() =>
            setWorkExperience([
              ...WorkExperience,
              {
                id: newId + Date.now(),
                companyrole: "",
                company: "",
                location: "",
                WorkstartYear: "",
                WorkendYear: "",
              },
            ])
          }
          //className={classes.inputBox}
        >
          Add Work Experience Details
        </Button>
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
};

const MultifieldLanguage = (props) => {
  const { Language, setLanguage } = props;
  const newId = useId();
  const removeItemAddLanguage = (id) => {
    const filterItem = Language.filter((obj) => {
      return obj.id !== id;
    });
    setLanguage(filterItem);
  };
  return (
    <Grid
      item
      sx={{
        flexDirection: "column",
        display: "flex",
        //alignItems: "center", btton of institute
        minWidth: "100%",
        paddingLeft: "50px",
        "@media(max-width:768px)": {
          //  border:'1px solid red',
          width: "100%",
          paddingLeft: "0px",
          placeItems: "center",
          paddingLeft: "3rem",
        },
        //justifyContent: "center",
      }}
    >
      {/* <Paper  elevation={3} sx={{padding:"30px 20px",width:1000,margin:"20px auto"}} > */}
      {Language.map((obj, key) => (
        <Grid
          item
          container
          // className={classes.inputBox}
          key={key}
          sx={{
            width: "100%",
            flexDirection: "column",
            "@media(max-width:768px)": {
              // border:'1px solid blue',
              width: "100%",
              margin: "auto",
              // placeItems:"center",
            },
          }}
        >
          {/* <Grid item xs={4}  sx={{  minWidth: "90%",paddingLeft: 0, paddingRight: 0,marginBottom:"15px"}}> */}
          {/* <TextField
                      select
                      label="Type of Company"
                      variant="outlined"
                      value={signupDetails.employeeCompany}
                      onChange={(event) => {
                        handleInput("employeeCompany", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Startup">Startup</MenuItem>
                      <MenuItem value="SME">SME</MenuItem>
                      <MenuItem value="Enterprise">Enterprise</MenuItem>
                    </TextField> */}

          {/* </Grid> */}
          <Grid
            sx={{
              display: "flex",
              placeItems: "center",
              gap: "1rem",
              "@media(max-width:768px)": {
                flexDirection: "column",
                width: "100%",
                //  border:"1px solid orange"
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "20px",
                minWidth: "100%",
                "@media(max-width:768px)": {
                  flexDirection: "column",
                  width: "100%",
                  // border:"1px solid green",
                  position: "relative",
                  marginRight: "2rem",
                },
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: "44%",
                  "@media(max-width:768px)": {
                    width: "100%",

                    //  border:"1px solid orange",
                    //  textAlign:"center",
                  },
                }}
              >
                <TextField
                  sx={{
                    marginTop: "1rem",
                    "@media(max-width:768px)": {
                      width: "150%",
                      // border:"4px solid gray",
                      marginLeft: "2rem",
                      marginTop: "1rem",
                    },
                  }}
                  fullWidth
                  select
                  label={`Language #${key + 1}`}
                  value={Language[key].Languageknow}
                  onChange={(event) => {
                    const newlan = [...Language];
                    newlan[key].Languageknow = event.target.value;
                    setLanguage(newlan);
                  }}
                  variant="outlined"
                >
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Spanish">Spanish</MenuItem>
                  <MenuItem value="French">French</MenuItem>
                  <MenuItem value="hindi">Hindi</MenuItem>
                  <MenuItem value="German">German</MenuItem>

                  <MenuItem value="Portuguese">Portuguese</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: "45%",
                  "@media(max-width:768px)": {
                    width: "100%",
                    // border:"4px solid gray",
                    // textAlign:"center",
                    // marginRight:"2rem"
                  },
                }}
              >
                <TextField
                  sx={{
                    marginTop: "1rem",
                    //  border:"1px solid red",
                    "@media(max-width:768px)": {
                      width: "150%",
                      marginLeft: "2rem",
                      marginTop: "1rem",
                    },
                  }}
                  fullWidth
                  select
                  label={` language level  `}
                  value={Language[key].ExpLevel}
                  onChange={(event) => {
                    const newlan = [...Language];
                    newlan[key].ExpLevel = event.target.value;
                    setLanguage(newlan);
                  }}
                  variant="outlined"
                >
                  <MenuItem value="Basic">Basic</MenuItem>
                  <MenuItem value="Conversational">Conversational</MenuItem>
                  <MenuItem value="Fluent">Fluent</MenuItem>
                  <MenuItem value="Native">Native</MenuItem>
                </TextField>
              </Grid>
              <Box
                sx={{
                  "@media(max-width:768px)": {
                    width: "2rem",
                    position: "absolute",
                    top: "0.5rem",
                    right: "3rem",
                    // left:"18rem",
                    // bottom:"4rem",
                    // border:"1px solid red",
                    // display:"none",
                  },
                }}
              >
                {key > 0 ? (
                  <DeleteIcon
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontSize: "1.5rem",
                    }}
                    onClick={() => removeItemAddLanguage(obj.id)}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Grid
        item
        sx={{
          alignSelf: "center",
          paddingRight: "4rem",
          "@media(max-widtH:768px)": {
            flexDirection: "column",
            // paddingRight:"0px",
            // border:'1px solid red'
          },
        }}
      >
        <Button
          variant="contained"
          // color="secondary"
          sx={{
            marginTop: "1rem",
            backgroundColor: "#03265B",
            "&:hover": {
              backgroundColor: "#03265B",
              //color:"black"
            },
            marginTop: "1rem",
            "@media(max-width:768px)": {
              marginTop: "1rem",
              // border:"4px solid gray"
            },
          }}
          onClick={() =>
            setLanguage([
              ...Language,
              { id: newId + Date.now(), Languageknow: "", ExpLevel: "" },
            ])
          }
          //className={classes.inputBox}
        >
          Add Language Details
        </Button>
      </Grid>
      {/* </Paper> */}
    </Grid>
  );
};

const EmployeeSignup = () => {
  const location = useNavigate();
  //const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  const [fileData, setFileData] = useState({
    fileName: "",
    fileType: "",
    file: null,
  });
  const [loading, setLoading] = useState(false);

  const [loggedin, setLoggedin] = useState(isAuth());
  const [jobTitle, setJobTitle] = useState("");
  const [signupDetails, setSignupDetails] = useState({
    type: "applicant",
    email: "",
    password: "",
    name: "",
    education: [],
    WorkExperience: [],
    Language: [],
    Profilerole: "",
    residenceCountry: "",
    citizenshipCountry: "",
    Role: "",
    experience: "",
    noticeperiod: "",
    lastCurrencyType: "",
    lastsalary: "",
    expectedsalary: "",
    expectedCurrencyType: "",
    Setavailability: "",
    skills: [],
    resume: "",
    profile: "",
    employeeCompany: "",
    employeeWeb: "",
    bio: "",
    contactNumber: "",
    employeeContact: "",
    jobTitleInput: "",
    currentCity: "",
  });

  const [phone, setPhone] = useState("");

  const [phoneemployee, setPhoneemployee] = useState("");

  // const [selectedCountry, setSelectedCountry] = useState('');

  const [education, setEducation] = useState([
    {
      institutionName: "",
      Degree: "",
      Stream: "",
      startYear: "",
      endYear: "",
    },
  ]);

  const [WorkExperience, setWorkExperience] = useState([
    {
      companyrole: "",
      company: "",
      location: "",
      WorkstartYear: "",
      WorkendYear: "",
    },
  ]);

  const [Language, setLanguage] = useState([
    {
      Languageknow: "",
      ExpLevel: "",
    },
  ]);

  const [inputErrorHandler, setInputErrorHandler] = useState({
    email: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    password: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
    name: {
      untouched: true,
      required: true,
      error: false,
      message: "",
    },
  });

  const handleInput = (key, value) => {
    setSignupDetails({
      ...signupDetails,
      [key]: value,
    });
  };

  const handleInputError = (key, status, message) => {
    setInputErrorHandler({
      ...inputErrorHandler,
      [key]: {
        required: true,
        untouched: false,
        error: status,
        message: message,
      },
    });
  };

  const handleLogin = () => {
    const tmpErrorHandler = {};
    Object.keys(inputErrorHandler).forEach((obj) => {
      if (inputErrorHandler[obj].required && inputErrorHandler[obj].untouched) {
        tmpErrorHandler[obj] = {
          required: true,
          untouched: false,
          error: true,
          message: `${obj[0].toUpperCase() + obj.substr(1)} is required`,
        };
      } else {
        tmpErrorHandler[obj] = inputErrorHandler[obj];
      }
    });

    console.log(education);

    console.log(WorkExperience);

    console.log(Language);

    // let updatedDetails = {
    //   ...signupDetails,
    //   education: education
    //     .filter((obj) => obj.institutionName.trim() !== "")
    //     .map((obj) => {
    //       if (obj["endYear"] === "") {
    //         delete obj["endYear"];
    //       }
    //       return obj;
    //     }),
    //      WorkExperience: WorkExperience
    //     .filter((obj) => obj.companyrole.trim() !== "")
    //     .map((obj) => {
    //       if (obj["WorkendYear"] === "") {
    //         delete obj["WorkendYear"];
    //       }
    //       return obj;
    //     }),
    //     Language: Language
    //         .filter((obj) => obj.Languageknow.trim() !== "")
    //         .map((obj) => {

    //           return obj;
    //         }),

    // };

    //   // let updatedDetails = {
    //   //   ...signupDetails,
    //   //   education: education
    //   //     .filter((obj) => obj.institutionName.trim() !== "")
    //   //     .map((obj) => {
    //   //       if (obj["endYear"] === "") {
    //   //         delete obj["endYear"];
    //   //       }
    //   //       return obj;
    //   //     }),
    //   //      WorkExperience: WorkExperience
    //   //     .filter((obj) => obj.companyrole.trim() !== "")
    //   //     .map((obj) => {
    //   //       if (obj["WorkendYear"] === "") {
    //   //         delete obj["WorkendYear"];
    //   //       }
    //   //       return obj;
    //   //     }),
    //   //     Language: Language
    //   //     .filter((obj) => obj.Languageknow.trim() !== "")
    //   //     .map((obj) => {

    //   //       return obj;
    //   //     }),
    //   // };

    //   if (phoneemployee !== " ") {
    //     updatedDetails = {
    //       ...signupDetails,
    //       employeeContact: `+${phoneemployee}`
    //     };
    //   } else {
    //     updatedDetails = {
    //       ...signupDetails,
    //       employeeContact: "",

    //     };
    //   };

    //   setSignupDetails(updatedDetails);

    let updatedDetails = {
      ...signupDetails,
    };

    updatedDetails.education = education
      .filter((obj) => obj.institutionName.trim() !== "")
      .map((obj) => {
        // if (obj["endYear"] === "") {
        //   delete obj["endYear"];
        // }
        return obj;
      });

    updatedDetails.WorkExperience = WorkExperience.filter(
      (obj) => obj.companyrole.trim() !== ""
    ).map((obj) => {
      // if (obj["WorkendYear"] === "") {
      //   delete obj["WorkendYear"];
      // }
      return obj;
    });

    updatedDetails.Language = Language.filter(
      (obj) => obj.Languageknow.trim() !== ""
    ).map((obj) => {
      return obj;
    });

    if (phoneemployee !== "") {
      updatedDetails.employeeContact = `+${phoneemployee}`;
    } else {
      updatedDetails.employeeContact = "";
    }

    setSignupDetails(updatedDetails);

    const verified = !Object.keys(tmpErrorHandler).some((obj) => {
      return tmpErrorHandler[obj].error;
    });

    // let updatedDetails = {
    //   ...signupDetails,
    // };

    const formData = new FormData();
    Object.entries({ ...updatedDetails }).map(([key, value]) => {
      if (typeof value === "object") {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    if (fileData.file) {
      formData.append("fileCV", fileData.file);
    }

    if (verified) {
      setLoading(true);
      axios
        .post(apiList.signup, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          setLoggedin(isAuth(location("/jobs")));
          setPopup({
            open: true,
            severity: "success",
            message: "Logged in successfully",
          });
          console.log(response);
        })
        .catch((err) => {
          setPopup({
            open: true,
            severity: "error",
            message: err.response.data.message,
          });
          console.log(err.response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setInputErrorHandler(tmpErrorHandler);
      setPopup({
        open: true,
        severity: "error",
        message: "Incorrect Input",
      });
    }
  };

  const handleLoginRecruiter = () => {
    const tmpErrorHandler = {};
    Object.keys(inputErrorHandler).forEach((obj) => {
      if (inputErrorHandler[obj].required && inputErrorHandler[obj].untouched) {
        tmpErrorHandler[obj] = {
          required: true,
          untouched: false,
          error: true,
          message: `${obj[0].toUpperCase() + obj.substr(1)} is required`,
        };
      } else {
        tmpErrorHandler[obj] = inputErrorHandler[obj];
      }
    });

    console.log(signupDetails);

    let updatedDetails = {
      ...signupDetails,
    };
    if (phone !== "") {
      updatedDetails = {
        ...signupDetails,
        contactNumber: `+${phone}`,
      };
    } else {
      updatedDetails = {
        ...signupDetails,
        contactNumber: "",
      };
    }

    // let updatedDetails = {
    //   ...signupDetails,
    // if (phoneemployee !== "") {
    //   updatedDetails = {
    //     ...signupDetails,
    //     employeeContact: `+${phone}`,
    //   };
    // } else {
    //   updatedDetails = {
    //     ...signupDetails,
    //     employeeContact: "",
    //   };
    // };

    // }

    setSignupDetails(updatedDetails);

    const verified = !Object.keys(tmpErrorHandler).some((obj) => {
      return tmpErrorHandler[obj].error;
    });

    console.log(updatedDetails);

    if (verified) {
      setLoading(true);
      axios
        .post(apiList.signup, updatedDetails)
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("type", response.data.type);
          setLoggedin(isAuth());
          setPopup({
            open: true,
            severity: "success",
            message: "Logged in successfully",
          });
          console.log(response);
        })
        .catch((err) => {
          setPopup({
            open: true,
            severity: "error",
            message: err.response.data.message,
          });
          console.log(err.response);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setInputErrorHandler(tmpErrorHandler);
      setPopup({
        open: true,
        severity: "error",
        message: "Incorrect Input",
      });
    }
  };

  const JobTitleApi = [
    {
      id: 1,
      input: "Data Entry Support",
      skills: [
        "Typing Speed and Accuracy",
        "Attention to detail",
        "Time Management",
        "Technical Profiency",
        "Communication Skills",
      ],
    },
    {
      id: 2,
      input: "Accounting Support",
      skills: [
        "Bookkeeping",
        "Attention to Detail",
        "Software Proficiency",
        "Analytical Skills",
        "Communication Skills",
      ],
    },
    {
      id: 3,
      input: "Documentation Support",
      skills: [
        "Technical Writing",
        "Organization and Information Management",
        "Attention to Detail",
        "Collaboration and Communication",
        "Software Proficiency",
      ],
    },
    {
      id: 4,
      input: "Custom Process Support",
      skills: [
        "Business Process Analysis",
        "Problem-Solving",
        "Stakeholder Management",
        "Project Management",
        "Data Analysis",
      ],
    },
    {
      id: 5,
      input: "Air/Sea Process Support",
      skills: [
        "International Trade and Regulations",
        "Logistics and Supply Chain Management",
        "Communication and Coordination",
        "Documentation and Record Keeping",
        "Decision-Making",
      ],
    },
    {
      id: 6,
      input: "Import/Export Process Support",
      skills: [
        "International Trade Regulations",
        "Documentation",
        "Logistics and Supply Chain Management",
        "Communication",
        "Problem-Solving",
      ],
    },
    {
      id: 7,
      input: "Sales Support",
      skills: [
        "Customer Service",
        "Interpersonal Skills",
        "Time Management",
        "Product and Industry Knowledge",
        "Technology Proficiency",
      ],
    },
    {
      id: 8,
      input: "Customer Service Support",
      skills: [
        "Communication Skills",
        "Emotional Intelligence",
        "Problem-Solving",
        "Patience and Resilience",
        "Product and Service Knowledge",
      ],
    },
    {
      id: 9,
      input: "CargoWise Consultant",
      skills: [
        "CargoWise Expertise",
        "Logistics and Supply Chain",
        "Project Management",
        "Problem-Solving",
        "Communication",
      ],
    },
    {
      id: 10,
      input: "CargoWise Support",
      skills: [
        "CargoWise Proficiency",
        "Troubleshooting",
        "Customer Service",
        "Problem-Solving",
        "Documentation",
      ],
    },
    {
      id: 11,
      input: "ERP Consultant",
      skills: [
        "ERP System Implementation",
        "Business Process Analysis",
        "Customization and Configuration",
        "Data Migration and Integration",
        "User Training and Support",
      ],
    },
    {
      id: 12,
      input: "Power BI Consultant",
      skills: [
        "Power BI Dashboard Development",
        "Data Modeling and Analysis",
        "DAX Programming",
        "Report Design and Visualization",
        "Client Training and Support",
      ],
    },
    {
      id: 13,
      input: "CRM Consultant",
      skills: [
        "CRM Implementation and Configuration",
        "Data Migration and Integration",
        "User Training and Adoption",
        "Customization and Workflow Automation",
        "CRM System Administration",
      ],
    },
    {
      id: 14,
      input: "SAP Consultant",
      skills: [
        "SAP System Implementation",
        "Business Process Analysis",
        "ABAP",
        "SAP Module Expertise",
        "SAP Customization and Configuration",
      ],
    },
    {
      id: 15,
      input: "Web Developer",
      skills: [
        "HTML5 and CSS3",
        "JavaScript (ES6+)",
        "Front-end Frameworks",
        "Back-end Development",
        "Responsive Design",
      ],
    },
    {
      id: 16,
      input: "Front End Developer",
      skills: [
        "HTML5",
        "CSS3",
        "JavaScript (ES6+)",
        "Responsive Web Design",
        "Front-end Frameworks",
      ],
    },
    {
      id: 17,
      input: "Back End Developer",
      skills: [
        "Server-Side Programming Languages",
        "Database Management",
        "API Development",
        "Authentication and Authorization",
        "Server Management and Deployment",
      ],
    },
    {
      id: 18,
      input: "Full Stack Developer",
      skills: [
        "Front-end Development",
        "Back-end Development",
        "Database Management",
        "API Development and Integration",
        "Version Control/Git",
      ],
    },
    {
      id: 19,
      input: "Web Designer",
      skills: [
        "Graphic Design",
        "User Interface (UI)",
        "Responsive Web Design",
        "Typography and Color Theory",
        "Prototyping and Wireframing",
      ],
    },
    {
      id: 20,
      input: "Mobile App Developer",
      skills: [
        "Mobile App Development (iOS, Android)",
        "Programming Languages",
        "User Interface (UI)",
        "Cross-Platform Development",
        "Mobile App Testing and Debugging",
      ],
    },
    {
      id: 21,
      input: "Android Developer",
      skills: [
        "Android App Development",
        "User Interface (UI)",
        "API Integration",
        "Android Studio",
        "Performance Optimization",
      ],
    },
    {
      id: 22,
      input: "IOS Developer",
      skills: [
        "iOS App Development",
        "User Interface (UI)",
        "Database Management",
        "Xcode and Development Tools",
        "App Store Submission",
      ],
    },
    {
      id: 23,
      input: "Software Engineer",
      skills: [
        "Programming Languages",
        "Algorithm Design and Problem Solving",
        "Software Development Life Cycle (SDLC)",
        "Version Control",
        "Performance Optimization",
      ],
    },
    {
      id: 24,
      input: "Integration Developer",
      skills: [
        "API Development and Integration",
        "Data Mapping and Transformation",
        "Message Brokers",
        "Integration Platforms",
        "Troubleshooting and Debugging",
      ],
    },
    {
      id: 25,
      input: "IT Manager",
      skills: [
        "Strategic Planning",
        "Team Leadership",
        "Project Management",
        "IT Infrastructure Management",
        "Budgeting and Cost Control",
      ],
    },
    {
      id: 26,
      input: "Help Desk Support",
      skills: [
        "Customer Service",
        "Troubleshooting and Problem-Solving",
        "Operating Systems and Software",
        "Ticketing Systems",
        "Remote Support",
      ],
    },
    {
      id: 27,
      input: "Database Administrator",
      skills: [
        "Database Management Systems",
        "Database Design and Optimization",
        "SQL (Structured Query Language)",
        "Backup and Recovery Strategies",
        "Security and Access Control",
      ],
    },
    {
      id: 28,
      input: "System Administrator",
      skills: [
        "Operating Systems Administration",
        "Server Configuration and Maintenance",
        "Network Administration and Troubleshooting",
        "Security Management and Compliance",
        "Scripting and Automation",
      ],
    },
    {
      id: 29,
      input: "IT Project Manager",
      skills: [
        "Project Planning and Execution",
        "Team Leadership and Management",
        "Stakeholder Communication",
        "Risk Management",
        "Budgeting and Resource Allocation",
      ],
    },
    {
      id: 30,
      input: "RPA Developer",
      skills: [
        "RPA Platform Proficiency",
        "Process Automation and Optimization",
        "Programming and Scripting",
        "Business Process Analysis",
        "Problem-Solving and Debugging",
      ],
    },
    {
      id: 31,
      input: "AI Developer",
      skills: [
        "Machine Learning",
        "Programming Languages",
        "Data Preprocessing and Feature Engineering",
        "Deep Learning Frameworks",
        "Natural Language Processing (NLP)",
      ],
    },
    {
      id: 32,
      input: "Cloud Architect",
      skills: [
        "Cloud Platforms",
        "Infrastructure as Code (IaC)",
        "Microservices Architecture",
        "Security and Compliance",
        "Scalability and Performance Optimization",
      ],
    },
    {
      id: 33,
      input: "Data Analyst",
      skills: [
        "Data Analysis and Visualization",
        "Statistical Analysis and Modeling",
        "SQL (Structured Query Language)",
        "Data Cleaning and Preprocessing",
        "Tools and Technologies",
      ],
    },
    {
      id: 34,
      input: "Cybersecurity Expert",
      skills: [
        "Network Security",
        "Incident Response and Forensics",
        "Security Auditing and Compliance",
        "Vulnerability Assessment and Penetration Testing",
        "Security Awareness and Training",
      ],
    },
    {
      id: 35,
      input: "IT Support",
      skills: [
        "Technical Troubleshooting and Problem-Solving",
        "Customer Service and Communication",
        "Hardware and Software Diagnosis",
        "User Training and Assistance",
        "IT Systems and Networks",
      ],
    },
    {
      id: 36,
      input: "Sales Executive",
      skills: [
        "Sales Prospecting and Lead Generation",
        "Relationship Building and Networking",
        "Negotiation and Closing Deals",
        "Product Knowledge and Industry Understanding",
        "Sales Analytics and Goal Setting",
      ],
    },
    {
      id: 37,
      input: "Lead Generation",
      skills: [
        "Market Research and Target Identification",
        "Effective Communication and Outreach",
        "Lead Qualification and Scoring",
        "CRM Tools",
        "Data Analysis and Reporting",
      ],
    },
    {
      id: 38,
      input: "Customer Service",
      skills: [
        "Effective Communication",
        "Problem-Solving and Conflict Resolution",
        "Empathy and Active Listening",
        "Product Knowledge",
        "Time Management and Multitasking",
      ],
    },
    {
      id: 39,
      input: "Sales Manager",
      skills: [
        "Sales Leadership and Team Management",
        "Strategic Planning and Goal Setting",
        "Sales Forecasting and Analysis",
        "Client Relationship Management",
        "Performance Coaching and Development",
      ],
    },
    {
      id: 40,
      input: "Marketing Specialist",
      skills: [
        "Digital Marketing Strategy",
        "Content Creation and Copywriting",
        "Social Media Management",
        "Data Analysis and Reporting",
        "Marketing Campaign Planning and Execution",
      ],
    },
    {
      id: 41,
      input: "SEO Specialist",
      skills: [
        "Keyword Research and Analysis",
        "On-Page SEO Optimization",
        "Off-Page SEO Strategies",
        "SEO Tools",
        "Content Strategy for SEO",
      ],
    },
    {
      id: 42,
      input: "Social Media Manager",
      skills: [
        "Social Media Strategy Development",
        "Content Creation and Curation",
        "Community Management",
        "Analytics and Reporting",
        "Social Media Advertising",
      ],
    },
    {
      id: 43,
      input: "Social Media Specialist",
      skills: [
        "Social Media Platform Expertise",
        "Content Creation and Curation",
        "Social Media Analytics",
        "Community Engagement",
        "Campaign Planning and Execution",
      ],
    },
  ];
  const jobHandleFunction = (e) => {
    const jobInputData = e.target.value;
    const newJobTitleInput = e.target.value.toLowerCase();
    setSignupDetails({
      ...signupDetails,
      jobTitleInput: jobInputData,
    });

    const filterData = JobTitleApi.filter((element) => {
      if (
        newJobTitleInput.length === null ||
        newJobTitleInput.length === 1 ||
        newJobTitleInput.length === 2 ||
        newJobTitleInput === "" ||
        newJobTitleInput.length === 0
      ) {
        return "";
      } else {
        return element.input.toLocaleLowerCase().includes(newJobTitleInput);
      }
    });
    setJobTitle(filterData);

    if (
      signupDetails.jobTitleInput.length === null ||
      signupDetails.jobTitleInput.length === 0 ||
      signupDetails.jobTitleInput.length === 1 ||
      jobTitle === ""
    ) {
      const jobid = document.getElementById("jobTitleId2");
      jobid.style.height = "0rem";
    } else {
      const jobid = document.getElementById("jobTitleId2");
      jobid.style.height = "8rem";
      jobid.style.overflowY = "scroll";
      if (filterData.length === 0) {
        const jobid = document.getElementById("jobTitleId2");
        jobid.style.height = "0rem";
      }
    }
  };

  const jobTitleInputFunction = (data) => {
    // setSignupDetails({
    //   ...signupDetails,
    //   jobTitleInput:data,
    // })
    const selectedJobTitle = JobTitleApi.filter((job) => job.input === data);
    setSignupDetails({
      ...signupDetails,
      jobTitleInput: selectedJobTitle[0].input,
      skills: selectedJobTitle[0].skills,
    });
    setJobTitle("");
    const jobid = document.getElementById("jobTitleId2");
    jobid.style.height = "0rem";
  };

  return (
    <>
      <ReactHelmet
        title={"Employee Signup | AMUS HIRING"}
        description={
          "Discover your dream job with Amus Hiring. Sign up now to access thousands of job listings and connect with top employers. Start your job search journey today."
        }
        route="HireEmployee"
      />
      <Paper
        elevation={3}
        sx={{
          padding: "30px 20px",
          width: 800,
          margin: "20px auto",
          // border:"1px solid red",
          marginTop: "85px",
          "@media(max-width:768px)": {
            width: "100%",
            marginTop: "12rem",
            // marginRight:"0px",
            //  gap:"0",

            // margin:"0px",
            // marginRight:"0px",

            // margin:"auto",
            // textAlign:"center",
            placeSelf: "center",
            placeContent: "center",
            // gap:"0rem"
          },
        }}
      >
        {/* <Grid container  spacing={4}  sx={{display: "flex",//marginLeft:"-16px"
}} > */}
        <Grid
          item
          sx={{
            flexDirection: "column",
            display: "flex",
            //alignItems: "center", btton of institute
            minWidth: "100%",
            //justifyContent: "center",
            // border:"1px solid red",
            "@media(max-width:768px)": {
              flexDirection: "column",
              width: "100%",
              //  border:"1px solid red"
            },
          }}
        >
          <Grid
            item
            container
            // className={classes.inputBox}

            sx={{
              // minWidth: "100%",
              // justifyContent: "space-evenly"
              flexDirection: "column",
              // border:"1px solid orange",
              "@media(max-width:768px)": {
                flexDirection: "column",
                width: "100%",
              },
            }}
          >
            <Grid
              item
              sx={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h3" component="h2">
                Signup as Candidate and Get Remote Job
              </Typography>
            </Grid>
            <Grid
              sx={{
                textAlign: "center",
                marginBottom: "1.5rem",
                fontSize: "1rem",
                textTransform: "capitalize",
              }}
            >
              Already have an account <Link to="/login">Login</Link>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              {/* <Grid item  sx={{width: "40%",marginBottom:"15px"}} >
<TextField
            select
            label="Category"
            variant="outlined"
           fullWidth
            value={signupDetails.type}
            onChange={(event) => {
              handleInput("type", event.target.value);
            }}
>
<MenuItem value="applicant">Employee</MenuItem>
<MenuItem value="recruiter">Employer</MenuItem>
</TextField>
</Grid> */}

              <Grid
                item
                sx={{
                  width: "87%",
                  marginBottom: "15px",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <TextField
                  fullWidth
                  label="Name"
                  value={signupDetails.name}
                  onChange={(event) => handleInput("name", event.target.value)}
                  error={inputErrorHandler.name.error}
                  helperText={inputErrorHandler.name.message}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      handleInputError("name", true, "Name is required");
                    } else {
                      handleInputError("name", false, "");
                    }
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                width: "87%",
                marginLeft: "50px",
                marginBottom: "30px",
                // border:"1px solid green",
                "@media(max-width:768px)": {
                  width: "90%",
                  position: "relative",
                  right: "2rem",
                  // border:"1px solid red"
                },
              }}
            >
              <PhoneInput
                inputStyle={{ width: "100%" }}
                country={"in"}
                value={phoneemployee}
                onChange={(phoneemployee) => setPhoneemployee(phoneemployee)}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                "@media(max-width:768px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                item
                sx={{
                  width: "40%",
                  marginBottom: "15px",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <EmailInput
                  fullWidth
                  label="Email"
                  value={signupDetails.email}
                  onChange={(event) => handleInput("email", event.target.value)}
                  inputErrorHandler={inputErrorHandler}
                  handleInputError={handleInputError}
                  required={true}
                />
              </Grid>

              <Grid
                item
                sx={{
                  width: "40%",
                  marginBottom: "15px",
                  marginTop: "20px",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <PasswordInput
                  fullWidth
                  label="Password"
                  value={signupDetails.password}
                  onChange={(event) =>
                    handleInput("password", event.target.value)
                  }
                  //className={classes.inputBox}
                  error={inputErrorHandler.password.error}
                  helperText={inputErrorHandler.password.message}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      handleInputError(
                        "password",
                        true,
                        "Password is required"
                      );
                    } else {
                      handleInputError("password", false, "");
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                "@media(max-width:768px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                item
                sx={{
                  minWidth: "40%",
                  marginBottom: "40px",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <TextField
                  select
                  label="Country of residence"
                  variant="outlined"
                  fullWidth
                  value={signupDetails.residenceCountry}
                  onChange={(event) => {
                    handleInput("residenceCountry", event.target.value);
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: "200px", // Set the maximum height here
                        },
                      },
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                sx={{
                  minWidth: "40%",
                  marginBottom: "40px",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <TextField
                  select
                  label="Country of citizenship"
                  variant="outlined"
                  fullWidth
                  value={signupDetails.citizenshipCountry}
                  onChange={(event) => {
                    handleInput("citizenshipCountry", event.target.value);
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: "200px", // Set the maximum height here
                        },
                      },
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "30px",
                flexDirection: "column",
                gap: "1.5rem",
                // border:"1px solid red"
              }}
            >
              <Grid item sx={{ width: "88%" }}>
                <TextField
                  label="Enter your current city"
                  type="text"
                  variant="outlined"
                  value={signupDetails.currentCity}
                  onChange={(event) => {
                    handleInput("currentCity", event.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "30px",
                "@media(max-width:768px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                item
                sx={{
                  width: "87%",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <TextField
                  select
                  label="Your Current Status"
                  variant="outlined"
                  fullWidth
                  value={signupDetails.Setavailability}
                  onChange={(event) => {
                    handleInput("Setavailability", event.target.value);
                  }}
                >
                  <MenuItem value="Working as Employee">
                    Working as Employee
                  </MenuItem>
                  <MenuItem value="Working as Freelancer">
                    Working as Freelancer
                  </MenuItem>
                  <MenuItem value="Not Working">Not Working </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "30px",
                flexDirection: "column",
                gap: "1.5rem",
                // border:"1px solid red"
              }}
            >
              <Grid item sx={{ width: "88%" }}>
                <TextField
                  label="Enter Linkedin Profile Link"
                  type="text"
                  variant="outlined"
                  value={signupDetails.profile}
                  onChange={(event) => {
                    handleInput("profile", event.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />
              </Grid>
              <Grid item sx={{ width: "88%" }}>
                <TextField
                  label="Enter Job Title"
                  helperText="Enter more than two character"
                  type="text"
                  variant="outlined"
                  value={signupDetails.jobTitleInput}
                  onChange={jobHandleFunction}
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />

                <Grid
                  id="jobTitleId2"
                  sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    ":hover": {
                      // backgroundColor:"#ededed"
                    },
                    //  height:"8rem",
                    //  overflowY:"scroll"
                  }}
                >
                  {jobTitle === ""
                    ? ""
                    : jobTitle.map((element) => {
                        return (
                          <Grid
                            onClick={() => jobTitleInputFunction(element.input)}
                            sx={{
                              marginTop: "0.5rem",
                              padding: "0.2rem 0.8rem",
                              ":hover": {
                                backgroundColor: "#ededed",
                                cursor: "pointer",
                              },
                            }}
                          >
                            {element.input}
                          </Grid>
                        );
                      })}
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-evenly",
              marginBottom: "30px",
              "@media(max-width:768px)": {
                flexDirection: "column",
                gap: "2rem",
              },
            }}
          >
            <Grid
              item
              sx={{
                width: "40%",
                "@media(max-width:768px)": {
                  width: "90%",
                },
              }}
            >
              <TextField
                select
                label="Profile"
                variant="outlined"
                fullWidth
                value={signupDetails.Profilerole}
                onChange={(event) => {
                  handleInput("Profilerole", event.target.value);
                }}
              >
                <MenuItem value="Data Entry Support">Data Entry Support</MenuItem>
                <MenuItem value="CargoWise Consultant">CargoWise Consultant</MenuItem>
                <MenuItem value="Web Developer">Web Developer</MenuItem>
                <MenuItem value="IT Manager">IT Manager</MenuItem>
                <MenuItem value="Sales Executive">Sales Executive</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </TextField>
            </Grid>
     

        {
  signupDetails.Profilerole==="Data Entry Support"? <Grid
  item
  sx={{
    width: "40%",
    
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >
    <MenuItem value="Accounting Support">Accounting Support</MenuItem>
    <MenuItem value="Documentation Support">
    Documentation Support
    </MenuItem>
    <MenuItem value="Custom Process Support">
    Custom Process Support
    </MenuItem>
    <MenuItem value="Air/Sea Process Support">
    Air/Sea Process Support
    </MenuItem>
    <MenuItem value="Import/Export Process Support">Import/Export Process Support</MenuItem>
    <MenuItem value="Sales SupportAccount Executive">Sales Support</MenuItem>
    <MenuItem value="Customer Service Support">
    Customer Service Support
    </MenuItem>

  </TextField>
</Grid>:signupDetails.Profilerole==="CargoWise Consultant"?<Grid
  item
  sx={{
    width: "40%",
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >
    <MenuItem value="CargoWise Support">CargoWise Support</MenuItem>
    <MenuItem value="ERP Consultant">
    ERP Consultant
    </MenuItem>
    <MenuItem value="Power BI Consultant">
    Power BI Consultant
    </MenuItem>
    <MenuItem value="CRM Consultant">
    CRM Consultant
    </MenuItem>
    <MenuItem value="SAP Consultant">SAP Consultant</MenuItem>


  </TextField>
</Grid>:signupDetails.Profilerole==="Web Developer"?<Grid
  item
  sx={{
    width: "40%",
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >
    <MenuItem value="Front End Developer">Front End Developer</MenuItem>
    <MenuItem value="Back End Developer">
    Back End Developer
    </MenuItem>
    <MenuItem value="Full Stack Developer">
    Full Stack Developer
    </MenuItem>
    <MenuItem value="Web Designer">
    Web Designer
    </MenuItem>
    <MenuItem value="Mobile App Developer">Mobile App Developer</MenuItem>
    <MenuItem value="Android Developer">Android Developer</MenuItem>
    <MenuItem value="IOS Developer">IOS Developer</MenuItem>
    <MenuItem value="Software Engineer">Software Engineer</MenuItem>
    <MenuItem value="Integration Developer">Integration Developer</MenuItem>


  </TextField>
</Grid>:signupDetails.Profilerole==="IT Manager"?
<Grid
  item
  sx={{
    width: "40%",
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >
    <MenuItem value="Help Desk Support">Help Desk Support </MenuItem>
    <MenuItem value="Database Administrator">
    Database Administrator
    </MenuItem>
    <MenuItem value="System Administrator">
    System Administrator
    </MenuItem>
    <MenuItem value="IT Project Manager">
    IT Project Manager
    </MenuItem>
    <MenuItem value="RPA Developer">RPA Developer</MenuItem>
    <MenuItem value="AI Developer">AI Developer</MenuItem>
    <MenuItem value="Cloud Architect">Cloud Architect</MenuItem>
    <MenuItem value="Data Analyst">Data Analyst</MenuItem>
    <MenuItem value="Cybersecurity Expert">Cybersecurity Expert</MenuItem>
    <MenuItem value="IT Support">IT Support</MenuItem>


  </TextField>
</Grid>:signupDetails.Profilerole==="Sales Executive"?
<Grid
  item
  sx={{
    width: "40%",
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >
    <MenuItem value="Lead Generation">Lead Generation </MenuItem>
    <MenuItem value="Customer Service">
    Customer Service
    </MenuItem>
    <MenuItem value="Sales Manager">
    Sales Manager
    </MenuItem>
    <MenuItem value="Marketing Specialist">
    Marketing Specialist
    </MenuItem>
    <MenuItem value="SEO Specialist">SEO Specialist</MenuItem>
    <MenuItem value="Social Media Manager">Social Media Manager</MenuItem>
    <MenuItem value="Social Media Specialist">Social Media Specialist</MenuItem>
  


  </TextField>
</Grid>:signupDetails.Profilerole==="Others"?
<Grid
  item
  sx={{
    width: "40%",
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    // select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >

  


  </TextField>
</Grid>:<Grid
  item
  sx={{
    width: "40%",
    "@media(max-width:768px)": {
      width: "90%",
    },
  }}
>
  <TextField
    select
    label="Role"
    variant="outlined"
    fullWidth
    value={signupDetails.Role}
    onChange={(event) => {
      handleInput("Role", event.target.value);
    }}
  >
        <MenuItem value="Accounting Support">Accounting Support</MenuItem>
    <MenuItem value="Documentation Support">
    Documentation Support
    </MenuItem>
    <MenuItem value="Custom Process Support">
    Custom Process Support
    </MenuItem>
    <MenuItem value="Air/Sea Process Support">
    Air/Sea Process Support
    </MenuItem>
    <MenuItem value="Import/Export Process Support">Import/Export Process Support</MenuItem>
    <MenuItem value="Sales SupportAccount Executive">Sales Support</MenuItem>
    <MenuItem value="Customer Service Support">
    Customer Service Support
    </MenuItem>
        <MenuItem value="CargoWise Support">CargoWise Support</MenuItem>
    <MenuItem value="ERP Consultant">
    ERP Consultant
    </MenuItem>
    <MenuItem value="Power BI Consultant">
    Power BI Consultant
    </MenuItem>
    <MenuItem value="CRM Consultant">
    CRM Consultant
    </MenuItem>
    <MenuItem value="SAP Consultant">SAP Consultant</MenuItem>
        <MenuItem value="Front End Developer">Front End Developer</MenuItem>
    <MenuItem value="Back End Developer">
    Back End Developer
    </MenuItem>
    <MenuItem value="Full Stack Developer">
    Full Stack Developer
    </MenuItem>
    <MenuItem value="Web Designer">
    Web Designer
    </MenuItem>
    <MenuItem value="Mobile App Developer">Mobile App Developer</MenuItem>
    <MenuItem value="Android Developer">Android Developer</MenuItem>
    <MenuItem value="IOS Developer">IOS Developer</MenuItem>
    <MenuItem value="Software Engineer">Software Engineer</MenuItem>
    <MenuItem value="Integration Developer">Integration Developer</MenuItem>
        <MenuItem value="Help Desk Support">Help Desk Support </MenuItem>
    <MenuItem value="Database Administrator">
    Database Administrator
    </MenuItem>
    <MenuItem value="System Administrator">
    System Administrator
    </MenuItem>
    <MenuItem value="IT Project Manager">
    IT Project Manager
    </MenuItem>
    <MenuItem value="RPA Developer">RPA Developer</MenuItem>
    <MenuItem value="AI Developer">AI Developer</MenuItem>
    <MenuItem value="Cloud Architect">Cloud Architect</MenuItem>
    <MenuItem value="Data Analyst">Data Analyst</MenuItem>
    <MenuItem value="Cybersecurity Expert">Cybersecurity Expert</MenuItem>
    <MenuItem value="IT Support">IT Support</MenuItem>
    <MenuItem value="Lead Generation">Lead Generation </MenuItem>
    <MenuItem value="Customer Service">
    Customer Service
    </MenuItem>
    <MenuItem value="Sales Manager">
    Sales Manager
    </MenuItem>
    <MenuItem value="Marketing Specialist">
    Marketing Specialist
    </MenuItem>
    <MenuItem value="SEO Specialist">SEO Specialist</MenuItem>
    <MenuItem value="Social Media Manager">Social Media Manager</MenuItem>
    <MenuItem value="Social Media Specialist">Social Media Specialist</MenuItem>
  


  </TextField>
</Grid>

        }
     
        
       



     
    
          </Grid> */}

            <Grid
              item
              sx={{
                // border:"1px solid green",
                width: "87%",
                display: "flex",
                marginBottom: "1rem",
                alignItems: "center",
                marginLeft: "50px",
                "@media(max-width:768px)": {
                  position: "relative",
                  right: "1.5rem",
                  // width:"100%",
                },
              }}
            >
              <MuiChipsInput
                fullWidth
                // sx={{width: "900px", paddingLeft: 0, paddingRight: 0,marginBottom:"15px"}}
                label="Skills"
                variant="outlined"
                helperText="Press enter to add skills"
                value={signupDetails.skills}
                onChange={(chips) =>
                  setSignupDetails({ ...signupDetails, skills: chips })
                }
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "30px",
                "@media(max-width:768px)": {
                  flexDirection: "column",
                  gap: "2rem",
                  // border:"1px solid red"
                },
              }}
            >
              <Grid
                item
                sx={{
                  width: "40%",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <TextField
                  label="Working Experience in Current Job"
                  variant="outlined"
                  fullWidth
                  value={signupDetails.experience}
                  onChange={(event) => {
                    handleInput("experience", event.target.value);
                  }}
                  type="number"
                >
                  {/* <MenuItem value="Fresher">Fresher</MenuItem>
                <MenuItem value="Junior (1-2 Years)">Junior (1-2 Years)</MenuItem>
                <MenuItem value="Intermediate (3 -5 Years)">Intermediate (3-5 Years)</MenuItem>
                <MenuItem value="Expert (<5 Years)">Expert (<span>&lt;5</span> Years)</MenuItem> */}
                </TextField>
              </Grid>
              <Grid
                item
                sx={{
                  width: "40%",
                  "@media(max-width:768px)": {
                    width: "90%",
                  },
                }}
              >
                <TextField
                  select
                  label="Notice Period"
                  variant="outlined"
                  fullWidth
                  value={signupDetails.noticeperiod}
                  onChange={(event) => {
                    handleInput("noticeperiod", event.target.value);
                  }}
                >
                  <MenuItem value="Immediately">Immediately</MenuItem>
                  <MenuItem value="15 days notice">15 days notice</MenuItem>
                  <MenuItem value="1 month notice">1 month notice</MenuItem>
                  <MenuItem value="2 months notice">2 months notice</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-evenly",
                marginBottom: "30px",
                // flexDirection:"column"
                // border:"1px solid red"
              }}
            >
              <Grid
                item
                sx={{
                  width: "40%",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "1fr 2fr",
                }}
              >
                <TextField
                  select
                  label="Currency"
                  variant="outlined"
                  fullWidth
                  // value={signupDetails.noticeperiod}
                  // onChange={(event) => {
                  //   handleInput("noticeperiod", event.target.value);
                  // }}
                  onChange={(e) => {
                    handleInput("lastCurrencyType", e.target.value);
                  }}
                >
                  <MenuItem value="AUD">AUD</MenuItem>
                  <MenuItem value="CNY">CNY</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="IDR">IDR</MenuItem>
                  <MenuItem value="PHP">PHP</MenuItem>
                  <MenuItem value="SGD">SGD</MenuItem>
                  <MenuItem value="ZAR">ZAR</MenuItem>

                  <MenuItem value="LKR">LKR</MenuItem>
                  <MenuItem value="TWD">TWD</MenuItem>
                  <MenuItem value="TBH">THB</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="VND">VND</MenuItem>
                  <MenuItem value="ZWD">ZWD</MenuItem>
                </TextField>
                <TextField
                  label="Last Salary "
                  type="number"
                  variant="outlined"
                  value={signupDetails.lastsalary}
                  onChange={(event) => {
                    handleInput("lastsalary", event.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: "40%",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: "1fr 2fr",
                }}
              >
                <TextField
                  select
                  label=" Currency"
                  variant="outlined"
                  fullWidth
                  // value={signupDetails.noticeperiod}
                  // onChange={(event) => {
                  //   handleInput("noticeperiod", event.target.value);
                  // }}
                  onChange={(e) => {
                    handleInput("expectedCurrencyType", e.target.value);
                  }}
                >
                  <MenuItem value="AUD">AUD</MenuItem>
                  <MenuItem value="CNY">CNY</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="IDR">IDR</MenuItem>
                  <MenuItem value="PHP">PHP</MenuItem>
                  <MenuItem value="SGD">SGD</MenuItem>
                  <MenuItem value="ZAR">ZAR</MenuItem>

                  <MenuItem value="LKR">LKR</MenuItem>
                  <MenuItem value="TWD">TWD</MenuItem>
                  <MenuItem value="TBH">THB</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="VND">VND</MenuItem>
                  <MenuItem value="ZWD">ZWD</MenuItem>
                </TextField>
                <TextField
                  label="Expected Salary "
                  type="number"
                  variant="outlined"
                  value={signupDetails.expectedsalary}
                  onChange={(event) => {
                    handleInput("expectedsalary", event.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                minWidth: "100%",
                marginBottom: "30px",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"1px solid blue"
                },
              }}
            >
              <MultifieldInput
                education={education}
                setEducation={setEducation}
              />
            </Grid>
            <Grid
              item
              sx={{
                minWidth: "100%",
                marginBottom: "40px",
                "@media(max-width:768px)": {
                  width: "100%",
                },
              }}
            >
              <MultifieldWorkExp
                WorkExperience={WorkExperience}
                setWorkExperience={setWorkExperience}
              />
            </Grid>
            <Grid
              item
              sx={{
                minWidth: "100%",
                marginBottom: "40px",
                "@media(max-width:768px)": {
                  width: "100%",
                  // border:"1px solid red"
                },
              }}
            >
              <MultifieldLanguage
                Language={Language}
                setLanguage={setLanguage}
              />
            </Grid>
            <Stack>
              <UploadFile
                fileData={fileData}
                setFileData={setFileData}
                title="Upload your resume"
              />
            </Stack>
            {/* <Grid item sx={{width: "100%",marginLeft:"20px"}}>
<PhoneInput
inputStyle={{width:"92%"}}
                country={"in"}
                value={phoneemployee}
                onChange={(phoneemployee) => setPhoneemployee(phoneemployee)}
              />
</Grid> */}

            {/* <Grid item sx={{width: "800px",marginBottom:"15px",}}>
<FileUploadInput
                
                label="Resume (.pdf)"
                icon={<DescriptionIcon />}
                // value={files.resume}
                // onChange={(event) =>
                //   setFiles({
                //     ...files,
                //     resume: event.target.files[0],
                //   })
                // }
                uploadTo={apiList.uploadResume}
                handleInput={handleInput}
                identifier={"resume"}
              />
</Grid> */}
            {/* <Grid item sx={{width: "900px",marginBottom:"15px"}}>
<FileUploadInput
                               style={{ width: "100%" }}
                label="Profile Photo (.jpg/.png)"
                icon={<FaceIcon />}
                // value={files.profileImage}
                // onChange={(event) =>
                //   setFiles({
                //     ...files,
                //     profileImage: event.target.files[0],
                //   })
                // }
                uploadTo={apiList.uploadProfileImage}
                handleInput={handleInput}
                identifier={"profile"}
              />
</Grid> */}
            <Grid
              sx={{
                width: "90%",
                display: "flex",
                marginLeft: "50px",
                alignItems: "center",
                paddingRight: "1rem",
                gap: "1rem",
              }}
            >
              <input type="checkbox" name="" id="" required />

              <p>
                I would like to receive notifications about the company,
                products, services and events.
              </p>
            </Grid>
            <Grid
              sx={{
                width: "90%",
                display: "flex",
                marginLeft: "50px",
                alignItems: "center",
                paddingRight: "1rem",
                gap: "1rem",
              }}
            >
              <input type="checkbox" name="" id="" required />

              <p>
                {" "}
                I have read and accept the
                <Link to={"/legal-notice"} style={{ textDecoration: "none" }}>
                  {" "}
                  Legal Notice
                </Link>{" "}
                and{" "}
                <Link to={"/privacy-policy"} style={{ textDecoration: "none" }}>
                  Privacy Policy
                </Link>
                .
              </p>
            </Grid>
            <Grid
              item
              sx={{
                width: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: "1rem",
                // border:"1px solid red",
                marginLeft: "50px",
              }}
            >
              <Button
                variant="contained"
                disabled={loading}
                // color="primary"
                sx={{
                  backgroundColor: "#03265B",
                  "&:hover": {
                    backgroundColor: "#03265B",
                    //color:"black"
                  },
                  "@media(max-width:768px)": {
                    marginLeft: "1rem",
                    "@media(max-width:768px)": {
                      marginRight: "3rem",
                    },
                  },
                }}
                onClick={() => {
                  signupDetails.type === "applicant"
                    ? handleLogin()
                    : handleLoginRecruiter();
                }}
                // className={classes.submitButton}
              >
                {"Signup"}{" "}
                {loading && (
                  <Stack justifyContent={"center"} sx={{ ml: "10px" }}>
                    <CircularProgress sx={{ color: "#1b2a59" }} size={16} />
                  </Stack>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default EmployeeSignup;
