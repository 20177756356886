import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Rating,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import apiList from "pages/authentication/lib/apiList";
import axios from "axios";
import ApplyModal from "./ApplyModal";

const Job = () => {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [singleJob, setSingleJob] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${apiList.jobs}/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(({ data }) => {
          if (data) {
            setSingleJob({ ...data });
          }
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  }, [id]);
  const isJobSelected = Object.keys(singleJob).length > 0;
  return (
    <Grid
      item
      lg={7}
      md={5}
      sm={7}
      xs={12}
      sx={{ "@media(max-width:600px)": { display: "none" } }}
    >
      <Box
        sx={{
          minHeight: "85vh",
          p: 2,
          borderRadius: 3,
          backgroundColor: "#f9f9f9",
          boxShadow: 1,
        }}
      >
        <Box
          sx={{
            minHeight: "80vh",
            p: 2,
            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        >
          {!isLoading && (
            <Box
              sx={{
                maxHeight: "75vh",
                overflow: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h3">{singleJob?.title}</Typography>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#284774",
                    marginTop: 1,
                    ":hover": { background: "#284774" },
                    borderRadius: 3,
                  }}
                  disabled={!isJobSelected}
                  onClick={() => {
                    if (isJobSelected) setOpen(true);
                  }}
                >
                  Apply now
                </Button>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ mt: 1 }}
              >
                <Typography>Job Type : {singleJob?.jobType}</Typography>
                <Rating value={Number(singleJob?.rating)} readOnly />
              </Box>
              <Typography textAlign={"start"}>
                Job Required Experience : {singleJob?.experience}
              </Typography>
              {singleJob?.workplaceType && (
                <Typography variant="body2" component="p" textAlign="left">
                  Workplace Type : {singleJob?.workplaceType}
                </Typography>
              )}
              {singleJob?.workplaceType === "Remote" &&
                singleJob?.preferredCountry && (
                  <Typography variant="body2" component="p" textAlign="left">
                    Preferred Employee Location Country:{" "}
                    {singleJob?.preferredCountry}
                  </Typography>
                )}
              {(singleJob?.workplaceType === "On-Site" ||
                singleJob?.workplaceType === "Hybrid") &&
                singleJob?.jobLocationCity && (
                  <Typography variant="body2" component="p" textAlign="left">
                    Job Location City : {singleJob?.jobLocationCity}
                  </Typography>
                )}
              {(singleJob?.workplaceType === "On-Site" ||
                singleJob?.workplaceType === "Hybrid") &&
                singleJob?.jobLocationCountry && (
                  <Typography variant="body2" component="p" textAlign="left">
                    Job Location Country : {singleJob?.jobLocationCountry}
                  </Typography>
                )}
              <Typography textAlign={"start"}>
                Duration :{" "}
                {singleJob?.duration === "" ? "Flexible" : singleJob?.duration}
              </Typography>
              <Typography textAlign={"start"}>
                Application Deadline : {singleJob?.deadline?.split("T")[0]}
              </Typography>
              <Box textAlign={"start"} sx={{ mt: 1 }}>
                <Typography variant="span">Skills :</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {singleJob?.skillsets?.map((e) => (
                    <Typography
                      variant="span"
                      sx={{
                        background: "#EDEDED",
                        color: "#000",
                        mr: 1,
                        mt: 1,
                        fontSize: "12px",
                        borderRadius: 4,
                        p: 1,
                      }}
                    >
                      {e}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 1 }}>
                <Typography textAlign={"start"}>Job Description :</Typography>
                <Typography textAlign={"start"}>
                  {singleJob?.description}
                </Typography>
              </Box>
            </Box>
          )}
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "75vh",
                fontSize: "20px",
              }}
            >
              {isLoading ? <CircularProgress /> : "No Job Data"}
            </Box>
          )}
        </Box>
      </Box>
      {open && (
        <ApplyModal open={open} setOpen={setOpen} jobDetail={singleJob} />
      )}
    </Grid>
  );
};

export default Job;
