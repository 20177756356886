import { useContext, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Paper,
  // Box,
  Link as MuiLink,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import PasswordInput from "../lib/PasswordInput";
import EmailInput from "../lib/EmailInput";
import { SetPopupContext } from "../../../routes/index";
import apiList from "../lib/apiList";
import isAuth from "../lib/isAuth";
import Logo from "../../../ui-component/Logo";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactHelmet from "component/react-helmet/ReactHelmet";
// import {apiList} from "../lib/apiList";


const ChangePassword = (props) => {

const {verifyId,token}=useParams();
console.log(verifyId,"yes",token);
const location=useNavigate();
const [input,setInput]=useState({
  password:"",
  confirmpassword:"",
});

const handleInput=(e)=>{
  e.preventDefault();
  const name=e.target.name;
  const value=e.target.value;
  setInput({...input,[name]:value});

}



const sentDataChangePassword=async()=>{
const {password,confirmpassword}=input;

try{
  // const response=await fetch(`http://localhost:4444/auth/pass/changePassword/:${verifyId}/:${token}`,{
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json"
  //   },
  const response=await fetch(`${apiList.changepassword}/:${verifyId}/:${token}`,{
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      password,confirmpassword
    })
  });
  const data=await response.json();
if(response.status===400){
  toast.error("Your password doesnot match Confirm password",{
    autoClose:2000
  })
}
if(response.status===200){
  toast.success("Password updated successfully",{
    autoClose:2000
  })
  location("/login");
}
if(response.status===412){
  toast.error("Please fill all the data",{
    autoClose:2000
  });
}

}catch(error){
  console.log(error);
}

}
  return  (
    <>
      <ReactHelmet 
        title={'Change Password | AMUS HIRING'}
        description={''}
        route=''
      />
      <Paper
      elevation={3}
      sx={{
        padding: "30px 20px",
        width: "400px",
        margin: "20px auto",
        marginTop: "85px",
        '@media(max-width:768px)':{
          marginTop:"12rem",
          width:"100%"
                }
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Link to="#">
            <Logo width="250" />
          </Link>
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            component="h2"
            sx={{
              fontSize: "25px",
              color: "#03265B",
              fontWeight: "700",
              fontFamily: "Gordita, sans-serif",
              marginBottom: "20px",
            }}
          >
            Change Password
          </Typography>
        </Grid>
        <Grid item sx={{ width: "80%" }}>
        <div
                className="inputs-data"
                style={{ width: "17rem", height: "2rem" }}
              >
                <input
                  type="password"
                  name="password"
                  id=""
                  style={{
                    width: "100%",
                    height: "100%",
                    outline: "none",
                    cursor: "pointer",
                    padding: "0.5rem 1rem",
                  }}
                  placeholder="Password"
                  value={input.password}
                  onChange={handleInput}
                  
                />
              </div>
        </Grid>
        <Grid item sx={{ width: "80%" }}>
        <div
                className="inputs-data"
                style={{ width: "17rem", height: "2rem" }}
              >
                <input
                  type="password"
                  name="confirmpassword"
                  id=""
                  style={{
                    width: "100%",
                    height: "100%",
                    outline: "none",
                    cursor: "pointer",
                    padding: "0.5rem 1rem",
                  }}
                  placeholder="Confirm Password"
                  value={input.confirmpassword}
                  onChange={handleInput}
                />
              </div>
        </Grid>
    
        
        <Grid
          item
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            // color="primary"
            
            sx={{ width: "100%", bgcolor:"#03265B" }}
            onClick={sentDataChangePassword}
          >
            Change Password
          </Button>
        </Grid>
        <Grid item sx={{ marginTop: "20px" }}>
         
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};





export default ChangePassword;