import * as React from "react";
import PropTypes from "prop-types";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import Hamburger from "hamburger-react";

//import Toolbar from "@mui/material/Toolbar";
//import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Box, Grid } from "@mui/material";
//import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../../../ui-component/Logo";
import Container from "@mui/material/Container";
import { display, lighten } from "@mui/system";

// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   makeStyles,
// } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import isAuth, { userType } from "../../../../pages/authentication/lib/isAuth";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));
import { styled, alpha } from "@mui/material/styles";
//import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import App from "./App";
import HamburgerMenu from "./HamburgerMenu";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,

    // color:"#fff",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
const Navbars = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [widthData, setWidthData] = useState("");

  // const classes = useStyles();
  let navigate = useNavigate();

  const handleClick = (location) => {
    console.log(location);
    navigate(location);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = () => {
    handleClick("/login");
    // Handle login in action
    // You can navigate to the sign in page
    handleClose();
  };

  const handleEmployeeSignUp = () => {
    handleClick("/Employeesignup");
    // Handle employee sign up action
    // You can navigate to the employee sign up page
    handleClose();
  };

  const handleEmployerSignUp = () => {
    handleClick("/Employersignup");
    // Handle employer sign up action
    // You can navigate to the employer sign up page
    handleClose();
  };

  useEffect(() => {
    const newWidth = window.innerWidth;
    setWidthData(newWidth);
  }, [window.innerWidth]);

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          color="default"
          sx={{ backgroundColor: "#1B2A59", height: 80 }}
          position="fixed"
        >
          <Container>
            <Grid item xs={12} sm={6} md={7}>
              <Toolbar
                sx={{ display: "flex", justifyContent: "space-between", px: 3 }}
              >
                {widthData > 768 ? (
                  <>
                    <Box display="flex" alignItems="center" gap={2}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="logo"
                      >
                        <Logo />
                      </IconButton>
                    </Box>
                    {isAuth() ? (
                      userType() === "recruiter" ? (
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                            "@media (max-width:768px)": {
                              fontSize: "1rem",
                              flexDirection: "column",
                              gap: "0.2rem",
                            },
                          }}
                        >
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/addjob")}
                          >
                            Add Jobs
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/myjobs")}
                          >
                            My Jobs
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/employees")}
                          >
                            Employees
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/profile")}
                          >
                            Profile
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/logout")}
                          >
                            Logout
                          </Button>
                        </Typography>
                      ) : userType() === "owner" ? (
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                            "@media (max-width:768px)": {
                              fontSize: "1rem",
                              flexDirection: "column",
                              gap: "0.2rem",
                            },
                          }}
                        >
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/recruiters")}
                          >
                            Recruiters
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/applicants")}
                          >
                            Applicants
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/allJobs")}
                          >
                            Jobs
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/feed-back-list")}
                          >
                            Feedback List
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/logout")}
                          >
                            Logout
                          </Button>
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                            "@media (max-width:768px)": {
                              fontSize: "1rem",
                              flexDirection: "column",
                              gap: "0.2rem",
                            },
                          }}
                        >
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/jobs")}
                          >
                            Home
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/applications")}
                          >
                            Applications
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/profile")}
                          >
                            Profile
                          </Button>
                          <Button
                            color="inherit"
                            onClick={() => handleClick("/logout")}
                          >
                            Logout
                          </Button>
                        </Typography>
                      )
                    ) : (
                      <>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                            "@media (max-width:768px)": {
                              fontSize: "1rem",
                              flexDirection: "column",
                              gap: "0.3rem",
                            },
                          }}
                        >
                          <Typography
                            variant="h6"
                            component={Link}
                            to="/"
                            color="textPrimary"
                            sx={{
                              fontSize: "1.2rem",
                              textDecoration: "none",
                              "&:hover": { borderBottom: "1px solid #03265B" },
                              "@media (max-width:768px)": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            Home
                          </Typography>
                          <Typography
                            variant="h6"
                            component={Link}
                            to="/HireEmployee"
                            color="textPrimary"
                            sx={{
                              fontSize: "1.2rem",
                              textDecoration: "none",
                              "&:hover": { borderBottom: "1px solid #03265B" },
                              "@media (max-width:768px)": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            Hire Employee
                          </Typography>
                          <Typography
                            variant="h6"
                            component={Link}
                            to="/GetRemoteJob"
                            color="textPrimary"
                            sx={{
                              fontSize: "1.2rem",
                              textDecoration: "none",
                              "&:hover": { borderBottom: "1px solid #03265B" },
                              "@media (max-width:768px)": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            Get Remote Job
                          </Typography>
                          <Typography
                            variant="h6"
                            component={Link}
                            to="/talk-to-expert"
                            color="textPrimary"
                            sx={{
                              fontSize: "1.2rem",
                              textDecoration: "none",
                              cursor: "pointer",
                              "@media (max-width:768px)": {
                                fontSize: "1rem",
                              },
                              border: "2px solid #000b20",
                              p: "0 3px",
                              borderRadius: "5px",
                            }}
                          >
                            Talk To An Expert
                          </Typography>

                          <div>
                            {/* <Button
          id="demo-customized-button"
          aria-controls={
            open ? "demo-customized-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          sx={{
            backgroundColor: "#03265B",
            "&:hover": {
              backgroundColor: lighten("#03265B", 0.85),
              color: "black",
              "@media (max-width:768px)": {
                fontSize: "1rem",
              },
            },
          }}
          disableElevation
          onClick={handleMenu}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Login In
        </Button> */}

                            <NavLink
                              to={"/Employersignup"}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#03265B",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: lighten("#03265B", 0.85),
                                    color: "black",
                                    "@media (max-width:768px)": {
                                      fontSize: "1rem",
                                    },
                                  },
                                }}
                              >
                                Hire Now
                              </Button>
                            </NavLink>
                            <NavLink
                              to={"/Employeesignup"}
                              style={{
                                textDecoration: "none",
                                color: "white",
                                marginLeft: "1rem",
                              }}
                            >
                              {" "}
                              <Button
                                sx={{
                                  backgroundColor: "#03265B",
                                  color: "#fff",
                                  "&:hover": {
                                    backgroundColor: lighten("#03265B", 0.85),
                                    color: "black",
                                    "@media (max-width:768px)": {
                                      fontSize: "1rem",
                                    },
                                  },
                                }}
                              >
                                Apply Now
                              </Button>
                            </NavLink>

                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleSignIn} disableRipple>
                                Login In
                              </MenuItem>
                              <Divider sx={{ my: 0.5 }} />
                              <MenuItem
                                onClick={handleEmployeeSignUp}
                                disableRipple
                              >
                                Employee Sign Up
                              </MenuItem>
                              <MenuItem
                                onClick={handleEmployerSignUp}
                                disableRipple
                              >
                                Employer Sign Up
                              </MenuItem>
                            </StyledMenu>
                          </div>
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isOpen === false ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "7rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Hamburger toggled={isOpen} toggle={setOpen} />
                          </div>
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="logo"
                          >
                            <Logo />
                          </IconButton>
                        </div>
                      </>
                    ) : (
                      <>
                        {isAuth() ? (
                          userType() === "recruiter" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "5rem",
                              }}
                            >
                              <div
                                style={{
                                  justifyItems: "flex-end",
                                  display: "block",
                                }}
                              >
                                <Hamburger
                                  toggled={isOpen}
                                  toggle={setOpen}
                                  style={{ display: "block" }}
                                />
                              </div>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2rem",
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                    flexDirection: "column",
                                    gap: "0.2rem",
                                  },
                                }}
                              >
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/addjob")}
                                >
                                  Add Jobs
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/myjobs")}
                                >
                                  My Jobs
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/employees")}
                                >
                                  Employees
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/profile")}
                                >
                                  Profile
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/logout")}
                                >
                                  Logout
                                </Button>
                              </Typography>
                            </div>
                          ) : userType() === "owner" ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "5rem",
                              }}
                            >
                              <div
                                style={{
                                  justifyItems: "flex-end",
                                  display: "block",
                                }}
                              >
                                <Hamburger
                                  toggled={isOpen}
                                  toggle={setOpen}
                                  style={{ display: "block" }}
                                />
                              </div>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2rem",
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                    flexDirection: "column",
                                    gap: "0.2rem",
                                  },
                                }}
                              >
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/recruiters")}
                                >
                                  Recruiters
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/applicants")}
                                >
                                  Applicants
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/allJobs")}
                                >
                                  Jobs
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/logout")}
                                >
                                  Logout
                                </Button>
                              </Typography>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "5rem",
                              }}
                            >
                              <div
                                style={{
                                  justifyItems: "flex-end",
                                  display: "block",
                                }}
                              >
                                <Hamburger
                                  toggled={isOpen}
                                  toggle={setOpen}
                                  style={{ display: "block" }}
                                />
                              </div>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2rem",
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                    flexDirection: "column",
                                    gap: "0.2rem",
                                  },
                                }}
                              >
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/jobs")}
                                >
                                  Home
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/applications")}
                                >
                                  Applications
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/profile")}
                                >
                                  Profile
                                </Button>
                                <Button
                                  color="inherit"
                                  onClick={() => handleClick("/logout")}
                                >
                                  Logout
                                </Button>
                              </Typography>
                            </div>
                          )
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "5rem",
                            }}
                          >
                            <div
                              style={{
                                justifyItems: "flex-end",
                                display: "block",
                              }}
                            >
                              <Hamburger
                                toggled={isOpen}
                                toggle={setOpen}
                                style={{ display: "block" }}
                              />
                            </div>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2rem",
                                "@media (max-width:768px)": {
                                  fontSize: "1rem",
                                  flexDirection: "column",
                                  gap: "0.3rem",
                                },
                              }}
                            >
                              {/* <div style={{display:"flex",justifyContent:"flex-end"}}><Hamburger toggled={isOpen} toggle={setOpen}/></div> */}
                              <Typography
                                variant="h6"
                                component={Link}
                                to="/"
                                color="textPrimary"
                                sx={{
                                  fontSize: "1.2rem",
                                  textDecoration: "none",
                                  "&:hover": {
                                    borderBottom: "1px solid #03265B",
                                  },
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                  },
                                }}
                              >
                                Home
                              </Typography>
                              <Typography
                                variant="h6"
                                component={Link}
                                to="/HireEmployee"
                                color="textPrimary"
                                sx={{
                                  fontSize: "1.2rem",
                                  textDecoration: "none",
                                  "&:hover": {
                                    borderBottom: "1px solid #03265B",
                                  },
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                  },
                                }}
                              >
                                Hire Employee
                              </Typography>
                              <Typography
                                variant="h6"
                                component={Link}
                                to="/GetRemoteJob"
                                color="textPrimary"
                                sx={{
                                  fontSize: "1.2rem",
                                  textDecoration: "none",
                                  "&:hover": {
                                    borderBottom: "1px solid #03265B",
                                  },
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                  },
                                }}
                              >
                                Get Remote Job
                              </Typography>
                              <Typography
                                variant="h6"
                                component={Link}
                                to="/talk-to-expert"
                                color="textPrimary"
                                sx={{
                                  fontSize: "1.2rem",
                                  textDecoration: "none",
                                  cursor: "pointer",
                                  "&:hover": {
                                    borderBottom: "1px solid #03265B",
                                  },
                                  "@media (max-width:768px)": {
                                    fontSize: "1rem",
                                  },
                                  border: "2px solid #000b20",
                                  p: "0 3px",
                                  borderRadius: "5px",
                                }}
                              >
                                Talk To An Expert
                              </Typography>

                              <div>
                                <Button
                                  id="demo-customized-button"
                                  aria-controls={
                                    open ? "demo-customized-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#03265B",
                                    "&:hover": {
                                      backgroundColor: lighten("#03265B", 0.85),
                                      color: "black",
                                      "@media (max-width:768px)": {
                                        fontSize: "1rem",
                                      },
                                    },
                                  }}
                                  disableElevation
                                  onClick={handleMenu}
                                  endIcon={<KeyboardArrowDownIcon />}
                                >
                                  Login In
                                </Button>
                                <StyledMenu
                                  id="demo-customized-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "demo-customized-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  <MenuItem
                                    onClick={handleSignIn}
                                    disableRipple
                                  >
                                    Login In
                                  </MenuItem>
                                  <Divider sx={{ my: 0.5 }} />
                                  <MenuItem
                                    onClick={handleEmployeeSignUp}
                                    disableRipple
                                  >
                                    Employee Sign Up
                                  </MenuItem>
                                  <MenuItem
                                    onClick={handleEmployerSignUp}
                                    disableRipple
                                  >
                                    Employer Sign Up
                                  </MenuItem>
                                </StyledMenu>
                              </div>
                            </Typography>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {/*             
                {



              
                isAuth() ? (
                  userType() === "recruiter" ? (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                        "@media (max-width:768px)": {
                          fontSize: "1rem",
                          flexDirection: "column",
                          gap: "0.2rem",
                        },
                      }}
                    >
                  
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/addjob")}
                      >
                        Add Jobs
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/myjobs")}
                      >
                        My Jobs
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/employees")}
                      >
                        Employees
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/profile")}
                      >
                        Profile
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/logout")}
                      >
                        Logout
                      </Button>
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                        "@media (max-width:768px)": {
                          fontSize: "1rem",
                          flexDirection: "column",
                          gap: "0.2rem",
                        },
                      }}
                    >
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/employeeHome")}
                      >
                        Home
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/applications")}
                      >
                        Applications
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/profile")}
                      >
                        Profile
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => handleClick("/logout")}
                      >
                        Logout
                      </Button>
                    </Typography>
                  )
                ) : (
                  <>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                        "@media (max-width:768px)": {
                          fontSize: "1rem",
                          flexDirection: "column",
                          gap: "0.3rem",
                        },
                      }}
                    >
                     
                      <Typography
                        variant="h6"
                        component={Link}
                        to="/"
                        color="textPrimary"
                        sx={{
                          fontSize: "1.2rem",
                          textDecoration: "none",
                          "&:hover": { borderBottom: "1px solid #03265B" },
                          "@media (max-width:768px)": {
                            fontSize: "1rem",
                          },
                        }}
                      >
                        Home
                      </Typography>
                      <Typography
                        variant="h6"
                        component={Link}
                        to="/HireEmployee"
                        color="textPrimary"
                        sx={{
                          fontSize: "1.2rem",
                          textDecoration: "none",
                          "&:hover": { borderBottom: "1px solid #03265B" },
                          "@media (max-width:768px)": {
                            fontSize: "1rem",
                          },
                        }}
                      >
                        Hire Employee
                      </Typography>
                      <Typography
                        variant="h6"
                        component={Link}
                        to="/GetRemoteJob"
                        color="textPrimary"
                        sx={{
                          fontSize: "1.2rem",
                          textDecoration: "none",
                          "&:hover": { borderBottom: "1px solid #03265B" },
                          "@media (max-width:768px)": {
                            fontSize: "1rem",
                          },
                        }}
                      >
                        Get Remote Job
                      </Typography>
         

                      <div>
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          sx={{
                            backgroundColor: "#03265B",
                            "&:hover": {
                              backgroundColor: lighten("#03265B", 0.85),
                              color: "black",
                              "@media (max-width:768px)": {
                                fontSize: "1rem",
                              },
                            },
                          }}
                          disableElevation
                          onClick={handleMenu}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          Login In
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleSignIn} disableRipple>
                            Login In
                          </MenuItem>
                          <Divider sx={{ my: 0.5 }} />
                          <MenuItem
                            onClick={handleEmployeeSignUp}
                            disableRipple
                          >
                            Employee Sign Up
                          </MenuItem>
                          <MenuItem
                            onClick={handleEmployerSignUp}
                            disableRipple
                          >
                            Employer Sign Up
                          </MenuItem>

                 
                        </StyledMenu>
                      </div>
               
                    </Typography>
                
                  </>
                )} */}
              </Toolbar>
            </Grid>
          </Container>
        </AppBar>
        {/* <HamburgerMenu/> */}
      </ElevationScroll>
    </React.Fragment>
  );
};

export default Navbars;
