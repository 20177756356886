import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import axios from "axios";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import apiList from "pages/authentication/lib/apiList";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import SendIcon from "@mui/icons-material/Send";
import { CloseRounded } from "@mui/icons-material";

const metaData = {
  title: "FeedBack | AMUS HIRING",
  description: "",
  route: "feed-back",
};

const FeedBack = ({ title, handleClose, display }) => {
  const FEEDBACK_TEMPLATE_DATA = {
    name: "",
    email: "",
    phoneNumber: "",
    description: "",
    notify: false,
    policyAgreement: true,
  };
  const [feedBackData, setFeedBackData] = useState({
    ...FEEDBACK_TEMPLATE_DATA,
  });
  const { name, email, phoneNumber, description, notify, policyAgreement } =
    feedBackData;
  const [loader, setLoader] = useState(false);

  const handleSubmit = () => {
    if (!name || !email) {
      toast.warning("Reqiured field missing!", {
        autoClose: 2000,
      });
      return;
    }
    if (phoneNumber.length <= 3) {
      toast.warning("Phone field missing!", {
        autoClose: 2000,
      });
      return;
    }
    if (!policyAgreement) {
      toast.warning("Check the policy agreement", {
        autoClose: 2000,
      });
      return;
    }
    setLoader((pre) => !pre);
    axios
      .post(apiList.feedback, { ...feedBackData })
      .then((res) => {
        const { message } = res.data;
        console.log("message:: ", message);
        setFeedBackData({ ...FEEDBACK_TEMPLATE_DATA }); // Reset once feedback submited!
        toast.success(message, {
          autoClose: 2000,
        });
      })
      .catch((err) => {
        toast.error("Internal Server Error", {
          autoClose: 2000,
        });
      })
      .finally(() => setLoader((pre) => !pre));
  };

  const textInputStyle = {
    "& label": {
      fontSize: "14px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "4px",
    },
    "& input": {
      borderRadius: "4px",
      padding: "12px 12px"
    },
    "& fieldset": {
      borderRadius: "4px",
      "& legend": {
        fontSize: "14px",
      },
    },
  };
  return (
    <>
      <ReactHelmet
        title={metaData.title}
        description={metaData.description}
        route={metaData.route}
        metaGraph={metaData}
      />
      <Box
        sx={{
          minHeight: "80vh",
          width: "100%",
          paddingTop: "6.5rem",
          "@media (max-width:768px)": {
            marginTop: "2rem",
          },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            margin: "0 5px",
            width: "500px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            p: "25px",
            overflow:'auto',
            maxHeight:'80vh'
          }}
        >
          <Stack>
            <Typography
              component={"h1"}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                userSelect: "none",
                m: "0px 0 2px 0px",
                fontSize: {
                  xs: "1.2rem",
                  md: "1.4rem",
                },
                letterSpacing: "0.3px",
                fontWeight: 600,
              }}
            >
              {title}
              <CloseRounded
                sx={{ width: "30px", color: "red", cursor: "pointer", display: {display} }}
                onClick={handleClose}
              />
            </Typography>
            <Typography
              component={"h3"}
              sx={{
                userSelect: "none",
                m: "0px 0 10px 0px",
                fontSize: {
                  xs: "1rem",
                  md: "1.1rem",
                },
                letterSpacing: "0.1px",
              }}
            >
              Fill out the form and we'll get back to you!
            </Typography>
          </Stack>
          <Stack direction={"column"} gap={"16px"}>
            <TextField
              sx={{ ...textInputStyle }}
              helperText=""
              id="demo-helper-text-aligned"
              label="Name"
              value={name}
              required
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setFeedBackData((pre) => ({ ...pre, name: e.target.value }));
              }}
            />
            <TextField
              sx={{ ...textInputStyle }}
              helperText=""
              id="demo-helper-text-aligned"
              label="Email"
              type="email"
              required
              value={email}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setFeedBackData((pre) => ({ ...pre, email: e.target.value }));
              }}
            />
            <PhoneInput
              inputStyle={{ width: "100%", background: "#f8fafc", padding: "12px 12px" }}
              rules={{ required: true }}
              placeholder=""
              value={phoneNumber}
              enableSearch={true}
              onChange={(phone_number) =>
                setFeedBackData((pre) => ({
                  ...pre,
                  phoneNumber: phone_number,
                }))
              }
            />
            <TextField
              sx={{ ...textInputStyle }}
              helperText=""
              id="demo-helper-text-aligned"
              label="Description"
              value={description}
              multiline
              rows={3}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setFeedBackData((pre) => ({
                  ...pre,
                  description: e.target.value,
                }));
              }}
            />
            <Stack>
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Checkbox
                  checked={notify}
                  sx={{
                    "&.Mui-checked": {
                      color: "#083559",
                    },
                  }}
                  onChange={(e) => {
                    setFeedBackData((pre) => ({
                      ...pre,
                      notify: e.target.checked,
                    }));
                  }}
                />
                <Typography>
                  {
                    "I would like to receive notifications about the company, products, services, and events."
                  }
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={2}>
                <Checkbox
                  checked={policyAgreement}
                  sx={{
                    "&.Mui-checked": {
                      color: "#083559",
                    },
                  }}
                  required
                  onChange={(e) => {
                    setFeedBackData((pre) => ({
                      ...pre,
                      policyAgreement: e.target.checked,
                    }));
                  }}
                />
                <Typography>
                  I have read and accept the&nbsp;<a href="">Legal Notice</a>
                  &nbsp; and &nbsp;
                  <a href="">Privacy Policy*</a>
                </Typography>
              </Stack>
            </Stack>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loader}
              sx={{
                bgcolor: "#07367e",
                "&:hover": {
                  bgcolor: "#03265b",
                },
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
            >
              Submit
              <SendIcon sx={{ width: "1rem", m: "0 7px" }} />
              {loader && (
                <CircularProgress
                  sx={{ position: "absolute", color: "#02213a" }}
                  size={18}
                />
              )}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </>
  );
};

export default FeedBack;
