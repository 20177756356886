import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ReactHelmet from "component/react-helmet/ReactHelmet";

const Container = styled.div`
  .main-cookie {
    padding: 5rem;
    @media (max-width: 768px) {
      padding: 5rem 0.5rem;
      width: 100vw;
      font-size: 0.5rem;
    }

    h1 {
      font-size: 3rem;
      @media (max-width: 768px) {
        line-height: 2rem;
        font-size: 2.5rem;
      }
    }
    p {
      color: gray;
      font-size: 1.2rem;
      line-height: 1.5rem;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    ol {
      p {
        p {
          font-size: 1.2rem;
          color: gray;

          line-height: 1.5rem;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }
      }
      tr {
        /* margin-top: 2rem; */
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        th {
          font-size: 1.5rem;
          padding: 2rem;
          text-align: start;
          p {
            font-size: 1.2rem;
            color: gray;
            @media (max-width: 768px) {
              font-size: 1rem;
            }
          }
        }
        td {
          font-size: 1.2rem;
          color: gray;
          padding: 2rem;
          line-height: 2rem;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }
      }
      table {
        margin-top: 3rem;
      }

      table,
      th,
      td {
        border: 0.1rem solid gray;
        border-collapse: collapse;

        li {
          color: gray;
          font-size: 1.2rem;
          line-height: 1rem;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }
      }
      li {
        font-size: 2rem;
        @media (max-width: 768px) {
          line-height: 2rem;
          font-size: 1.5rem;
        }

        li {
          color: gray;
          font-size: 1.2rem;
          line-height: 2rem;
        }
        p {
          color: gray;
          font-size: 1.2rem;
          line-height: 2rem;
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }
      }
      p {
        color: gray;
        font-size: 1.2rem;
        line-height: 2rem;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
        ol {
          li {
            color: gray;
            font-size: 1.2rem;
            line-height: 2rem;
            @media (max-width: 768px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
`;

const Cookie = () => {
  return (
    <>
      <ReactHelmet 
        title={'Cookies Policy | AMUS HIRING'}
        description={''}
        route='cookies-policy'
      />
      <Container>
      <div className="main-cookie">
        <h1>Cookies Policy</h1>

        <p>
          This cookie policy was last updated on 15 June 2022 and applies to
          citizens of the European Economic Area.
        </p>
        <ol type="1">
          <li>Introduction</li>
          <p>
            Our website,
            <NavLink to={"#"} style={{ textDecoration: "none" }}>
              https://www.amushiring.com
            </NavLink>{" "}
            (hereinafter: “the Website”) uses cookies and other related
            technologies (for convenience, all technologies are referred to as
            “cookies”). Cookies are also placed by third parties with whom we
            have contracted. In the following document we inform you about the
            use of cookies on our website.
          </p>
          <li>What Are Cookies?</li>
          <p>
            A cookie is a small simple file that is sent along with the pages of
            this website and stored by your browser on the hard drive of your
            computer or other device. The stored information may be sent back to
            our servers or to relevant third party servers during a subsequent
            visit.
          </p>
          <li> What Are Scripts?</li>
          <p>
            A script is a piece of program code that is used to make our website
            work correctly and interactively. This code runs on our server or on
            your device.
          </p>
          <li>What Is A Web Beacon?</li>
          <p>
            A web beacon (or pixel tag) is a small, invisible piece of text or
            image on a website that is used to track traffic on a website. To do
            this, various data about you is stored using web beacons.
          </p>
          <li>Cookies</li>
          <p>
            <ol type="1" style={{ position: "relative", right: "3.2rem" }}>
              <li>Technical or functional cookies</li>
              <p>
                Some cookies ensure that certain parts of the website work
                properly and that your user preferences are remembered. By
                placing functional cookies, we make it easier for you to visit
                our website. This way, you do not need to repeatedly enter the
                same information when you visit our website and, for example,
                items remain in your shopping basket until you have paid. We may
                place these cookies without your consent
              </p>
              <li>Analytics cookies</li>
              <p>
                We use analytics cookies to optimize the website experience for
                our users. With these analytics cookies we gain insight into the
                usage of our website. We ask your permission to insert
                analytical cookies.
              </p>
              <li> Advertising cookies</li>
              <p>
                We use advertising cookies on this website, which allow us to
                personalise advertisements for you, and we (and third parties)
                obtain information about campaign results. This happens on the
                basis of a profile we create of your clicks and browsing on and
                off https://www.amussoft.com. With these cookies you, as a
                visitor to the website, are linked to a unique ID, so you will
                not see the same ad more than once, for example. Because these
                cookies are marked as tracking cookies, we ask for your
                permission to place them
              </p>
              <li>Social media buttons</li>
              <p>
                We have included social media buttons on our website to promote
                pages (e.g. “like”, “pin”) or share them (e.g. “tweet”) on
                social networks such as Facebook, Instagram, Linkedin, Twitter,
                etc. These buttons work using the code of the social networks
                themselves. This code embeds cookies. These social media buttons
                can store and process certain information, so that a
                personalized advertisement can be displayed. Please read the
                privacy policy of these social networks (which may change
                frequently) to find out what they do with your (personal) data
                that they process using these cookies. The data they receive is
                anonymised as much as possible. Facebook is located in the
                United States
              </p>
            </ol>
          </p>

          <li> Cookies Used</li>

          <table style={{ width: "100%", position: "relative", right: "1rem" }}>
            <tr>
              <th>
                Cookies Name
                <p>Verizon</p>
              </th>
              <th>
                Description/Purpose
                <p>
                  To collect demographic and interest data, as well as device
                  type, carrier, city and state to provide relevant messaging to
                  users.
                </p>
              </th>
              <th>
                Expiry & Type
                <p>
                  3 months, 13 months, 30 days, 30 days, 5 years, 13 months
                  respectively 1st Party
                </p>
              </th>
            </tr>
            <tr>
              <td>Adwords Conversion Tag</td>
              <td>
                To track actions completed on the website such as Purchases, App
                Installs, Sign-Ups.
              </td>
              <td>
                The cookie duration can be set anywhere between 7 and 90 days
                3rd Party
              </td>
            </tr>
            <tr>
              <td>DoubleClick (now known as Google Marketing Platform)</td>
              <td>
                DoubleClick (now known as Google Marketing Platform) is a tool
                used by advertisers to run display, paid search and other media
                in order to drive consumer engagement and action. It also shows
                users more relevant content based on what is relevant to
              </td>
              <td>2 years 3rd Party</td>
            </tr>
          </table>

          <li style={{ marginTop: "2rem" }}> Consent</li>
          <p>
            When you visit our website for the first time, we will show you a
            pop-up window with an explanation about cookies. As soon as you
            click “Save preferences”, you agree to our use of the categories of
            cookies and plugins you have selected in the pop-up window, as
            described in this cookie policy. You can disable the use of cookies
            through your browser, but please note that our website may no longer
            function properly
          </p>
          <li>Your Rights With Respect To Personal Data</li>
          <p>
            <p>
              You have the following rights with respect to your personal data:
            </p>
            <p>
              You have the right to know why your personal data is needed, what
              will happen to it and how long it will be kept. Right of access:
              You have the right to access the personal data we hold about you.
              Right of rectification: You have the right to supplement, rectify,
              erase or block your personal data at any time. If you give us your
              consent to process your data, you have the right to revoke this
              consent and to have your personal data deleted. Right to transfer
              your data: you have the right to request all your personal data
              from the controller and to transfer it in full to another
              controller
            </p>
            <p>
              Right to object: You can object to the processing of your data. We
              comply with this, unless there are justified grounds for the
              processing. To exercise these rights, please contact us. Please
              see the contact details at the bottom of this cookie policy. If
              you have any complaints about how we handle your data, we would
              like to hear from you, but you also have the right to submit a
              complaint to the supervisory authority (the data protection
              authority).
            </p>
          </p>
          <li>Enabling, Disabling And Deleting Cookies</li>
          <p>
            <p>
              You can use your Internet browser to delete cookies automatically
              or manually. You can also specify that certain cookies cannot be
              placed. Alternatively, you can change your Internet browser
              settings so that you receive a message each time a cookie is
              placed. For more information about these options, see the
              instructions in the “Help” section of your browser.
            </p>
            <p>
              You can accept, block or delete cookies installed on your computer
              by configuring your browser options, but please note that part of
              the site will not work properly or that some of its
              functionalities will be unavailable or have errors.
            </p>
            <p>
              The following links provide instructions on how to enable or
              disable cookies in the most common browsers.
            </p>
            <p>Firefox</p>
            <p>Google Chrome</p>
            <p>Internet Explorer</p>
            <p>Microsoft Edge</p>
            <p>Safari</p>
            <p>
              Please note that our website may not function properly if all
              cookies are disabled. If you delete cookies from your browser,
              they will be placed again after your consent when you revisit our
              websites.
            </p>
          </p>
          <li>Contact Details</li>
          <p>
            <p>
              For questions and/or comments about our cookie policy and this
              statement, please contact us using the contact details below:
            </p>
            <p>AMUS SOFTWARE S.L.</p>
            <p>Vilamari, 51, bajos-1, 08015, Barcelona</p>
            <p>Spain</p>
            <p>
              Website:{" "}
              <NavLink style={{ textDecoration: "none" }} to={"#"}>
                {" "}
                https://www.amushiring.com
              </NavLink>
            </p>
            <p>Email: hola@amussoft.com</p>
          </p>
        </ol>
      </div>
    </Container>
    </>
  );
};

export default Cookie;
