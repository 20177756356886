import { style } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const Container=styled.div`
margin-top: 10rem;

h1{
    text-align: center;
    @media (max-width: 768px) {
   
   font-size: 1.3rem;

 }
}
h2{
    font-size: 3rem;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 15rem;
    @media (max-width: 768px) {
   
   font-size: 1.5rem;

 }
}
.boxes{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  font-size: 1.5rem;
  color: gray;
  @media (max-width: 768px) {
   
   font-size: 1rem;

 }
}

`;



const Error = () => {
  return (
    <Container>
    <h1 >Page Not Found</h1>

    <div className="boxes">
<div className="home-link">
<Link to={"/"} style={{color:"gray",}}>Home</Link>
</div>
<div className="error">
/error
</div>

    </div>
    <h2>Oops! That Page Can’t Be Found.</h2>
    </Container>
  )
}

export default Error;