import React from "react";
import { Helmet } from "react-helmet-async";
import jobPortal from "../../asset/images/jobPortal.jpg"

const ReactHelmet = ({
  title,
  description,
  route,
  metaGraph={},
}) => {
  const {title: ogTitle='', description: ogDescription='', image: ogImage=jobPortal} = metaGraph;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://amushiring.com/${route}`} />
      { (!!ogTitle) && <meta property="og:type" content="website" /> }
      { (!!ogTitle) && <meta property="og:title" content={ogTitle} /> }
      { (!!ogDescription) && <meta property="og:description" content={ogDescription} /> }
      { (!!ogImage) && <meta property="og:image" content={ogImage} /> }
      { (!!ogDescription) && <meta property="og:url" content={`https://amushiring.com/${route}`} /> }

      { !!(ogTitle) && <meta name="twitter:card" content="summary" /> }
      { !!(ogTitle) && <meta name="twitter:site" content="amushiring" />}
      { !!(ogTitle) && <meta name="twitter:title" content={ogTitle} /> }
      { !!(ogDescription) && <meta name="twitter:description" content={ogDescription}/> }
      { !!(ogImage) && <meta name="twitter:image" content={ogImage} /> }
      { !!(ogDescription) && <meta name="twitter:domain" content={`amushiring.com/${route}`} /> }
    </Helmet>
  );
};

export default ReactHelmet;
