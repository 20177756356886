import React from "react";
import {
  Modal,
  Button,
  Box,
  Typography,
  Rating,
  Divider,
  Slide,
  CircularProgress
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import DynamicButtons from "./DynamicButtons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const NewSingleMyJobModal = ({
  openSingleMyJobModal,
  setOpenSingleMyJobModal,
  singleMyJob,
  setOpen,
  setMyJobId,
  setUpdateModalOpen,
  allApplicants,
  refetch,
  loading,
  openChatByUserId,
  setOpenCloseModal,
  FetchApplicants,
  setAllApplicants,
  setAllMyJobs,
  setOpenApplicant,
  setApplicantData
}) => {
  return (
    <Modal
      open={openSingleMyJobModal}
      onClose={() => setOpenSingleMyJobModal(false)}
      sx={{ "@media(min-width:600px)": { display: "none" }, p: 1 , zIndex:5 }}
    >
      <Slide
        direction="up"
        in={openSingleMyJobModal}
        mountOnEnter
        unmountOnExit
        transitionDuration={500}
      >
        <Box sx={{ display: "flex", justifyContent: "end", mt: 10 }}>
          <Box
            sx={{
              bgcolor: "#f9f9f9",
              borderRadius: 5,
              maxHeight: "90vh",
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              width: "100%",
              textAlign: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                minHeight: "90vh",
                overflow: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                background: "#fff",
                p: 2,
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h3"
                  sx={{ display: "flex", alignItems: "center", gap: 2 }}
                >
                  <KeyboardBackspaceIcon
                    cursor="pointer"
                    onClick={() => setOpenSingleMyJobModal(false)}
                  />{" "}
                  {singleMyJob?.title}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#284774",
                    marginTop: 1,
                    ":hover": { background: "#284774" },
                    borderRadius: 3,
                  }}
                  onClick={() => {
                    // setMyJobId(singleMyJob?.id);
                    setUpdateModalOpen(true);
                  }}
                >
                  Update Details
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#284774",
                    marginTop: 1,
                    ":hover": { background: "#284774" },
                    borderRadius: 3,
                  }}
                  onClick={() => {
                    setOpenCloseModal(true);
                  }}
                >
                  {singleMyJob.status === "Close"
                    ? "Open Job"
                    : "Close Job"}
                </Button>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ mt: 1 }}
              >
                <Typography>Job Type : {singleMyJob?.jobType}</Typography>
                <Rating value={Number(singleMyJob?.rating)} readOnly />
              </Box>
              <Typography textAlign={"start"}>
                Job Required Experience : {singleMyJob?.experience}
              </Typography>
              <Typography textAlign={"start"}>
              {
                singleMyJob?.workplaceType && 
                <Typography variant="body2" component='p' textAlign='left'>Workplace Type : {singleMyJob?.workplaceType}</Typography>
              }
              {
                ((singleMyJob?.workplaceType==="Remote") && singleMyJob?.preferredCountry) && 
                <Typography variant="body2" component='p' textAlign='left'>Preferred Employee Location Country: {singleMyJob?.preferredCountry}</Typography>
              }
              {
                ((singleMyJob?.workplaceType==="On-Site" || singleMyJob?.workplaceType==="Hybrid") && singleMyJob?.jobLocationCity) && 
                <Typography variant="body2" component='p' textAlign='left'>Job Location City : {singleMyJob?.jobLocationCity}</Typography>
              }
              {
                ((singleMyJob?.workplaceType==="On-Site" || singleMyJob?.workplaceType==="Hybrid") && singleMyJob?.jobLocationCountry) && 
                <Typography variant="body2" component='p' textAlign='left'>Job Location Country : {singleMyJob?.jobLocationCountry}</Typography>
              }
                Duration :{" "}
                {singleMyJob?.duration === "" ? "Flexible" : singleMyJob?.duration}
              </Typography>
              <Typography textAlign={"start"}>
                Date of Posting : {singleMyJob?.dateOfPosting?.split("T")[0]}
              </Typography>
              <Typography textAlign={"start"}>
                      Approval Status :{" "}
                      {!singleMyJob.isApproved && !singleMyJob.isReviewed && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "10px",
                            padding: "2px 0",
                            width: "fit-content",
                            height: "fit-content",
                            borderRadius: "10px",
                            backgroundColor: "#ffa500",
                          }}
                        >
                          Pending
                        </Button>
                      )}
                      {singleMyJob.isApproved && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "10px",
                            padding: "2px 0",
                            width: "fit-content",
                            height: "fit-content",
                            borderRadius: "10px",
                            bgcolor: "green",
                          }}
                        >
                          Approved
                        </Button>
                      )}
                      {!singleMyJob.isApproved && singleMyJob.isReviewed && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "10px",
                            padding: "2px 0",
                            width: "fit-content",
                            height: "fit-content",
                            borderRadius: "10px",
                            bgcolor: "red",
                          }}
                        >
                          Rejected
                        </Button>
                      )}
                    </Typography>
              <Box textAlign={"start"} sx={{ mt: 1 }}>
                <Typography variant="span">Skills :</Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {singleMyJob?.skillsets?.map((e) => (
                    <Typography
                      variant="span"
                      sx={{
                        background: "#EDEDED",
                        color: "#000",
                        mr: 1,
                        mt: 1,
                        fontSize: "12px",
                        borderRadius: 4,
                        p: 1,
                      }}
                    >
                      {e}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Divider sx={{ mt: 3 }} />
              <Box sx={{ mt: 1 }}>
                <Typography variant="h4" textAlign={"start"} sx={{ mb: 1 }}>
                  About the job
                </Typography>
                <Typography textAlign={"start"}>Job Description :</Typography>
                <Typography textAlign={"start"}>
                  {singleMyJob?.description}
                </Typography>
                <Box sx={{ mt: 1 }}>
                      <Typography
                        textAlign={"start"}
                        sx={{ fontWeight: "700" }}
                      >
                        Job Applications :
                      </Typography>
                      {allApplicants.length ? allApplicants?.map((app, i) => {
                        return (
                          <Box
                            sx={{
                              mt: 1,
                              p: "20px 20px",
                              border: "1px solid lightgray",
                              borderRadius: 8,
                              marginBottom: 2,
                              cursor: "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography variant="h4" textAlign={"start"}>
                                {app.jobApplicant.name}
                              </Typography>

                              <Rating
                                value={Number(app.jobApplicant.rating)}
                                readOnly
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                                Profile :{" "}
                                {app.jobApplicant?.Profilerole}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                              Job Type: {app.jobApplicant.Role}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                              Experience: {app.jobApplicant.experience}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                              Notice Period: {app.jobApplicant.noticeperiod}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "0.5rem",
                              }}
                            >
                              <Typography textAlign={"start"}>
                                Education:{" "}
                                {app.jobApplicant.Education
                                  .map((edu) => {
                                    return `${edu.institutionName} (start Year:${edu.startYear})(end Year:${edu.endYear ? edu.endYear : "Ongoing"
                                      })`;
                                  })
                                  .join(", ")}
                              </Typography>
                            </Box>
                            <Typography textAlign={"start"}>
                                Date of Application : {app.dateOfApplication?.split("T")[0]}
                              </Typography>
                              <Typography textAlign={"start"}>
                                Date of Joining : {app.dateOfJoining?.split("T")[0]}
                              </Typography>
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                             <DynamicButtons app={app} refetch={refetch} FetchApplicants={FetchApplicants} setAllApplicants={setAllApplicants}/>
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: "#284774",
                                  marginTop: 1,
                                  m: 1,
                                  ":hover": { background: "#284774" },
                                  borderRadius: 3,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openChatByUserId(app.jobApplicant.userId)
                                }}
                              >
                                Chat
                                <SendIcon sx={{ ml: '10px' }} />
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  bgcolor: "#CC5500",
                                  marginTop: 1,
                                  m: 1,
                                  ":hover": { background: "#CC5500" },
                                  borderRadius: 3,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenApplicant(true);
                                  setApplicantData(app);
                                }}
                              >
                                View Applicant
                                <ViewModuleIcon sx={{ ml: '10px' }} />
                              </Button>
                            </Box>
                          </Box>
                        );
                      }) :  <Box sx={{height:'100px' , display:'flex' , alignItems:'center' , justifyContent:'center'}}>{loading ? <CircularProgress/> : "No Application Found"}</Box>}
                    </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default NewSingleMyJobModal;
