import { Modal, Box, Typography, FormControlLabel, Checkbox, CircularProgress, Stack, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails, Button, Container, Chip, Grid, TextField, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import apiList from "pages/authentication/lib/apiList";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SetPopupContext } from "routes";

export default function ScreeningQuestionModal({
    open, 
    setOpen, 
    screeningQuestions, 
    setScreeningQuestions, 
    jobDetails,
    screeningAdded,
    setScreeningAdded,
    handleUpdate
    }){
    const setPopup = useContext(SetPopupContext);
    const handleClose = ()=> setOpen(false);
    const [allQuestions, setAllQuestions] = useState({});
    const [questionSet, setQuestionSet] = useState(new Set());
    const [addCustom, setAddCustom] = useState(false);
    const [customCount, setCustomCount] = useState(1);
    const [customQuestion, setCustomQuestion] = useState({
        id: 0,
        question: "",
        answerType: ""
    });
    useEffect(()=>{
        setCustomQuestion({
            ...customQuestion,
            id: allQuestions?.questions?.length + customCount
        })
    }, [screeningQuestions, allQuestions, customCount])
    const [error, setError] = useState(false);
    const handleQuestionAdd = useCallback((e) => {
        // if(screeningQuestions?.questions?.length>5){
        //     return;
        // }
        setQuestionSet(prevSet => {
            const newSet = new Set(prevSet);
            if (e.target.checked) {
                newSet.add(e.target.id);
            } else {
                newSet.delete(e.target.id);
            }
            return newSet;
        });
    }, []);

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    useEffect(()=>{
        // console.log(questionSet);
        let tempArr = screeningQuestions?.questions?.filter(q=>(!questionSet.has(String(q.id)) && q.id>allQuestions?.questions?.length) ?? q) ?? [];
        if(questionSet.size>0){
            for(const i of questionSet){
                allQuestions.questions.map(elem=>{
                    if(elem.id===Number(i)){
                        tempArr.push(elem);
                    }
                })
            }
            setScreeningQuestions(prev=>({
                questions: tempArr
            }));
            // console.log(screeningQuestions);
        }else{
            setScreeningQuestions({
                questions: tempArr
            });
        }
    }, [questionSet])

    const getScreeningQestions = async()=>{
        return await axios.get(`${apiList.screening}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    }
    
    const {data, isLoading, isFetching} = useQuery(['get-question-list', open, screeningQuestions], getScreeningQestions, {
        onSuccess: data=>{
            setAllQuestions(data?.data?.questionList.screeningQuestions);
            setScreeningAdded(true);
        },
        onError: err=>{
            console.log(err);
        },
        enabled: Object.keys(allQuestions).length===0
    })

    const handleDeleteQuestion = (e)=>{
        setScreeningQuestions(prev=>({
            questions: prev.questions.filter(q=>q.id !== Number(e.currentTarget.name))
        }))
        questionSet.delete(String(e.currentTarget.name));
    }

    useEffect(()=>{
        if(screeningQuestions?.questions?.length>5){
          setScreeningAdded(false);
        }else{
            setScreeningAdded(true);
        }
    }, [screeningQuestions]);
      
    const addCustomQuestion = async()=>{
        if(!customQuestion.question || !customQuestion.answerType){
            setError(true);
            return;
        }
        if(!screeningAdded){
            return;
        }
        const tempArr = screeningQuestions?.questions;
        tempArr.push(customQuestion);
        await new Promise((resolve)=>{
            setScreeningQuestions(prev=>({
                ...prev,
                questions: tempArr
            }))
            resolve();
        })
        setCustomQuestion({
            ...customQuestion,
            question: "",
            answerType: ""
        })
        setCustomCount(prev=>prev+1);
    }

    useEffect(()=>{
        if(!allQuestions?.questions?.length){
            return;
        }
        if(!screeningAdded){
            setPopup({
                open: true,
                severity: "error",
                message: "Screening questions cannot be more than five",
            });
        }
    }, [screeningAdded])

    const handleCreateJob = ()=>{
        if(!screeningAdded){
            setPopup({
                open: true,
                severity: "error",
                message: "Screening questions cannot be more than five",
            });
            return;
        }
        handleUpdate();
    }

    return (
        // <Modal
        // open={open}
        // onClose={handleClose}
        // >
            <Box
            sx={{
            width:'100%',
            height: '100%',
            bgcolor:'transparent',
            // boxShadow: 24,
            p:2,
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            WebkitScrollbarWidth: 'none',
            }}
            >
                <Typography variant="h1" component='h2' textAlign='left'>
                    Add screening Questions
                </Typography>
                <Divider sx={{height:'2px'}}/>
                <Accordion
                >
                    <AccordionSummary
                    expandIcon={<ExpandCircleDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{width:'max-content'}}
                    >
                        <Typography variant="h4" component='p'>View Job details</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                    sx={{width:'100%', border:'1px solid #3f51b5', borderRadius:'20px', margin:'0 auto'}}
                    >
                        <Box display='flex'
                        sx={{flexWrap: 'wrap', gap:'10px'}}
                        >
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Job Title:</strong></span> &nbsp;{jobDetails.title}</Typography>
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Job Required Experience:</strong></span> &nbsp;{jobDetails.experience}&nbsp;{jobDetails.experience>1? "years" : "year"}</Typography>
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Workplace Type:</strong></span> &nbsp;{jobDetails.workplaceType}</Typography>
                            {jobDetails.workplaceType==="Remote" && <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Preferred Employee Location Country:</strong></span> &nbsp;{jobDetails.preferredCountry}</Typography>}
                            {jobDetails.workplaceType!=="Remote" && <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Job Location City:</strong></span> &nbsp;{jobDetails.jobLocationCity}</Typography>}
                            {jobDetails.workplaceType!=="Remote" && <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Job Location Country:</strong></span> &nbsp;{jobDetails.jobLocationCountry}</Typography>}
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Duration:</strong></span> &nbsp;{jobDetails.duration}</Typography>
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Maximum Positions:</strong></span> &nbsp;{jobDetails.maxPositions}</Typography>
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Deadline:</strong></span> &nbsp;{jobDetails.deadline}</Typography>
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Salary:</strong></span> &nbsp;{jobDetails.salary}USD($)</Typography>
                            <Typography variant="p" component='p'><span style={{color:'green'}}><strong>Description:</strong></span> &nbsp;{jobDetails.description}</Typography>
                            <Typography variant='p' component='p'>
                                <strong><span style={{color:'green'}}>Required Skills:</span></strong> 
                                <Box spacing={1} display='flex' sx={{flexWrap:'wrap', gap:"5px"}}>
                                {
                                    jobDetails.skillsets.map(skill=><Chip label={skill}/>)
                                }
                                </Box>
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {isFetching ? 
                <Stack display='flex' sx={{height:'100%', justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress size={50} />
                </Stack>
                :
                <Grid
                container
                item
                direction="row"
                alignItems="baseline"
                sx={{
                  padding: "30px",
                  minHeight: "93vh",
                  "@media(max-width:768px)": {
                    width:"100%",
                    flexDirection:"column"
                  },
                }}
                >
                    <Grid 
                    item
                    sx={{
                        width:'60%',
                        "@media(max-width:768px)":{
                            width:'100%'
                        }
                    }}
                    >
                    {
                        allQuestions?.questions?.map((elem, idx)=>{
                            return (
                                <Box display='flex'
                                sx={{justifyContent: 'space-between', alignItems:'baseline', p:2}}
                                >
                                    <Typography variant="p" component='h3' sx={{p:1}}>
                                    <span>Q{idx+1}.&nbsp;</span>
                                    {elem.question}
                                    </Typography>
                                    <Typography>
                                        <FormControlLabel 
                                        control={<Checkbox id={elem.id} onChange={handleQuestionAdd}
                                        checked={questionSet.has(String(elem.id))} 
                                        />}>
                                        </FormControlLabel>
                                    </Typography>
                                </Box>
                            )
                        })
                    }
                    </Grid>
                    <Grid 
                    item
                    sx={{
                        width:'40%',
                        "@media(max-width:768px)":{
                            width:'100%'
                        }
                    }}
                    >
                        <Typography variant="p"component='h3' id="custom-add">List of Selected Questions</Typography>
                        <Divider sx={{height:'2px'}}/>
                        {Object.keys(screeningQuestions).length ? 
                            <Box
                            sx={{width:'100%'}}
                            >
                                <Box display="flex"
                                sx={{justifyContent:'space-between', alignItems:'baseline', gap:'20px 0', pr:'2', width:'90%'}}
                                >
                                    <Typography variant="p" component='h3'>Questions</Typography>
                                    <Typography variant="p" component='h3'>Answer type</Typography>
                                </Box>
                            {
                                screeningQuestions?.questions.map(q=>{
                                    return (
                                        <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems='center'
                                        >
                                        <Grid 
                                        item
                                        sx={{width:'90%'}}
                                        >
                                            <Box display="flex"
                                            sx={{justifyContent:'space-between', alignItems:'baseline', mt:2, pr:'2'}}
                                            >
                                                <Typography variant="p" component="p">{q.question}</Typography>
                                                <Typography variant="p" component='h4'>{q.answerType==="boolean" ? "Yes/No" : "Numeric"}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item
                                        sx={{width:'10%', mt:2, pl:'20px'}}>
                                            <IconButton 
                                            name={q.id}
                                            onClick={handleDeleteQuestion}
                                            >
                                                <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20px"
                                                height="20px"
                                                fill="none"
                                                stroke="#a00"
                                                viewBox="0 0 24 24"
                                                >
                                                <g
                                                    stroke="#a00"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                >
                                                    <path d="M10 11v6M14 11v6M4 7h16M6 7h12v11a3 3 0 01-3 3H9a3 3 0 01-3-3V7zM9 5a2 2 0 012-2h2a2 2 0 012 2v2H9V5z"></path>
                                                </g>
                                                </svg>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    )
                                })
                            }
                            </Box>
                            :
                            null
                        }
                        {
                            addCustom &&
                            <Box
                            sx={{p:2, mt:3}}
                            >
                                <TextField
                                label="Enter you question"
                                type="text"
                                variant="outlined"
                                multiline
                                rows={5}
                                fullWidth
                                value={customQuestion.question}
                                onChange={(e)=>setCustomQuestion({
                                    ...customQuestion,
                                    question: e.target.value
                                })}
                                onFocus={()=>setError(false)}
                                helperText={
                                    error && !customQuestion.question && <p style={{color:'red'}}>Requied field*</p>
                                }
                                />
                                <Box display='flex'
                                sx={{justifyContent:'space-between', alignItems:'center', mt:2}}
                                >
                                    <Typography variant="p" component='h3'>Select answer type</Typography>
                                    <TextField
                                    select
                                    sx={{width:'30%'}}
                                    value={customQuestion.answerType}
                                    onChange={(e)=>setCustomQuestion({
                                        ...customQuestion,
                                        answerType: e.target.value
                                    })}
                                    onFocus={()=>setError(false)}
                                    >
                                    <MenuItem value="boolean">Yes/No</MenuItem>
                                    <MenuItem value="numeric">Numeric</MenuItem>
                                    </TextField>
                                </Box>
                                {error && !customQuestion.answerType && <p style={{color:'red'}}>Requied field*</p>}
                                <Box
                                sx={{pt:2}}
                                >
                                    <Button
                                    variant="outlined"
                                    sx={{float:'right', color:'primary', border:'1px solid primary', '&:hover':{border:'1px solid primary'}}}
                                    onClick={addCustomQuestion}
                                    >Add</Button>
                                    <Button
                                    variant="outlined"
                                    sx={{float:'right', mr:'20px', color:'#a00', border:'1px solid #a00', '&:hover':{border:'1px solid #a00'}}}
                                    onClick={()=>setAddCustom(false)}
                                    >Cancel</Button>
                                </Box>
                            </Box>
                        }
                    </Grid>
                </Grid>

                }
                <Container
                sx={{
                    p:'0 50px 50px 0',
                    "@media(max-width:768px)":{
                       p:0
                    }
                }}
                >
                    <Button
                    variant="contained"
                    sx={{
                        float:'right',
                        bgcolor: "#284774",
                        "&:hover": { background: "#284774", boxShadow:10 },
                        borderRadius: 3,
                        "@media(max-width:768px)":{
                            display:'flex',
                            margin:'0 auto',
                            width:'100%',
                            float:'none'
                        }
                    }}
                    endIcon={screeningAdded ? <CheckCircleIcon /> : null}
                    onClick={handleCreateJob}
                    >Create Job</Button>
                    <Button
                    variant="contained"
                    sx={{
                        float:'right', mr:'20px',
                        bgcolor: "green",
                        "&:hover": { background: "green", boxShadow:10 },
                        borderRadius: 3,
                        "@media(max-width:768px)":{
                            display:'block',
                            margin:'20px auto',
                            width:'100%',
                            float:'none'
                        }
                    }}
                    onClick={()=>{
                        setAddCustom(true);
                        const element = document.getElementById("custom-add");
                        if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}
                    >Add Custom Question</Button>
                    <Button
                    variant="contained"
                    sx={{
                        float:'right', mr:'20px',
                        bgcolor: "#a00",
                        "&:hover": { background: "#a00", boxShadow:10 },
                        borderRadius: 3,
                        "@media(max-width:768px)":{
                            display:'block',
                            margin:'0 auto',
                            width:'100%',
                            float:'none'
                        }
                    }}
                    onClick={handleClose}
                    >Back</Button>
                </Container>
            </Box>
        // </Modal>
    )
}