import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Modal, Stack } from "@mui/material";
import axios from "axios";
import apiList from "pages/authentication/lib/apiList";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import "./style/index.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { SetPopupContext } from "routes";

function ResumeViewer({
  data: { url, applicantName = "" },
  handleClose = () => undefined,
  open = false,
}) {
  const [fileUrl, setFileUrl] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const setPopup = useContext(SetPopupContext);
  useEffect(() => {
    if (url && !url.includes("https://")) {
      axios
        .post(
          apiList.s3fileUrl,
          { fileKey: url },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setFileUrl(res.data.url);
        });
    } else {
      setFileUrl(url);
    }
  }, [url]);
  const [fileType] = url?.split(".")?.slice(-1);
  const downloadHandler = () => {
    setDownloading(true);
    axios
      .post(
        apiList.s3fileUrl + "/download",
        { fileKey: fileUrl },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const { data, contentType } = res.data;
        const binaryData = new Uint8Array(data.data).buffer;
        const blob = new Blob([binaryData], {
          type: contentType,
        });
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("href", url);
        downloadLink.setAttribute(
          "download",
          `${applicantName}-resume.${fileType}`
        );
        downloadLink.click();
        downloadLink.remove();
      })
      .catch((err) => {
        console.log(err);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message ?? "Failed to download file!",
        });
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          minWidth: "500px",
          "@media(max-width:768px)": {
            minWidth: "80%",
          },
        }}
      >
        <Stack
          sx={{
            width: "100%",
            height: "40px",
            background: "#1b2a59",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <button
            className="download"
            onClick={downloadHandler}
            disabled={downloading}
          >
            {downloading ? (
              <CircularProgress
                size={15}
                sx={{
                  position: "absolute",
                  "& svg": {
                    color: "gray",
                  },
                }}
              />
            ) : (
              <CloudDownloadOutlinedIcon />
            )}
          </button>
          <button
            className="cancel"
            onClick={(e) => {
              e.stopPropagation();
              handleClose(undefined);
            }}
          >
            <CloseRoundedIcon
              sx={{
                fontSize: "18px",
                color: "#1b2a59",
              }}
            />
          </button>
        </Stack>
        <Box
          pb={"32px"}
          sx={{ overflowY: "scroll", height: "calc(100vh - 205px)" }}
        >
          {!!fileUrl && (
            <DocViewer
              config={{
                pdfZoom: {
                  defaultZoom: 1.5,
                },
              }}
              documents={[
                {
                  uri: fileUrl,
                  fileType: fileType,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default ResumeViewer;
