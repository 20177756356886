import { Outlet, useLocation, Navigate } from "react-router-dom";

const ProtectedRoute = ({}) => {
  const location = useLocation();
  const user = localStorage.getItem("token");

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={location} replace={true} />
  );
};

export default ProtectedRoute;