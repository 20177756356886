import { useContext, useEffect, useRef, useState } from "react";
// import {
//   Button,
//   Grid,
//   Typography,
//   Modal,
//   Paper,
//   makeStyles,
//   TextField,
//   MenuItem,
// } from "@material-ui/core";
import axios from "axios";
// import ChipInput from "material-ui-chip-input";

//import { SetPopupContext } from "../../App";
import { SetPopupContext } from "../../../routes/index";

import apiList, { server } from "../../authentication/lib/apiList";
//import apiList from "../../lib/apiList";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  // makeStyles,
} from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import ReactHelmet from "component/react-helmet/ReactHelmet";
import countries from "../../../asset/countryList.json";
import ScreeningQuestionModal from "./ScreeningQuestionModal";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   body: {
//     height: "inherit",
//   },
//   popupDialog: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     // padding: "30px",
//   },
// }));

const CreateJobs = (props) => {
  // const classes = useStyles();
  const setPopup = useContext(SetPopupContext);
  const [jobTitle, setJobTitle] = useState("");
  const [jobTitleError , setJobTitleError] = useState(false);
  const [selectedCounty, setSelectedCountry] = useState(null);
  const [openScreeningModal, setOpenScreeningModal] = useState(false);
  const [screeningQuestions, setScreeningQuestions] = useState({});
  const [screeningAdded, setScreeningAdded] = useState(false);
  const [multipleErrors, setMultipleErrors] = useState(false);

  const [jobDetails, setJobDetails] = useState({
    title: "",
    maxApplicants: 100,
    experience: 0,
    maxPositions: 1,
    deadline: new Date(
      new Date()
      // .getTime() + 10 * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .substr(0, 16),
    skillsets: [],
    jobType: "Full Time",
    // employeeProfile: "",
    // EmployeeJobrole: "",
    // employeeCompany: "",
    // employeeWeb: "",
    duration: "",
    salary: 0,
    description:"",
    experience: 0,
    workplaceType: "",
    jobLocationCountry: "",
    jobLocationCity: "",
    preferredCountry: ""
  });

  useEffect(()=>{
    if(jobDetails.workplaceType==="Remote"){
      setJobDetails({
        ...jobDetails,
        jobLocationCountry: ""
      })
    }
  }, [jobDetails.workplaceType])

  const JobTitleApi = [
    {
      id:1,
      input:"Data Entry Support",
      skills: ["Typing Speed and Accuracy", "Attention to detail", "Time Management", "Technical Profiency", "Communication Skills"]
    },{
      id:2,
      input:"Accounting Support",
      skills: ["Bookkeeping", "Attention to Detail", "Software Proficiency", "Analytical Skills", "Communication Skills"]
    
    },
    {
      id:3,
      input:"Documentation Support",
      skills: ["Technical Writing", "Organization and Information Management", "Attention to Detail", "Collaboration and Communication", "Software Proficiency"]
    },
    {
      id:4,
      input:"Custom Process Support",
      skills: ["Business Process Analysis", "Problem-Solving", "Stakeholder Management", "Project Management", "Data Analysis"]
    },
    {
      id:5,
      input:"Air/Sea Process Support",
      skills: ["International Trade and Regulations", "Logistics and Supply Chain Management", "Communication and Coordination", "Documentation and Record Keeping", "Decision-Making"]
    },
    {
      id:6,
      input:"Import/Export Process Support", 
      skills: ["International Trade Regulations", "Documentation", "Logistics and Supply Chain Management", "Communication", "Problem-Solving"]
    },
    {
      id:7,
      input:"Sales Support",
      skills: ["Customer Service", "Interpersonal Skills", "Time Management", "Product and Industry Knowledge", "Technology Proficiency"]
    },
    {
      id:8,
      input:"Customer Service Support",
      skills: ["Communication Skills", "Emotional Intelligence", "Problem-Solving", "Patience and Resilience", "Product and Service Knowledge"]
    },
    {
      id:9,
      input:"CargoWise Consultant",
      skills: ["CargoWise Expertise", "Logistics and Supply Chain", "Project Management", "Problem-Solving", "Communication"]
    },
    {
      id:10,
      input:"CargoWise Support",
      skills: ["CargoWise Proficiency", "Troubleshooting", "Customer Service", "Problem-Solving", "Documentation"]
    },
    {
      id:11,
      input:"ERP Consultant",
      skills: ["ERP System Implementation",
      "Business Process Analysis",
      "Customization and Configuration",
      "Data Migration and Integration",
      "User Training and Support"]
    },
    {
      id:12,
      input:"Power BI Consultant",
      skills: ["Power BI Dashboard Development",
      "Data Modeling and Analysis",
      "DAX Programming",
      "Report Design and Visualization",
      "Client Training and Support"]
    },
    {
      id:13,
      input:"CRM Consultant",
      skills: ["CRM Implementation and Configuration",
      "Data Migration and Integration",
      "User Training and Adoption",
      "Customization and Workflow Automation",
      "CRM System Administration"]
    },
    {
      id:14,
      input:"SAP Consultant",
      skills: ["SAP System Implementation",
      "Business Process Analysis",
      "ABAP",
      "SAP Module Expertise",
      "SAP Customization and Configuration"]
    },
    {
      id:15,
      input:"Web Developer",
      skills: ["HTML5 and CSS3",
      "JavaScript (ES6+)",
      "Front-end Frameworks",
      "Back-end Development",
      "Responsive Design"]
    },
    {
      id:16,
      input:"Front End Developer",
      skills: ["HTML5",
      "CSS3",
      "JavaScript (ES6+)",
      "Responsive Web Design",
      "Front-end Frameworks"]
    },
    {
      id:17,
      input:"Back End Developer",
      skills: ["Server-Side Programming Languages",
      "Database Management",
      "API Development",
      "Authentication and Authorization",
      "Server Management and Deployment"]
    },
    {
      id:18,
      input:"Full Stack Developer",
      skills: ["Front-end Development",
      "Back-end Development",
      "Database Management",
      "API Development and Integration",
      "Version Control/Git"]
    },
    {
      id:19,
      input:"Web Designer",
      skills: ["Graphic Design",
      "User Interface (UI)",
      "Responsive Web Design",
      "Typography and Color Theory",
      "Prototyping and Wireframing"]
    },
    {
      id:20,
      input:"Mobile App Developer",
      skills: ["Mobile App Development (iOS, Android)",
      "Programming Languages",
      "User Interface (UI)",
      "Cross-Platform Development",
      "Mobile App Testing and Debugging"]
    },
    {
      id:21,
      input:"Android Developer",
      skills: ["Android App Development",
      "User Interface (UI)",
      "API Integration",
      "Android Studio",
      "Performance Optimization"]
    },
    {
      id:22,
      input:"IOS Developer",
      skills: ["iOS App Development",
      "User Interface (UI)",
      "Database Management",
      "Xcode and Development Tools",
      "App Store Submission"]
    },{
      id:23,
      input:"Software Engineer",
      skills: ["Programming Languages",
      "Algorithm Design and Problem Solving",
      "Software Development Life Cycle (SDLC)",
      "Version Control",
      "Performance Optimization"]
    },{
      id:24,
      input:"Integration Developer",
      skills: ["API Development and Integration",
      "Data Mapping and Transformation",
      "Message Brokers",
      "Integration Platforms",
      "Troubleshooting and Debugging"]
    },{
      id:25,
      input:"IT Manager",
      skills: ["Strategic Planning",
      "Team Leadership",
      "Project Management",
      "IT Infrastructure Management",
      "Budgeting and Cost Control"]
    },{
      id:26,
      input:"Help Desk Support",
      skills: ["Customer Service",
      "Troubleshooting and Problem-Solving",
      "Operating Systems and Software",
      "Ticketing Systems",
      "Remote Support"]
    },{
      id:27,
      input:"Database Administrator",
      skills: ["Database Management Systems",
      "Database Design and Optimization",
      "SQL (Structured Query Language)",
      "Backup and Recovery Strategies",
      "Security and Access Control"]
    },{
      id:28,
      input:"System Administrator",
      skills: ["Operating Systems Administration",
      "Server Configuration and Maintenance",
      "Network Administration and Troubleshooting",
      "Security Management and Compliance",
      "Scripting and Automation"]
    },{
      id:29,
      input:"IT Project Manager",
      skills: ["Project Planning and Execution",
      "Team Leadership and Management",
      "Stakeholder Communication",
      "Risk Management",
      "Budgeting and Resource Allocation"]
    },{
      id:30,
      input:"RPA Developer",
      skills: ["RPA Platform Proficiency",
      "Process Automation and Optimization",
      "Programming and Scripting",
      "Business Process Analysis",
      "Problem-Solving and Debugging"]
    },{
      id:31,
      input:"AI Developer",
      skills: ["Machine Learning",
      "Programming Languages",
      "Data Preprocessing and Feature Engineering",
      "Deep Learning Frameworks",
      "Natural Language Processing (NLP)"]
    },{
      id:32,
      input:"Cloud Architect",
      skills: ["Cloud Platforms",
      "Infrastructure as Code (IaC)",
      "Microservices Architecture",
      "Security and Compliance",
      "Scalability and Performance Optimization"]
    },{
      id:33,
      input:"Data Analyst",
      skills: ["Data Analysis and Visualization",
      "Statistical Analysis and Modeling",
      "SQL (Structured Query Language)",
      "Data Cleaning and Preprocessing",
      "Tools and Technologies"]
    },{
      id:34,
      input:"Cybersecurity Expert",
      skills: ["Network Security",
      "Incident Response and Forensics",
      "Security Auditing and Compliance",
      "Vulnerability Assessment and Penetration Testing",
      "Security Awareness and Training"]
    },{
      id:35,
      input:"IT Support",
      skills: ["Technical Troubleshooting and Problem-Solving",
      "Customer Service and Communication",
      "Hardware and Software Diagnosis",
      "User Training and Assistance",
      "IT Systems and Networks"]
    },{
      id:36,
      input:"Sales Executive",
      skills: ["Sales Prospecting and Lead Generation",
      "Relationship Building and Networking",
      "Negotiation and Closing Deals",
      "Product Knowledge and Industry Understanding",
      "Sales Analytics and Goal Setting"]
    },{
      id:37,
      input:"Lead Generation",
      skills: ["Market Research and Target Identification",
      "Effective Communication and Outreach",
      "Lead Qualification and Scoring",
      "CRM Tools",
      "Data Analysis and Reporting"]
    },{
      id:38,
      input:"Customer Service",
      skills: ["Effective Communication",
      "Problem-Solving and Conflict Resolution",
      "Empathy and Active Listening",
      "Product Knowledge",
      "Time Management and Multitasking"]
    },{
      id:39,
      input:"Sales Manager",
      skills: ["Sales Leadership and Team Management",
      "Strategic Planning and Goal Setting",
      "Sales Forecasting and Analysis",
      "Client Relationship Management",
      "Performance Coaching and Development"]
    },{
      id:40,
      input:"Marketing Specialist",
      skills: ["Digital Marketing Strategy",
      "Content Creation and Copywriting",
      "Social Media Management",
      "Data Analysis and Reporting",
      "Marketing Campaign Planning and Execution"]
    },{
      id:41,
      input:"SEO Specialist",
      skills: ["Keyword Research and Analysis",
      "On-Page SEO Optimization",
      "Off-Page SEO Strategies",
      "SEO Tools",
      "Content Strategy for SEO"]
    },{
      id:42,
      input:"Social Media Manager",
      skills: ["Social Media Strategy Development",
      "Content Creation and Curation",
      "Community Management",
      "Analytics and Reporting",
      "Social Media Advertising"]
    },{
      id:43,
      input:"Social Media Specialist",
      skills: ["Social Media Platform Expertise",
      "Content Creation and Curation",
      "Social Media Analytics",
      "Community Engagement",
      "Campaign Planning and Execution"]
    },
  ];


  const jobHandleFunction = (e) => {
    const jobInputData = e.target.value;
    const newJobTitleInput = e.target.value.toLowerCase();
    setJobDetails({
      ...jobDetails,
      title: jobInputData,
    });

    const filterData = JobTitleApi.filter((element) => {
      if (
        newJobTitleInput.length === null ||
        newJobTitleInput.length === 1 ||
        newJobTitleInput.length === 2 ||
        newJobTitleInput === "" ||
        newJobTitleInput.length === 0
      ) {
        return "";
      } else {
        return element.input.toLocaleLowerCase().includes(newJobTitleInput);
      }
    });
    setJobTitle(filterData);
    console.log(jobDetails.title.length);
    console.log(jobTitle);
    if (
      jobDetails.title.length === null ||
      jobDetails.title.length === 0 ||
      jobDetails.title.length === 1 ||
      jobTitle === ""
    ) {
      const jobid = document.getElementById("jobTitleId");
      jobid.style.height = "0rem";
    } else {
      const jobid = document.getElementById("jobTitleId");
      jobid.style.height = "8rem";
      jobid.style.overflowY = "scroll";
      if (filterData.length === 0) {
        const jobid = document.getElementById("jobTitleId");
        jobid.style.height = "0rem";
      }
    }
  };

  const jobTitleInputFunction = (data) => {
    // setJobDetails({
    //   ...jobDetails,
    //   title: data,
    // });
    const selectedJobTitle = JobTitleApi.filter(job=>job.input===data);
    setJobDetails({
      ...jobDetails,
      title: selectedJobTitle[0].input,
      skillsets: selectedJobTitle[0].skills
    })
    setJobTitle("");
    const jobid = document.getElementById("jobTitleId");
    jobid.style.height = "0rem";
  };
  const handleInput = (key, value) => {
    setJobDetails({
      ...jobDetails,
      [key]: value,
    });
    // console.log(jobDetails);
  };

  const handleUpdate = () => {
    console.log(jobDetails);
    axios
      .post(apiList.jobs, {...jobDetails, screeningQuestions}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setPopup({
          open: true,
          severity: "success",
          message:"Thank You! We'll be in touch soon." ,
        });
        setJobDetails({
          title: "",
          experience: 0,
          maxApplicants: 100,
          maxPositions: 1,
          deadline: new Date(
            new Date()
            // .getTime() + 10 * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .substr(0, 16),
          skillsets: [],
          jobType: "Full Time",
          // employeeProfile: "",
          EmployeeJobRole: "",
          employeeCompany: "",
          employeeWeb: "",
          duration: "",
          salary: 0,
          description:"",
          workplaceType: "",
          jobLocationCountry: "",
          jobLocationCity: "",
          preferredCountry: ""
        });
        setOpenScreeningModal(false);
        setScreeningQuestions({});
      })
      .catch((err) => {
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        console.log(err.response);
      });
  };

  const handleNext = ()=>{
    setMultipleErrors(false);
    if(jobDetails.title === "" ){
      setJobTitleError(true);
      return;
    }else if(jobDetails.workplaceType===""){
      setMultipleErrors(true);
      return;
    }else if(jobDetails.workplaceType==="Remote" && !jobDetails.preferredCountry){
      setMultipleErrors(true);
      return;
    }else if((jobDetails.workplaceType==="On-Site" || 
      jobDetails.workplaceType==="Hybrid") 
      && !jobDetails.jobLocationCountry){
      setMultipleErrors(true);
      return;
    }else if((jobDetails.workplaceType==="On-Site" || 
      jobDetails.workplaceType==="Hybrid") 
      && !jobDetails.jobLocationCity){
      setMultipleErrors(true);
      return;
    }
    setOpenScreeningModal(true)
  }

  // console.log(jobDetails.experience);
  return (
    <>
      <ReactHelmet 
        title={'Add Job | AMUS HIRING'}
        description={''}
        route='addjob'
      />
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{
          marginTop: "80px",
          padding: "30px",
          minHeight: "93vh",
          "@media(max-width:768px)": {
            // width:"100%",
            // flexDirection:"column"
            // border:"1px solid red"
          },
        }}
      >
        <Grid item>
          <Typography variant="h2">Post a Job</Typography>
        </Grid>
        <Grid item container xs direction="column" justify="center">
          <Grid item>
            <Paper
              elevation={3}
              sx={{
                padding: "30px 20px",
                width: 1000,
                margin: "50px auto",
                "@media(max-width:768px)": {
                  width: "100%",
                  display:'block',
                  margin:'0 auto'
                },
              }}
              // style={{
              //   padding: "20px",
              //   outline: "none",
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
            >
              {!openScreeningModal ?
              <Grid
                container
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  //  border:"1px solid red",
                  "@media(max-width:768px)": {
                    flexDirection: "column",
                    width: "100%",
                    
                  },
                }}
                alignItems="stretch"
                spacing={3}
              >
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Job Title"
                    helperText="Enter more than two character"
                    value={jobDetails.title}
                    onChange={jobHandleFunction}
                    variant="outlined"
                    fullWidth
                    error = {jobTitleError}
                    onBlur={(e) => e.target.value === ""?setJobTitleError(true):setJobTitleError(false)}
                  />

                  <Grid
                    id="jobTitleId"
                    sx={{
                      backgroundColor: "#fff",
                      width: "100%",
                      ":hover": {
                        // backgroundColor:"#ededed"
                      },
                      //  height:"8rem",
                      //  overflowY:"scroll"
                    }}
                  >
                    {jobTitle === ""
                      ? ""
                      : jobTitle.map((element) => {
                          return (
                            <Grid
                              onClick={() =>
                                jobTitleInputFunction(element.input)
                              }
                              sx={{
                                marginTop: "0.5rem",
                                padding: "0.2rem 0.8rem",
                                ":hover": {
                                  backgroundColor: "#ededed",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {element.input}
                            </Grid>
                          );
                        })}
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <MuiChipsInput
                    // className={classes.inputBox}
                    label="Enter Required Skills"
                    variant="outlined"
                    helperText="Press enter to add skills"
                    value={jobDetails.skillsets}
                    onChange={(chip) =>
                      setJobDetails({ ...jobDetails, skillsets: chip })
                    }
                    // onAdd={(chip) =>
                    //   setJobDetails({
                    //     ...jobDetails,
                    //     skillsets: [...jobDetails.skillsets, chip],
                    //   })
                    // }
                    // onDelete={(chip, index) => {
                    //   let skillsets = jobDetails.skillsets;
                    //   skillsets.splice(index, 1);
                    //   setJobDetails({
                    //     ...jobDetails,
                    //     skillsets: skillsets,
                    //   });
                    // }}
                    fullWidth
                  />
                </Grid>
                {/* <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Profile"
                    variant="outlined"
                    value={jobDetails.employeeProfile}
                    onChange={(event) => {
                      handleInput("employeeProfile", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Data Entry Support">
                      Data Entry Support
                    </MenuItem>
                    <MenuItem value="CargoWise Consultant">
                      CargoWise Consultant
                    </MenuItem>
                    <MenuItem value="Web Developer">Web Developer</MenuItem>
                    <MenuItem value="IT Manager">IT Manager</MenuItem>
                    <MenuItem value="Sales Executive">Sales Executive</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </TextField>
                </Grid>

                {jobDetails.employeeProfile === "Data Entry Support" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Accounting Support">
                        Accounting Support
                      </MenuItem>
                      <MenuItem value="Documentation Support">
                        Documentation Support
                      </MenuItem>
                      <MenuItem value="Custom Process Support">
                        Custom Process Support
                      </MenuItem>
                      <MenuItem value="Air/Sea Process Support">
                        Air/Sea Process Support
                      </MenuItem>
                      <MenuItem value="Import/Export Process Support">
                        Import/Export Process Support
                      </MenuItem>
                      <MenuItem value="Sales SupportAccount Executive">
                        Sales Support
                      </MenuItem>
                      <MenuItem value="Customer Service Support">
                        Customer Service Support
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "CargoWise Consultant" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="CargoWise Support">
                        CargoWise Support
                      </MenuItem>
                      <MenuItem value="ERP Consultant">ERP Consultant</MenuItem>
                      <MenuItem value="Power BI Consultant">
                        Power BI Consultant
                      </MenuItem>
                      <MenuItem value="CRM Consultant">CRM Consultant</MenuItem>
                      <MenuItem value="SAP Consultant">SAP Consultant</MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "Web Developer" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Front End Developer">
                        Front End Developer
                      </MenuItem>
                      <MenuItem value="Back End Developer">
                        Back End Developer
                      </MenuItem>
                      <MenuItem value="Full Stack Developer">
                        Full Stack Developer
                      </MenuItem>
                      <MenuItem value="Web Designer">Web Designer</MenuItem>
                      <MenuItem value="Mobile App Developer">
                        Mobile App Developer
                      </MenuItem>
                      <MenuItem value="Android Developer">
                        Android Developer
                      </MenuItem>
                      <MenuItem value="IOS Developer">IOS Developer</MenuItem>
                      <MenuItem value="Software Engineer">
                        Software Engineer
                      </MenuItem>
                      <MenuItem value="Integration Developer">
                        Integration Developer
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "IT Manager" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Help Desk Support">
                        Help Desk Support{" "}
                      </MenuItem>
                      <MenuItem value="Database Administrator">
                        Database Administrator
                      </MenuItem>
                      <MenuItem value="System Administrator">
                        System Administrator
                      </MenuItem>
                      <MenuItem value="IT Project Manager">
                        IT Project Manager
                      </MenuItem>
                      <MenuItem value="RPA Developer">RPA Developer</MenuItem>
                      <MenuItem value="AI Developer">AI Developer</MenuItem>
                      <MenuItem value="Cloud Architect">
                        Cloud Architect
                      </MenuItem>
                      <MenuItem value="Data Analyst">Data Analyst</MenuItem>
                      <MenuItem value="Cybersecurity Expert">
                        Cybersecurity Expert
                      </MenuItem>
                      <MenuItem value="IT Support">IT Support</MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "Sales Executive" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Lead Generation">
                        Lead Generation{" "}
                      </MenuItem>
                      <MenuItem value="Customer Service">
                        Customer Service
                      </MenuItem>
                      <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                      <MenuItem value="Marketing Specialist">
                        Marketing Specialist
                      </MenuItem>
                      <MenuItem value="SEO Specialist">SEO Specialist</MenuItem>
                      <MenuItem value="Social Media Manager">
                        Social Media Manager
                      </MenuItem>
                      <MenuItem value="Social Media Specialist">
                        Social Media Specialist
                      </MenuItem>
                    </TextField>
                  </Grid>
                ) : jobDetails.employeeProfile === "Others" ? (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    ></TextField>
                  </Grid>
                ) : (
                  <Grid
                    item
                    sx={{
                      width: "40%",
                      "@media(max-width:768px)": {
                        width: "100%",
                        marginLeft: "0.7rem",
                      },
                    }}
                  >
                    <TextField
                      select
                      label="Select Role"
                      variant="outlined"
                      value={jobDetails.EmployeeJobrole}
                      onChange={(event) => {
                        handleInput("EmployeeJobrole", event.target.value);
                      }}
                      fullWidth
                    >
                      <MenuItem value="Accounting Support">
                        Accounting Support
                      </MenuItem>
                      <MenuItem value="Documentation Support">
                        Documentation Support
                      </MenuItem>
                      <MenuItem value="Custom Process Support">
                        Custom Process Support
                      </MenuItem>
                      <MenuItem value="Air/Sea Process Support">
                        Air/Sea Process Support
                      </MenuItem>
                      <MenuItem value="Import/Export Process Support">
                        Import/Export Process Support
                      </MenuItem>
                      <MenuItem value="Sales SupportAccount Executive">
                        Sales Support
                      </MenuItem>
                      <MenuItem value="Customer Service Support">
                        Customer Service Support
                      </MenuItem>
                      <MenuItem value="CargoWise Support">
                        CargoWise Support
                      </MenuItem>
                      <MenuItem value="ERP Consultant">ERP Consultant</MenuItem>
                      <MenuItem value="Power BI Consultant">
                        Power BI Consultant
                      </MenuItem>
                      <MenuItem value="CRM Consultant">CRM Consultant</MenuItem>
                      <MenuItem value="SAP Consultant">SAP Consultant</MenuItem>
                      <MenuItem value="Front End Developer">
                        Front End Developer
                      </MenuItem>
                      <MenuItem value="Back End Developer">
                        Back End Developer
                      </MenuItem>
                      <MenuItem value="Full Stack Developer">
                        Full Stack Developer
                      </MenuItem>
                      <MenuItem value="Web Designer">Web Designer</MenuItem>
                      <MenuItem value="Mobile App Developer">
                        Mobile App Developer
                      </MenuItem>
                      <MenuItem value="Android Developer">
                        Android Developer
                      </MenuItem>
                      <MenuItem value="IOS Developer">IOS Developer</MenuItem>
                      <MenuItem value="Software Engineer">
                        Software Engineer
                      </MenuItem>
                      <MenuItem value="Integration Developer">
                        Integration Developer
                      </MenuItem>
                      <MenuItem value="Help Desk Support">
                        Help Desk Support{" "}
                      </MenuItem>
                      <MenuItem value="Database Administrator">
                        Database Administrator
                      </MenuItem>
                      <MenuItem value="System Administrator">
                        System Administrator
                      </MenuItem>
                      <MenuItem value="IT Project Manager">
                        IT Project Manager
                      </MenuItem>
                      <MenuItem value="RPA Developer">RPA Developer</MenuItem>
                      <MenuItem value="AI Developer">AI Developer</MenuItem>
                      <MenuItem value="Cloud Architect">
                        Cloud Architect
                      </MenuItem>
                      <MenuItem value="Data Analyst">Data Analyst</MenuItem>
                      <MenuItem value="Cybersecurity Expert">
                        Cybersecurity Expert
                      </MenuItem>
                      <MenuItem value="IT Support">IT Support</MenuItem>
                      <MenuItem value="Lead Generation">
                        Lead Generation{" "}
                      </MenuItem>
                      <MenuItem value="Customer Service">
                        Customer Service
                      </MenuItem>
                      <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                      <MenuItem value="Marketing Specialist">
                        Marketing Specialist
                      </MenuItem>
                      <MenuItem value="SEO Specialist">SEO Specialist</MenuItem>
                      <MenuItem value="Social Media Manager">
                        Social Media Manager
                      </MenuItem>
                      <MenuItem value="Social Media Specialist">
                        Social Media Specialist
                      </MenuItem>
                    </TextField>
                  </Grid>
                )} */}
                {/* <Grid item sx={{width:"40%",
              '@media(max-width:768px)':{
                width:"100%",
               marginLeft:"0.7rem"
                
              }}}>
                <TextField
                    select
                    label="Select Role"
                    variant="outlined"
                    value={jobDetails.EmployeeJobrole}
                    onChange={(event) => {
                      handleInput("EmployeeJobrole", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Web developer">Web developer</MenuItem>
                    <MenuItem value="Front End Developer">Front End Developer</MenuItem>
                    <MenuItem value="Back End Developer">Back End Developer</MenuItem>
                    <MenuItem value="Junior Process Executive">Junior Process Executive</MenuItem>
                    <MenuItem value="Sales Support">Sales Support</MenuItem>
                    <MenuItem value="Account Executive">Account Executive</MenuItem>
                    <MenuItem value="Documentation Executive">Documentation Executive</MenuItem>

                    <MenuItem value="Cargowise Consultant"> Cargowise Consultant</MenuItem>
                    <MenuItem value="Cargowise Support">Cargowise Support</MenuItem>
                    <MenuItem value="ERP Consultant">ERP Consultant</MenuItem>
                    <MenuItem value="BI Consultant">BI Consultant</MenuItem>
                    <MenuItem value="CRM Consultant">CRM Consultant</MenuItem>

                  </TextField>
                </Grid> */}
                <Grid
                  item
                  sx={{
                    width: jobDetails.workplaceType==="Remote" ? "40%" : "80%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Workplace Type"
                    variant="outlined"
                    value={jobDetails.workplaceType}
                    onChange={(event) => {
                      handleInput("workplaceType", event.target.value);
                    }}
                    fullWidth
                    error={multipleErrors && !jobDetails.workplaceType}
                    helperText={multipleErrors && "Select workplace type"}
                    onFocus={()=>setMultipleErrors(false)}
                  >
                    <MenuItem value="Remote">Remote</MenuItem>
                    <MenuItem value="On-Site">On-Site</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                  </TextField>
                </Grid>
                {jobDetails.workplaceType==="Remote" && 
                <Grid
                item
                sx={{
                  width: "40%",
                  "@media(max-width:768px)": {
                    width: "100%",
                    marginLeft: "0.7rem",
                  },
                }}
                >
                  <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => option ? option.name : ""}
                  value={selectedCounty}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setJobDetails({
                      ...jobDetails,
                      preferredCountry: newValue && newValue.name ? newValue.name : ""
                    })
                    setSelectedCountry(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} label="Preferred Employee Location Country" variant="outlined" 
                  error={multipleErrors && !jobDetails.preferredCountry}
                  helperText={multipleErrors && "Select Preffered Country"}
                  onFocus={()=>setMultipleErrors(false)}
                  />}
                  />
                </Grid>
                }
                {
                  (jobDetails.workplaceType==="On-Site" || jobDetails.workplaceType==="Hybrid") &&
                  <> 
                  <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                  >
                    <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option ? option.name : ""}
                    value={selectedCounty}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setJobDetails({
                        ...jobDetails,
                        jobLocationCountry: newValue && newValue.name ? newValue.name : ""
                      })
                      setSelectedCountry(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label="Search & Select Job Location Country" variant="outlined" 
                    error={multipleErrors && !jobDetails.jobLocationCountry}
                    helperText={multipleErrors && "Select job location country"}
                    onFocus={()=>setMultipleErrors(false)}
                    />}
                    />
                  </Grid>
                  <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Job Location City"
                    variant="outlined"
                    value={jobDetails.jobLocationCity}
                    onChange={(event) => {
                      handleInput("jobLocationCity", event.target.value);
                    }}
                    fullWidth
                    error={multipleErrors && !jobDetails.jobLocationCity}
                    helperText={multipleErrors && "Select Job Location City"}
                    onFocus={()=>setMultipleErrors(false)}
                  />
                </Grid>
                </>
                }
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Job Type"
                    variant="outlined"
                    value={jobDetails.jobType}
                    onChange={(event) => {
                      handleInput("jobType", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                    <MenuItem value="Internship">Internship</MenuItem>
                  </TextField>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    select
                    label="Select Expected Duration"
                    variant="outlined"
                    value={jobDetails.duration}
                    onChange={(event) => {
                      handleInput("duration", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Flexible">Flexible</MenuItem>
                    <MenuItem value="Less than 1 month">
                      Less than 1 month
                    </MenuItem>
                    <MenuItem value="1 - 3 months">1 - 3 months</MenuItem>
                    <MenuItem value="3 - 6 months">3 - 6 months</MenuItem>
                    <MenuItem value="6 mon - 1 yr">6 mon - 1 yr</MenuItem>
                    <MenuItem value="more than 1 yr">more than 1+ yr</MenuItem>
                    {/* <MenuItem value={6}>6 year</MenuItem> */}
                  </TextField>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Required Experience Level"
                    type="number"
                    variant="outlined"
                    value={jobDetails.experience}
                    onChange={(event) => {
                      handleInput("experience", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter No. of Positions Available"
                    type="number"
                    variant="outlined"
                    value={jobDetails.maxPositions}
                    onChange={(event) => {
                      handleInput("maxPositions", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 1 } }}
                    fullWidth
                  />
                </Grid>

                {/* <Grid item sx={{width:"40%"}}> 
                  <TextField
                    select
                    label="Type of Company"
                    variant="outlined"
                    value={jobDetails.employeeCompany}
                    onChange={(event) => {
                      handleInput("employeeCompany", event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value="Startup">Startup</MenuItem>
                    <MenuItem value="SME">SME</MenuItem>
                    <MenuItem value="Enterprise">Enterprise</MenuItem>
                  </TextField>
                </Grid> */}
                {/* <Grid item sx={{width:"40%"}}> 
                <TextField
                    
                    label="Enter web address"
                    value={jobDetails.employeeWeb}
                    onChange={(event) =>
                      handleInput("employeeWeb", event.target.value)
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid> */}

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Expected Deadline For Hiring"
                    // type="datetime-local"
                    type="date"
                    value={jobDetails.deadline}
                    onChange={(event) => {
                      handleInput("deadline", event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0.7rem",
                    },
                  }}
                >
                  <TextField
                    label="Enter Monthly Pay Budget (USD)"
                    type="number"
                    variant="outlined"
                    value={jobDetails.salary}
                    onChange={(event) => {
                      handleInput("salary", event.target.value);
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                  />
                </Grid>
                <Grid item sx={{
                  width:"80%",
                  "@media (max-width: 768px)":{
                  width: "100%",
                  marginLeft: "0.7rem"
                  }
                }}  >
                <TextField
                    label="Enter Job Description"
                    type="text"
                    variant="outlined"
                    multiline
                    rows={5}
                    onChange={(event) => {
                      handleInput("description", event.target.value)
                    }}
                    value={jobDetails.description}
                    fullWidth
                  />
                  <Box display='flex'
                  sx={{width:'100%', justifyContent:'flex-end'}}
                  >
                    <Button
                    variant="contained"
                    sx={{bgcolor:'#1B2A59', 
                    color:'#fff', 
                    margin:'2rem 0',
                    borderRadius: 3,
                    pl:2,
                    pr:2,
                    '&:hover': {
                      bgcolor:'#1B2A59'
                    },
                    "@media(max-width:768px)": {
                      width: "100%",
                      marginLeft: "0rem",
                    },
                    }}
                    onClick={handleNext}
                    >Next</Button>
                  </Box>
                </Grid>
              </Grid>
              :
              <Grid
                container
                sx={{
                  flexDirection: "row",
                  justifyContent: "center",
                  //  border:"1px solid red",
                  "@media(max-width:768px)": {
                    flexDirection: "column",
                    width: "100%",
                    
                  },
                }}
                alignItems="stretch"
                // spacing={3}
              >
                <ScreeningQuestionModal 
                open={openScreeningModal}
                setOpen={setOpenScreeningModal}
                screeningQuestions={screeningQuestions} 
                setScreeningQuestions={setScreeningQuestions}
                jobDetails={jobDetails}
                handleUpdate={handleUpdate}
                screeningAdded={screeningAdded}
                setScreeningAdded={setScreeningAdded}
                />
              </Grid>
              }
              {/* <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 50px",
                  marginTop: "30px",
                  marginLeft: "408px",
                  "@media(max-width:768px)": {
                    width: "100%",
                    marginLeft: "0rem",
                  },
                }}
                onClick={() => handleUpdate()}
              >
                Create Job
              </Button> */}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
     
    </>
  );
};

export default CreateJobs;
